import React from 'react';
import { Routes, Route } from "react-router-dom";

// All Layout files
import LoginLayout from '../Layout/Loginindex';
import CustomerWithoutLoginindex from '../Layout/CustomerWOLindex';  
import Customerindex from '../Layout/CustomerWLindex';  
import AdminLayout from '../Layout/Adminindex';
import NewStylistLayout from '../Layout/NewStylistindex';
import StylistLayout from '../Layout/Stylistindex';
import TipLayout from "../Layout/Tipindex";
import StylistBookingDetailsLayout from "../Layout/StylistBookingDetailsIndex";
import AcceptRejectLayout from "../Layout/AcceptRejectIndex";
import PolicyLayout from "../Layout/Policyindex";

// All portal register and login component import
import AdminLogin from '../Components/Login/Admin/AdminLogin'; 
import AdminForgotpassword from '../Components/Login/Admin/AdminForgotpassword'; 
import StylistLogin from '../Components/Login/Stylist/StylistLogin'; 
import StylistForgotpassword from '../Components/Login/Stylist/StylistForgotpassword'; 
import StylistRegistration from '../Components/Login/Stylist/StylistRegistration'; 
import EmailActivation from '../Components/Login/Assets/EmailActivation';
import CustomerLogin from '../Components/Login/Customer/CustomerLogin'; 
import CustomerForgotpassword from '../Components/Login/Customer/CustomerForgotpassword'; 
import CustomerCreateanaccount from '../Components/Login/Customer/CustomerCreateanaccount'; 
import RegisterThanksPage from '../Components/Login/Assets/RegisterThanksPage';

// Customer without login component import
import CustomerHome from '../Components/Customer/CustomerWithoutLogin/Home/Home'; 
import CustomerScanQRcode from '../Components/Customer/CustomerWithoutLogin/QR_Code/ScanQRcode'; 
import CustomerStylist from '../Components/Customer/CustomerWithoutLogin/StylistProfile/Stylist'; 
import CustomerBookingCalendar from '../Components/Customer/CustomerWithoutLogin/BookingCalendar/BookingCalendar';
import OurMission from '../Components/Customer/CustomerWithoutLogin/Mission/mission';
// import SlotBookingCalendar from '../Components/Customer/SlotBookingCalendar';
import SlotBookingCalendar from '../Components/Customer/CustomerWithoutLogin/BookingCalendar/MultiSlotBookingCalender';
import NotForund from '../Components/PageNotFound/PageNotFound404';

// Customer with login component import 
import CustomerBookingHistory from '../Components/Customer/CustomerWithLogin/Appoinments/BookingHistory'; 
import CustomerSettings from '../Components/Customer/CustomerWithLogin/Settings/Settings'; 
import PaymentDetails from '../Components/Customer/CustomerWithLogin/Payment/PaymentDetail'; 
import CustomerBookingHistoryDetails from '../Components/Customer/CustomerWithLogin/Appoinments/BookingHistoryDetails';

// Admin components import
import Dashboard from '../Components/Admin/Dashboard/Dashboard';
import ManageStylist from '../Components/Admin/ManageProfessionals/ManageStylist'; 
import Transactions from '../Components/Admin/Transactions/Transactions';
import ManageProfessions from '../Components/Admin/ManageProfessions/ManageProfessions';  
import ManageServices from '../Components/Admin/ManageServices/ManageServices';  
import CustomerList from '../Components/Admin/CustomerList/CustomerList';
import BookingCalendar from '../Components/Admin/BookingCalendar/BookingCalendar';
import AdminBookingDetails from '../Components/Admin/BookingCalendar/bookingDetail';
import CustomerBookingDetails from '../Components/Admin/CustomerList/CustomerBookingHistory';

// New register professional components import 
import StepOne from '../Components/Stylist/NewProfessionalSetup/StepOne';
import StepTwo from '../Components/Stylist/NewProfessionalSetup/StepTwo';
import StepThree from '../Components/Stylist/NewProfessionalSetup/StepThree';

// professional component import
import StylistDashboard from '../Components/Stylist/Dashboard/Dashboard';
import EarningDetails from '../Components/Stylist/Dashboard/EarningDetails';
import StylistBookingCalendar from '../Components/Stylist/BookingCalendar/BookingCalendar';
import StylistBookingDetails from '../Components/Stylist/BookingCalendar/BookingDetails';
import StylistTransactions from '../Components/Stylist/Transactions/Transactions';
import StylistCustomerList from '../Components/Stylist/CustomerList/CustomerList';
import StylistManageServices from '../Components/Stylist/ManageServices/ManageServices';  
import StylistPortfolio from '../Components/Stylist/Portfolio/AddPortfolio';  
import StylistPortfolioView from '../Components/Stylist/Portfolio/PortfolioView';  
import Stylisettings from '../Components/Stylist/Settings/Settings';
import AdjustUpcomingHours from '../Components/Stylist/ManageAvailability/ManageAvailability';
import AvailabilityII from '../Components/Stylist/ManageAvailability/AdjustHours';
import StylistCustomerBookingDetails from '../Components/Stylist/CustomerList/CustomerBookingHistory';
import AddBookingForStylist from '../Components/Stylist/BookingCalendar/AddBookingForCustomer/CustomerSearch';
// import BookingForCustomer from '../Components/Stylist/AddBookingCalendar';
import BookingForCustomer from '../Components/Stylist/BookingCalendar/AddBookingForCustomer/BookingForCustomerMultiSlotCalendar';
import SelfBooking from '../Components/Stylist/BookingCalendar/SelfBooking/SelfBookingCalendar';
// import EditBooking from '../Components/Stylist/EditBookingCalendar';
import EditBooking from '../Components/Stylist/BookingCalendar/EditBookingForCustomer/EditBookingMultiSlotCalendar';
import EditBookingWithNewService from '../Components/Stylist/BookingCalendar/EditBookingForCustomer/EditBookingNewServiceList';
import SelectServiceForCustomerBooking from '../Components/Stylist/BookingCalendar/AddBookingForCustomer/SelectService';



// Email tips component import
import TipLogin from "../Components/Tip/Login";
import TipIndex from "../Components/Tip/EmailTip";

// Email accept reject component import
import AcceptBooking from "../Components/AcceptReject/AcceptBooking";
import RejectBooking from "../Components/AcceptReject/RejectBooking";
import AcceptRejectLogin from "../Components/AcceptReject/Login";
import NotMe from '../Components/AcceptReject/NotMe';

// Terms and condition & privacy policy page for paypal
import TermsAndConditions from '../Components/terms/TermsAndConditionsPage';
import PrivacyPolicyPage from '../Components/terms/PrivacyPolicyPage';
import CookiesPolicyPage from '../Components/terms/CookiePolicyPage';
import Cancellationpolicy from '../Components/policy/cancellationpolicy';

import GoogleReCaptcha from '../Components/Login/GoogleReCaptch';
import CryptoJSForJson from '../Components/Login/CryptoJSForJson';

export default function StylistRoutes(){

    return(
        <Routes>
            {/* All portal register and login routes */}
            <Route element={<LoginLayout />}>
                <Route path="adminlogin" element={<AdminLogin />} /> 
                <Route path="adminforgotpassword" element={<AdminForgotpassword />} />  
                <Route path="stylistlogin" element={<StylistLogin />} /> 
                <Route path="stylistforgotpassword" element={<StylistForgotpassword />} /> 
                <Route path="stylistregistration" element={<StylistRegistration />} /> 
                <Route path="emailactivation/:type/:code" element={<EmailActivation />} /> 
                <Route path="customerlogin" element={<CustomerLogin />} />  
                <Route path="customerforgotpassword" element={<CustomerForgotpassword />} />
                <Route path="customercreateanaccount" element={<CustomerCreateanaccount />} />  
                <Route path="thanks" element={<RegisterThanksPage />} />      
            </Route>

            {/* Customer without login routes */}
            <Route element={<CustomerWithoutLoginindex />}>
                <Route path="/" element={<CustomerHome />} /> 
                <Route path="customer/" element={<CustomerHome />} /> 
                <Route path="customer/home" element={<CustomerHome />} /> 
                <Route path="customer/scanqrcode" element={<CustomerScanQRcode />} /> 
                <Route path="customer/stylist/:name/:id" element={<CustomerStylist />} />  
                <Route path="customer/bookingcalendar-old/:clientId/:productId" element={<CustomerBookingCalendar />} />
                <Route path="customer/bookingcalendar/:clientId/:productId" element={<SlotBookingCalendar />} />
                <Route path='/mission' element={<OurMission />}  />
            </Route>

            {/* Customer with login routes */}
            <Route element={<Customerindex />}> 
                <Route path="customer/bookinghistory" element={<CustomerBookingHistory />} /> 
                <Route path="customer/bookinghistorydetails" element={<CustomerBookingHistoryDetails />} /> 
                <Route path="customer/settings" element={<CustomerSettings />} />
                <Route path="customer/payment" element={<PaymentDetails />} />
            </Route>

            {/* Admin routes */}
            <Route element={<AdminLayout />}> 
                <Route path="admin/" element={<Dashboard />} />
                <Route path="admin/dashboard" element={<Dashboard />} />
                <Route path="admin/managestylist" element={<ManageStylist />} />
                <Route path="admin/bookingcalendar" element={<BookingCalendar/>} />
                <Route path="admin/transactions" element={<Transactions />} />
                <Route path="admin/manageprofessions" element={<ManageProfessions />} />
                <Route path="admin/manageservices" element={<ManageServices />} />
                <Route path="admin/customerlist" element={<CustomerList />} /> 
                <Route path='admin/bookingdetails' element={<AdminBookingDetails />} /> 
                <Route path='admin/CustomerBookingDetails/:customerId' element={<CustomerBookingDetails />} /> 
            </Route>

            {/* New register professional routes */}
            <Route element={<NewStylistLayout />}>
                <Route path="stylist/step-I" element={<StepOne />} />
                <Route path="stylist/step-II" element={<StepTwo />} />
                <Route path="stylist/step-III" element={<StepThree />} />
            </Route>

            {/* Professional routes */}
            <Route element={<StylistLayout />}>
                <Route path="stylist/" element={<StylistDashboard />} />
                <Route path="stylist/dashboard" element={<StylistDashboard />} />
                <Route path="stylist/myearning/:type" element={<EarningDetails />} />
                <Route path="stylist/bookingcalendar" element={<StylistBookingCalendar/>} />
                <Route path="stylist/configurebookingslot" element={<AdjustUpcomingHours/>} />
                <Route path="stylist/transactions" element={<StylistTransactions />} />
                <Route path="stylist/customerlist" element={<StylistCustomerList />} />  
                <Route path="stylist/manageservices" element={<StylistManageServices />} />
                <Route path="stylist/portfolio" element={<StylistPortfolio />} />
                <Route path="stylist/portfolioview" element={<StylistPortfolioView />} />
                <Route path="stylist/settings" element={<Stylisettings />} />
                <Route path='stylist/upcominghours' element={<AdjustUpcomingHours />} />
                <Route path="stylist/availability-II" element={<AvailabilityII />} />
                <Route path='stylist/CustomerBookingDetails/:customerId' element={<StylistCustomerBookingDetails />} /> 
                <Route path='stylist/addbooking' element={<AddBookingForStylist/>} />
                <Route path='stylist/bookingforcustomer/:productId' element={<BookingForCustomer/>} />
                <Route path='stylist/selfbooking' element={<SelfBooking/>} />
                <Route path='stylist/selectservice' element={<SelectServiceForCustomerBooking/>} />
            </Route>

            {/* Professional booking details routes */}
            <Route element={<StylistBookingDetailsLayout />}>
                <Route path="stylist/bookingdetails" element={<StylistBookingDetails/>} />
                <Route path='stylist/editbooking/:clientId/:productId/:customerId/:bookingId' element={<EditBooking/>} />
                <Route path='stylist/editbookingwithservice' element={<EditBookingWithNewService/>} />
            </Route>

            {/* Email tips routes */}
            <Route element={<TipLayout />} >
                <Route path="tiplogin/:customerId/:bookingId"  element={<TipLogin />} />
                <Route path="emailtip/:customerId/:bookingId" element={<TipIndex />} />
                <Route path="terms&conditions" element={<TermsAndConditions />} />
                <Route path="privacypolicy" element={<PrivacyPolicyPage />} />
                <Route path="cookiespolicy" element={<CookiesPolicyPage />} />
            </Route>

             {/* Policy routes */}
             <Route element={<PolicyLayout />} >
                <Route path="cancellationpolicy" element={<Cancellationpolicy />} />
            </Route>


            {/* Email Accept Reject routes */}
            <Route element={<AcceptRejectLayout />} >
                <Route path="acceptbooking/:customerId/:bookingId"  element={<AcceptBooking />} />
                <Route path="rejectbooking/:customerId/:bookingId" element={<RejectBooking />} />
                <Route path="acceptrejectlogin/:customerId/:bookingId/:status"  element={<AcceptRejectLogin />} />
                <Route path="notmybooking/:customerId/:bookingId"  element={<NotMe />} />
            </Route>

            {/* <Route path="CapthaCheck"  element={<GoogleReCaptcha />} /> */}
            <Route path="jsonConvert"  element={<CryptoJSForJson />} />

            <Route path='*' element={<NotForund />} />
        </Routes>
    )
}