import { React, useState, useEffect } from "react";
import { Container, Image, Navbar, Offcanvas, Nav, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate} from "react-router-dom";
import Logo from '../../../images/logo1.png';
import { CalendarMonth, DashboardCustomizeRounded, DesignServices, AutoAwesomeMotion, List, Person2Sharp, Autorenew, Settings } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import { setStylistLogout } from '../../../reducers/User/action';
import Swal from "sweetalert2";
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import person from "../../../images/person.png";
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { postClientActiveStatus } from '../../../Service';

const Dashboard = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [stickyClass, setStickyClass] = useState('');

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => setShow(true);

    const doLogout = (popupState)=>{
        popupState.close();
        Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to Log Out?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log Out!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setStylistLogout());
                navigate("/stylistlogin");
            }
        })  
    }

    let loggedUser = useSelector(store => store.user['stylistuser']);

    const apiToken = useSelector(store => store.user['stylisttoken']);

    useEffect(() => {
        let stylistTokenStatus = {'jwtToken' : apiToken};
        postClientActiveStatus(stylistTokenStatus).then(res => userStatusCheck(res));
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const userStatusCheck = (res) => {
        if(res.data.statusCode == 200){
            if(res.data.paypalVerifiedClient === false){
                dispatch(setStylistLogout());
                navigate("/stylistlogin");
            }
        }
    }

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 60 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    }

    return(
        <div className="leftbar">
            <Navbar  navbar="dark" expand="md"  className={`${stickyClass}`}>
                <Container className="navContainer">
                    <Navbar.Brand>
                        <Link to="../stylist/dashboard"><Image src={Logo} /></Link>
                    </Navbar.Brand>
                    <div className="pro-mobile-view">
                        <PopupState variant="popover" popupId="demo-popup-popover" >
                            {(popupState) => (
                                <div>
                                    <Button variant="" {...bindTrigger(popupState)}>
                                        <div className="pro-sec">
                                            <div className="top-profile"> <Image src={person} />
                                                <span className="active circle"></span>
                                            </div>
                                        </div>
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                        }}
                                    >
                                        <Typography sx={{ p: 2 }}> 
                                            <Row className="profile-dropdown">
                                                <Col>
                                                    <Image src={person} /> 
                                                    <h4>{loggedUser ? loggedUser.firstName+' '+loggedUser.lastName : ''}</h4>
                                                    <h6>{loggedUser ? loggedUser.emailAddress : ''}</h6>
                                                </Col>
                                            </Row> 
                                            <hr/>
                                            <Row className="profile-link">
                                                <Col>
                                                    <div className="text-center">
                                                        <a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Sign Out</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Typography>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>     
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={handleShow}/>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-md`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                        placement="end"
                        show={show} 
                        onHide={handleClose}
                        className="adminOffCanvas"
                    >
                        <Offcanvas.Header>
                            <button className="offCanvasCloseBtn" closeButton onClick={handleClose}>
                                <CloseIcon />
                            </button>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                <Navbar.Brand>
                                    <Link to="../stylist/dashboard">
                                        <Image src={Logo} />
                                    </Link>
                                </Navbar.Brand>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Link to="../stylist/dashboard"><DashboardCustomizeRounded />Dashboard</Link>
                                <Link to="../stylist/bookingcalendar"><CalendarMonth />Booking Calendar</Link>
                                <Link to="../stylist/configurebookingslot"><Person2Sharp />Manage Availability</Link>
                                <Link to="../stylist/transactions"><Autorenew />Transactions</Link>
                                <Link to="../stylist/customerlist"><List/>Customer List</Link>
                                <Link to="../stylist/manageservices"><DesignServices />Manage Services</Link>
                                <Link to="../stylist/portfolioview"><AutoAwesomeMotion />Portfolio</Link>
                                <Link to="../stylist/settings"><Settings/>Settings</Link>
                            </Nav>
                        </Offcanvas.Body> 
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default Dashboard;