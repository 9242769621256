import { React, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { updateService } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function EditServicePopup(props){
    // set props data into variable
    let professionList = props.professionList;
    let serviceData = props.serviceData;

    // state declaration
    const updateInitialValues = {name : serviceData.name, professionId : serviceData.professionId, description : serviceData.description, serviceId : serviceData.id};
    const [updateFormValues, setUpdateformValues] = useState(updateInitialValues);
    const [updateFormErrors, setUpdateFormErrors] = useState({}); 
    const [popupShow, setPopupShow] = useState(true);
    const [formSubmitLoader, setFormSubmitLoader] = useState(false);

    // popup close
    const popupClose = () => {
        setPopupShow(false);
        setUpdateFormErrors({});
        setUpdateformValues(updateInitialValues);
        props.getBack(true, false);
    }

    // input field onchange
    const updateHandleChange = (e)=> {
        const value=e.target.value;
        const field=e.target.name;
        setUpdateformValues(prevState => ({
            ...prevState,
            [field]: value  }
        ));
    }

    // edit service form submit
    const updateHandleSubmit = (e) => {
        e.preventDefault();
        const errorCount = inputFieldValidate(updateFormValues);
        setUpdateFormErrors(errorCount);
        if(errorCount.status){
            updateService(updateFormValues).then(res => updatehandleResponse(res));
            setFormSubmitLoader(true);
        }
    } 

    // edit service api response
    const updatehandleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Service Updated successfully',
                showConfirmButton: false,
                timer: 3000
            });
            setFormSubmitLoader(false);
            setPopupShow(false);
            setUpdateFormErrors({});
            setUpdateformValues(updateInitialValues);
            props.getBack(false, true);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: response.data.statusMessage,
                timer: 3000
            });
            setFormSubmitLoader(false); 
        }
    }

    // form validation
    const inputFieldValidate = (values)=>{
        const errors = {};
        errors.status = true; 
    
        if(!values.name || values.name.trim() === ""){
          errors.name='Service name is required';
          errors.status=false;
        }
    
        if(!values.professionId){
            errors.professionId='Profession is required';
            errors.status=false;
        }

        const validations = {
            name: HtmlTagValidation(values.name),
            description: HtmlTagValidation(values.description)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });
    
        return errors;
    }

    return(
        <Modal show={popupShow} onHide={popupClose} className="custom-modal">
            <Form onSubmit={updateHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>   
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Service Name <span className="star">*</span></Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name="name" defaultValue={updateFormValues.name} onChange={updateHandleChange}/>
                            <p className="error">{updateFormErrors.name}</p>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Profession <span className="star">*</span></Form.Label>
                            <Form.Select aria-label="Default select example" name="professionId" onChange={updateHandleChange} disabled style={{cursor : 'not-allowed'}}>
                                <option>Select an option...</option>
                                {
                                    professionList.length > 0 ?
                                        professionList.map((data, index) => (
                                            <option value={data.id} selected={updateFormValues.professionId === data.id ? 'selected' : ''} key={index}>{data.name}</option>
                                        ))
                                    : 
                                        ''
                                }
                            </Form.Select>
                            <p className="error">{updateFormErrors.professionId}</p>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Description</Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='description' defaultValue={updateFormValues.description} onChange={updateHandleChange} />
                            <p className="error">{updateFormErrors.description}</p>
                        </Form.Group>
                    </Row>   
                </Modal.Body>
                <Modal.Footer>
                    {
                        formSubmitLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <>
                                <Button variant="primary" type="submit">Update</Button>
                                <Button variant="outline-secondary" onClick={popupClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}