import { React, useState } from 'react';
import Select from 'react-select';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { postClientAdd } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function AddProfessionalPopup(props){
    // set props data to variable
    const businessListOption = props.businessOptions;
    const professionListOption = props.professionOption;
    const stateListOption = props.stateOptions;

    // state declaration
    const initialValues = { shopName : "", shopId : "", professionName : "", professionId : "", firstName : "", lastName: "", phoneNumber: "", email: "", address1 : "", address2 : "",  city : "", stateProvinceId : "", zipPostalCode : ""};

    const [popupShow, setPopupShow] = useState(true);
    const [formValues, setformValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);

    // popup close
    const popupHandleClose = () => {
        setPopupShow(false);
        setFormErrors({});
        setformValues(initialValues);
        props.getBack(true, false);
    }

    // select business onchange
    const businessHandleChange = (e) => {
        let value = e.value;
        let valueArr = value.split(',');
        const field1 = 'shopId';
        const field2 = 'shopName';
        setformValues(prevState => ({
            ...prevState,
            [field1]:  valueArr[0], [field2] : valueArr[1]  
        }));
    }

    // select profession onchange
    const professionHandleChange = (e) => {
        let value = e.value;
        let valueArr = value.split(',');
        const field1 = 'professionId';
        const field2 = 'professionName';
        setformValues(prevState => ({
            ...prevState,
            [field1]:  valueArr[0], [field2] : valueArr[1]  
        }));
    }

    // select state onchange
    const stateHandleChange = (e) => {
        const value = e.value;
        const field= 'stateProvinceId';
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // other input fields onchange
    const fieldsHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    
        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setformValues(prevState => ({
                ...prevState,
                [field]:  newValue  
            }));
        }
    
        const formatPhoneNumber = (numberValue) => {
            if (!numberValue) return numberValue;
            const phoneNumber = numberValue.replace(/[^\d]/g, "");
            const phoneNumberLength = phoneNumber.length;
            if (phoneNumberLength < 4) {
                return `(${phoneNumber.slice(0, 3)}`;
            }
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }
      
    
        if(field === 'phoneNumber'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setformValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  
            }));
        }
    } 

    // add professional form submit
    const formHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = fieldsvalidate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){

            let jsonData = await JsonEncode(formValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postClientAdd(loginPayload).then(res => addProfessionalResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    // add professional api respose
    const addProfessionalResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Professional added successfully!',
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            setPopupShow(false);
            setFormErrors({});
            setformValues(initialValues);
            props.getBack(false, true);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    } 

    // input fields validation
    const fieldsvalidate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;
    
        if(!values.shopName){
            errors.shopName = 'Business Name is required';
            errors.status = false;
        }else if(values.shopName && values.shopName == '0'){
            errors.shopName = 'Business Name is required';
            errors.status = false;
        }
    
        if(!values.professionName){
            errors.professionName = 'Profession is required';
            errors.status = false;
        }
    
        if(!values.firstName || values.firstName.trim() === ""){
            errors.firstName = 'First name is required';
            errors.status = false;
        }
    
        if(!values.lastName || values.lastName.trim() === ""){
            errors.lastName = 'Last name is required';
            errors.status = false;
        }
    
        if(!values.email){
            errors.email = 'Email is required';
            errors.status = false;
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }
    
        if(!values.phoneNumber){
            errors.phoneNumber = 'Phone is required';
            errors.status = false;
        }else if(values.phoneNumber){
            const numval = values.phoneNumber.split('');
            if(numval[0] === '0'){
                errors.phoneNumber = 'Enter valid phone';
                errors.status = false;
            }else if(values.phoneNumber && values.phoneNumber.length !== 14){
                errors.phoneNumber = 'Phone must have 10 digit';
                errors.status = false;
            } 
        } 
    
        if(!values.city || values.city.trim() === ""){
            errors.city = 'City is required';
            errors.status = false;
        }
    
        if(!values.address1   || values.address1.trim() === ""){
            errors.address1 = 'Address  is required';
            errors.status = false;
        }
    
        if(!values.stateProvinceId){
            errors.stateProvinceId = 'Please select a state';
            errors.status = false;
        }
    
        if(!values.zipPostalCode){
            errors.zipPostalCode = 'Zipcode  is required';
            errors.status = false;
        } else if(values.zipPostalCode && values.zipPostalCode.length !== 5){
            errors.zipPostalCode = 'Zipcode  must have 5 digit';
            errors.status = false;
        } else if(values.zipPostalCode === "00000"){
            errors.zipPostalCode = 'Invalid Zipcode';
            errors.status = false;
        }
        else if(values.zipPostalCode === "00000"){
            errors.zipPostalCode = 'Invalid Zipcode';
            errors.status = false;
        }

        const validations = {
            email: HtmlTagValidation(values.email),
            address1: HtmlTagValidation(values.address1),
            address2: HtmlTagValidation(values.address2),
            city: HtmlTagValidation(values.city)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    return(
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal">
            <Form onSubmit={formHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Professional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group className="mb-2 select-search-drp adm" as={Col} controlId="formSelect">
                                <Form.Label>Business Name <span>*</span></Form.Label>
                                <Select 
                                className='selectSearch'
                                options={businessListOption}
                                name='shopNames'
                                onChange={businessHandleChange}
                                placeholder="Select an option..."
                                />
                                <p className="error">{formErrors.shopName}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2 select-search-drp adm" as={Col} controlId="formprofessionSelect">
                                <Form.Label>Profession <span>*</span></Form.Label>
                                <Select 
                                    className='selectSearch'
                                    options={professionListOption}
                                    name='professionName'
                                    onChange={professionHandleChange}
                                    placeholder="Select an option..."
                                />
                                <p className="error">{formErrors.professionName}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label>First Name <span>*</span></Form.Label>
                                <Form.Control type="text" name='firstName' value={formValues.firstName} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.firstName}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridLastName">
                                <Form.Label>Last Name <span>*</span></Form.Label>
                                <Form.Control type="text" name='lastName' value={formValues.lastName} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.lastName}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="email"  name='email' defaultValue={formValues.email} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.email}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone <span>*</span></Form.Label>
                                <Form.Control type="tel" name='phoneNumber' value={formValues.phoneNumber} onChange={fieldsHandleChange} maxLength={14}/>
                                <p className="error">{formErrors.phoneNumber}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group className="mb-2"  as={Col} controlId="formGridAddress1">
                                <Form.Label>Business Address 1 <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='address1' defaultValue={formValues.address1} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.address1}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2"  as={Col} controlId="formGridAddress2">
                                <Form.Label>Business Address 2</Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='address2' defaultValue={formValues.address2} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.address2}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='city' defaultValue={formValues.city} onChange={fieldsHandleChange}/>
                                <p className="error">{formErrors.city}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} className="select-search-drp adm" controlId="formGridState">
                                <Form.Label>State <span>*</span></Form.Label>
                                <Select 
                                    className='selectSearch'
                                    options={stateListOption}
                                    name='stateProvinceId'
                                    onChange={stateHandleChange}
                                    placeholder="Select an option..."
                                />
                                <p className="error">{formErrors.stateProvinceId}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Zipcode <span>*</span></Form.Label>
                            <Form.Control type="number"  name='zipPostalCode' defaultValue={formValues.zipPostalCode} onChange={fieldsHandleChange}/>
                            <p className="error">{formErrors.zipPostalCode}</p>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <>
                                <Button variant="primary" type='submit'>Submit</Button>
                                <Button variant="outline-secondary" onClick={popupHandleClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}