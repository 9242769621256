import React, { useEffect } from 'react';
import Header from '../Components/Stylist/Assets/Header';
import Footer from '../Components/Stylist/Assets/Footer';
import {Outlet, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const StylistLayout = ({ props }) => {
  const navigation = useNavigate();
  const apiToken = useSelector(store => store.user['stylisttoken']);
  const stylistData = useSelector(store => store.user['stylistuser']);
  const bookingDetails = useSelector(store => store.user['bookingDetails']);
  useEffect(() => {
    if(!apiToken || !stylistData){
        navigation('/stylistlogin');
    }else if(bookingDetails === null){
        navigation('/stylist/bookingcalendar');
    }
  },[apiToken]);

    return (
    <>
     <Header/>
     <main style={{ padding: '1rem 0' }}>
      <Outlet />
    </main>
    <Footer/>
    </>)
  };

  export default StylistLayout;