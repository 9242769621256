import { React } from "react";
import { Outlet } from "react-router-dom";

export default function PolicyLayout() {
    return(
        <>
            <main style={{ padding: '1rem 0' }}> 
                <Outlet />
            </main>
        </>
    )
}