import { React, useState, useEffect} from 'react';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import moment from 'moment';
import CustomerHeader from '../../Assets/CustomerHeader';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { patchCancelAuthorizeBookingSlot, postCustomerAccountDetails } from '../../../../Service';
import moments from 'moment-timezone';
import AddTipPopup from './Popup/AddTipPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import AcceptRejectPopup from './Popup/AcceptRejectPopup';
import { JsonEncode, JsonDecode } from '../../../Reusable/JsonModify';

export default function CustomerBookingHistoryDetails() {

    var abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
        AKST : 'Alaskan Standard Time',
        HST : 'Hawaiian Standard Time'
    };

    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };

    // initial state declarations
    const [loader, setLoader] = useState(false);
    
    const [refreshCall, setRefreshCall] = useState(false);
    const [tipPageStatus, setTipPageStatus] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [acceptPageStatus, setAcceptPageStatus] = useState(false);

    // get booking history data from store
    const getData =  useSelector(store => store.user.bookingHistoryDetails);
    console.log('getData', getData);
    let zoone = getData.timeZone.split(' ');
    let currentDateTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));
    let slotStattingDateTime = new Date(moment(getData.appoinmentSlotDate));
    
    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);

    // get service list and card list data by api
    useEffect(() => {
        (async () => {
            if (userData) {
                let whereUser = {'customer_Id' : userData.id};
    
                let jsonData = await JsonEncode(whereUser).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerAccountDetails(loginPayload).then(res => cardHandleResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })();
    },[]);

    const cardHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });
        if ( decryptData.statusCode === 200 ) {
            setCardList(decryptData.data)
        }
    }

    const navigate = useNavigate();

    const getUTCtoLocal = (onlyTime) => {
        var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

        return local_date;
    }

    const handleBookingCancel = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to cancel this booking?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!',
            timer: 3000
        }).then(async (result) => {
            if (result.isConfirmed) {
                const zoneSplit = getData.timeZone.split(' ');
                let zone = '';
                if(zoneSplit[0] == 'US/Arizona'){
                    zone = 'US Mountain Standard Time';
                }else{
                    zone = moments.tz([2012, 0], zoneSplit[0]).format('zz');
                }
                var cancelData = {"customerId" : userData.id, "timeZone" : zone, "bookingId" : getData.bookingId, "status" : "Cancelled", "startDateTime" : getData.appoinmentSlotDate, "amount" : getData.payment, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

                let jsonData = await JsonEncode(cancelData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    patchCancelAuthorizeBookingSlot(loginPayload).then(res => handleCancelResponse(res));
                    setLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })
    }
  
    const handleCancelResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setRefreshCall(prevRefreshCall => !prevRefreshCall);
            navigate('/customer/bookingHistory');
            setLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false)
        }
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    const handleTipClick = () => {
        setTipPageStatus(true);
    }

    const getDataFromPopup = (data) => {
        if(data === false){
            setTipPageStatus(false);
        }
    }

    const handleAcceptClick = () => {
        setAcceptPageStatus(true);
    }

    const getDataFromAcceptPopup = (data) => {
        if(data === false){
            setAcceptPageStatus(false);
        }else if(data === true){
            setAcceptPageStatus(false);
            navigate(-1);
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <div>
            <CustomerHeader />
            <section className='history-bg'>
                <div className='history-section'>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md='12'className='detailsMb'>
                                <h1>Booking History Details</h1>
                                <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="justify-content-md-center text-center">
                            <div className='booking-details'>
                                <Col md={12}>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Professional Name</h4>    
                                        </Col>
                                        <Col>
                                            <p>{getData.clientName}</p> 
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Business Name</h4>    
                                        </Col>
                                        <Col>
                                            <p>{getData.shopName}</p> 
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Service Offered</h4>    
                                        </Col>
                                        <Col>
                                            <p>{getData.serviceName}</p> 
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Appointment Date</h4>   
                                        </Col>
                                        <Col>
                                            <p>{moment(getData.appoinmentSlotDate).format('MM-DD-YYYY')}</p> 
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Time</h4>  
                                        </Col>
                                        <Col>
                                            <div style={{display : 'flex', justifyContent : 'end'}}>
                                                <p>
                                                    {getData.startTime}<br></br>{getData.endTime}
                                                </p>
                                                <p>
                                                    <sup className='payment-icon'>
                                                        <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                            overlay={
                                                                <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                    <p className='time-timezone'>{zoone[0]}</p>
                                                                </Popover>
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                                        </OverlayTrigger>
                                                    </sup>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Booked Date</h4>   
                                        </Col>
                                        <Col>
                                            <p>{moment(getUTCtoLocal(getData.createdOnUtc)).format('MM-DD-YYYY')}</p> 
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Service Charge fee</h4> 
                                        </Col>
                                        <Col>
                                            <p>
                                              ${getFullCost(getData.payment)}
                                              <sup className='payment-icon'>
                                                  <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                    overlay={
                                                        <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                            <div>
                                                                <h5>Service : ${getFullCost(getData.serviceCost)} </h5>  
                                                                <h5>Tip : ${getFullCost(getData.tip)}</h5>  
                                                                <h5>Platform Fees : ${getFullCost(getData.platformFees)}</h5>  
                                                                <p><strong>Total : </strong>${getFullCost(getData.payment)}</p>
                                                            </div>
                                                        </Popover>
                                                    }
                                                  >
                                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                                  </OverlayTrigger>
                                              </sup>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Payment Type</h4> 
                                        </Col>
                                        <Col>
                                            <p>{getData.paymentType}</p>
                                        </Col>
                                    </Row>
                                    <Row className='text-start'>
                                        <Col>
                                            <h4>Status</h4> 
                                        </Col>
                                        <Col>
                                            <p className={ getData.status === 'Booked' ? 'bookedStatus' : 'cancelStatus'} style={{float: 'right'}}>{getData.status === 'Booked' ? (getData.bookingType === 'CL' || getData.bookingType === 'CLEB') && getData.isCustomerRespondent === false ? 'Verification Pending' : getData.status : getData.status}</p>
                                        </Col>
                                    </Row>
                                    {
                                        (getData.status === 'Booked' && getData.bookingType == 'CS') || (getData.status === 'Booked' && (getData.bookingType == 'CL' || getData.bookingType == 'CL') && getData.isCustomerRespondent == true) ?
                                            currentDateTime < slotStattingDateTime ?
                                                <Row className='text-start'>
                                                    <Col style={{"textAlign": "center"}}>
                                                        {
                                                            loader === true ?
                                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                            :
                                                                <Button className='bookingCancel' onClick={handleBookingCancel} variant="danger">Cancel</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            : 
                                                '-'
                                        :
                                            getData.status === 'Booked' && (getData.bookingType == 'CL' || getData.bookingType == 'CLEB') && getData.isCustomerRespondent == false ?
                                                <Row className='text-start'>
                                                    <Col style={{"textAlign": "center"}}>
                                                        {
                                                            loader === true ?
                                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                            :
                                                                <Button className='bookingCancel' onClick={handleAcceptClick} variant="success">Accept/Reject</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                        :
                                            getData.status === 'Completed' && getData.isTipNotInterested == 0 && getData.tip == 0 ? 
                                                <Row className='text-start'>
                                                    <Col style={{"textAlign": "center"}}>
                                                        {
                                                            loader === true ?
                                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                            :
                                                                <Button className='addTipBtn' onClick={handleTipClick} variant="danger">Add Tip</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                            :
                                                '-'
                                    }
                                </Col>
                            </div>
                        </Row>
                        {
                            acceptPageStatus === true ?
                                <AcceptRejectPopup data={getData} getBack={getDataFromAcceptPopup} />
                            :
                                ''
                        }
                        {
                            tipPageStatus === true ?
                                <AddTipPopup data={getData} getBack={getDataFromPopup} card={cardList} />
                            :
                                ''
                        }
                    </Container>
                </div>
            </section>
        </div>
    )
}       