import { React } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import mission from '../../../../images/mission.jpg';
import vision from "../../../../images/vision.jpg";
import CustomerHeader from "../../Assets/CustomerHeader";

export default function OurMission() {
    return(
        <div>
            <CustomerHeader />
        
        <section className=''>
            <Container fluid>
                <Row className="justify-content-md-center mission-row">
                    <Col md={3}>
                        
                    </Col>
                    <Col md={9} className="img-cover">
                        <Image src={mission}  fluid	/>
                    </Col>
                    <Col md={7} className="mission-col">
                    <div className="mission">
                            <h4>Mission</h4>
                            <p>
                            UNEXT provides a customer facing professional booking platform for the masses that can be utilized by anyone on the planet.
                            UNEXT provides a mutually beneficial meeting point linking people to localized industry professionals and entrepreneurs and bridging the gap between local professionals and big businesses.
                            </p>
                        </div>
                    </Col>
                    
                </Row>
                <Row className="justify-content-md-center vission-row" id="ourvision">
                    <Col md={9} className="img-cover"   >
                        <Image src={vision} fluid />

                    </Col>
                    <Col md={3}>
                        
                    </Col>
                    <Col md={7} className="vission-col" >
                        <div className="vission">
                            <h4>Vision</h4>
                            <p>
                            Creating a welcoming and empowered community that celebrates, supports, and highlights equality by providing an access point for local resources.
                            </p>
                            <p  className="inner-text">
                                <strong>
                                Values</strong>
                            </p>
                            <Row>
                                <Col>
                                    <ul>
                                        <li>Higher purpose</li>
                                        <li>Equality</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Equity</li>
                                        <li>Integrity</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Empowerment</li>
                                    </ul>
                                </Col>
                            </Row>
                            
                            <p  className="inner-text">
                                <strong>Purpose</strong>
                            </p>
                            
                            <p>
                            To devise a platform that recognizes, empowers and shapes the talents of individual entrepreneurs and grants them opportunities to deliver their God-given talents to the world.
                            </p>
                            <p className="inner-text">
                                <strong>Goals</strong>
                            </p>
                            <ul>
                                <li>To create a platform to showcase individuals’ God-given talents.</li>
                                <li>Develop a platform that paves the way for individual entrepreneurs to achieve greater equity.</li>
                                <li>Focus on the importance of relationship building in booking platforms.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    )
}


export function Vission() {
    return(
        <div>
            <CustomerHeader />
        
        <section className=''>
            <Container fluid>
                
                <Row className="justify-content-md-center vission-row">
                    <Col md={9} className="img-cover"   >
                        <Image src={vision} fluid />

                    </Col>
                    <Col md={3}>
                        
                    </Col>
                    <Col md={7} className="vission-col" >
                        <div className="vission">
                            <h4>Vision</h4>
                            <p>
                            Creating a welcoming and empowered community that celebrates, supports, and highlights equality by providing an access point for local resources.
                            </p>
                            <p  className="inner-text">
                                <strong>
                                Values</strong>
                            </p>
                            <Row>
                                <Col>
                                    <ul>
                                        <li>Higher purpose</li>
                                        <li>Equality</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Equity</li>
                                        <li>Integrity</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul>
                                        <li>Empowerment</li>
                                    </ul>
                                </Col>
                            </Row>
                            
                            <p  className="inner-text">
                                <strong>Purpose</strong>
                            </p>
                            
                            <p>
                            To devise a platform that recognizes, empowers and shapes the talents of individual entrepreneurs and grants them opportunities to deliver their God-given talents to the world.
                            </p>
                            <p className="inner-text">
                                <strong>Goals</strong>
                            </p>
                            <ul>
                                <li>To create a platform to showcase individuals’ God-given talents.</li>
                                <li>Develop a platform that paves the way for individual entrepreneurs to achieve greater equity.</li>
                                <li>Focus on the importance of relationship building in booking platforms.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </div>
    )
}