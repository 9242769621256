import React from 'react';
import './App.css';
import './css/Responsive.css';
import AllRoutes from './AppRoutes/AllPortalRoutes';

function App() {
  return ( 
    <React.Fragment>
        <AllRoutes/>
    </React.Fragment>      
  );
}

export default App;