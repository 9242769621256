import Header from '../Components/Customer/Assets/Header';
import Footer from '../Components/Customer/Assets/Footer';
import {Outlet} from 'react-router-dom';

const CustomerLayout = ({ props }) => {
    return (
    <>
     <Header/>
     <main style={{ padding: '1rem 0' }}>
      <Outlet />
    </main>
    <Footer/>
    </>)
  };

  export default CustomerLayout;