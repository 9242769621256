import { React, useState, useRef } from 'react';
import {Button, Col, Modal, Row, Form } from "react-bootstrap"; 
import { InfinitySpin } from 'react-loader-spinner';
import { postCustomerSendEnquiry } from '../../../../../Service';
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection } from '../../../../Reusable';

export default function SendMessagePopup({data, getBack}){
    let clientId = data.clientId;
    let clientEmail = data.clientEmail;
    const recaptcha = useRef();

    // initial state declaration
    const initialValues = {firstName : '', lastName : '', email : '', phone : '', message : ''};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [formLoader, setFormLoader] = useState(false);
    const [popupShow, setPopupShow] = useState(true);

    // popup close fucntion
    const popupHandleClose = () => {
        setFormValues(initialValues);
        setFormErrors({});
        setPopupShow(false);
        getBack(false);
        setFormLoader(false);
    }

    // phone number format 
    const formatPhoneNumber = (numberValue) => {
        if (!numberValue) return numberValue;
        const phoneNumber = numberValue.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) {
            return `(${phoneNumber.slice(0, 3)}`;
        }
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    // form field onchange
    const formHandleChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        if (name === 'phone') {
            const formattedPhoneNumber = formatPhoneNumber(value);
            updatedValue = formattedPhoneNumber;
        }

        setFormValues(prevState => ({
            ...prevState,
            [name]: updatedValue
        }));
    }

    // popup submit function
    const formHandleSubmit = (e) => {
        e.preventDefault();
        let validate = formValidate(formValues);
        setFormErrors(validate);
        if(validate.status){
            setFormLoader(true)
            let customername = formValues.firstName+' '+formValues.lastName;
            let sendMessageParams = {'clientId' : clientId, 'clientEmail' : clientEmail, 'customerName' : customername, 'customerEmail' : formValues.email, 'customerPhoneNumber' : formValues.phone, 'message' : formValues.message, 'reCaptchaToken' : recaptcha.current.getValue() };
            postCustomerSendEnquiry(sendMessageParams).then(res => sendMessageResponse(res));
        }
    }

    // submit response
    const sendMessageResponse = (res) => {
        if(res.data.statusCode == 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setFormLoader(false);
            popupHandleClose();
        }else{
            recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setFormLoader(false);
        }
    }

    // input field validation
    const formValidate = (values) => {
        let errors = { status: true };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
        const validateField = (fieldName, errorMessage) => {
            if (!values[fieldName] || values[fieldName].trim() === '') {
                errors[fieldName] = errorMessage;
                errors.status = false;
            }
        };
    
        validateField('firstName', 'First name is required.');
        validateField('email', 'Email address is required.');
        validateField('phone', 'Phone number is required.');
        validateField('message', 'Message is required.');

        if (values.email && !emailRegex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }

        if (values.phone && values.phone[1] === '0') {
            errors.phone = 'Enter valid phone';
            errors.status = false;
        } else if (values.phone && values.phone.length !== 14) {
            errors.phone = 'Phone must have 10 digits';
            errors.status = false;
        }
    
        if (recaptcha.current.getValue() === '') {
            errors.captcha = 'Captcha is required';
            errors.status = false;
        }

        const validations = {
            firstName: HtmlTagValidation(values.firstName),
            lastName: HtmlTagValidation(values.lastName),
            email: HtmlTagValidation(values.email),
            message: HtmlTagValidation(values.message)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });
    
        return errors;
    }

    return(
        // send message popup html
        
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal addtippopup" >
            <Form onSubmit={formHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Message To Professional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="md-3" controlId="formBasicEmail">
                                <Form.Label className='mt-2'>First Name <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name='firstName' defaultValue={formValues.firstName} onChange={formHandleChange}/>
                                <span className="error">{formErrors.firstName}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="md-3" controlId="formBasicEmail">
                                <Form.Label className='mt-2'>Last Name</Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name='lastName' defaultValue={formValues.lastName} onChange={formHandleChange}/>
                                <span className="error">{formErrors.lastName}</span>
                            </Form.Group>
                        </Col>

                    </Row>  
                    <Row>
                        <Col md={6}>
                            <Form.Group className="md-3" controlId="formBasicEmail">
                                <Form.Label className='mt-2'>Email <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name='email' defaultValue={formValues.email} onChange={formHandleChange}/>
                                <span className="error">{formErrors.email}</span>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="md-3" controlId="formBasicEmail">
                                <Form.Label className='mt-2'>Phone <span>*</span></Form.Label>
                                <Form.Control type="text" name='phone' value={formValues.phone} onChange={formHandleChange}/>
                                <span className="error">{formErrors.phone}</span>
                            </Form.Group>
                        </Col>
                    </Row>  
                    <Row>
                        <Form.Group className="md-3" controlId="formBasicEmail">
                            <Form.Label className='mt-2'>Message <span>*</span></Form.Label>
                            <textarea class="form-control" onKeyDown={BlockSQLInjection} name='message' defaultValue={formValues.message} onChange={formHandleChange} rows="4"></textarea>
                            <span className="error">{formErrors.message}</span>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="md-3" controlId="formCaptchaLoad">
                            <Form.Label className='mt-2'>Captcha <span>*</span></Form.Label>
                            <ReCAPTCHA size="normal" ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            <p className="error">{formErrors.captcha}</p>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        formLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :   
                            <>
                                <Button variant="primary" type="submit">Send</Button>
                                <Button variant="outline-secondary" onClick={popupHandleClose}>Cancel </Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}