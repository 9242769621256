import { React, useState } from 'react';
import Select from 'react-select';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { patchClientUpdate } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function EditProfessionalPopup(props){
    // set props data to variable
    let professionalData = props.professionalData;

    // state declaration
    const initialEditFormValues = {clientId : professionalData.clientId, shopNames : professionalData.shopName, professionName : professionalData.professionName, firstName : professionalData.firstName, lastName : professionalData.lastName, email : professionalData.email, phoneNumber : professionalData.phone, address1 : professionalData.address1, address2 : professionalData.address2, city : professionalData.city, stateProvinceId : professionalData.stateProvinceId, stateName : professionalData.state, zipPostalCode : professionalData.zipPostalCode};
    const [submitLoader, setsubmitLoader] = useState(false);
    const [editPopupShow, setEditPopupShow] = useState(true);
    const [editFormValues, setEditFormValues] = useState(initialEditFormValues);
    const [editFormErrors, setEditFormErrors] = useState({});
    const [stateProvinceOption, setStateProvinceOption] = useState(props.stateOptions);

    // popup close
    const editPopupHandleClose = () =>{
        setEditPopupShow(false);
        props.getBack(true, false);
        setEditFormValues(initialEditFormValues);
        setEditFormErrors({});
        setsubmitLoader(false);
    }

    // select state onchange
    const stateHandleChange = (e) => {
        const value = e.value;
        const field= 'stateProvinceId';
        setEditFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // phone number formate
    const formatPhoneNumber = (numberValue) => {
        if (!numberValue) return numberValue;
        const phoneNumber = numberValue.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) {
            return `(${phoneNumber.slice(0, 3)}`;
        }
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    // other input fields onchange
    const editFieldHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;

        setEditFormValues(prevState => ({
            ...prevState,
            [field]:  value 
        }));

        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setEditFormValues(prevState => ({
                ...prevState,
                [field]:  newValue  }));
        }

        if(field === 'phoneNumber'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setEditFormValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  })
            );
        }
    }

    // update profession form submit 
    const editFormHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = validate(editFormValues);
        setEditFormErrors(errorCount);
        if(errorCount.status){
            let updateParams = {'clientId' : editFormValues.clientId, 'firstName' : editFormValues.firstName, 'lastName' : editFormValues.lastName, 'address1' : editFormValues.address1, 'address2' : editFormValues.address2, 'city' : editFormValues.city, 'stateProvinceId' : editFormValues.stateProvinceId, 'zipCode' : editFormValues.zipPostalCode, 'phoneNumber' : editFormValues.phoneNumber, 'email' : editFormValues.email};

            let jsonData = await JsonEncode(updateParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                patchClientUpdate(loginPayload).then(res => updateHandleResponse(res));
                setsubmitLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    // update professionals api response
    const updateHandleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setsubmitLoader(false);
            setEditPopupShow(false);
            props.getBack(false, true);
            setEditFormValues(initialEditFormValues);
            setEditFormErrors({});
            setsubmitLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setsubmitLoader(false);
        }
    }

    // input fields validation
    const validate = (values) => {
        const errors = {};
        errors.status = true;

        if(!values.firstName || values.firstName.trim() === ""){
        errors.firstName = 'First name is required';
        errors.status = false;
        }

        if(!values.lastName || values.lastName.trim() === ""){
        errors.lastName = 'Last name is required';
        errors.status = false;
        }

        if(!values.phoneNumber){
            errors.phoneNumber = 'Phone is required';
            errors.status = false;
        }else if(values.phoneNumber){
            const numval = values.phoneNumber.split('');
            if(numval[0] === '0'){
                errors.phoneNumber = 'Enter valid phone';
                errors.status = false;
            }else if(values.phoneNumber && values.phoneNumber.length !== 14){
                errors.phoneNumber = 'Phone must have 10 digit';
                errors.status = false;
            } 
        } 
    
        if(!values.city || values.city.trim() === ""){
            errors.city = 'City is required';
            errors.status = false;
        }
    
        if(!values.address1   || values.address1.trim() === ""){
            errors.address1 = 'Address  is required';
            errors.status = false;
        }
    
        if(!values.stateProvinceId){
            errors.stateProvinceId = 'Please select a state';
            errors.status = false;
        }
    
        if(!values.zipPostalCode){
            errors.zipPostalCode = 'Zipcode  is required';
            errors.status = false;
        } else if(values.zipPostalCode && values.zipPostalCode.length !== 5){
            errors.zipPostalCode = 'Zipcode  must have 5 digit';
            errors.status = false;
        } else if(values.zipPostalCode === "00000"){
            errors.zipPostalCode = 'Invalid Zipcode';
            errors.status = false;
        }
        else if(values.zipPostalCode === "00000"){
            errors.zipPostalCode = 'Invalid Zipcode';
            errors.status = false;
        }

        const validations = {
            email: HtmlTagValidation(values.email),
            address1: HtmlTagValidation(values.address1),
            address2: HtmlTagValidation(values.address2),
            city: HtmlTagValidation(values.city)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });
        
        return errors;
    }

    return(
        <Modal show={editPopupShow} onHide={editPopupHandleClose} className="custom-modal">
            <Form onSubmit={editFormHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Professional</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group className="mb-2 select-search-drp adm" as={Col} controlId="formSelect">
                                <Form.Label>Business Name <span>*</span></Form.Label>
                                <Form.Control type="text" name='shopNames' defaultValue={editFormValues.shopNames} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.shopName}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2 select-search-drp adm" as={Col} controlId="formprofessionSelect">
                                <Form.Label>Profession <span>*</span></Form.Label>
                                <Form.Control type="text" name='professionName' value={editFormValues.professionName} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.professionName}</p>
                            </Form.Group>
                        </Col>
                    </Row> */}
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label>First Name <span>*</span></Form.Label>
                                <Form.Control type="text" name='firstName' value={editFormValues.firstName} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.firstName}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridLastName">
                                <Form.Label>Last Name <span>*</span></Form.Label>
                                <Form.Control type="text" name='lastName' value={editFormValues.lastName} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.lastName}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control type="email"  name='email' defaultValue={editFormValues.email} onChange={editFieldHandleChange} disabled style={{cursor : 'not-allowed'}}/>
                                <p className="error">{editFormErrors.email}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Label>Phone <span>*</span></Form.Label>
                                <Form.Control type="tel" name='phoneNumber' value={editFormValues.phoneNumber} onChange={editFieldHandleChange} maxLength={14}/>
                                <p className="error">{editFormErrors.phoneNumber}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group className="mb-2"  as={Col} controlId="formGridAddress1">
                                <Form.Label>Business Address 1 <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='address1' defaultValue={editFormValues.address1} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.address1}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2"  as={Col} controlId="formGridAddress2">
                                <Form.Label>Business Address 2</Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='address2' defaultValue={editFormValues.address2} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.address2}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type="text"  name='city' defaultValue={editFormValues.city} onChange={editFieldHandleChange}/>
                                <p className="error">{editFormErrors.city}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Col} className="select-search-drp adm" controlId="formGridState">
                                <Form.Label>State <span>*</span></Form.Label>
                                <Select 
                                    className='selectSearch'
                                    options={stateProvinceOption}
                                    name='stateProvinceId'
                                    onChange={stateHandleChange}
                                    placeholder={professionalData.state}
                                />
                                <p className="error">{editFormErrors.stateProvinceId}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Zipcode <span>*</span></Form.Label>
                            <Form.Control type="number"  name='zipPostalCode' defaultValue={editFormValues.zipPostalCode} onChange={editFieldHandleChange}/>
                            <p className="error">{editFormErrors.zipPostalCode}</p>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        submitLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :   
                            <>
                                <Button variant="primary" type='submit'>Save</Button>
                                <Button variant="outline-secondary" onClick={editPopupHandleClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}