import { React } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

export default function RegisterThanksPage(){

    return(
        <section className='login-bg' style={{background : 'none'}}>
            <Container>
                <Row style={{display : 'block', textAlign : 'center'}}>
                    <Col md="auto">
                        <div className='text-center'>
                            <Link to="../customer" >
                                <img src='images/logo.png'/>
                            </Link>
                        </div>
                        <h1 className="text-center" style={{marginBottom : '16px'}}><span>Thank you. Your registration has been successfully submitted.</span></h1>
                        <p style={{color : '#fff'}}>You will now receive an email containing an activation link. Please click on the link to activate your account. Additionally, please ensure to check your spam and junk folder for the email, as it may be directed there.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}