import { SET_ADMINUSER,SET_STYLISTUSER,SET_CUSTOMERUSER,SET_ADMINTOKEN,SET_STYLISTTOKEN,SET_CUSTOMERTOKEN,SET_ADMINLOGOUT,SET_STYLISTLOGOUT,SET_CUSTOMERLOGOUT,SET_BOOKINGURL,SET_BOOKINGDETAILS,SET_ADMINBOOKINGDETAILS, SET_CUSTOMERBOOKINGDETAILS, SET_BOOKINGHISTORYDETAILS, SET_QBACCESSTOKEN, SET_PRIVACYPREFERENCE, SET_BOOKINGTIMEOUT, SET_NEWBOOKINGCUSTOMERDETAILS, SET_STYLISTLOGOUTURL, SET_CUSTOMERLOGOUTURL} from './type';

export const setAdminUserData = (data) => {
  return {
    type: SET_ADMINUSER,
    data
  }
};
export const setStylistUserData = (data) => {
  return {
    type: SET_STYLISTUSER,
    data
  }
};
export const setCustomerUserData = (data) => {
  return {
    type: SET_CUSTOMERUSER,
    data
  }
};
export const setAdminToken = (data) => {
    return {
      type: SET_ADMINTOKEN,
      data
    }
};
export const setStylistToken = (data) => {
  return {
    type: SET_STYLISTTOKEN,
    data
  }
};
export const setCustomerToken = (data) => {
  return {
    type: SET_CUSTOMERTOKEN,
    data
  }
};
export const setAdminLogout = () => {
  return {
    type: SET_ADMINLOGOUT
  }
};
export const setStylistLogout = () => {
  return {
    type: SET_STYLISTLOGOUT
  }
};
export const setCustomerLogout = () => {
  return {
    type: SET_CUSTOMERLOGOUT
  }
};
export const setBookingUrl = (data) => {
  return {
    type: SET_BOOKINGURL,
    data
  }
};
export const setBookingDetails = (data) => {
  return {
    type: SET_BOOKINGDETAILS,
    data
  }
};
export const setAdminBookingDetails = (data) => {
  return {
    type: SET_ADMINBOOKINGDETAILS,
    data
  }
};
export const setCustomerBookingDetails = (data) => {
  return {
    type: SET_CUSTOMERBOOKINGDETAILS,
    data
  }
};
export const setQBAccessToken = (data) => {
  return {
    type: SET_QBACCESSTOKEN,
    data
  }
};

export const setBookingHistoryDetails = (data) => {
  return {
    type: SET_BOOKINGHISTORYDETAILS,
    data
  }
};
export const setPrivacyPreference = (data) => {
  return {
    type: SET_PRIVACYPREFERENCE,
    data
  }
};
export const setBookingTimeout = (data) => {
  return {
    type: SET_BOOKINGTIMEOUT,
    data
  }
};
export const setNewBookingCustomerDetails = (data) => {
  return {
    type: SET_NEWBOOKINGCUSTOMERDETAILS,
    data
  }
};
export const setStylistLogoutUrl = (data) => {
  return {
    type: SET_STYLISTLOGOUTURL,
    data
  }
};
export const setCustomerLogoutUrl = (data) => {
  return {
    type: SET_CUSTOMERLOGOUTURL,
    data
  }
};
