import React, { useEffect } from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const NewStylistLayout = ({ props }) => {
  const navigation = useNavigate();
  const apiToken = useSelector(store => store.user['stylisttoken']);
  const stylistData = useSelector(store => store.user['stylistuser']);
  useEffect(() => {
    if(!apiToken || !stylistData){
      navigation('/stylistlogin');
    }
  },[apiToken]);
    return (
    <>
     <main style={{ padding: '1rem 0' }}>
      <Outlet />
    </main>
    </>)
  };

  export default NewStylistLayout;