import axios from 'axios';
import store from '../store';
import { setCustomerToken, setAdminLogout, setStylistLogout } from '../reducers/User/action';
import { useDispatch} from 'react-redux';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function getToken(userType)  //async 
{
    let token='';
    if(userType=='admin')
    {
         token= store.getState().user['admintoken'];
    }
    else if(userType=='customer')
    {
       token= store.getState().user['customertoken'];
    }
    else if(userType=='client')
    {
       token= store.getState().user['stylisttoken'];
    }
    
    return token;
}

export const getInstance=async (guest,userType) => {
  
    
    if(guest){
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API,
              headers: { "Content-Type" : "application/json" },
        }); 
        return instance;
    } else {
        const token=getToken(userType);
        const instance = axios.create({
            baseURL: process.env.REACT_APP_API,
            headers: {Authorization: `Bearer ${token}`, "Content-type": "application/json" } 

        });
         instance.interceptors.response.use(
              response => response,
              error => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                if (error.message === "Network Error") {
                  Swal.fire({
                    icon: 'error',
                    text: "ERROR: " + error.response.statusText,
                    title: "Network Error",
                    showConfirmButton: true
                  });
                } else if (error.response.status === 401) {
                  Swal.fire({
                    icon: 'error',
                    text: "Unauthorized Access or Session Expired!",
                    title: "Access denied!!",
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      store.dispatch(setStylistLogout());
                      store.dispatch(setAdminLogout());
                      store.dispatch(setCustomerToken());
                    }
                  })
                } else if (error.response.status === 500 || error.response.status === 403 ){
                  Swal.fire({
                    icon: 'error',
                    text: "ERROR : " + error.response.statusText,
                    title: "Oops! Something went to wrong...",
                    showConfirmButton: true
                  });
                } else{
                  Swal.fire({
                    icon: 'error',
                    text: "ERROR : " + error.response.statusText,
                    title: "Oops! Something went to wrong...",
                    showConfirmButton: true
                  });
                }
                return Promise.reject(error);
              }
            );

            return instance;
        
    }


    
}
