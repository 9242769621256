import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Image, Row, Stack, Button, Card, Modal } from 'react-bootstrap';
import Topbar from '../Assets/Topbar';  
import LeftSidebar from '../Assets/LeftSidebar';
import Swal from "sweetalert2";
import { useSelector } from 'react-redux';
import { stylistPortfolioList, deletePortfoliobystylist } from '../../../Service';
import closeIcon from "../../../images/close.png";
import { InfinitySpin } from 'react-loader-spinner';
import ShowImagePopup from "./Popup/ShowImagePopup";

export default function StylistPortfolioview(){
    // initial state declaration
    const [portfolioList, setPortfolioList] = useState([]);
    const [portfolioListRefresh, setPortfolioListRefresh] = useState(false);
    const [loader, setLoader] = useState(false);
    const [portfolioImage, setPortfolioImage] = useState('');
    const [showImagePopup, setShowImagePopup] = useState(false);

    // get stylist data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get portfolio list by client id
    useEffect(() => {
        const clientId = {'clientId' : userData.clientId};
        stylistPortfolioList(clientId).then(res => initialApiResponse(res));
        setLoader(true);
    },[portfolioListRefresh]);

    const initialApiResponse = (response) => {
        if(response.data.statusCode === 200) {
            setPortfolioList(response.data.portfolioList);
            setLoader(false);
        }else{
            setLoader(false);
        }
    }

    // portfolio image delete functionality start here
    const deletePortfolio = (data)=> {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this portfolio?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var whereDelete = {"portfolioId" : data.portfolioId};
                deletePortfoliobystylist(whereDelete).then(res => handleDeleteResponse(res));
            }
        })
    }

    const handleDeleteResponse = (response) => {
        if(response.data.statusCode === 200){  
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setPortfolioListRefresh(prevPortfolioList => !prevPortfolioList);
        } else { 
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    const portfolioImageHandler = (imageData) => {
        setShowImagePopup(true);
        setPortfolioImage(imageData);
    }

    // get status from show image popup
    const getStatusFromShowImagePopup = () => {
        setShowImagePopup(false);
        setShowImagePopup('')
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>    
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/> 
                            <Row className="main-title">
                                <Col>
                                    <h1>Portfolio</h1>
                                </Col>
                            </Row> 
                            <Row>
                                <Col className="add-profession">
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <Stack direction='horizontal' gap={3}>
                                        <div className='ms-auto'>
                                            {/* <Button variant='success' as="a" href='portfolio'>+ Add Portfolio</Button> */}
                                            <Link className='btn btn-success' to='../stylist/portfolio'>+ Add Portfolio</Link>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                              {
                                loader === true ?
                                    <Row xs={1} md={1} className="g-4 portfolio-view">
                                      <p style={{color : '#fff', textAlign : 'center'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></p>
                                    </Row>
                                :
                                    portfolioList.length > 0 ?
                                        <Row xs={1} md={3} className="g-4 portfolio-view">
                                            {
                                                portfolioList.map((data, index) => (
                                                    <Col key={index}>
                                                        <Card>
                                                            <div className='view-card'>
                                                                <Card.Img variant="top" src={data.imageUrl} style={{cursor : 'pointer'}} onClick={() => portfolioImageHandler(data)}/>
                                                            </div>
                                                            <Card.Body>
                                                                <Card.Text>{data.description}</Card.Text>
                                                            </Card.Body>
                                                            <Image src={closeIcon} style={{cursor: 'pointer', position : 'absolute', top : '0', right : '0', background : '#fff', padding : '6px'}} onClick={() => deletePortfolio(data)}/>
                                                        </Card>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    : 
                                        <Row xs={1} md={1} className="g-4 portfolio-view">
                                            <p style={{color : '#fff', textAlign : 'center'}}>No Data Found...</p>
                                        </Row>
                              }
                        </div>

                        {
                            showImagePopup ?
                                <ShowImagePopup imageData={portfolioImage} getBack={getStatusFromShowImagePopup}/>
                            :
                                ''
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 