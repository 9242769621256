import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Image, Button, Table, Form } from "react-bootstrap";
import deleteIcon from "../../../images/delete.png";
import edit from "../../../images/edit.png";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { getAllServiceList, getAllProfessions, deleteService, updateService } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../Assets/Pagination';
import AddServicePopup from './Popup/AddServicePopup';
import EditServicePopup from './Popup/EditServicePopup';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';

export default function ManageStylist() {
    // state declaration
    const [serviceList, setServiceList] = useState([]);
    const [serviceAdd, setServiceAdd] = useState(false);
    const [allProfession, setAllProfession] = useState([]);
    const [serviceLoader, setServiceLoader] = useState(true);
    const searchInitialValues = {searchByServiceName : '', searchByProfessionName : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('asc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [addServiceStatus, setAddServiceStatus] = useState(false);
    const [editServiceStatus, setEditServiceStatus] = useState(false);
    const [editServiceData, setEditServiceData] = useState({});

    // get service list data by api
    useEffect(() => {
        setServiceLoader(true);
        let allServiceParams = {'serviceName' : searchFormValues.searchByServiceName, 'professionName' : searchFormValues.searchByProfessionName, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
        getAllServiceList(allServiceParams).then(res => serviceResponse(res));
        getAllProfessions().then(res => professionResponse(res));
    },[serviceAdd, currentPage, sortBy, sortDir, perPage]);

    // service list api response
    const serviceResponse = (response) => {
        if(response.data.statusCode === 200){
            setServiceList(response.data.serviceList);
            setServiceLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setServiceLoader(false);
        }
    }

    // profession list api response
    const professionResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllProfession(response.data.professionList)
        }
    }

    // add service buttion onclick 
    const handleShow = () => {
        setAddServiceStatus(true);
    }

    // call back from add profession popup
    const getStatusFromAddPopup = (popupStatus, reloadStatus) => {
        if(popupStatus === true){
            setAddServiceStatus(false);
        }

        if(reloadStatus === true){
            setAddServiceStatus(false);
            setServiceAdd(prevServiceCheck => !prevServiceCheck);
        }
    }

    const updateHandleShow = (data) => {
        setEditServiceStatus(true);
        setEditServiceData(data);
    }

    // call back from add profession popup
    const getStatusFromEditPopup = (popupStatus, reloadStatus) => {
      if(popupStatus === true){
          setEditServiceStatus(false);
      }

      if(reloadStatus === true){
          setEditServiceStatus(false);
          setServiceAdd(prevServiceCheck => !prevServiceCheck);
      }
    }

    // delete icon onclick
    const deleteUser = (data)=> {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this Service?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var id = data.id;
                var whereDelete = {"serviceId" : id};
                deleteService(whereDelete).then(res => handleDeleteResponse(res, id));
            }
        })
    }

    // delete service api response
    const handleDeleteResponse = (response, profession_id) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            if(serviceList.length === 1 && currentPage !== 1){
                setCurrentPage(currentPage - 1);
            }else{
                setServiceAdd(prevServiceCheck => !prevServiceCheck);
            }
        } else { 
            const errors = {};
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    // new filter button onclick
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    // clear search button onlick
    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('asc');
        setServiceLoader(true);
        setClearbtnDisable(true);

        let allServiceParams = {'serviceName' : '', 'professionName' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        getAllServiceList(allServiceParams).then(res => serviceResponse(res));
    }

    // search input field onchange
    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // search button onclick
    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);
            setServiceLoader(true);

            let allServiceParams = {'serviceName' : searchFormValues.searchByServiceName, 'professionName' : searchFormValues.searchByProfessionName, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
            getAllServiceList(allServiceParams).then(res => searchServiceResponse(res));
        }
    }

    // search api response
    const searchServiceResponse = (response) => {
        if(response.data.statusCode === 200) {
            setServiceList(response.data.serviceList);
            setServiceLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setServiceLoader(false);
        }
    }

    // search input field validation
    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if((values.searchByProfessionName == '' || values.searchByProfessionName.trim() === '') && (values.searchByServiceName == '' || values.searchByServiceName.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        const validations = {
            searchByProfessionName: HtmlTagValidation(values.searchByProfessionName),
            searchByServiceName: HtmlTagValidation(values.searchByServiceName)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    // call back function from pagination
    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    // sorting icon onclick
    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Manage Services</h1>
                                </Col>
                            </Row> 
                            <Row className="mb-3 managesearch">
                                <Col className="">   
                                    <Stack direction="horizontal" className="align-items-end" gap={2}>
                                        <div className="add-btn ms-auto">
                                            <Button variant="success" onClick={handleShow}>+ Add Service</Button>  
                                        </div>
                                        <div className="search-status-btn">
                                          {
                                              searchStatus == false ?
                                                  <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                              :
                                                  ''
                                          }
                                        </div>
                                    </Stack>                        
                                </Col>
                            </Row>
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                            searchStatus == false ? 
                                                ''
                                            :
                                                <>
                                                    <Col xl={8}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Service Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByServiceName' placeholder='Service name...' onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Profession Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfessionName' placeholder='Profession name...' onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={4} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit'>Search</Button>
                                                        <Button variant="danger" onClick={clearSearchOnClick} disabled={clearbtnDisable} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                </> 
                                        }
                                        <span className='error'>{searchErrors}</span>
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col >
                                    <div className="table-responsive" id='no-more-tables'>
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>
                                                        Services
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'name' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'name', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/>}</span>
                                                            :
                                                                ''
                                                        }
                                                    </th>
                                                    <th>
                                                        Profession
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'professionName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'professionName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'professionName', dir : 'asc'})}/>}</span>
                                                            : 
                                                                ''
                                                        }
                                                    </th>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    serviceLoader === true ?
                                                        <tr>
                                                            <td colSpan='5' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                        </tr>
                                                    :
                                                        serviceList.length > 0 ?
                                                            serviceList.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title='Services'>{data.name}</td>
                                                                    <td data-title='Profession'>{data.professionName}</td>
                                                                    {
                                                                        data.description ?
                                                                            <td data-title='Description' className='seviceDescriptionAvail'>{data.description}</td>
                                                                        :
                                                                            <td data-title='Description' className='seviceDescriptionNotAvail'><span>No Description</span></td>
                                                                    }
                                                                    <td data-title='Action'>
                                                                        <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                            <div >
                                                                                <Image src={edit} style={{cursor: 'pointer'}} onClick={() => updateHandleShow(data)} className='tableActionIcon' />
                                                                                <Button className='actionText editBtn' style={{cursor: 'pointer'}} onClick={() => updateHandleShow(data)}>Edit</Button>
                                                                            </div>
                                                                            <div className="">
                                                                                <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteUser(data)} className='tableActionIcon' />
                                                                                <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteUser(data)}>Delete</Button>
                                                                            </div>
                                                                        </Stack>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        : 
                                                            <tr>
                                                                <td colSpan='5' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>    
                            </Row>
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }

                            {
                              addServiceStatus === true ?
                                  <AddServicePopup getBack={getStatusFromAddPopup} professionList={allProfession} />
                              :
                                  ''
                            }

                            {
                              editServiceStatus === true ?
                                  <EditServicePopup getBack={getStatusFromEditPopup} professionList={allProfession} serviceData={editServiceData}/>
                              :
                                  ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 