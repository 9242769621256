import { React, useState, useEffect } from "react";
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { doAdminforgotPassword } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { HtmlTagValidation, BlockSQLInjection} from '../../Reusable';

export default function ForgotPassword() {
    // state declaration
    const initialValues = {email: "" };
    const [formValues, setformValues] = useState(initialValues);
    const [FormErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);

    // get customer privacy preference data from store
    const privacyStatus =  useSelector(store => store.user['privacypreference']);

    useEffect(() => {
        if(privacyStatus === true){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.remove('ppbtnhide');
                btn2.classList.remove('ppbtnhide');
            }
        }else if(privacyStatus === false){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.add('ppbtnhide');
                btn2.classList.add('ppbtnhide');
            }

        }
    },[])

    // input field onchange
    const handleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // form onsubmit
    const handleSubmit = (e) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
            doAdminforgotPassword(formValues).then(res => handleResponse(res));
            setLoader(true);
        }
    }

    // forgot password api response
    const handleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }
  
    // input field validation
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;

        if(!values.email){
          errors.email = 'Email is required';
          errors.status = false;
        } else if (!regex.test(values.email)) {
          errors.email = "This is not a valid email format!";
          errors.status = false;
        }

        let tagValidate = HtmlTagValidation(values.email);

        if(!tagValidate){
            errors.email = "Invalid Input";
            errors.status = false;
        }

        return errors;
    } 
    
    return(
        <section className='login-bg forgot-bg'>
            <Container>
                <Row className="justify-content-md-center ">
                    <Col md="auto">
                        <div className='text-center'>
                            <img src='images/logo.png'/>
                        </div>
                        <h1 className="text-center">Admin <br></br> <span>Forgot Password</span></h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control type="email" name='email' defaultValue={formValues.email} onChange={handleChange} />
                                <p className="error">{FormErrors.email}</p>
                            </Form.Group> 
                            <Stack gap={3} className="mx-autos">       
                                {
                                  loader === true ? 
                                      <div style={{textAlign : 'center'}}>
                                          <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                      </div>
                                  :
                                      <Button variant="secondary" type="submit">Submit <img src='images/arrow-right.svg'/></Button>
                                }           
                                <div className="text-center backto">Back to <Link to="/adminlogin" className="forgot" style={{"text-decoration": "none"}}>Login</Link></div>
                            </Stack>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 