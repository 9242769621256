import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../css/StylistStyle.css';
 
export default function AdminHeader () {
    return(
        <section className='top-nav'>
            <div className='container'>
                <div className='row'> 
                    <div className='col-12 col-md-12'>
                        
                    </div>
                </div> 
            </div>
        </section>
    )

}