import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { doCustomerRegister} from '../../../Service';
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import TermsAndConditions from './Popup/TermsAndConditions';
import CaptchaBackground from '../../../images/captcha-background.jpeg';
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection} from '../../Reusable';
import { JsonEncode, JsonDecode } from '../../Reusable/JsonModify';

export default function Login() {
    // state declaration
    const navigate = useNavigate();
    const recaptcha = useRef(null);

    const initialValues ={ firstName:"", lastName:"", phoneNumber:"", gender:"", email:"", password:"", confirmpassword:""};
    const [formValues, setformValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [confrimPassEye, setConfrimPassEye] = useState(false);
    const [tearmsCheckedStatus, setTearmsCheckedStatus] = useState(false);
    const [tearmsPopupStatus, setTearmsPopupStatus] = useState(false);

    // get customer privacy preference data from store
    const privacyStatus =  useSelector(store => store.user['privacypreference']);

    useEffect(() => {
        if(privacyStatus === true){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.remove('ppbtnhide');
                btn2.classList.remove('ppbtnhide');
            }
        }else if(privacyStatus === false){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.add('ppbtnhide');
                btn2.classList.add('ppbtnhide');
            }

        }
    },[])

    useEffect(()=> {
        captchaLoad();
    },[]);

    const captchaLoad = () => {
        let uniquechar = ""; 
        const randomchar ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 1; i < 5; i++) {
            uniquechar += randomchar.charAt(Math.random() * randomchar.length)
        }
        setCaptcha(uniquechar);
    }

    // input field onchange
    const handleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  }));

        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setformValues(prevState => ({
                ...prevState,
                [field]:  newValue  }));
        }
        const formatPhoneNumber = (numberValue) => {
            if (!numberValue) return numberValue;
            const phoneNumber = numberValue.replace(/[^\d]/g, "");
            const phoneNumberLength = phoneNumber.length;
            //if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 4) {
                return `(${phoneNumber.slice(0, 3)}`;
            }
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }
        

        if(field === 'phoneNumber'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setformValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  })
            );
        }
    }

    // register form onsubmit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);
        const formDefaultValues = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phoneNumber: formValues.phoneNumber,
            email: formValues.email,
            password: formValues.password,
            confirmpassword: formValues.confirmpassword,
            captcha: formValues.captcha,
            gender: "Male",
            termsAndCondition : tearmsCheckedStatus,
            reCaptchaToken : recaptcha.current.getValue()
        }
        if(errorCount.status){

            let jsonData = await JsonEncode(formDefaultValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doCustomerRegister(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    // register api response
    const handleResponse = (response) =>{
        if(response.data.statusCode==200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setTimeout(() => {
                navigate('/thanks');
            }, 3000);
            setLoader(false);
        } else {
            recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: response.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    // input field validation
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_-]).{8,}/;
        errors.status = true;
        //var numbers = /^[0-9]+$/;

        if(!values.firstName  || values.firstName.trim() === ''){
            errors.firstName = 'First name is required';
            errors.status = false;
        }

        if(!values.lastName  || values.lastName.trim() === ''){
            errors.lastName = 'Last name is required';
            errors.status = false;
        }
        
        if(!values.phoneNumber){
            errors.phoneNumber = 'Phone is required';
            errors.status = false;
        } else if(values.phoneNumber){
            const numval = values.phoneNumber.split('');
            if(numval[1] === '0'){
                errors.phoneNumber = 'Enter valid phone number';
                errors.status = false;
            }else if(values.phoneNumber && values.phoneNumber.length !== 14){
                errors.phoneNumber = 'Phone number must have 10 digit';
                errors.status = false;
            }
        }

        if(!values.email){
            errors.email = 'Email is required';
            errors.status = false;
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }

        if(!values.password){
            errors.password = 'Password is required';
            errors.status = false;
        }else if(values.password && !passRegex.test(values.password)){
            errors.password = 'Password should contain atleast a Uppercase, a Lowercase, a Number and a Special character';
            errors.status = false;
        }

        if(!values.confirmpassword){
            errors.confirmpassword = 'Confirm password is required';
            errors.status = false;
        }

        if(values.password && values.confirmpassword && values.password !== values.confirmpassword){
            errors.confirmpassword = 'Password and confirm password does not match';
            errors.status = false;
        }

        // if (!values.captcha) {
        //     errors.captcha = 'Captcha code is required';
        //     errors.status = false;
        // }

        // if (values.captcha && values.captcha != captcha) {
        //     errors.captcha = 'Invalid captcha code';
        //     errors.status = false;
        // }

        if(recaptcha.current.getValue() === ''){
            errors.captcha = 'Captcha is required';
            errors.status = false;
        }

        if (tearmsCheckedStatus === false) {
            errors.tearms = 'Accept terms & conditions';
            errors.status = false;
        }

        const validations = {
            email: HtmlTagValidation(values.email),
            password: HtmlTagValidation(values.password),
            confirmpassword: HtmlTagValidation(values.confirmpassword)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    // show passowrd icon onclick 
    const confrimPassView = () => {
        setConfrimPassEye(prevState => !prevState);
    }

    const getStatusFromTearmsPopup = (popupStatus) => {
        if(popupStatus === true){
            setTearmsPopupStatus(false);
        }
    }

    const tearmHandleChange = (e) => {
        if(e.target.checked === true){
            setTearmsCheckedStatus(true);
        }else{
            setTearmsCheckedStatus(false);
        }
    }

    const openTermsandConditions = () => {
        setTearmsPopupStatus(true);
    }

    return(
        <section className='login-bg register-bg'>
            <Container>
                <Row className="justify-content-md-center ">
                    <Col md={5} >
                        <div className='text-center'>
                            <img src='images/logo.png'/>
                        </div>
                        <h1 className="text-center">Customer <br></br> <span>Create an account</span></h1>
                        <Form onSubmit={handleSubmit}>  
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>First Name <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="text" name='firstName' value={formValues.firstName} onChange={handleChange} />
                                    <p className='error'>{formErrors.firstName}</p>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridLastName">
                                    <Form.Label>Last Name <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="text" name='lastName' value={formValues.lastName} onChange={handleChange} />
                                    <p className='error'>{formErrors.lastName}</p>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridStylistExperience">
                                    <Form.Label>Phone <span>*</span></Form.Label>l
                                    <Form.Control autoComplete="off" type="tel" name='phoneNumber' value={formValues.phoneNumber} onChange={handleChange}  maxLength={14}/>
                                    <p className='error'>{formErrors.phoneNumber}</p>
                                </Form.Group>
                                {/*<Form.Group as={Col} controlId="formGridServiceName">
                                    <Form.Label>Gender <span>*</span></Form.Label>
                                    <Form.Select aria-label="Default select example" name='gender' onChange={handleChange}>
                                        <option value=''>Select an option...</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </Form.Select>
                                    <p className='error'>{formErrors.gender}</p>
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="formBasicEmail">
                                    <Form.Label>Email <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="email" name='email' defaultValue={formValues.email} onChange={handleChange}  />
                                    <p className='error'>{formErrors.email}</p>
                                </Form.Group>
                            </Row>
                            
                            <Row className="mb-2"> 
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Password <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type='password' name='password' defaultValue={formValues.password} onChange={handleChange}/>
                                    <p className='error'>{formErrors.password}</p>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridConfirmPassword" className='passwordRow'>
                                    <Form.Label>Confirm Password <span>*</span></Form.Label>
                                    <FontAwesomeIcon icon={confrimPassEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type={confrimPassEye === false ? 'password' : 'text'} name='confirmpassword' defaultValue={formValues.confirmpassword} onChange={handleChange}/>
                                    <p className='error'>{formErrors.confirmpassword}</p>
                                </Form.Group>
                            </Row>
                            <Row>
                                {/* <Form.Group as={Col} controlId="formCaptchaLoad">
                                    <Form.Label>Captcha <span>*</span></Form.Label>
                                    <Row className='captchaRow'>
                                        <Col className='captchaCol'>
                                            <Form.Control type="text"   name='captcha' defaultValue={formValues.captcha} onChange={handleChange}/>
                                        </Col>
                                        <Col>
                                            <FontAwesomeIcon icon={faRotate} onClick={captchaLoad} /><Form.Text >{captcha}</Form.Text>
                                        </Col>
                                    </Row>
                                    <p className="error">{formErrors.captcha}</p>
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="formCaptchaLoad">
                                    <Form.Label>Captcha <span>*</span></Form.Label>
                                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                    <p className="error">{formErrors.captcha}</p>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formCheckTearm" className='tearmsAndConditions'>
                                    <Form.Check type="checkbox" checked={tearmsCheckedStatus} id='formCheckTearm' onChange={tearmHandleChange} className={tearmsCheckedStatus === true ? 'tearmsChecked' : 'termsdivition'} />
                                    <Form.Control.Feedback>
                                    By checking this box, I confirm I'm 18 years old or older and to view the Terms and Conditions <span onClick={openTermsandConditions} className='opentermsandconditions'>click here</span>
                                    </Form.Control.Feedback>
                                    <p className="error">{formErrors.tearms}</p>
                                </Form.Group> 
                            </Row>
                            <Stack gap={2} className="mx-autos">     
                                {
                                    loader === true ? 
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Button variant="secondary" type='submit'>Register <img src='images/arrow-right.svg'/></Button>
                                }   
                            </Stack>
                            <p className='customerNotes'><span>*</span> If you are a Service Professional looking to use UNEXT as your booking platform, create an account <Link to={"../stylistregistration"} style={{textDecoration : 'none'}}>here</Link></p>
                        </Form>

                        {
                            tearmsPopupStatus === true ?
                                <TermsAndConditions getBack={getStatusFromTearmsPopup} />
                            :       
                                ''
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 