import { React } from "react";
import { Modal } from "react-bootstrap";
import "../../css/AdminStyle.css";

export default function CancellationPolicy (){
return(
    <div className="customer-profile-bg"> 
        <div className="custom-profile"> 
            <div className="container"> 
            <div className="row"> 
                <div className="col"> 
                    <div className='text-center'>
                    <img src='images/logo.png'/>
                    </div>
                    <h1 className="termsHead">Cancellation Policy</h1> <br/>

                        <div className=" termsSection userAgrement">
                        <h2>Life can be unpredictable, and we understand that plans may need to change. Here's what you need to know about our
                        cancellation policy:</h2>

                            <ul>
                                <li>Cancellations must be made at least 24 hours prior to the scheduled appointment to avoid any charges.</li>
                                <li>If a cancellation is made within 24 hours of the appointment, a cancellation fee of 50% will be charged.</li>
                                <li>In the event of No show as well as cancellation made with in 24 hours of appointment, a Platform fee of 3% will be charged.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

)
}