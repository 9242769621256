import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Stack, Table, Modal, Form } from "react-bootstrap";
import deleteIcon from "../../../images/delete.png";
import Topbar from '../Assets/Topbar';
import edit from "../../../images/edit.png";
import LeftSidebar from '../Assets/LeftSidebar';
import { useSelector } from 'react-redux';
import { stylistServiceList, getAllServiceListByProfessionId } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../Assets/Pagination';
import AddServicePopup from './Popup/AddServicePopup';
import EditServicePopup from './Popup/EditServicePopup';
import DeleteServicePopup from './Popup/DeleteServicePopup';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';

export default function ManageStylist() {
    // initial state declaration
    const [serviceList, setServiceList] = useState([]);
    const [serviceListByProfession, setServiceListByProfession] = useState([]);
    const [serviceRefresh, setServiceRefresh] = useState(false);
    const [serviceLoader, setServiceLoader] = useState(true);
    const searchInitialValues = {searchByServiceName : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('desc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [addServicePopupStatus, setAddServicePopupStatus] = useState(false);
    const [editServicePopupStatus, setEditServicePopupStatus] = useState(false);
    const [editServiceData, setEditServiceData] = useState({});
    const [updateDurationTime, setUpdateDurationTime] = useState({hours : '', minutes : ''});
    const [deletePopupStatus, setDeletePopupStatus] = useState(false);
    const [deleteServiceId, setDeleteServiceId] = useState('');

    // get Professional data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get service data by userid
    useEffect(() => {
        setServiceLoader(true);
        const userId = {'clientId' : userData.clientId, 'searchServiceName' : searchFormValues.searchByServiceName, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
        stylistServiceList(userId).then(res => initialStylistServiceResponse(res));
    },[serviceRefresh, currentPage, sortBy, sortDir, perPage]);

    const initialStylistServiceResponse = (response) => {
        if(response.data.statusCode === 200){
            setServiceList(response.data.productList);
            setServiceLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setServiceLoader(false);
        }
    }

    // get service by profession
    useEffect(() => {
        const professionId = {'professionId' : userData.professionId};
        getAllServiceListByProfessionId(professionId).then(res => initialServiceResponse(res));
    },[]);

    const initialServiceResponse = (response) => {
        if(response.data.statusCode === 200){
            setServiceListByProfession(response.data.serviceList);
        }
    }

    // service add popup open function
    const handleShow = () => setAddServicePopupStatus(true);

    // profession delete functionality start here
    const deleteService = (data)=> {
        setDeleteServiceId(data.productId);
        setDeletePopupStatus(true);
    }

    const updateHandleShow = (data) => {
        let durationinMinutes = data.duration;
        let getHours = Math.floor(durationinMinutes / 60);
        let hoursInminutes = getHours * 60;
        let getMinutes = durationinMinutes - hoursInminutes;
        setEditServicePopupStatus(true);
        setEditServiceData({baseServiceId : data.baseServiceId, baseserviceName : data.baseserviceName, description : data.description, price : data.price, oldPrice : data.price, productId : data.productId, subServiceId : data.subServiceId, subServiceName : data.subServiceName});
        setUpdateDurationTime({'hours' : getHours, 'minutes' : getMinutes})
    };

    
    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('desc');
        setClearbtnDisable(true);
        setServiceLoader(true);

        const userId = {'clientId' : userData ? userData.clientId : '', 'searchServiceName' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'desc'};
        stylistServiceList(userId).then(res => initialStylistServiceResponse(res));

    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setServiceLoader(true);
            setCurrentPage(1);
            setPerPage(10);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);

            const userId = {'clientId' : userData.clientId, 'searchServiceName' : searchFormValues.searchByServiceName, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
            stylistServiceList(userId).then(res => searchServiceResponse(res));
        }
    }

    const searchServiceResponse = (response) => {
        if(response.data.statusCode === 200) {
            setServiceList(response.data.productList);
            setServiceLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setServiceLoader(false);
        }
    }

    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if(values.searchByServiceName == '' || values.searchByServiceName.trim() == ''){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        let tagValidate = HtmlTagValidation(values.searchByServiceName);

        if(!tagValidate){
            errors.search = 'Invalid input';
            errors.status = false;
        }

        return errors;
    }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    // call back from add service popup
    const getStatusFromAddServicePopup = (reloadStatus) => {
        setAddServicePopupStatus(false);

        if(reloadStatus === true){
            setServiceRefresh(prevServiceRefresh => !prevServiceRefresh);
        }
    }

    // call back from edit service popup
    const getStatusFromEditServicePopup = (reloadStatus) => {
        setEditServicePopupStatus(false);
        setEditServiceData({});

        if(reloadStatus === true){
            setServiceRefresh(prevServiceRefresh => !prevServiceRefresh);
        }
    }

    // call back from edit service popup
    const getStatusFromDeleteServicePopup = (reloadStatus) => {
        setDeletePopupStatus(false);
        setDeleteServiceId('');

        if(reloadStatus === true){
            if(serviceList.length === 1 && currentPage !== 1){
                setCurrentPage(currentPage - 1);
            }else{
                setServiceRefresh(prevServiceRefresh => !prevServiceRefresh);
            }   
        }
    }
    
    return(
        <section className='dashboard'>
            {/* Service list HTML start here */}
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Manage Services</h1>
                                </Col>
                            </Row> 
                            <Row className="mb-3 managesearch">
                                <Col>
                                    <Stack direction="horizontal" className="align-items-end" gap={2}>
                                        <div className="add-btn ms-auto">
                                            <Button variant="success" onClick={handleShow}>+ Add Service</Button>  
                                        </div>
                                        <div className="search-status-btn">
                                        {
                                            searchStatus == false ?
                                                <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                            :
                                                ''
                                        }
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                    {
                                        searchStatus == true ? 
                                            <>
                                                <Col xl={4}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group as={Col} controlId="formGridSearchName">
                                                                <Form.Label>Service Name</Form.Label>
                                                                <Form.Control type="text" name='searchByServiceName' placeholder='Service name...' onChange={searchHandleChange} onKeyDown={BlockSQLInjection}/>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl={8} className="searcchAllBtn">
                                                    <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                    <Button variant="danger" onClick={clearSearchOnClick} disabled={clearbtnDisable} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                </Col>
                                                <span className='error'>{searchErrors}</span>
                                            </>
                                        :
                                            ''
                                    }
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col >
                                    <div className="table-responsive" id='no-more-tables'>
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Base service</th>
                                                    <th>
                                                        Services
                                                        {
                                                            listCount > 0 ?
                                                                <span className='sort'> {sortBy == 'serviceName' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'serviceName', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'serviceName', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'serviceName', dir : 'asc'})}/>}</span>
                                                            :
                                                                ''
                                                        }
                                                    </th>
                                                    <th>Cost</th>
                                                    <th>Duration</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    serviceLoader === true ?
                                                        <tr>
                                                            <td colSpan='6' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                        </tr>
                                                    :
                                                        serviceList.length > 0 ?
                                                            serviceList.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title='Base service'>{data.baseserviceName}</td>
                                                                    <td data-title='Services'>{data.subServiceName}</td>
                                                                    <td data-title='Cost'>${getFullCost(data.price)}</td>
                                                                    <td data-title='Duration'>{data.duration} Mins</td>
                                                                    <td data-title='Action'>
                                                                        <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                        <div >
                                                                            <Image src={edit} style={{cursor: 'pointer'}} onClick={() => updateHandleShow(data)} className='tableActionIcon'  />
                                                                            <Button className='actionText editBtn' style={{cursor: 'pointer'}} onClick={() => updateHandleShow(data)}>Edit</Button>
                                                                        </div>
                                                                        <div className="">
                                                                            <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteService(data)} className='tableActionIcon'  />
                                                                            <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteService(data)}>Delete</Button>
                                                                        </div>
                                                                        </Stack>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        :
                                                            <tr>
                                                                <td colSpan='6' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        </Table> 
                                    </div>
                                </Col>    
                            </Row>
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>

            {
                addServicePopupStatus ? 
                    <AddServicePopup serviceList={serviceListByProfession} getBack={getStatusFromAddServicePopup}/>
                :
                    ''
            }

            {
                editServicePopupStatus ? 
                    <EditServicePopup serviceData={editServiceData} duration={updateDurationTime} getBack={getStatusFromEditServicePopup}/>
                :
                    ''
            }

            {
                deletePopupStatus ?
                    <DeleteServicePopup serviceId={deleteServiceId} getBack={getStatusFromDeleteServicePopup}/>
                :
                    ''
            }
        </section>
    )
} 