import { React, useEffect, useMemo, useState } from "react";
import {Button, Col, Container, Modal, Row, Form, Stack, OverlayTrigger } from "react-bootstrap"; 
import { Scheduler } from "devextreme-react";
import { Editing } from "devextreme-react/scheduler";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { InfinitySpin } from 'react-loader-spinner';
import LeftSidebar from '../../Assets/LeftSidebar';
import Topbar from '../../Assets/Topbar';
import { postClientBookingSlotList, postClientUpcomingBookingSlotList, customerBookingList, postClientSelfBooking, postClientUpcomingSlotDaysList } from "../../../../Service";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { getYear, getWeek, addYears, getMonth } from "date-fns";
import Tooltip from 'react-bootstrap/Tooltip';
import { JsonEncode } from "../../../Reusable/JsonModify";

export default function SelfBooking () {
    const navigate = useNavigate();

    // get Professional data from store
    const userData =  useSelector(store => store.user.stylistuser);

    // initial state declaration
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [ daysTimeSlot, setDaysTimeSlot ] = useState({});
    const [ daysBreakTimeSlot, setDaysBreakTimeSlot ] = useState({});
    const [ timezone, setTimezone ] = useState('');
    const [slotList, setSlotList] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [slotListLoader, setSlotListLoader] = useState(true);
    const [show, setShow] = useState(false);
    const [FormErrors, setFormErrors] = useState('');
    const [bookingList, setBookingList] = useState([]);
    const [slotCount, setSlotCount] = useState(0);
    const [allDayOpen, setAllDayOpen] = useState(true);
    const [bookingLoader, setBookingLoader] = useState(false);
    const [updatedDaysList, setUpdatedDaysList] = useState([]);
    const [statusOfDays, setstatusOfDays] = useState('');

    const views = ['month'];

    const clientId = {"clientId": userData.clientId};

    // initial get api declaration
    useEffect(()=>{
        let disableParams = {"clientId": userData.clientId, 'fromDate' : new Date()};
        postClientUpcomingSlotDaysList(disableParams).then(res => updatedDaysHandleResponse(res));
        postClientBookingSlotList(clientId).then(res => initialClientBookingSlotList(res));
    }, []);

    const updatedDaysHandleResponse = (res) => {
        if(res.data.statusCode === 200){
            setUpdatedDaysList(res.data.data);
        }
    }

    // client slot and break data api handle response
    const initialClientBookingSlotList = (response) => {
        if(response.data.statusCode === 200) {
            setstatusOfDays(response.data.statusOfTheDays);
            setDaysTimeSlot(response.data.daysTimeSlot);
            setDaysBreakTimeSlot(response.data.daysBreakTimeSlot);
            setTimezone(response.data.timeZone);
            setAllDayOpen(response.data.statusOfTheDays.allDay);
        }
    }

    const checkDisabled = (day, allDays, checkDayStatus) => {
        let dayName = '';
        let dayStatus = true;
        if(day === 0){
            dayName = 'sunday';
        }else if(day === 1){
            dayName = 'monday';
        }else if(day === 2){
            dayName = 'tuesday';
        }else if(day === 3){
            dayName = 'wednesday';
        }else if(day === 4){
            dayName = 'thursday';
        }else if(day === 5){
            dayName = 'friday';
        }else if(day === 6){
            dayName = 'saturday';
        }

        if(allDays){
            dayStatus = allDays[dayName];
        }

        if(checkDayStatus.length > 0){
            if(dayStatus === true && checkDayStatus[0].status === false){
                dayStatus = false;
            }else if(dayStatus === false && checkDayStatus[0].status === true){
                dayStatus = true;
            }
        }

        return dayStatus;
    }

    const dataCellRender = (itemData) => {
        const { startDate } = itemData;
        if(timezone){
            let checkDayStatus = updatedDaysList.filter((item) => {return item.date === moment(startDate).format('YYYY-MM-DD')});
            let checkDisable = checkDisabled(itemData.startDate.getDay(), statusOfDays, checkDayStatus);
            let zoone = timezone.split(' ');
            let currentZoneTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));

            const isDisabled =  startDate.getFullYear() < currentZoneTime.getFullYear() || (startDate.getDate() < currentZoneTime.getDate() && startDate.getMonth() === currentZoneTime.getMonth() && startDate.getFullYear() <= currentZoneTime.getFullYear())  || (startDate.getMonth() < currentZoneTime.getMonth() && startDate.getFullYear() === currentZoneTime.getFullYear()) ? true : checkDisable === false ? true : false;

            return <div className={ isDisabled ? 'dx-scheduler-date-table-cell-text disable-data-table-cell' : "dx-scheduler-date-table-cell-text" }>{itemData.startDate.getDate()}</div>
        }
    }

    // const onCellClick = (e) => {
    //     let zoone = timezone.split(' ');
    //     let currentZoneTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));
    //     const startDate  = e.cellData.startDate;
    //     setSelectedDate(moment(e.cellData.startDate).format('YYYY-MM-DD'));
    //     const currentDate = startDate;
    //     let currentYearNumber = getYear(startDate);
    //     let currentMonthNumber = getMonth(startDate) + 1;
    //     if(currentMonthNumber == 12 && getWeek(startDate)  == 1){
    //         currentYearNumber = getYear(addYears(startDate, 1));
    //     }
    //     const clientAndWeekId = {"clientId": userData.clientId, "week": getWeek(startDate), "year" : currentYearNumber};

    //     const currentTimeZoneDate = moment().tz(zoone[0]).format('YYYY-MM-DD');
    //     const currentCellClickdate = moment(e.cellData.startDate).format('YYYY-MM-DD');

    //     if (currentTimeZoneDate <= currentCellClickdate) {
    //         setShow(true);
    //         let newAPIDate =  moment(e.cellData.startDate).format('YYYY-MM-DD');
    //         const whereBookingSlot = {'clientId' : userData.clientId, 'filterDate' : newAPIDate, 'filterType' : 'day'}
    //         customerBookingList(whereBookingSlot).then(res => initialBooedResponse(res));

    //         const initialBooedResponse = (response) => {
    //             if(response.data.statusCode === 200){
    //                 setBookingList(response.data.customerBookingList);
    //             }
    //             postClientUpcomingBookingSlotList(clientAndWeekId).then(response => initialUpcommingBookingSlotList(response, startDate));
    //         }
            
    //     }else if (currentTimeZoneDate > currentCellClickdate) {
    //         setShow(false);
    //     }   
    // }

    const onCellClick = (e) => {
        let zoone = timezone.split(' ');
        const startDate  = e.cellData.startDate;
        let currentYearNumber = getYear(startDate);
        let currentMonthNumber = getMonth(startDate) + 1;
        if(currentMonthNumber == 12 && getWeek(startDate)  == 1){
            currentYearNumber = getYear(addYears(startDate, 1));
        }
        const clientAndWeekId = {"clientId": userData.clientId, "week": getWeek(startDate), "year" : currentYearNumber};

        const currentTimeZoneDate = moment().tz(zoone[0]).format('YYYY-MM-DD');
        const currentCellClickdate = moment(e.cellData.startDate).format('YYYY-MM-DD');
        let checkDayStatus = updatedDaysList.filter((item) => {return item.date === moment(startDate).format('YYYY-MM-DD')});
        let checkCurrentDay =  checkDisabled(moment(e.cellData.startDate).day(), statusOfDays, checkDayStatus);
        if (currentTimeZoneDate <= currentCellClickdate && checkCurrentDay) {
            if (currentTimeZoneDate <= currentCellClickdate) {
                setSelectedDate(moment(e.cellData.startDate).format('YYYY-MM-DD'));
                setShow(true);
                let newAPIDate =  moment(e.cellData.startDate).format('YYYY-MM-DD');
                const whereBookingSlot = {'clientId' : userData.clientId, 'filterDate' : newAPIDate, 'filterType' : 'day'}
                customerBookingList(whereBookingSlot).then(res => initialBooedResponse(res));

                const initialBooedResponse = (response) => {
                    if(response.data.statusCode === 200){
                        setBookingList(response.data.customerBookingList);
                    }
                    postClientUpcomingBookingSlotList(clientAndWeekId).then(response => initialUpcommingBookingSlotList(response, startDate));
                }
            }else if (currentTimeZoneDate > currentCellClickdate) {
                setShow(false);
            }
        }
    }

    // get upcomming api response hanlde
    const initialUpcommingBookingSlotList = (response, startDate) => {
        if(response.data.statusCode === 200){
           if (response.data.clientId === 0) {
                let startDay = startDate.toString().split(' ')[0].toUpperCase();

                let slotTime = [];
                let dayTimeSlot = daysTimeSlot;
                for( let x in dayTimeSlot){
                    let slotTimeDay = x.toUpperCase();
                    if (slotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithSlotTime = dayTimeSlot[x];
                        slotTime.push(daysWithSlotTime);
                    }
                }
                
                let breakSlotTime = [];
                let breakTimeSlot = daysBreakTimeSlot;
                for( let x in breakTimeSlot){
                    let breakSlotTimeDay = x.toUpperCase();
                    if (breakSlotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithBreakSlotTime = breakTimeSlot[x];
                        breakSlotTime.push(daysWithBreakSlotTime);
                    }
                }

                let slotTimeArr = [];
                let breakSlotTimeArr = [];
                if(breakSlotTime[0] != ''){
                    breakSlotTimeArr = {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]};

                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : breakSlotTime[0]}, {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]}, {startDayTime : breakSlotTime[1], endDayTime : slotTime[1]}];
                }else{
                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : slotTime[1]}];
                }

                getSlot(slotTimeArr, startDate);

            }else{
                let dayTimeSlot = response.data.daysTimeSlot;
                let slotTime = [];
                let startDay = startDate.toString().split(' ')[0].toUpperCase();
                
                for( let x in dayTimeSlot){
                    let slotTimeDay = x.toUpperCase();
                    if (slotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithSlotTime = dayTimeSlot[x];
                        if(!daysWithSlotTime){
                        }
                        
                        slotTime.push(daysWithSlotTime);
                    }
                }
                
                let breakTimeSlot = response.data.daysBreakTimeSlot;
                let breakSlotTime = [];
                for( let x in breakTimeSlot){
                    let slotTimeBreak = x.toUpperCase();
                    if (slotTimeBreak.indexOf(startDay) !== -1) {
                        let daysWithBreakSlotTime = breakTimeSlot[x];
                        breakSlotTime.push(daysWithBreakSlotTime);
                    }
                }
                let slotTimeArr = [];
                let breakSlotTimeArr = [];
                if(breakSlotTime[0] != ''){
                    breakSlotTimeArr = {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]};

                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : breakSlotTime[0]}, {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]}, {startDayTime : breakSlotTime[1], endDayTime : slotTime[1]}];
                }else{
                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : slotTime[1]}];
                }       

                getSlot(slotTimeArr, startDate);

            }
        }
    }

    const getAvailableList = () => { // set break and booking into individual slots function 
        if(bookingList.length > 0 ){
            bookingList.map((list) => { 
                if(list.status == 'Booked'){
                    let bookOpen = new Date(moment(selectedDate).format('YYYY-MM-DD')+' '+moment(list.startDateTime).format('HH:mm'));
                    let bookClose = new Date(moment(selectedDate).format('YYYY-MM-DD')+' '+moment(list.endDateTime).format('HH:mm'));
                    let bookingStartingTime = moment(bookOpen).format('HH:mm');
                    let bookingStartminutes = bookingStartingTime.split(':');
                    let bookingStartinMin = (Number(bookingStartminutes[0]) * 60) + Number(bookingStartminutes[1]);
                    let bookingEndingTime = moment(bookClose).format('HH:mm');
                    let bookingEndminutes = bookingEndingTime.split(':');
                    let bookingEndinMin = (Number(bookingEndminutes[0]) * 60) + Number(bookingEndminutes[1]);
                    slotList.map((data, index) => {
                        let slotStartingTime = moment(data.startDate).format('HH:mm');
                        let slotStartminutes = slotStartingTime.split(':');
                        let slotStartinMin = (Number(slotStartminutes[0]) * 60) + Number(slotStartminutes[1]);
                        let slotEndingTime = moment(data.endDate).format('HH:mm');
                        let slotEndminutes = slotEndingTime.split(':');
                        let slotendinMin = (Number(slotEndminutes[0]) * 60) + Number(slotEndminutes[1]);
                        if((bookingStartinMin < slotStartinMin && slotStartinMin < bookingEndinMin) || (bookingStartinMin < slotendinMin && slotendinMin <= bookingEndinMin)){
                            slotList[index] = {...data, ...{isBooked : true}};
                        } else if((bookingStartinMin > slotStartinMin && bookingStartinMin < slotendinMin) || (bookingEndinMin > slotStartinMin && bookingEndinMin <= slotendinMin)){
                            slotList[index] = {...data, ...{isBooked : true}};
                        }
                    })
                }
            })
        }
    }

    useMemo(() => getAvailableList(), [slotList]);

    // get individual slot from day open time and close time 
    const getSlot = (slotTime, selectDay) => {
        const finalArray = [];
        if(allDayOpen === true){
            let breakStatus = slotTime.length === 3 ? true : false;
            slotTime.map((data, index) => {
                if(breakStatus === true && index === 1){
                    var startTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.startDayTime);
                    var endTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.endDayTime);

                    let dateloop = {'startDate' : moment(selectDay).format("YYYY-MM-DD")+'T'+moment(startTimeForSelectDate).add(1, 'minutes').format("HH:mm"), 'endDate' : moment(selectDay).format("YYYY-MM-DD")+'T'+moment(endTimeForSelectDate).format("HH:mm"), 'isBreak' : true};
                    finalArray.push(dateloop);
                }else{
                    const slotTimer = 15;
                    var startTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.startDayTime);
                    var endTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.endDayTime);
                    
                    var newDateObj = new Date();
                    var newstartDate = startTimeForSelectDate;
                    newDateObj.setTime(newstartDate.getTime() + (slotTimer * 60000));
                    while(newDateObj <= endTimeForSelectDate){
                        let slotStart = moment(newstartDate).format("YYYY-MM-DD")+'T'+moment(newstartDate).format("HH:mm");
                        let slotEnd = moment(newDateObj).format("YYYY-MM-DD")+'T'+moment(newDateObj).format("HH:mm");
                        let dateloop = {'startDate' : slotStart, 'endDate' : slotEnd, 'isBreak' : false};
                        finalArray.push(dateloop);
                        newstartDate.setTime(newDateObj.getTime() + 60000);
                        newDateObj.setTime(newDateObj.getTime() + (slotTimer * 60000));
                    }
                }
            });
            finalArray.sort((date1, date2) => (date1.startDate > date2.startDate) ? 1 : (date1.startDate < date2.startDate) ? -1 : 0);
        }
        let timezoneSlots = [];
        finalArray.map(data => {
            let zoone = timezone.split(' ');
            let currentDateTime = moment().tz(zoone[0]).format('YYYY-MM-DD');
            let slotDateTime = moment(data.startDate).format('YYYY-MM-DD');
            if(currentDateTime === slotDateTime){
                                                                                
                let slotOpenTimeOnly = moment(data.startDate).format('HH:mm');
                let currentDateTimeOnly = moment().tz(zoone[0]).format('HH:mm');
                if(currentDateTimeOnly < slotOpenTimeOnly){
                    timezoneSlots.push(data);
                }
            }else{
                timezoneSlots.push(data);
            }    
        })
        setSlotList(timezoneSlots);
        setSlotListLoader(false);
    }

    // slot popup close
    const handleClose = () => {
        setShow(false);
        setFormErrors('');
        setSlotList([]);
        setSelectedDate('');
        setSlotListLoader(true);
        setSlotCount(0);
        setBookingList([]);
        setSelectedSlots([]);
    }

    // booking time slot handle change
    const bookingHandleChange = (e, data) => {
          
          let openFull = data.startDate.split("");
          if(openFull[15] == 6 || openFull[15] == 1){
            openFull[15] = openFull[15] - 1;
          }
          let updatedOpenTime = openFull.join('');
          const openUTC = updatedOpenTime;
          const closeUTC = data.endDate;
          let newObject = {'startDateTime' : openUTC, 'endDateTime' : closeUTC};

        if(e.target.checked === true){
            setSelectedSlots(prevState => [...prevState, newObject]);
        }else{
            let copySelectedSlots =  [...selectedSlots];
            copySelectedSlots.splice(copySelectedSlots.findIndex(a => a.startDateTime === openUTC) , 1);
            setSelectedSlots(copySelectedSlots);
        }
    }

    // booking submit 
    const handleBooking = async (e) => {
        e.preventDefault();
        if(selectedSlots.length > 0){
            setFormErrors('');
            setBookingLoader(true);
            let professionalName = userData.firstName+' '+userData.lastName;
            let selfBookingParams = {'clientId' : userData.clientId, 'clientName' : professionalName, 'shopId' : userData.shopId, 'shopName' : userData.shopName, 'status' : 'Self Booked', 'slotDateTime' : selectedSlots};

            let jsonData = await JsonEncode(selfBookingParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postClientSelfBooking(loginPayload).then(res => selfBookingHandleResponse(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }else{
            setFormErrors('Please Select Available Slots');
        }
    } 

    // self booking api response
    const selfBookingHandleResponse = (res) => {
        setBookingLoader(false);
        if(res.data.statusCode == 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            navigate('../stylist/bookingcalendar');
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div >
                                            <h1>Self Booking</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row className='addBooking-title'>
                                <h2>Choose a Date</h2>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md='12'>      
                                    <div className='booking_calendar'>
                                        <Scheduler
                                            views={views}
                                            defaultCurrentView='month'
                                            shadeUntilCurrentTime={true}
                                            adaptivityEnabled={true}
                                            dataCellRender={dataCellRender}
                                            onCellClick={onCellClick}
                                        >
                                            <Editing allowAdding={false} allowDeleting={false} />
                                        </Scheduler>
                                        
                                        <Modal show={show} onHide={handleClose} size="lg" className='booking-slot-model'>
                                            <Form onSubmit={handleBooking}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Select Slot</Modal.Title>
                                                </Modal.Header>
                                                {
                                                    slotListLoader === true ?
                                                        <div style={{textAlign:'center'}}>
                                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                        </div>
                                                    :
                                                        <>
                                                            <Modal.Body>
                                                                <Row >
                                                                    <Col>
                                                                        <p className="selfBookingNote"><span>* </span>Note: Select one or more slot</p>
                                                                        <Form.Label>Available Slots</Form.Label>
                                                                        <div className={ slotList.length > 4 ? "newSlot radio day-bookingslots checkSlot" : "newSlot radio day-bookingslots "}>
                                                                            <div>
                                                                                <h5>Morning</h5>
                                                                                {
                                                                                    slotList.length > 0 ?
                                                                                        slotList.map((data, index) => {
                                                                                            let openTime = moment(data.startDate).format('hh:mm A');
                                                                                            let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                            let openFull = openTime.split("");
                                                                                            if(openFull[4] == 6 || openFull[4] == 1){
                                                                                            openFull[4] = openFull[4] - 1;
                                                                                            }
                                                                                            let updatedOpenTime = openFull.join('');
                                                                                            if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                if(data.breakTime === true){

                                                                                                }else{
                                                                                                    let selectDateTimeForCheck = selectedDate+'T12:01';
                                                                                                    if(data.startDate < selectDateTimeForCheck){
                                                                                                        if(slotCount < 1){
                                                                                                            setSlotCount(slotCount + 1);
                                                                                                        }
                                                                                                        if(data.isBreak){
                                                                                                            return(
                                                                                                                <OverlayTrigger
                                                                                                                    placement="bottom"
                                                                                                                    overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                >
                                                                                                                    <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true || data.isBreak === true ? 'disabled' : ''}/>
                                                                                                                </OverlayTrigger>
                                                                                                                
                                                                                                            )
                                                                                                        }else{
                                                                                                            return(
                                                                                                                <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true ? 'disabled' : ''}/>
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    :
                                                                                        <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <h5>Afternoon</h5>
                                                                                {
                                                                                    slotList.length > 0 ?
                                                                                        slotList.map((data, index) => {
                                                                                            let openTime = moment(data.startDate).format('hh:mm A');
                                                                                            let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                            let openFull = openTime.split("");
                                                                                            if(openFull[4] == 6 || openFull[4] == 1){
                                                                                            openFull[4] = openFull[4] - 1;
                                                                                            }
                                                                                            let updatedOpenTime = openFull.join('');
                                                                                            if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                if(data.breakTime === true){

                                                                                                }else{
                                                                                                    let selectDateStartTimeForCheck = selectedDate+'T12:01';
                                                                                                    let selectDateEndTimeForCheck = selectedDate+'T17:01';
                                                                                                    if(data.startDate >= selectDateStartTimeForCheck && data.startDate < selectDateEndTimeForCheck){
                                                                                                        if(slotCount < 1){
                                                                                                            setSlotCount(slotCount + 1);
                                                                                                        }
                                                                                                        if(data.isBreak){
                                                                                                            return(
                                                                                                                <OverlayTrigger
                                                                                                                    placement="bottom"
                                                                                                                    overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                >
                                                                                                                    <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true || data.isBreak === true ? 'disabled' : ''}/>
                                                                                                                </OverlayTrigger>
                                                                                                                
                                                                                                            )
                                                                                                        }else{
                                                                                                            return(
                                                                                                                <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true ? 'disabled' : ''}/>
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    :
                                                                                        <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <h5>Evening</h5>
                                                                                {
                                                                                    slotList.length > 0 ?
                                                                                        slotList.map((data, index) => {
                                                                                            let openTime = moment(data.startDate).format('hh:mm A');
                                                                                            let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                            let openFull = openTime.split("");
                                                                                            if(openFull[4] == 6 || openFull[4] == 1){
                                                                                            openFull[4] = openFull[4] - 1;
                                                                                            }
                                                                                            let updatedOpenTime = openFull.join('');
                                                                                            if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                if(data.breakTime === true){

                                                                                                }else{
                                                                                                    let selectDateTimeForCheck = selectedDate+'T17:01';
                                                                                                    if(data.startDate >= selectDateTimeForCheck){
                                                                                                        if(slotCount < 1){
                                                                                                            setSlotCount(slotCount + 1);
                                                                                                        }
                                                                                                        if(data.isBreak){
                                                                                                            return(
                                                                                                                <OverlayTrigger
                                                                                                                    placement="bottom"
                                                                                                                    overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                >
                                                                                                                    <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true || data.isBreak === true ? 'disabled' : ''}/>
                                                                                                                </OverlayTrigger>
                                                                                                                
                                                                                                            )
                                                                                                        }else{
                                                                                                            return(
                                                                                                                <input key={index} type="checkbox" className={data.isBooked === true || data.isBreak === true ? 'bookedslot' : 'slot'} name={"slotId-"+index} value={data} openTime={data.startDate} closeTime={data.endDate} label={updatedOpenTime} onChange={(e) => bookingHandleChange(e, data)} disabled={data.isBooked === true ? 'disabled' : ''}/>
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    :
                                                                                        <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            slotCount == 0 && slotList.length > 0 ?
                                                                                <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                            :''
                                                                        }
                                                                        <p className="error">{FormErrors}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                {
                                                                    bookingLoader === true ?
                                                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                    :
                                                                        <Button variant="primary" type='submit'>Confirm Booking</Button>
                                                                }
                                                                <Button variant="secondary" onClick={handleClose} className="paymentCancelBtn">Cancel</Button>
                                                            </Modal.Footer>
                                                        </>
                                                }
                                            </Form>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}