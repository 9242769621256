import { getInstance } from "./api";

// General 
export const getAllBarberShop = async () => {
    const api = await getInstance(true,'');
    return api.post('/api/Shop/postShopList');
}

export const getAllCounty = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Master/postCountyList', data);
}

export const getAllState = async () => {
    const api = await getInstance(true, '');
    return api.post('api/Master/postStateList');
}

export const getAllProfessions = async () => {
    const api = await getInstance(true, '');
    return api.get('api/Profession/getProfessionList');
}

export const getAllProfessionsBySearch = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('api/Profession/getProfessionListBySearch', data);
}

export const postCreateProfession = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Profession/postCreateProfession', data);
}

export const deleteProfession = async (data) => {
    const api = await getInstance(true, '');
    return api.delete('api/Profession/deleteProfession', {'data' : data});
}

export const updateProfession = async (data) => {
    const api = await getInstance(true, '');
    return api.patch('api/Profession/patchUpdateProfession', data);
}

export const getAllServiceList = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Service/getServiceList', data);
}

export const getAllServiceListByProfessionId = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Service/postServiceListByProfessionId', data);
}

export const postCreateService = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Service/postCreateService', data);
}

export const deleteService = async (data) => {
    const api = await getInstance(true, '');
    return api.delete('api/Service/deleteService', {'data' : data});
}

export const updateService = async (data) => {
    const api = await getInstance(true, '');
    return api.patch('api/Service/patchUpdateService', data);
}

// Admin Data
export const doAdminLogin = async (data) => {
    const api = await getInstance(true,'');
    return api.post('/api/Admin/postLogin', data);
}
export const doAdminforgotPassword = async (data) => {
    const api = await getInstance(true, '');
    return api.post('/api/Admin/postForgotPassword', data);
}

export const getAdminDashboard = async () => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postDashboard', );
}

export const getAllClientList = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postClientList', data);
}

export const postClientAdd = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postClientAdd', data);
}

export const getAllCustomerList = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postCustomerList', data);
}

export const deleteStylistbyAdmin = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postClientDelete', data);
}

export const suspendStylistbyAdmin = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postClientSuspend', data);
}

export const adminTransactionList = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('/api/Admin/postTransactionList', data);
}

export const adminCustomerBookingHistory = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('api/Admin/postBookingListByCustomer', data);
}
export const adminBookingList = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('api/Admin/postBookingList', data);
}
export const getAuthUrlForQuickBooks = async () => {
    const api = await getInstance(false, 'admin');
    return api.get('api/Admin/getAuthUrlForQuickBooks');
}
export const postAccessTokenForQuickBooks = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('api/Admin/postAccessTokenForQuickBooks', data);
}
export const postQuickBookVendorCreation = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.post('api/Admin/postQuickBookVendorCreation', data);
}
export const patchClientUpdate = async (data) => {
    const api = await getInstance(false, 'admin');
    return api.patch('api/Admin/patchClientUpdate', data);
}

// Customer Data
export const doCustomerRegister = async (data) => {
    const api = await getInstance(true,'');
    return api.post('api/Customer/postRegister', data);
}
export const doCustomerLogin = async (data) => {
    const api = await getInstance(true,'');
    return api.post('api/Customer/postLogin', data);
}
export const doCustomerChangePassword = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('api/Customer/postChangePassword', data);
}
export const doCustomerforgotPassword = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Customer/postForgotPassword', data);
}
export const doCustomerUpdateProfile = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.patch('api/Customer/patchUpdateCustomer', data);
}
export const customerClientList = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Customer/postClientList', data);
}
export const customerClientDetail = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Customer/postClientDetail', data);
}
export const bookingSlotDayList = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Customer/postBookingSlotDayList', data);
}
export const addBooking = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('api/Customer/postBookingAdd', data);
}
export const customerBookingList = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('api/Customer/postBookingList', data);
}
export const customerMyBookingList = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('api/Customer/postBookingHistory', data);
}
export const postCreateCustomerCreditCard = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postCreateCustomerCreditCard', data);
}
export const patchCancelAuthorizeBookingSlot = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/patchCancelAuthorizeBookingSlot', data);
}
export const postCustomerAccountDetails = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postCustomerAccountDetails', data);
}
export const postCustomerTipAdd = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postCustomerTipAdd', data);
}
export const postMailBookingList = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postMailBookingList', data);
}
export const postCustomerDetails = async (data) => {
    const api = await getInstance(true, '');
    return api.post('/api/Customer/postCustomerDetails', data);
}
export const postCustomerCardDelete = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postCustomerCardDelete', data);
}
export const patchUpdateCustomerCardStatus = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.patch('/api/Customer/patchUpdateCustomerCardStatus', data);
}
export const postCustomerBookingApproveOrReject = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postCustomerBookingApproveOrReject', data);
}
export const postCustomerSendEnquiry = async (data) => {
    const api = await getInstance(true, '');
    return api.post('/api/Customer/postCustomerSendEnquiry', data);
}
export const postAddFavouriteClient = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postAddFavouriteClient', data);
}
export const postRemoveFavouriteClient = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postRemoveFavouriteClient', data);
}
export const postTempBookingAdd = async (data) => {
    const api = await getInstance(false, 'customer');
    return api.post('/api/Customer/postTempBookingAdd', data);
}



// client Data
export const doclientlogin = async (data) => {
    const api = await getInstance(true, '');
    return api.post('/api/Client/postLogin', data);
}
export const doclientforgotpassword = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Client/postForgotPassword', data);
}
export const doStylistRegistration = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Client/postRegister', data);
}
export const profileImageUpdate = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postProfileImageUpdate', data);
}
export const logoImageUpdate = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postProfileLogoUpdate', data);
}
export const getClientById = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postProfileDetailsById', data);
}
export const stylistChangePassword = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postChangePassword', data);
}
export const stylistServiceList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postProductList', data);
}
export const stylistServiceAdd = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postProductAdd', data);
}
export const deleteStylistService = async (data) => {
    const api = await getInstance(false, 'client');
    return api.delete('api/Client/deleteProduct', {'data' : data});
}
export const stylistProfileUpdate = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchProfileUpdate', data);
}
export const getStylistDashboard = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postDashboard', data);
}
export const getStylistCustomerList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postCustomerList', data);
}
export const customerBookingHistory = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postBookingHistoryList', data);
}
export const addStylistPortfolio = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postPortfolioCreate', data);
}
export const stylistPortfolioList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postPortfolioList', data);
}
export const deletePortfoliobystylist = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postPortfolioDelete', data);
}
export const stylistBookingSlotList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postBookingSlotList', data);
}
export const stylistBookingSlotAdd = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postBookingSlotAdd', data);
}
export const stylistBookingSlotEdit = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchBookingSlot', data);
}
export const deleteBookingSlot = async (data) => {
    const api = await getInstance(false, 'client');
    return api.delete('api/Client/deleteBookingSlot', {'data' : data});
}
export const StylistTransactionList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientTransactionList', data);
}
export const postClientOnlineTransaction = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientOnlineTransaction', data);
}
export const postClientCashRequest = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientCashRequest', data);
}
export const postClientAccountDetails = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientAccountDetails', data);
}
export const postClientAccountDetailsList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientAccountDetailsList', data);
}
export const postClientNoShowRequest = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientNoShowRequest', data);
}
export const postClientBookingSlotAdd = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientBookingSlotAdd', data);
}

export const postClientBookingSlotList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientBookingSlotList', data);
}

export const patchClientBookingSlotUpdate = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchClientBookingSlotUpdate', data);
}

export const postClientUpcomingBookingSlotList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientUpcomingBookingSlotList', data);
}

export const postUpComingBookingSlotAdd = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postUpComingBookingSlotAdd', data);
}

export const patchUpComingBookingSlot = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchUpComingBookingSlot', data);
}

export const patchUpdateProduct = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchUpdateProduct', data);
}

export const postClientAcountCreate = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientAcountCreate', data);
}

export const clientAccountList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/clientAccountList', data);
}

export const patchUpdateClientAcount = async (data) => {
    const api = await getInstance(false, 'client');
    return api.patch('api/Client/patchUpdateClientAcount', data);
}
export const postPayPalAuthCode = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postPayPalAuthCode', data);
}
export const postClientActiveStatus = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientActiveStatus', data);
}
export const postClientCancelBooking = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientCancelBooking', data);
}
export const postClientTransferSingleBooking = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientTransferSingleBooking', data);
}
export const postCustomerEmailVerification = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postCustomerEmailVerification', data);
}
export const postClientBookingAdd = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientBookingAdd', data);
}
export const postClientSelfBooking = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientSelfBooking', data);
}
export const postCreateSubService = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postCreateSubService', data);
}
export const postUpdateSubService = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postUpdateSubService', data);
}
export const postCustomerListBySearch = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postCustomerListBySearch', data);
}
export const postCancelSelfBooking = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postCancelSelfBooking', data);
}
export const postEmailAddressVerification = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Client/postEmailAddressVerification', data);
}
export const postResendVerificationEmail = async (data) => {
    const api = await getInstance(true, '');
    return api.post('api/Client/postResendVerificationEmail', data);
}
export const postClientUpcomingSlotDaysList = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientUpcomingSlotDaysList', data);
}
export const postClientEarnings = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientEarnings', data);
}
export const postClientTimeZoneDetails = async (data) => {
    const api = await getInstance(false, 'client');
    return api.post('api/Client/postClientTimeZoneDetails', data);
}