export const BlockInvalidNumber = ( e ) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
}

export const BlockSQLInjection = ( e ) => {
    ['<', '>'].includes(e.key) && e.preventDefault()
}

export const HtmlTagValidation = ( value ) => {
    let status = true;
    let tagValidate =/<(.|\n)*?>/g; 

    if ( tagValidate.test(value) ) {
        status = false;
    }

    return status;
}

export const bookingcalendarListColor = [
    {
        text : 'completedBooking',
        id : 1,
        color : '#008000'
    }, 
    {
        text : 'selfBooking',
        id : 2,
        color : '#6c757d'
    }, 
    {
        text : 'upcommingBokking',
        id : 3,
        color : '#ff5200'
    }
]