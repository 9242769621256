import React, { useEffect } from 'react';
import Footer from '../Components/Admin/Assets/Footer';
import {Outlet, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

const AdminLayout = ({ props }) => {
  const navigation = useNavigate();
  const apiToken = useSelector(store => store.user['admintoken']);
  const adminData = useSelector(store => store.user['adminuser']);
  useEffect(() => {
    if(!apiToken || !adminData){
      navigation('/adminlogin');
    }
  },[apiToken]);
    return (
    <>
     <main style={{ padding: '1rem 0' }}>
      <Outlet />
    </main>
    <Footer/>
    </>)
  };

  export default AdminLayout;