import { React, useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { getAllCounty, getAllState, getAllProfessions, getAllBarberShop, doStylistRegistration } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import SelectSearch from 'react-select-search';
// import 'react-select-search/style.css';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import TermsAndConditions from './Popup/TermsAndConditions';
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection} from '../../Reusable';
import { JsonEncode } from '../../Reusable/JsonModify';

export default function Login() {
    // initial state declaration
    const navigate = useNavigate();
    const recaptcha = useRef();

    const initialValues = { shopName : "", shopId : "", professionName : "", professionId : "", firstName : "", lastName: "", phoneNumber: "", email: "", address1 : "", address2 : "",  city : "", stateProvinceId : "", zipPostalCode : "", password : "", confrimPassword : ""};
    const [allCounty, setAllCounty] = useState([]);
    const [allState, setAllState] = useState([]);
    const [allProfession, setAllProfession] = useState([]);
    const [formValues, setformValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [barberShop, setBarberShop] = useState([]);
    const [loader, setLoader] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [option, setOption] = useState([]);
    const [confrimPassEye, setConfrimPassEye] = useState(false);
    const [professionOption, setProfessionOption] = useState([]);
    const [stateProvinceOption, setStateProvinceOption] = useState([]);
    const [tearmsCheckedStatus, setTearmsCheckedStatus] = useState(false);
    const [tearmsPopupStatus, setTearmsPopupStatus] = useState(false);

    // get customer privacy preference data from store
    const privacyStatus =  useSelector(store => store.user['privacypreference']);

    useEffect(() => {
        if(privacyStatus === true){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.remove('ppbtnhide');
                btn2.classList.remove('ppbtnhide');
            }
        }else if(privacyStatus === false){
            var btn1 = document.getElementById("termsfeed_pc1_sticky_dnsmpi_element_open");
            var btn2 = document.getElementById("termsfeed_pc1_sticky_element_open");
            if(btn1 && btn2){
                btn1.classList.add('ppbtnhide');
                btn2.classList.add('ppbtnhide');
            }

        }
    },[])

    // get initial state, professions data
    useEffect(() => {
        getAllState().then(res => stateResponse(res));
        getAllProfessions().then(res => professionResponse(res));
        getAllBarberShop().then(res => barberShopResponse(res));
        captchaLoad();
    },[]);

    const stateResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllState(response.data.stateList);
            const stateShowOption = [];
            response.data.stateList.map((list, index) => {
                const stateProvinceData = {label : list.stateName, value : list.stateId};
                stateShowOption.push(stateProvinceData);
            })
            setStateProvinceOption(stateShowOption);
        }
    }

    const stateHandleChange = (e) => {
        const value = e.value;
        const field= 'stateProvinceId';
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const captchaLoad = () => {
        let uniquechar = ""; 
        const randomchar ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 1; i < 5; i++) {
            uniquechar += randomchar.charAt(Math.random() * randomchar.length)
        }
        setCaptcha(uniquechar);
    }

    const professionResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllProfession(response.data.professionList);
            const professionShowOption = [];
            response.data.professionList.map((list, index) => {
                const professionId = list.id+','+list.name;
                const professionData = {label : list.name, value : professionId};
                professionShowOption.push(professionData);
            })
            setProfessionOption(professionShowOption);
        }
    }

    const professionHandleChange = (e) => {
        let value = e.value;
        let valueArr = value.split(',');
        const field1 = 'professionId';
        const field2 = 'professionName';
        setformValues(prevState => ({
            ...prevState,
            [field1]:  valueArr[0], [field2] : valueArr[1]  }));

    }

    
    const barberShopResponse = (response) => {
        if(response.data.statusCode === 200){
            setBarberShop(response.data.shopList);
            const barbershowOption = [];
            //barbershowOption.push({name : 'Select an option...', value : '0,0'});
            response.data.shopList.map((list, index) => {
                const newShopid = list.shopId+','+list.name;
                const op = {label : list.name, value : newShopid};
                barbershowOption.push(op);
            });
            setOption(barbershowOption);
        }
    }

    // Stylist register form functionality start here

    const businessHandleChange = (e) => {
        let value = e.value;
        let valueArr = value.split(',');
        const field1 = 'shopId';
        const field2 = 'shopName';
        setformValues(prevState => ({
            ...prevState,
            [field1]:  valueArr[0], [field2] : valueArr[1]  }));

    }


    const handleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]:  value  }));
        
        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setformValues(prevState => ({
                ...prevState,
                [field]:  newValue  }));
        }

        // if(field === 'stateProvinceId'){ // get county list by state id 
        //     const stateId = {'stateId' : value};
        //     getAllCounty(stateId).then(res => countyResponse(res));
        // }

        //if(field === 'professionName'){
           // const index = e.target.selectedIndex;
           // const el = e.target.childNodes[index]
            //const option =  el.getAttribute('id');
      
            //const fieldName='professionId';
           // setformValues(prevState => ({
               // ...prevState,
              //  [fieldName]:  option  }));
        //}

        const formatPhoneNumber = (numberValue) => {
            if (!numberValue) return numberValue;
            const phoneNumber = numberValue.replace(/[^\d]/g, "");
            const phoneNumberLength = phoneNumber.length;
            //if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 4) {
                return `(${phoneNumber.slice(0, 3)}`;
            }
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }
        

        if(field === 'phoneNumber'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setformValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  })
            );
        }
    }

    // const countyResponse = (response) => {
    //     if(response.data.statusCode === 200){
    //         setAllCounty(response.data.countyList)
    //     }
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
            formValues['termsAndCondition'] = true;
            formValues['reCaptchaToken'] = recaptcha.current.getValue();

            let jsonData = await JsonEncode(formValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doStylistRegistration(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const handleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setTimeout(() => {
                navigate('/thanks');
            }, 3000);
            // navigate('/stylistlogin');
            setLoader(false);
        }else{
            recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: response.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_-]).{8,}/;
        errors.status = true;

        if(!values.shopName){
            errors.shopName = 'Business Name is required';
            errors.status = false;
        }else if(values.shopName && values.shopName == '0'){
            errors.shopName = 'Business Name is required';
            errors.status = false;
        }

        

        if(!values.professionName){
            errors.professionName = 'Profession is required';
            errors.status = false;
        }

        if(!values.firstName || values.firstName.trim() === ''){
            errors.firstName = 'First name is required';
            errors.status = false;
        }

        if(!values.lastName || values.lastName.trim() === ''){
            errors.lastName = 'Last name is required';
            errors.status = false;
        }

        if(!values.email){
            errors.email = 'Email is required';
            errors.status = false;
        }else if (!regex.test(values.email)) {
            errors.email ="This is not a valid email format!";
            errors.status = false;
        }

        if(!values.phoneNumber){
            errors.phoneNumber = 'Phone is required';
            errors.status = false;
        } else if(values.phoneNumber){
            const numval = values.phoneNumber.split('');
            if(numval[1] === '0'){
                errors.phoneNumber = 'Enter valid phone';
                errors.status = false;
            }else if(values.phoneNumber && values.phoneNumber.length !== 14){
                errors.phoneNumber = 'Phone must have 10 digit';
                errors.status = false;
            } 
        }

        if(!values.city  || values.city.trim() === ''){
            errors.city = 'City is required';
            errors.status = false;
        }

        // if(!values.countyId){
        //     errors.countyId = 'Please select a county';
        //     errors.status = false;
        // }

        if(!values.address1  || values.address1.trim() === ''){
            errors.address1 = 'Address  is required';
            errors.status = false;
        }

        if(!values.stateProvinceId){
            errors.stateProvinceId = 'Please select a state';
            errors.status = false;
        }

        if(!values.zipPostalCode){
            errors.zipPostalCode = 'Zipcode  is required';
            errors.status = false;
        } else if(values.zipPostalCode && values.zipPostalCode.length !== 5){
            errors.zipPostalCode = 'Zipcode  must have 5 digit';
            errors.status = false;
        }

        if(!values.password){
            errors.password = 'Password  is required';
            errors.status = false;
        }else if(values.password && !passRegex.test(values.password)){
            errors.password = 'Password should contain atleast a Uppercase, a Lowercase, a Number and a Special character';
            errors.status = false;
        }

        if(!values.confrimPassword){
            errors.confrimPassword = 'Confirm password  is required';
            errors.status = false;
        }

        if(values.password && values.confrimPassword && values.password !== values.confrimPassword){
            errors.confrimPassword = 'Password and confirm password does not match';
            errors.status = false;
        }

        // if (!values.captcha) {
        //     errors.captcha = 'Captcha code is required';
        //     errors.status = false;
        // }

        // if (values.captcha && values.captcha != captcha) {
        //     errors.captcha = 'Invalid captcha code';
        //     errors.status = false;
        // }

        if(recaptcha.current.getValue() === ''){
            errors.captcha = 'Captcha is required';
            errors.status = false;
        }

        if (tearmsCheckedStatus === false) {
            errors.tearms = 'Accept terms & conditions';
            errors.status = false;
        }

        const validations = {
            email: HtmlTagValidation(values.email),
            address1: HtmlTagValidation(values.address1),
            address2: HtmlTagValidation(values.address2),
            city: HtmlTagValidation(values.city),
            password: HtmlTagValidation(values.password),
            confrimPassword: HtmlTagValidation(values.confrimPassword),
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });
        
        return errors;
    }

    const confrimPassView = () => {
        setConfrimPassEye(prevState => !prevState);
    }

    const getStatusFromTearmsPopup = (popupStatus) => {
        if(popupStatus === true){
            setTearmsPopupStatus(false);
        }
    }

    const tearmHandleChange = (e) => {
        if(e.target.checked === true){
            setTearmsCheckedStatus(true);
        }else{
            setTearmsCheckedStatus(false);
        }
    }

    const openTermsandConditions = () => {
        setTearmsPopupStatus(true);
    }

    return(
        // stylist register form HTML start here
        <section className='login-bg register-bg'>
            <Container>
                <Row className="justify-content-md-center ">
                    <Col md={5}>
                        <div className='text-center'>
                            <img src='images/logo.png'/>
                        </div>
                        <h1 className="text-center">Professional <br></br> <span>Create an account</span></h1>
                        <Form onSubmit={handleSubmit}> 
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridServiceName" className='select-search-drp'>
                                    <Form.Label>Business Name<span>*</span></Form.Label>
                                    {/*<SelectSearch emptyMessage='No data found...' options={option} search='true' name='shopNames'  onChange={businessHandleChange}/>*/}
                                    <Select 
                                        className='selectSearch'
                                        options={option}
                                        name='shopNames'
                                        onChange={businessHandleChange}
                                        placeholder="Select an option..."
                                    />
                                    <p className="error">{formErrors.shopName}</p>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formSelect">
                                    <Form.Label>Profession <span>*</span></Form.Label>
                                    {/*<Form.Select aria-label="Default select example" name='professionName' onChange={handleChange}>
                                        <option value=''>Select an option...</option>
                                        {
                                            allProfession.length > 0 ?
                                                allProfession.map((data, index) => (
                                                    <option value={data.name} id={data.id} key={index}>{data.name}</option>
                                                ))
                                            : ''
                                        }
                                    </Form.Select>*/}
                                    <Select 
                                        className='selectSearch'
                                        options={professionOption}
                                        name='professionName'
                                        onChange={professionHandleChange}
                                        placeholder="Select an option..."
                                    />
                                    <p className="error">{formErrors.professionName}</p>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>First Name <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="text" name='firstName' value={formValues.firstName} onChange={handleChange}/>
                                    <p className="error">{formErrors.firstName}</p>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridLastName">
                                    <Form.Label>Last Name <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="text" name='lastName' value={formValues.lastName} onChange={handleChange}/>
                                    <p className="error">{formErrors.lastName}</p>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group  as={Col} className="mb-2" controlId="formBasicEmail">
                                    <Form.Label>Email <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="email"  name='email' defaultValue={formValues.email} onChange={handleChange}/>
                                    <p className="error">{formErrors.email}</p>
                                </Form.Group>
                                <Form.Group  as={Col} className="mb-2" controlId="formBasicPhone">
                                    <Form.Label>Phone <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="text" name='phoneNumber' value={formValues.phoneNumber} onChange={handleChange} maxLength={14}/>
                                    <p className="error">{formErrors.phoneNumber}</p>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} className="mb-2" controlId="formGridAddress1">
                                    <Form.Label>Business Address 1 <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="text"  name='address1' defaultValue={formValues.address1} onChange={handleChange}/>
                                    <p className="error">{formErrors.address1}</p>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-2" controlId="formGridAddress2">
                                    <Form.Label>Business Address 2</Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="text"  name='address2' defaultValue={formValues.address2} onChange={handleChange}/>
                                    <p className="error">{formErrors.address2}</p>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">                            
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>City <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="text"  name='city' defaultValue={formValues.city} onChange={handleChange}/>
                                    <p className="error">{formErrors.city}</p>
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>State <span>*</span></Form.Label>
                                    {/*<Form.Select aria-label="Default select example" name='stateProvinceId' onChange={handleChange} >
                                        <option value=''>Select an option...</option>
                                        {
                                            allState.length > 0 ?
                                                allState.map((data, index) => (
                                                    <option value={data.stateId} key={index}>{data.stateName}</option>
                                                ))
                                            : ''
                                        }
                                    </Form.Select>*/}
                                    <Select 
                                        className='selectSearch'
                                        options={stateProvinceOption}
                                        name='stateProvinceId'
                                        onChange={stateHandleChange}
                                        placeholder="Select an option..."
                                    />
                                    <p className="error">{formErrors.stateProvinceId}</p>
                                </Form.Group>
                            </Row>
                            {/* <Row className="mb-2">    
                                <Form.Group as={Col} controlId="formGridCountry">
                                    <Form.Label>County <span>*</span></Form.Label>
                                    <Form.Select aria-label="Default select example" name='countyId' onChange={handleChange}>
                                        <option value=''>Select an option...</option>
                                        {
                                            allCounty.length > 0 ?
                                                allCounty.map((data, index) => (
                                                    <option value={data.countyId} key={index}>{data.countyName}</option>
                                                ))
                                            
                                            : ''
                                        }
                                    </Form.Select>
                                    <p className="error">{formErrors.countyId}</p>
                                </Form.Group>
                                
                            </Row> */}
                            <Row className="mb-2">  
                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zipcode <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" type="number"  name='zipPostalCode' defaultValue={formValues.zipPostalCode} onChange={handleChange}/>
                                    <p className="error">{formErrors.zipPostalCode}</p>
                                </Form.Group> 
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Password <span>*</span></Form.Label>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type="password"   name='password' defaultValue={formValues.password} onChange={handleChange}/>
                                    <p className="error">{formErrors.password}</p>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formGridConfirmPassword" className='passwordRow'>
                                    <Form.Label>Confirm Password <span>*</span></Form.Label>
                                    <FontAwesomeIcon icon={confrimPassEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                    <Form.Control autoComplete="off" onKeyDown={BlockSQLInjection} type={confrimPassEye === false ? 'password' : 'text'}  name='confrimPassword' defaultValue={formValues.confrimPassword} onChange={handleChange}/>
                                    <p className="error">{formErrors.confrimPassword}</p>
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="formCaptchaLoad">
                                    <Form.Label>Captcha <span>*</span></Form.Label>
                                    <Row className='captchaRow'>
                                        <Col className='captchaCol'>
                                            <Form.Control type="text"   name='captcha' defaultValue={formValues.captcha} onChange={handleChange}/>
                                        </Col>
                                        <Col>
                                            <FontAwesomeIcon icon={faRotate} onClick={captchaLoad} /><Form.Text >{captcha}</Form.Text>
                                        </Col>
                                    </Row>
                                    <p className="error">{formErrors.captcha}</p>
                                </Form.Group> */}
                                <Form.Group as={Col} controlId="formCaptchaLoad">
                                    <Form.Label>Captcha <span>*</span></Form.Label>
                                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                    <p className="error">{formErrors.captcha}</p>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="formCheckTearm" className='tearmsAndConditions'>
                                    <Form.Check type="checkbox" checked={tearmsCheckedStatus} id='formCheckTearm' onChange={tearmHandleChange} className={tearmsCheckedStatus === true ? 'tearmsChecked' : 'termsdivition'} />
                                    <Form.Control.Feedback>
                                    By checking this box, I confirm I'm 18 years old or older and to view the Terms and Conditions <span onClick={openTermsandConditions} className='opentermsandconditions'>click here</span>
                                    </Form.Control.Feedback>
                                    <p className="error">{formErrors.tearms}</p>
                                </Form.Group> 
                            </Row>
                            <Stack gap={2} className="mx-autos">   
                                {
                                    loader === true ? 
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Button variant="secondary" type='submit'>Register <img src='images/arrow-right.svg'/></Button>
                                }                              
                            </Stack>
                            <p className='customerNotes'><span>*</span> If you are a Customer looking to use UNEXT to book a service, create an account <Link to={"../customercreateanaccount"} style={{textDecoration : 'none'}}>here</Link></p>
                        </Form>
                        {
                            tearmsPopupStatus === true ?
                                <TermsAndConditions getBack={getStatusFromTearmsPopup} />
                            :       
                                ''
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 