import { React, useState, useEffect } from "react";
import { Col, Container, Image, Row, Form, Button, Stack, OverlayTrigger, Popover, Modal } from 'react-bootstrap'; 
import userImage from "../../../images/booking-profile.png";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Email, Phone } from '@mui/icons-material';
import { postClientCashRequest, postClientOnlineTransaction, postClientNoShowRequest, postClientCancelBooking, postCancelSelfBooking } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moments from 'moment-timezone';
import { JsonEncode } from "../../Reusable/JsonModify";
 
export default function BookingDetails() {
    var abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
        AKST : 'Alaskan Standard Time',
        HST : 'Hawaiian Standard Time'
    };

    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };

    const getBookingDetails = useSelector(store => store.user.bookingDetails);
    const [chooseCard, setChooseCard] = useState('');
    const [finalErrors, setFinalErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [cancelPopupShow, setCancelPopupShow] = useState(false);
    const [cancelLoader, setCancelLoader] = useState(false)
    const [cancelReason, setCancelReason] = useState('');
    const [cancelError, setcancelError] = useState('');
    const [editPopupShow, setEditPopupShow] = useState(false);
    const [selfBookingCancelStatus, setSelfBookingCancelStatus] = useState(false);

    const navigate = useNavigate();

    // get Professional data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const bookingDate = getBookingDetails !== null ? moment(new Date(getBookingDetails.startDate)).format('MM-DD-YYYY') : '';
    const bookingStartTIme = getBookingDetails !== null ? moment(new Date(getBookingDetails.startDate)).format('LT') : '';
    const bookingEndTIme = getBookingDetails !== null ? moment(new Date(getBookingDetails.endDate)).format('LT') : '';
    let zoone = getBookingDetails !== null ? getBookingDetails.timezone.split(' ') : '';
    let currentDateTime = zoone !== '' ? new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm')) : '';
    let slotStattingDateTime = getBookingDetails !== null ? new Date(getBookingDetails.startDateTime) : '';

    console.log('zone', zoone)
    console.log('timexone test', currentDateTime);
    console.log('timezone', moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm:ss'))

    const onCardChange = ( e ) => {
        setChooseCard(e.target.value);
    }

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = finalValidate(chooseCard);
        setFinalErrors(errorCount);

        if ( errorCount.status ) {
            setLoader(true);
            const zoneSplit = getBookingDetails.timezone.split(' ');
            let zone = '';

            if ( zoneSplit[0] == 'US/Arizona' ) {
                zone = 'US Mountain Standard Time';
            } else {
                zone = moments.tz([2012, 0], zoneSplit[0]).format('zz');
            }

            if ( chooseCard === 'card' ) {
                const onlinePayload = {bookingId : getBookingDetails.bookingId, customerId : getBookingDetails.customerId, status : 'Completed', startDateTime : getBookingDetails.startDateTime, amount : getBookingDetails.payment, timeZone : zone, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};
                
                let jsonData = await JsonEncode(onlinePayload).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postClientOnlineTransaction(loginPayload).then(res => handleResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }

            } else if ( chooseCard === 'cash' ) {
                const cashPayload = {bookingId : getBookingDetails.bookingId, customerId : getBookingDetails.customerId, status : 'Cash', startDateTime : getBookingDetails.startDateTime, amount : getBookingDetails.payment, timeZone : zone, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

                let jsonData = await JsonEncode(cashPayload).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postClientCashRequest(loginPayload).then(res => handleResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }

            } else if ( chooseCard === 'noshow' ) {
                const noshowPayload = {bookingId : getBookingDetails.bookingId, customerId : getBookingDetails.customerId, status : 'Noshow', startDateTime : getBookingDetails.startDateTime, amount : getBookingDetails.payment, timeZone : zone, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

                let jsonData = await JsonEncode(noshowPayload).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postClientNoShowRequest(loginPayload).then(res => handleResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }

            }

        }

    }

    const handleResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            setTimeout(() => {
                navigate(-1);
            }, 3100);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }

    }

    const finalValidate = ( values ) => {
        const errors = {};
        errors.status = true;

        if ( !values  || values.trim() === '' ) {
            errors.card = 'Please select a payment mode.';
            errors.status = false;
        }
        
        return errors;
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    const cancelPopupHandleClose = () => {
        setCancelPopupShow(false);
        setCancelReason('');
        setCancelLoader(false);
        setcancelError('');
    }

    const cancelService = () => {
        setCancelPopupShow(true);
    }

    const cancelHandleChange = ( e ) => {
        setCancelReason(e.target.value);
    }

    const cancelHandleSubmit = async ( e ) => {
        e.preventDefault();

        if ( cancelReason !== '' ) {
            setcancelError('');
            let cancelParams = {'clientId' : getBookingDetails.clientId, 'customerId' : getBookingDetails.customerId, 'bookingId' : getBookingDetails.bookingId, 'status' : 'Cancelled', 'startDateTime' : getBookingDetails.startDateTime, 'statusDescription' : cancelReason};

            let jsonData = await JsonEncode(cancelParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postClientCancelBooking(loginPayload).then(res => cancelResponse(res));
                setCancelLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        } else {
            setcancelError('Enter the reason for cancel');
        }

    }

    const cancelResponse = ( res ) => {

        if ( res.data.statusCode == 200 ) {
            setCancelPopupShow(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setCancelLoader(false);
            navigate(-1);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setCancelLoader(false);
        }

    }

    const editPopupHandleClose = () => {
        setEditPopupShow(false);
    }

    const editService = () => {
        setEditPopupShow(true);
    }

    const selfBookingCancel = async () => {
        setSelfBookingCancelStatus(true);
        let selfBookingCancelParams = {'clientId' : getBookingDetails.clientId, 'clientName' : getBookingDetails.clientName, 'status' : 'Cancelled', 'data' : [{'bookingId' : getBookingDetails.bookingId, 'startDateTime' : getBookingDetails.startDateTime, 'endDateTime' : getBookingDetails.endDateTime}]};

        let jsonData = await JsonEncode(selfBookingCancelParams).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });
        
        if ( jsonData ) {
            let loginPayload = {data : jsonData};
            postCancelSelfBooking(loginPayload).then(res => selfBookingCancelResponse(res));
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: 'Something went wrong, Please try after sometimes',
                timer: 3000
            }); 
        }
        
    }

    const selfBookingCancelResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            setSelfBookingCancelStatus(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            navigate(-1);
        } else {
            setSelfBookingCancelStatus(false);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }

    }

    // get decimal two degit cost
    const getFullCost = ( dollar ) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }
  
    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            {
                                getBookingDetails !== null ?
                                    <> 
                                        <Row className="mb-3 main-title">
                                            <Col>
                                                <hr />
                                                <Stack direction='horizontal' className='stackInside'>
                                                    <div >
                                                        <h1>Booking Details</h1>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                                    </div>
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-md-center text-center">
                                            <div className='booking-details'>
                                                <Col md={12}>
                                                    <div className='profile'>
                                                        <Image src={userImage} />
                                                    </div>
                                                    {
                                                        getBookingDetails.text != 'Self Booking' ?
                                                            <>
                                                                <h2>{getBookingDetails.text} <span className='bookcal-cus-details'>
                                                                    <a href={"mailto:"+getBookingDetails.customerEmail}>
                                                                        <Email /> {getBookingDetails.customerEmail}</a>
                                                                        <a href={"tel:"+getBookingDetails.customerPhone}>
                                                                            <Phone /> {getBookingDetails.customerPhone}</a></span></h2>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Service Offered</h4>    
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{getBookingDetails.serviceName}</p> 
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Date</h4>   
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{bookingDate}</p> 
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Time</h4>  
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{bookingStartTIme + " " + "-" +  " " + bookingEndTIme}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Service Charge fee</h4> 
                                                                    </Col>
                                                                    <Col>
                                                                        <p>
                                                                            ${getFullCost(getBookingDetails.payment)}
                                                                            <sup className='payment-icon'>
                                                                                <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                                  overlay={
                                                                                      <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                      <div>
                                                                                            <h5>Service : ${getFullCost(getBookingDetails.serviceCost)} </h5>  
                                                                                            <h5>Tip : ${getFullCost(getBookingDetails.tip)}</h5>  
                                                                                            <h5>Platform Fees : ${getFullCost(getBookingDetails.platformFees)}</h5>  
                                                                                            <p><strong>Total : </strong>${getFullCost(getBookingDetails.payment)}</p>
                                                                                      </div>
                                                                                      </Popover>
                                                                                  }
                                                                                >
                                                                                  <FontAwesomeIcon icon={faCircleExclamation} />
                                                                                </OverlayTrigger>
                                                                            </sup>  
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Payment Type</h4> 
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{getBookingDetails.paymentType}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Status</h4> 
                                                                    </Col>
                                                                    <Col>
                                                                        <p className='' style={{float: 'right'}}>{getBookingDetails.status === 'Booked' ? (getBookingDetails.bookingType == 'CL' || getBookingDetails.bookingType == 'CLEB') && getBookingDetails.isCustomerRespondent === false ? 'Verification Pending' : getBookingDetails.status : getBookingDetails.status}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Booked / Edited By</h4> 
                                                                    </Col>
                                                                    <Col>
                                                                        <p className='' style={{float: 'right'}}>{getBookingDetails.bookingType == 'CS' ? 'Customer' : (getBookingDetails.bookingType == 'CL' || getBookingDetails.bookingType == 'CLEB') ? 'Professional' : 'Self Booking'}</p>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    getBookingDetails.status == 'Booked' ?
                                                                        currentDateTime < slotStattingDateTime ?
                                                                            getBookingDetails.bookingType != 'CL' &&  getBookingDetails.bookingType != 'CLEB'?
                                                                                <Row className='text-start'>
                                                                                    <Col>
                                                                                        <h4>Action</h4>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Stack direction="horizontal" gap={3} className='bookingDetailsAction'>
                                                                                            <div >
                                                                                                <Button className='editBtn' style={{cursor: 'pointer'}} onClick={() => editService()}>Edit</Button>
                                                                                            </div>
                                                                                            <div className="">
                                                                                                <Button className='dltBtn' style={{cursor: 'pointer'}} onClick={() => cancelService()}>Cancel</Button>
                                                                                            </div>
                                                                                        </Stack>
                                                                                    </Col>
                                                                                </Row>
                                                                            :
                                                                                getBookingDetails.isCustomerRespondent === true ? 
                                                                                    <Row className='text-start'>
                                                                                        <Col>
                                                                                            <h4>Action</h4>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Stack direction="horizontal" gap={3} className='bookingDetailsAction'>
                                                                                                <div >
                                                                                                    <Button className='editBtn' style={{cursor: 'pointer'}} onClick={() => editService()}>Edit</Button>
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    <Button className='dltBtn' style={{cursor: 'pointer'}} onClick={() => cancelService()}>Cancel</Button>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Col>
                                                                                    </Row>
                                                                                :
                                                                                  ''
                                                                        :
                                                                          ''
                                                                    :
                                                                      ''
                                                                }

                                                                {
                                                                    getBookingDetails.status == 'Booked' ?  
                                                                        getBookingDetails.bookingType !== 'CL' || getBookingDetails.bookingType !== 'CLEB' ?
                                                                            currentDateTime > slotStattingDateTime ?
                                                                                <Row className='text-start last-row'>
                                                                                    <Form onSubmit={handleSubmit} className='payment-mode-update stylist-radio'>
                                                                                        <Col>
                                                                                            <h4>Payment mode</h4> 
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div className="radio">
                                                                                                <label>
                                                                                                    <input type="radio" name='paymentMode' value='cash' onChange={onCardChange} />
                                                                                                    Cash
                                                                                                </label>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div className="radio">
                                                                                                <label>
                                                                                                    <input type="radio" name='paymentMode' value='card' onChange={onCardChange} />
                                                                                                    Card
                                                                                                </label>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <div className="radio">
                                                                                                <label>
                                                                                                    <input type="radio" name='paymentMode' value='noshow' onChange={onCardChange} />
                                                                                                    No Show
                                                                                                </label>
                                                                                            </div>
                                                                                            <p className="error" style={{textAlign:'left', color:'red'}}>{finalErrors.card}</p>
                                                                                        </Col>
                                                                                        <Row className='payment-submit-btn'>
                                                                                            {
                                                                                                loader === true ?
                                                                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                                                :
                                                                                                  <Button variant="primary" className='addbtn' type='submit'>Save</Button>
                                                                                            }
                                                                                        </Row>
                                                                                    </Form>
                                                                                </Row>
                                                                            :
                                                                                ''
                                                                        :
                                                                            (getBookingDetails.bookingType === 'CL' || getBookingDetails.bookingType === 'CLEB') && getBookingDetails.isCustomerRespondent === true ?
                                                                                currentDateTime > slotStattingDateTime ?
                                                                                    <Row className='text-start last-row'>
                                                                                        <Form onSubmit={handleSubmit} className='payment-mode-update stylist-radio'>
                                                                                            <Col>
                                                                                                <h4>Payment mode</h4> 
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <div className="radio">
                                                                                                    <label>
                                                                                                        <input type="radio" name='paymentMode' value='cash' onChange={onCardChange} />
                                                                                                        Cash
                                                                                                    </label>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <div className="radio">
                                                                                                    <label>
                                                                                                        <input type="radio" name='paymentMode' value='card' onChange={onCardChange} />
                                                                                                        Card
                                                                                                    </label>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                <div className="radio">
                                                                                                    <label>
                                                                                                        <input type="radio" name='paymentMode' value='noshow' onChange={onCardChange} />
                                                                                                        No Show
                                                                                                    </label>
                                                                                                </div>
                                                                                                <p className="error" style={{textAlign:'left', color:'red'}}>{finalErrors.card}</p>
                                                                                            </Col>
                                                                                            <Row className='payment-submit-btn'>
                                                                                                {
                                                                                                    loader === true ?
                                                                                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                                                    :
                                                                                                      <Button variant="primary" className='addbtn' type='submit'>Save</Button>
                                                                                                }
                                                                                            </Row>
                                                                                        </Form>
                                                                                    </Row>
                                                                                :
                                                                                  ''
                                                                          :
                                                                            ''
                                                                    :
                                                                      ''
                                                                }
                                                            </>
                                                        :
                                                            <>
                                                                <h2>{getBookingDetails.text}</h2>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Date</h4>   
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{bookingDate}</p> 
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Time</h4>  
                                                                    </Col>
                                                                    <Col>
                                                                        <p>{bookingStartTIme + " " + "-" +  " " + bookingEndTIme}</p>
                                                                    </Col>
                                                                </Row>
                                                                <Row className='text-start'>
                                                                    <Col>
                                                                        <h4>Action</h4>
                                                                    </Col>
                                                                    <Col>
                                                                        <Stack direction="horizontal" gap={3} className='bookingDetailsAction'>
                                                                            <div className="">
                                                                              {
                                                                                  selfBookingCancelStatus === true ? 
                                                                                      <span style={{color : '#fff'}}>Canceling...</span>
                                                                                  :
                                                                                      <Button className='dltBtn' style={{cursor: 'pointer'}} onClick={() => selfBookingCancel()}>Cancel</Button>
                                                                              }
                                                                            </div>
                                                                        </Stack>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                    }
                                                </Col>
                                            </div>
                                        </Row>
                                    </>
                                :
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Cancel Booking HTML start here */}
            <Modal show={cancelPopupShow} onHide={cancelPopupHandleClose} className="custom-modal ">
                <Form onSubmit={cancelHandleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label>Reason for cancel <span>*</span></Form.Label>
                                <textarea name="postContent" rows={4} className="cancelBookingReasonInput" onChange={cancelHandleChange}/>
                                <p className="error">{cancelError}</p>
                            </Form.Group> 
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            cancelLoader === true ?
                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                            :
                                <Button variant="primary" type='submit'>Confirm Cancel</Button>
                        }
                        <Button variant="outline-secondary" onClick={cancelPopupHandleClose}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Edit Booking HTML start here */}
            {
              getBookingDetails !== null ?
                <Modal show={editPopupShow} onHide={editPopupHandleClose} className="custom-modal serviceEdit">
                    <Modal.Header closeButton>
                        <Modal.Title>Select Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Stack direction="horizontal" gap={2} className="serviceEditFlex">
                            <div>
                                <Link to={"../stylist/editbooking/"+userData.clientId+"/"+getBookingDetails.productId+'/'+getBookingDetails.customerId+'/'+getBookingDetails.bookingId} className='btn btn-success'>Proceed with same service</Link>
                            </div>
                            <div>
                                <Link to={"../stylist/editbookingwithservice/"} className='btn btn-success newServiceBtn'>Select New service</Link>
                            </div>
                        </Stack>
                    </Modal.Body>
                </Modal>
              :
                ''
            }
          
        </section>
    )
}
   