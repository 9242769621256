import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import UpcomingHours from "./UpcomingHours";
import AdjustHours from "./AdjustHours";

export default function AdjustUpcomingHours() {
    
    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col className="upcommingHours">
                                    <h1>Manage Availability</h1>
                                </Col>
                            </Row>
                            <Row className='availability-sec'>
                                <Col>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="firstKey">
                                        <Nav variant="pills" className="mb-4">
                                            <Nav.Item>
                                                <Nav.Link eventKey="firstKey">Adjust Hours</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="secondKey">Upcoming Hours</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="firstKey">
                                                <Row className="justify-content-md-center text-center">
                                                    <Col>
                                                        <AdjustHours />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="secondKey">
                                                <Row className="justify-content-md-center text-center">
                                                    <Col>
                                                        <UpcomingHours />
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}