import {React, useState, useEffect} from 'react';
import { stylistServiceList } from '../../../../Service';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Button, Container, Stack, Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import LeftSidebar from '../../Assets/LeftSidebar';
import Topbar from '../../Assets/Topbar';
import { InfinitySpin } from 'react-loader-spinner';

export default function SelectService(){
    const navigate = useNavigate();

    const [ServiceList, setServiceList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);

    // get Professional data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get service list 
    useEffect(() => {
        const getServiceParams = {'clientId' : userData ? userData.clientId : 0, 'searchServiceName' : '', 'pageNo' : 1, 'pageSize' : 500, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        stylistServiceList(getServiceParams).then(res => initialStylistServiceResponse(res));
    }, []);

    const initialStylistServiceResponse = (response) => {
        if(response.data.statusCode === 200){
            setServiceList(response.data.productList);
        }
        setPageLoader(false);
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div >
                                            <h1>Select Service For Booking Customer</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row>
                                <Stack direction='horizontal' className='backStack'>
                                    <div className='addBooking-title' style={{marginTop : '24px'}}>
                                        <h2>Select Service</h2>
                                    </div>
                                </Stack>
                                {
                                    pageLoader === true ?
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <div className="table-responsive" id='no-more-tables'>
                                            <Table striped bordered hover variant="dark">
                                                <thead>
                                                    <tr>
                                                        <th>Service</th>
                                                        <th>Cost</th>
                                                        <th>Duration</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ServiceList.length > 0 ?
                                                            ServiceList.map((list, index) => (
                                                                <tr key={index}>
                                                                    <td data-title='Service'>{list.subServiceName}<span style={{display : 'block', fontSize :'12px'}}><i>{list.serviceDescription}</i></span></td>
                                                                    <td data-title='Cost'>${getFullCost(list.price)}</td>
                                                                    <td data-title='Duration'>{list.duration} Minutes</td>
                                                                    <td data-title='Action'><Link to={"../stylist/bookingforcustomer/"+list.productId} className='btn btn-success'>Select Service</Link></td>
                                                                </tr>
                                                            ))
                                                        :
                                                            <tr>
                                                                <td colSpan={4} style={{textAlign : 'center'}} className='tableNoDataFoundMBL'>No data found...</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div> 
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}