import { React, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { postCreateService } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function AddServicePopup(props){
    // set props data into variable
    let professionList = props.professionList;

    // state declaration
    const initialValues = { name : '', professionId : '', description : '', published : true, displayOrder : 0};
    const [formValues, setformValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({}); 
    const [popupShow, setPopupShow] = useState(true);
    const [formSubmitLoader, setFormSubmitLoader] = useState(false);
    
    // popup close
    const popupClose = () => {
        setPopupShow(false);
        setFormErrors({});
        setformValues(initialValues);
        props.getBack(true, false);
    }

    // input field onchange
    const handleChange = (e)=>{
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]: value  }
        ));
    } 

    // add service form onsubmit
    const handleSubmit = (e) => {
        e.preventDefault();
        const errorCount = inputFieldValidate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
          postCreateService(formValues).then(res => handleResponse(res));
          setFormSubmitLoader(true);
        }
    }

    // add service api response
    const handleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            })
            setFormSubmitLoader(false);
            setPopupShow(false);
            setFormErrors({});
            setformValues(initialValues);
            props.getBack(false, true);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: response.data.statusMessage,
                timer: 3000
            })
            setFormSubmitLoader(false);
        }
    }

    // input fields validation
    const inputFieldValidate = (values)=>{
        const errors = {};
        errors.status = true;
    
        if(!values.name || values.name.trim() === ""){
            errors.name='Service name is required';
            errors.status=false;
        }
    
        if(!values.professionId){
            errors.professionId='Profession is required';
            errors.status=false;
        }

        const validations = {
            name: HtmlTagValidation(values.name),
            description: HtmlTagValidation(values.description)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    return(
        <Modal show={popupShow} onHide={popupClose} className="custom-modal">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Service</Modal.Title>
                </Modal.Header>
                <Modal.Body>   
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Service Name <span className="star">*</span></Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name="name" defaultValue={formValues.name} onChange={handleChange}/>
                            <p className="error">{formErrors.name}</p>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Profession <span className="star">*</span></Form.Label>
                            <Form.Select aria-label="Default select example" name="professionId" onChange={handleChange}>
                                <option>Select an option...</option>
                                {
                                    professionList.length > 0 ?
                                        professionList.map((data, index) => (
                                            <option value={data.id} key={index}>{data.name}</option>
                                        ))
                                    : 
                                        ''
                                }
                            </Form.Select>
                            <p className="error">{formErrors.professionId}</p>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Description</Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='description' defaultValue={formValues.description} onChange={handleChange} />
                            <p className="error">{formErrors.description}</p>
                        </Form.Group>
                    </Row>   
                </Modal.Body>
                <Modal.Footer>
                    {
                        formSubmitLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <>
                                <Button variant="primary" type="submit">Add</Button>
                                <Button variant="outline-secondary" onClick={popupClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}