import { React, useEffect } from 'react';
import { deleteStylistService } from '../../../../Service';
import Swal from "sweetalert2";

export default function DeleteServicePopup({ serviceId, getBack }) {

    const handleDeleteResponse = (response) => {
        if(response.data.statusCode === 200){  
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Service deleted successfully...!',
                showConfirmButton: false,
                timer: 3000
            });
            getBack(true);
        } else { 
            const errors = {};
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    useEffect(() => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this Service?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var whereDelete = {"productId" : serviceId};
                deleteStylistService(whereDelete).then(res => handleDeleteResponse(res));
            }else{
                getBack(false);
            }
        })
    }, []);

    return(<></>)
}