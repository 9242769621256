import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../css/CustomerStyle.css';
import { Nav, Navbar, Offcanvas, Container, Image, Row, Col, Button, NavDropdown } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from '../../../images/logo1.png';
import person from "../../../images/person.png";
import { useSelector, useDispatch} from 'react-redux';
import { setCustomerLogout, setCustomerLogoutUrl } from '../../../reducers/User/action';
import Swal from "sweetalert2";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomerHeader(props){

    const params = useLocation();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => setShow(true);
    let urlPathName = params.pathname;

    const navigate = useNavigate();
    const dispatch=useDispatch();
    const doLogout = (popupState)=>{
        popupState.close();
        Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to Log Out?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log Out!'
        }).then((result) => {
            if (result.isConfirmed) {
                if(props.getBack){
                    props.getBack(true);
                }
                dispatch(setCustomerLogoutUrl(urlPathName));
                dispatch(setCustomerLogout());
                if(urlPathName === '/customer/settings' || urlPathName === '/customer/bookinghistory'){
                    navigate("/customerlogin");
                }else{
                    navigate("/customer/home");
                }
            }
        })   
    }
    const navigator = (nivigation) => {
        navigate(nivigation);
    }

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
      }, []);

      const stickNavbar = () => {
        if (window !== undefined) {
          let windowHeight = window.scrollY;
          // window height changed for the demo
          windowHeight > 60 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
      };

    let loggedUser = useSelector(store => store.user['customeruser']);
    let apiToken = useSelector(store => store.user['customertoken']);

    const [stickyClass, setStickyClass] = useState('');

    return(
        <div  className={`custom-header ${stickyClass}`}>
            <Navbar expand="md" >
                <Container >
                    <Navbar.Brand>
                    <Link to="../customer"><Image src={logo} /></Link> 
                    </Navbar.Brand>
                    {
                        loggedUser !== null && apiToken != '' ? 
                            <div className='pro-mobile-view '>
                                <PopupState variant="popover" popupId="demo-popup-popover" >
                                    {(popupState) => (
                                        <div>
                                            <Button variant="" {...bindTrigger(popupState)}>
                                                <div className="pro-sec">
                                                <div className="top-profile">
                                                    <Image src={person} />
                                                </div>
                                                </div>
                                            </Button>

                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                >
                                                <Typography sx={{ p: 2 }}> 
                                                    <Row className="profile-dropdown">
                                                        <Col><Image src={person} />
                                                        <h4>{loggedUser ? loggedUser.firstName+' '+loggedUser.lastName : ''}</h4>
                                                        <h6>{loggedUser ? loggedUser.email : ''}</h6>
                                                        </Col>
                                                    </Row> 
                                                    <hr/>
                                                    <Row className="profile-link">
                                                        <Col>
                                                        <div className="text-center"><a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Log Out</a></div>
                                                        
                                                    </Col>
                                                    </Row>
                                                    
                                                </Typography>
                                            </Popover>
                                        </div>
                                    )}
                                </PopupState>
                            </div>
                        : ''
                    }
                    

                    
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" onClick={handleShow} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-md`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                        placement="end"
                        show={show} 
                        onHide={handleClose}
                        className="customerOffcanvas"
                    >
                        
                        <Offcanvas.Header>
                            <button onClick={handleClose}><CloseIcon /></button>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <Link to="../customer"><Image src={logo} /></Link>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>


                            <Nav className="justify-content-center flex-grow-1 align-items-center menu-body">
                                {
                                    loggedUser === null || apiToken === '' ?
                                        ''
                                    :
                                        <Link to="../stylistlogin" className="professionLoginBtm" target="_blank" >Pro Login</Link>
                                }

                                {
                                    loggedUser !== null && apiToken != '' ?
                                        <>
                                            <Link to="../customer/home" ><HomeIcon /> HOME</Link>
                                            <Link to="../stylistlogin" className="professionLogin" target="_blank" ><PersonAddIcon />PRO LOGIN</Link>
                                            <Link to="../mission" ><Diversity2Icon /> MISSION</Link>
                                            <Link to="../customer/bookinghistory" ><EventAvailableIcon /> APPOINTMENTS</Link>
                                            <Link to="../customer/settings" ><AppSettingsAltIcon /> SETTINGS</Link>
                                        </>
                                    : 
                                        <>
                                            <Link to="../customer/home" ><HomeIcon /> HOME</Link>
                                            {/* <Link to="../stylistlogin" target="_blank" className="professionLogin"><PersonAddIcon />PRO LOGIN</Link> */}
                                            <Link to="../mission" ><Diversity2Icon /> MISSION</Link>
                                        </>
                                }
                            </Nav>
                            
                            <div className='sign-up' style={{display: 'flex'}}>
                                {
                                    loggedUser === null || apiToken === '' ?
                                        <>
                                            <NavDropdown title={<span><LoginIcon /> LOGIN</span>} id="basic-nav-dropdown">
                                                <NavDropdown.Item as={Link} to="/customerlogin">Customer Login</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/stylistlogin" target="_blank">Pro Login</NavDropdown.Item>
                                            </NavDropdown>
                                            <NavDropdown title={<span><LockOpenIcon /> SIGN UP</span>} id="basic-nav-dropdown" className='SignUpDrapdown'>
                                                <NavDropdown.Item as={Link} to="/customercreateanaccount">Customer Sign Up</NavDropdown.Item>
                                                <NavDropdown.Item as={Link} to="/stylistregistration" target="_blank">Pro Sign Up</NavDropdown.Item>
                                            </NavDropdown>
                                            {/* <Link to="/customerlogin" ><LoginIcon /> LOGIN </Link>  */}
                                            {/* <Link to="/customercreateanaccount" ><LockOpenIcon /> SIGN UP</Link>  */}
                                            
                                        </>
                                    :
                                        <div className='hide-mobile'>
                                            <PopupState variant="popover" popupId="demo-popup-popover" >
                                                {(popupState) => (
                                                    <div>
                                                        <Button variant="" {...bindTrigger(popupState)}>
                                                            <div className="pro-sec">
                                                            <div className="top-profile">
                                                                <Image src={person} />
                                                            </div>
                                                            </div>
                                                        </Button>

                                                        <Popover
                                                            {...bindPopover(popupState)}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            }}
                                                            >
                                                            <Typography sx={{ p: 2 }}> 
                                                                <Row className="profile-dropdown">
                                                                    <Col><Image src={person} />
                                                                    <h4>{loggedUser ? loggedUser.firstName+' '+loggedUser.lastName : ''}</h4>
                                                                    <h6>{loggedUser ? loggedUser.email : ''}</h6>
                                                                    </Col>
                                                                </Row> 
                                                                <hr/>
                                                                <Row className="profile-link">
                                                                    <Col>
                                                                    <div className="text-center"><a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Log Out</a></div>
                                                                </Col>
                                                                </Row>
                                                                
                                                            </Typography>
                                                        </Popover>
                                                    </div>
                                                )}
                                            </PopupState>
                                        </div>
                                }
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}