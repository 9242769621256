import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
// import QRCode from "react-qr-code";
import CustomerHeader from '../../Assets/CustomerHeader';


export default function CustomerScanQR() {
  return(
    <div>
      <CustomerHeader />
  <section className='customer-bg'>
    <Container>
      <Row>
        <Col className="text-center">
        <div className='qr-code'>
          <h1 className="mb-4 ">Scan QR code</h1>
          {/* <QRCode 
          className="mb-4"
          size={256}
          style={{ height: "auto", maxWidth: "", width: "auto" }}
          value=""
          viewBox={`0 0 256 256`}
          /> */}
          <p><strong>Payment with QR Code</strong></p>
          <p className='grey-txt'>Hold the code inside the frame, it will be scanned automatically</p>
        </div>
      </Col>
    </Row>
  </Container>
</section>
</div>
)
}   