import { React, useState } from 'react';
import { Row, Col, Button, Container, Form, Stack, Table } from "react-bootstrap";
import { postCustomerEmailVerification, postCustomerListBySearch } from '../../../../Service';
import LeftSidebar from '../../Assets/LeftSidebar';
import Topbar from '../../Assets/Topbar';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setNewBookingCustomerDetails } from "../../../../reducers/User/action";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function CustomerSearch() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    // inital state declaration
    const initialSearchValues = { customerName : '', customerPhone : '' };
    const [searchFromValues, setSearchFromValues] = useState(initialSearchValues);
    const [searchError, setSearchError] = useState({});
    const [showServiceList, setShowServiceList] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [verifyBtnActive, setVerifyBtnActive] = useState(true);
    const [customerListShow, setcustomerListShow] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [verifyBTNDisable, setVerifyBTNDisable] = useState(false);

    // search form input fields Onchange
    const seachHandleChange = (e) => {
        setVerifyBtnActive(false);
        setcustomerListShow(false);
        setCustomerList([]);

        if(showServiceList === true){
            setShowServiceList(false);
        }

        if(verifyLoader === true){
            setVerifyLoader(false);
        }

        const value=e.target.value;
        const field=e.target.name;

        const formatPhoneNumber = (numberValue) => {
            if (!numberValue) return numberValue;
            const phoneNumber = numberValue.replace(/[^\d]/g, "");
            const phoneNumberLength = phoneNumber.length;
            //if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 4) {
                return `(${phoneNumber.slice(0, 3)}`;
            }
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
                3,
                6
            )}-${phoneNumber.slice(6, 10)}`;
        }
    
        if(field === 'customerPhone'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setSearchFromValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  
            }));
        }else{
            setSearchFromValues(prevState =>({
                ...prevState,
                [field]: value  
            }))
        }
    }

    // customer email verify
    const handleSubmit = (e) => {
        e.preventDefault();
        let searchValidation = inputFieldValidate(searchFromValues);
        setSearchError(searchValidation);
        if(searchValidation.status){
            setVerifyLoader(true);
            let noSpaceName = searchFromValues.customerName.replace(/^\s+|\s+$/gm,'')
            let customerListParams = { 'customerName' : noSpaceName, 'phoneNumber' : searchFromValues.customerPhone };
            postCustomerListBySearch(customerListParams).then(res => customerListResponse(res));
        }
    }

    // client list api response
    const customerListResponse = (res) => {
        if(res.data.statusCode === 200){
            setCustomerList(res.data.customerDetails);
            setVerifyLoader(false);
            setShowServiceList(true);
            setVerifyBtnActive(true);
            setcustomerListShow(true);
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Customer not found',
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    // search input field validation
    const inputFieldValidate = (value) => {
        let errors = {};
        errors.status = true;

        if((!value.customerName || value.customerName.trim() === '') && (!value.customerPhone || value.customerPhone.trim() === '')){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        if(value.customerPhone || value.customerPhone.trim() !== ''){
            const numval = value.customerPhone.split('');
            if(numval[1] === '0'){
                errors.search = 'Enter valid phone';
                errors.status = false;
            }else if(value.customerPhone && value.customerPhone.length !== 14){
                errors.search = 'Phone must have 10 digit';
                errors.status = false;
            } 
        }

        const validations = {
            productName: HtmlTagValidation(value.customerName),
            description: HtmlTagValidation(value.customerPhone)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }

    // customer verify button onclick
    const customerVerifyHandle = (customerData) => {
        setVerifyBTNDisable(true);
        let customerCheckParams = {'customerEmail' : customerData.customerEmail};
        postCustomerEmailVerification(customerCheckParams).then(res => customerVerifyResponse(res));
    }

    // customer verification response
    const customerVerifyResponse = (res) => {
        setVerifyBTNDisable(false);
        if(res.data.statusCode == 200){
            dispatch(setNewBookingCustomerDetails(res.data.datas));
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Customer Successfully Verified!',
                text: 'Please select a service...',
                showConfirmButton: false,
                timer: 3000
            });
            navigate('../stylist/selectservice/')
        }else if(res.data.statusCode == 203){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.data.statusMessage,
                showConfirmButton: false,
                timer: 5000
            });
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Customer not found',
                showConfirmButton: false,
                timer: 3000
            });
        }
    }

    // back button onclick function
    const handlePrevPage = () => {
        navigate(-1);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div >
                                            <h1>Booking For Customer</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row>
                                <Form onSubmit={handleSubmit}>
                                    <div className='addBooking-title'>
                                        <h2>Customer Search</h2>
                                    </div>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label style={{color : '#fff'}}>Customer Name <span>*</span></Form.Label>
                                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name="customerName" value={searchFromValues.customerName} onChange={seachHandleChange}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="" controlId="formBasicEmail">
                                                <Form.Label style={{color : '#fff'}}>Customer Phone <span>*</span></Form.Label>
                                                <Form.Control onKeyDown={BlockSQLInjection} type="text" name="customerPhone" value={searchFromValues.customerPhone} onChange={seachHandleChange} maxLength={14}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className='btnCenter'>
                                            {
                                                verifyLoader === true ? 
                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                :
                                                    <Button variant="primary" className='verifyUserBtn' type='submit' disabled={verifyBtnActive}>Search</Button>
                                            }
                                        </Col>
                                        <Col>
                                            <span className='error'>{searchError.search}</span>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                            {
                                customerListShow === true ?
                                <Row>
                                    <Stack direction='horizontal' className='backStack'>
                                        <div className='addBooking-title' style={{marginTop : '24px'}}>
                                            <h2>Verify Customer & Payment Profile</h2>
                                        </div>
                                    </Stack>
                                    <div className="table-responsive" id='no-more-tables'>
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Phone Number</th>
                                                    <th>Email</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    customerList.length > 0 ?
                                                        customerList.map((list, index) => (
                                                            <tr key={index}>
                                                                <td data-title='Name'>{list.customerName}</td>
                                                                <td data-title='Phone Number'>{list.customerPhoneNumber}</td>
                                                                <td data-title='Email'>{list.customerEmail}</td>
                                                                <td data-title='Action'><Button variant="primary" className='verifyCustomer'onClick={() => customerVerifyHandle(list)} disabled={verifyBTNDisable}>Verify User</Button></td>
                                                            </tr>
                                                        ))
                                    
                                                    :
                                                        <tr>
                                                            <td colSpan={4} style={{textAlign : 'center'}} className='tableNoDataFoundMBL'>Customer Not Found...</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                                :   
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}