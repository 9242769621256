import { React } from 'react';
import { Col, Container, Row, Nav, Tab } from 'react-bootstrap';
import Topbar from '../Assets/Topbar';  
import LeftSidebar from '../Assets/LeftSidebar';
import PayoutAccount from './TabPages/PayoutAccount';
import ChangePassword from './TabPages/ChangePassword';
import EditProfile from './TabPages/EditProfile';

export default function StylistSettings() {

    return(
        <div>
            <Container>
                <Row className='sideBarRow'>    
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/> 
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Settings</h1>
                                </Col>
                            </Row> 
                            <Row className='setting-sec'>
                                <Col>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="account">
                                        <Nav variant="pills" className="mb-4">
                                            <Nav.Item>
                                                <Nav.Link eventKey="account">Payout Account</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="profile">Edit Profile</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="password">Change Password</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                                <Tab.Pane eventKey="profile" className='setform'>
                                                    <EditProfile />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="account" className='setform'>
                                                    <PayoutAccount />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="password" className='setform'>
                                                    <ChangePassword />
                                                </Tab.Pane>
                                        </Tab.Content> 
                                    </Tab.Container>
                                </Col>
                            </Row>   
                        </div>
                    </Col>
                </Row>       
            </Container>
        </div>
    )
}