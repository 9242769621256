import React, { useEffect } from 'react';
import Header from '../Components/Customer/Assets/Header';
import Footer from '../Components/Customer/Assets/Footer';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { setCustomerLogoutUrl } from '../reducers/User/action';

const CustomerLayout = ({ props }) => {
    const navigation = useNavigate();
    const params = useLocation();
    const dispatch = useDispatch();

    let urlPathName = params.pathname;
    const apiToken = useSelector(store => store.user['customertoken']);
    const customerData = useSelector(store => store.user['customeruser']);
    const customerLogoutUrl = useSelector(store => store.user.customerLogoutUrl);

    useEffect(() => {
        dispatch(setCustomerLogoutUrl(null));
    }, []);

    useEffect(() => {
        if(!apiToken || !customerData){
            if (customerData !== '/customer/payment'){
                dispatch(setCustomerLogoutUrl(urlPathName));
                navigation('/customerlogin');
            } else {
                navigation('/');
            }
            
        }
    },[apiToken]);

    return (
        <>
            <Header/>
                <main style={{ padding: '1rem 0' }}>
                    <Outlet />
                </main>
            <Footer/>
        </>
    )
};

  export default CustomerLayout;