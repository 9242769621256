//import { React, useState } from "react";
import { React, useState, useEffect, useCallback} from "react";
import { Container, Row, Col, Stack} from "react-bootstrap";
import { Scheduler, Editing, Resource } from 'devextreme-react/scheduler';
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { adminBookingList } from "../../../Service";
import moment from 'moment';
import { setAdminBookingDetails } from "../../../reducers/User/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bookingcalendarListColor } from "../../Reusable";

export default function BookingCalendar() {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [appoinments , setAppoinments] = useState([]);
    const [allAppontment, setAllAppontment] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newDate = moment(new Date()).format('YYYY-MM-DD');
    const listParams = {"filterDate": newDate, "filterType": "month"};

    useEffect(()=>{
        adminBookingList(listParams).then(res=> handleAdminBookingList(res));
    },[]);

    const handleAdminBookingList = (response) => {
        if (response.data.statusCode === 200) {
            let initialBookedData  = response.data.customerBookingList;
            let initialmonthBookedData  = [];
            initialBookedData.map((list)=>{
                if(list.status !== "Cancelled"){
                    if(list.bookingType != 'CLSB'){
                        let listOpenTime = list.startDateTime;
                        let listEndTime = list.endDateTime;
                        let singleBooedData  = {"startDate": listOpenTime, "endDate": listEndTime, "text": list.customerName, "bookingId": list.bookingId, "clientId": list.clientId,
                        "clientName": list.clientName, "createdOnUtc": list.createdOnUtc, "customerId": list.customerId, "serviceName": list.serviceName, "payment": list.payment, "productId": list.productId, "serviceId": list.serviceId,
                        "shopId": list.shopId, "shopName": list.shopName, "slotId": list.slotId, "status": list.status, "serviceCost" : list.serviceCost, "platformFee" : list.platformFee, "tips" : list.tips, "bookingType" : list.bookingType, "isCustomerRespondent" : list.isCustomerRespondent, "paymentType" : list.paymentType
                        // , 'colorId' : list.bookingType === "CLSB" ? 2 : list.status === 'Completed' ? 1 : 3  
                        };
                        let currentData = moment(singleBooedData.startDate).format('DD');
                        let currentDataMonth = moment(singleBooedData.startDate).format('MM');
                        initialmonthBookedData.push(singleBooedData);
                    }else if(list.bookingType == 'CLSB'){
                        let listOpenTime = list.startDateTime;
                        let listEndTime = list.endDateTime;
                        let singleBooedData  = {"startDate": listOpenTime, "endDate": listEndTime, "text": 'Self Booking', "bookingId": list.bookingId, "clientId": list.clientId,
                        "clientName": list.clientName, "createdOnUtc": list.createdOnUtc, "customerId": list.customerId, "serviceName": list.serviceName, "payment": list.payment, "productId": list.productId, "serviceId": list.serviceId,
                        "shopId": list.shopId, "shopName": list.shopName, "slotId": list.slotId, "status": list.status, "serviceCost" : list.serviceCost, "platformFee" : list.platformFee, "tips" : list.tips, "bookingType" : list.bookingType, "isCustomerRespondent" : list.isCustomerRespondent, "paymentType" : list.paymentType
                        // , 'colorId' : list.bookingType === "CLSB" ? 2 : list.status === 'Completed' ? 1 : 3  
                        };
                        let currentData = moment(singleBooedData.startDate).format('DD');
                        let currentDataMonth = moment(singleBooedData.startDate).format('MM');
                        initialmonthBookedData.push(singleBooedData);
                    }
                }
            });
            setAppoinments(initialmonthBookedData);

            const confirmedCount = initialmonthBookedData.filter(
                (appointment) => appointment.bookingType !== "CLSB"
            );
            setAllAppontment(confirmedCount)
        }
    }

    const views = ['month'];

    const cellClick = (e) => {
      
    }

    const onAppointmentFormOpening = useCallback((e) => {
        dispatch(setAdminBookingDetails(e.appointmentData));
        navigate('/admin/bookingdetails');
    }, []);

    const dataCellRender = (itemData) => {
        const { startDate } = itemData;
        const ctDate = new Date();
        const isDisabled = (startDate.getMonth() < ctDate.getMonth()) || (startDate.getDate() < ctDate.getDate() && startDate.getMonth() === ctDate.getMonth()) ? true : false;
        return <div className={ isDisabled ? 'dx-scheduler-date-table-cell-text disable-data-table-cell' : "dx-scheduler-date-table-cell-text" }>{itemData.startDate.getDate()}</div>
    }

    const AppointmentTooltip = (e) => {
        const targetData = e.data.targetedAppointmentData;
        return(
            <div>
                <div class="dx-tooltip-appointment-item">
                    <div class="dx-tooltip-appointment-item-marker">
                        <div class="dx-tooltip-appointment-item-marker-body"></div>
                    </div>
                    <div class="dx-tooltip-appointment-item-content">
                        <div class="dx-tooltip-appointment-item-content-subject">{targetData.customerName} - {targetData.text}</div>
                        <div class="dx-tooltip-appointment-item-content-date">
                            {moment(targetData.startDate).format('MMMM D')}
                            {' '}
                            {moment(targetData.startDate).format('hh:mm A')}
                            {' - '}
                            {moment(targetData.endDate).format('hh:mm A')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const onOptionChanged = useCallback((e) => {
        if(e.name === 'currentDate') {
            onDateNavigate(e.value);
        }
    },[]);

    const onDateNavigate = (e)=>{
        let year = moment(e).format('YYYY');
        let month = moment(e).format('MM');
        let date  = '01';
        let changedDate = year+'-'+month+'-'+date;
        const listParams = {"filterDate": changedDate, "filterType": "month"};
        adminBookingList(listParams).then(res=> {
            handleAdminBookingList(res);
        });
    };

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Booking Calendar</h1>
                                </Col>
                            </Row> 
                            <Row className="mb-3">
                                <Col className="client-search">
                                    <Stack direction="horizontal" className="align-items-end booking-list" gap={3}>
                                        <div className="Search-group">
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                            <div className='booking_calendar'>
                                  <Scheduler
                                      dataSource={appoinments}
                                      currentDate={currentDate}
                                      views={views}
                                      defaultCurrentView="month"
                                      startDayHour={0}
                                      endDayHour={24}
                                      adaptivityEnabled={true}
                                      showAllDayPanel={false}
                                      onCellClick={cellClick}
                                      /*appointmentTooltipComponent={AppointmentTooltip}*/
                                      onAppointmentClick={onAppointmentFormOpening}
                                      onOptionChanged={onOptionChanged}
                                    >   
                                        <Editing allowAdding={false} allowDeleting={false} />
                                        {/* <Resource
                                            dataSource={bookingcalendarListColor}
                                            allowMultiple={true}
                                            fieldExpr="colorId"
                                            label="Assignee"
                                            useColorAsDefault={true}
                                        /> */}
                                  </Scheduler>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container> 
        </section>
    )
}