export const SET_ADMINUSER="SET_ADMINUSER";
export const SET_STYLISTUSER="SET_STYLISTUSER";
export const SET_CUSTOMERUSER="SET_CUSTOMERUSER";
export const SET_ADMINTOKEN="SET_ADMINTOKEN";
export const SET_STYLISTTOKEN="SET_STYLISTTOKEN";
export const SET_CUSTOMERTOKEN="SET_CUSTOMERTOKEN";
export const SET_ADMINLOGOUT="SET_ADMINLOGOUT";
export const SET_STYLISTLOGOUT="SET_STYLISTLOGOUT";
export const SET_CUSTOMERLOGOUT="SET_CUSTOMERLOGOUT";
export const SET_BOOKINGURL="SET_BOOKINGURL";
export const SET_BOOKINGDETAILS="SET_BOOKINGDETAILS";
export const SET_ADMINBOOKINGDETAILS="SET_ADMINBOOKINGDETAILS";
export const SET_CUSTOMERBOOKINGDETAILS="SET_CUSTOMERBOOKINGDETAILS";
export const SET_QBACCESSTOKEN="SET_QBACCESSTOKEN";
export const SET_BOOKINGHISTORYDETAILS="SET_BOOKINGHISTORYDETAILS";
export const SET_PRIVACYPREFERENCE="SET_PRIVACYPREFERENCE";
export const SET_BOOKINGTIMEOUT="SET_BOOKINGTIMEOUT";
export const SET_NEWBOOKINGCUSTOMERDETAILS="SET_NEWBOOKINGCUSTOMERDETAILS";
export const SET_STYLISTLOGOUTURL = "SET_STYLISTLOGOUTURL"; 
export const SET_CUSTOMERLOGOUTURL = "SET_CUSTOMERLOGOUTURL"; 