import { React, useState, useEffect } from "react";
import { Container, Image, Navbar, Offcanvas, Nav, Button, Row, Col } from "react-bootstrap";
import Logo from '../../../images/logo1.png';
import { CalendarMonth, DashboardCustomizeRounded, DesignServices,  List, Person2Sharp, Autorenew, MiscellaneousServices } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import person from "../../../images/person.png";
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setAdminLogout } from '../../../reducers/User/action';
import CloseIcon from '@mui/icons-material/Close';

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch=useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => {
      setShow(false);
    }

    const handleShow = () => setShow(true);

    const doLogout = (popupState)=>{
        popupState.close();
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to Log Out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Log Out!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setAdminLogout());
                navigate("/adminlogin");
            }
        })
    }

    let loggedUser = useSelector(store => store.user['adminuser']);

    const [stickyClass, setStickyClass] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            // window height changed for the demo
            windowHeight > 60 ? setStickyClass('sticky-nav') : setStickyClass('');
        }
    }

    return(
        <div className="leftbar">
            <Navbar  navbar="dark" expand="md"  className={`${stickyClass}`}>
                <Container className="navContainer">
                    <Navbar.Brand>
                        <Link to="../admin/dashboard"><Image src={Logo} /></Link>
                    </Navbar.Brand>
                    <div className="pro-mobile-view">       
                        <PopupState variant="popover" popupId="demo-popup-popover" >
                            {(popupState) => (
                                <div>
                                  <Button variant="" {...bindTrigger(popupState)}>
                                      <div className="pro-sec">
                                          <div className="top-profile"> <Image src={person} />
                                              <span className="active circle"></span>
                                          </div>
                                      </div>
                                  </Button>
                                  <Popover
                                      {...bindPopover(popupState)}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                  >
                                      <Typography sx={{ p: 2 }}> 
                                            <Row className="profile-dropdown">
                                                <Col>
                                                    <Image src={person} /> 
                                                    <h4>{loggedUser ? loggedUser.userName : ''}</h4>
                                                </Col>
                                            </Row> 
                                            <hr/>
                                            <Row className="profile-link">
                                                <Col>
                                                    <div className="text-center"><a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Sign Out</a></div>
                                                </Col>
                                            </Row>
                                      </Typography>
                                  </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={handleShow}/>
                    <Navbar.Offcanvas
                      id={`offcanvasNavbar-expand-md`}
                      aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                      placement="end"
                      show={show} 
                      onHide={handleClose}
                      className="adminOffCanvas"
                    >
                        <Offcanvas.Header >
                            <button className="offCanvasCloseBtn" closeButton onClick={handleClose}><CloseIcon /></button>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                                <Navbar.Brand>
                                    <Link to="../admin/dashboard"><Image src={Logo} /></Link>
                                </Navbar.Brand>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Link to="../admin/dashboard"><DashboardCustomizeRounded /> Dashboard</Link>
                                <Link to="../admin/bookingcalendar"><CalendarMonth /> Booking Calendar</Link>
                                <Link to="../admin/manageprofessions"><DesignServices/> Manage Professions</Link>
                                <Link to="../admin/manageservices"><MiscellaneousServices /> Manage Services</Link>
                                <Link to="../admin/managestylist"><Person2Sharp /> Manage Professionals</Link>
                                <Link to="../admin/transactions"><Autorenew /> Transactions</Link>
                                <Link to="../admin/customerlist"><List /> Customer List</Link> 
                            </Nav>
                        </Offcanvas.Body> 
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default Dashboard;