import { React, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import TermsAndConditions from '../../terms/termsandconditions';
import CookiesPolicy from '../../policy/cookiepolicy';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

export default function StylistFooter() {

    const [modalShow, setModalShow] = useState(false);
    const [poliicyModalShow, setPoliicyModalShow] = useState(false);
    const currentYear = moment.utc().format('YYYY');

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://cdn.termsfeedtag.com/plugins/pc/v1/404891cc715341f7946d05a5b4a8425b/plugin.js";
        script.async = true;

        document.body.appendChild(script);
    }, []);

    return(
        <section className='adminStylist admin-footer'>
            <div className='footer'>
                <div className='container'>    
                    <Row>
                        <Col md={9}>
                            <p>© {currentYear} Unext. All rights reserved | <Link  onClick={() => setModalShow(true)}> Terms and Conditions</Link> | <Link onClick={() => setPoliicyModalShow(true)}>Cookie Policy</Link> | <span id='open_privacy_preferences_element' style={{textDecoration : 'underline', cursor : 'pointer'}}>Privacy Preference</span> | <span id='open_dnsmpi_element' style={{textDecoration : 'underline', cursor : 'pointer'}}>Do Not Sell My Personal Information</span></p>
                            <p>Developed and Supported by <a href="https://www.datanetiix.com/" className="dnx-link" target="_blank"> Datanetiix Solutions Inc.,</a></p> 
                        </Col>
                    </Row>          
                </div>
            </div> 
            <TermsAndConditions show={modalShow} onHide={() => setModalShow(false)} />
            <CookiesPolicy show={poliicyModalShow} onHide={() => setPoliicyModalShow(false)} />
        </section>
    )
}