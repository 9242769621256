import { React, useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Image, Button, Table, Form } from "react-bootstrap";
import deleteIcon from "../../../images/delete.png";
import edit from "../../../images/edit.png";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { getAllProfessionsBySearch, deleteProfession } from '../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterCircleXmark, faArrowDownAZ, faArrowDownZA } from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../Assets/Pagination';
import AddProfessionPopup from './Popup/AddProfessionPopup';
import EditProfessionPopup from './Popup/EditProfessionPopup';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';

export default function ManageStylist() {
    // state diclaration
    const [allProfession, setAllProfession] = useState([]);
    const [professionAdd, setProfessionAdd] = useState(false);
    const [professionLoader, setProfessionLoader] = useState(true);
    const searchInitialValues = {searchByProfessionName : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('asc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [addprofessionStatus, setAddprofessionStatus] = useState(false);
    const [editprofessionStatus, setEditprofessionStatus] = useState(false);
    const [editProfessionData, setEditProfessionData] = useState({});

    // get all profession list
    useEffect(() => {
        setProfessionLoader(true);
        let params = {'searchProfessionName' : searchFormValues.searchByProfessionName, 'pageNo' : currentPage,  'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
        getAllProfessionsBySearch(params).then(res => professionResponse(res));
    },[professionAdd, currentPage, sortBy, sortDir, perPage]);

    const professionResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllProfession(response.data.professionList);
            setProfessionLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setProfessionLoader(false);
        }
    }

    // profession delete icon onclick
    const deleteUser = (data)=> {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this profession?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                var id = data.id;
                var whereDelete = {"id" : id};
                deleteProfession(whereDelete).then(res => handleDeleteResponse(res));
            }
        })
    }

    // profession delete api response
    const handleDeleteResponse = (response) => {
        if(response.data.statusCode === 200){  
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            if(allProfession.length === 1 && currentPage !== 1){
                setCurrentPage(currentPage - 1);
            }else{
                setProfessionAdd(prevProfessionCheck => !prevProfessionCheck);
            }
        } else { 
            const errors = {};
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
        } 
    }

    // add profession button onclick
    const handleShow = () => {
        setAddprofessionStatus(true);
    }

    // call back from add profession popup
    const getStatusFromAddPopup = (popupStatus, reloadStatus) => {
        if(popupStatus === true){
            setAddprofessionStatus(false);
        }

        if(reloadStatus === true){
            setAddprofessionStatus(false);
            setProfessionAdd(prevProfessionCheck => !prevProfessionCheck);
        }
    }

    // edit profession icon onClick
    const editHandleClick = (data) => {
        setEditprofessionStatus(true)
        setEditProfessionData(data);
    }

    // call back from add profession popup
    const getStatusFromEditPopup = (popupStatus, reloadStatus) => {
        if(popupStatus === true){
            setEditprofessionStatus(false);
            setEditProfessionData({});
        }

        if(reloadStatus === true){
            setEditprofessionStatus(false);
            setEditProfessionData({});
            setProfessionAdd(prevProfessionCheck => !prevProfessionCheck);
        }
    }

    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    // clear filter button onclick
    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('asc');
        setProfessionLoader(true);
        setClearbtnDisable(true);

        let params = {'searchProfessionName' : '', 'pageNo' : 1,  'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'asc'};
        getAllProfessionsBySearch(params).then(res => professionResponse(res));
    }

    // search input fields onchange
    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    // search form onsubmit
    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setProfessionLoader(true);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);
            let params = {'searchProfessionName' : searchFormValues.searchByProfessionName, 'pageNo' : 1,  'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};

            getAllProfessionsBySearch(params).then(res => searchProfessionResponse(res));
        }
    }

    // search api response
    const searchProfessionResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllProfession(response.data.professionList);
            setProfessionLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setProfessionLoader(false);
        }
    }

    // search input field validations
    const searchValidate = (values) => {
        const errors = {};
        errors.status = true;
        
        if(values.searchByProfessionName == '' || values.searchByProfessionName.trim() === ''){
            errors.search = 'Provide any search term';
            errors.status = false;
        }

        let tagValidate = HtmlTagValidation(values.searchByProfessionName);

        if(!tagValidate){
            errors.search='Invalid Input';
            errors.status=false;
        }

        return errors;
    }

    // call back from paginations
    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    // sorting icon onclick
    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Manage Professions</h1>
                                </Col>
                            </Row> 
                            <Row className="mb-3 managesearch">
                                <Col className="">   
                                    <Stack direction="horizontal" className="align-items-end" gap={2}>
                                        <div className="add-btn ms-auto">
                                            <Button variant="success" onClick={handleShow}>+ Add Profession</Button> 
                                        </div>
                                        <div className="search-status-btn">
                                            {
                                                searchStatus == false ?
                                                    <Button variant="primary" onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                                :
                                                    ''
                                            }
                                        </div>
                                    </Stack>                        
                                </Col>
                            </Row>
                            <Row>
                                <Form onSubmit={searchHandleSearch}>
                                    <Row className='searchRow'>
                                        {
                                            searchStatus == false ? 
                                                ''
                                            :
                                                <>
                                                    <Col xl={4}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Profession Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfessionName' placeholder='Profession name...' onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={8} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter <FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                </> 
                                        }
                                        <span className='error'>{searchErrors}</span>
                                    </Row>
                                </Form>
                            </Row>
                            <Row>
                                <Col >
                                    <div className="table-responsive" id='no-more-tables'>
                                        <Table striped bordered hover variant="dark">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th width="50" colSpan="3">
                                                        Profession
                                                        {
                                                            listCount > 0 ?
                                                            <span className='sort'> {sortBy == 'name' ? sortDir == 'asc' ? <FontAwesomeIcon icon={faArrowDownZA}  onClick={() => sortingOnClick({by : 'name', dir : 'desc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/> : <FontAwesomeIcon icon={faArrowDownAZ} onClick={() => sortingOnClick({by : 'name', dir : 'asc'})}/>}</span>
                                                            :
                                                            ''
                                                        }
                                                    </th>
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    professionLoader === true ?
                                                        <tr>
                                                            <td colSpan='5' style={{textAlign : 'center', paddingLeft : '0px'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                        </tr>
                                                    :
                                                        allProfession.length > 0 ?
                                                            allProfession.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td data-title="Profession" width="50" colSpan="3" className='professionAdjust'>{data.name}</td>
                                                                    <td data-title="Action">
                                                                        <Stack direction="horizontal" gap={3} className='professionAction'>
                                                                            <div className="">
                                                                                <Image src={edit} style={{cursor: 'pointer'}} onClick={() => editHandleClick(data)} className='tableActionIcon'/>
                                                                                <Button className='actionText editBtn' style={{cursor: 'pointer'}} onClick={() => editHandleClick(data)}>Edit</Button>
                                                                            </div>
                                                                            <div >
                                                                                <Image src={deleteIcon} style={{cursor: 'pointer'}} onClick={() => deleteUser(data)} className='tableActionIcon'/>
                                                                                <Button className='actionText dltBtn' style={{cursor: 'pointer'}} onClick={() => deleteUser(data)}>Delete</Button>
                                                                            </div>
                                                                            
                                                                        </Stack>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        :
                                                            <tr>
                                                                <td colSpan='5' style={{textAlign : 'center'}} className='noDataFound'>No Data Found...</td>
                                                            </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>    
                            </Row>   
                            {
                                listCount > 0 ?
                                    <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                                : 
                                    ''
                            }

                            {
                                addprofessionStatus === true ?
                                    <AddProfessionPopup getBack={getStatusFromAddPopup} />
                                :
                                    ''
                            }

                            {
                                editprofessionStatus === true ? 
                                    <EditProfessionPopup getBack={getStatusFromEditPopup} profesiionData={editProfessionData} />
                                :
                                    ''
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 