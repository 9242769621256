import react from 'react';
import { Col, Container, Row, Stack, Form, Button } from "react-bootstrap";
import logo from "../../images/logo.png";
import PageNotFoundImage from '../../images/404.jpg';
import { Link } from "react-router-dom";

export default function PageNotFound () {

    return(
        <section>
            <Container>
                <Row>
                    <Col>
                        <div className="text-center">
                            <img src={logo} />
                        </div>
                    </Col>
                </Row>
                <Row style={{textAlign : 'center'}}>
                    <p style={{color : '#fff'}}><b>Sorry</b></p>
                    <p style={{color : '#fff'}}>The page you are looking for is unavailable, go to <Link to='/'>unextpro.com</Link></p>
                </Row>
                <Row>
                    <div style={{textAlign:'center', marginTop : '16px', marginBottom : '16px'}}>
                        <img src={PageNotFoundImage} className='img-responsive' />
                    </div>
                </Row>
            </Container>
        </section>
    )
}