import { React, useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { patchUpdateClientAcount, clientAccountList, postClientAcountCreate, postPayPalAuthCode } from '../../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function PayoutAccount() {
    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const accountInitial = {accountType : 'PAYPAL', number : '', email : '', clientAccountId : 0};

    const [payoutLoader, setPayoutLoader] = useState(false);
    const [accountData, setAccountData] = useState(accountInitial);
    const [accountError, setAccountError] = useState('');
    const [accountAddLoader, setAccountAddLoader] = useState(false);
    const [accountAddRefresh, setAccountAddRefresh] = useState(false);

    // get bank account data 
    useEffect(() => {
        setPayoutLoader(true);
        const accountParams = {'clientId' : userData ?  userData.clientId : ''};
        clientAccountList(accountParams).then(res => bankAccountResponce(res));

        const getURLParams = new URLSearchParams(window.location.search);
        const getURLCode = getURLParams.get('code');
        if(getURLCode != null){
            let requestPrams = {'clientId' : userData.clientId, 'authCode' : getURLCode};
            postPayPalAuthCode(requestPrams).then(res => authCodeResponse(res));
        }
    }, [accountAddRefresh]); 

    const bankAccountResponce = (res) => {
        if(res.data.statusCode === 200) {
            setAccountData({'accountType' : res.data.accountType, 'number' : res.data.walletPhoneNumber, 'email' : res.data.walletEmailId, 'clientAccountId' : res.data.id});
            setPayoutLoader(false);
        }else{
            setPayoutLoader(false);
        }
    }

    const authCodeResponse = (res) => {
        if(res.data.statusCode == 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Your PayPal account is verified successfully." ,
                showConfirmButton: false,
                timer: 3000
            });
            setPayoutLoader(false);
        }else if(res.data.statusCode == 403){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Provided email address does not match with PayPal account. Please update your email address.',
                showConfirmButton: false,
                // text: res.data.statusMessage,
                timer: 5000
            }); 
            setPayoutLoader(false);
        }else if(res.data.statusCode == 405){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'The PayPal account is not verified or else the email is not verified in your PayPal account',
                showConfirmButton: false,
                timer: 5000
            }); 
            setPayoutLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setPayoutLoader(false);
        }
    }

    // phone number formater
    const formatPhoneNumber = (numberValue) => {
        if (!numberValue) return numberValue;
        const phoneNumber = numberValue.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        //if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 4) {
            return `(${phoneNumber.slice(0, 3)}`;
        }
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    // Payout Account details functionalities starts
    const accAddHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;

        setAccountData(prevState => ({
            ...prevState,
            [field]: value 
        }));

        if(field === 'number'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setAccountData(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber
            }));
        }
    }

    const accountHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = accountValidate(accountData);
        setAccountError(errorCount);
        if(errorCount.status){
            if(accountData.clientAccountId == 0){
                let payoutData = {};
                if(accountData.accountType == 'PAYPAL'){
                    payoutData = {'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : '', 'walletEmail' : accountData.email};
                }else if(accountData.accountType == 'VENMO'){
                    payoutData = {'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : accountData.number, 'walletEmail' : ''};
                }

                let jsonData = await JsonEncode(payoutData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postClientAcountCreate(loginPayload).then(res => accountHanldeResponse(res))
                    setAccountAddLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }else{
                let payoutData = {};
                if(accountData.accountType == 'PAYPAL'){
                    payoutData = {'clientAccountId' : accountData.clientAccountId, 'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : '', 'walletEmailId' : accountData.email};
                }else if(accountData.accountType == 'VENMO'){
                    payoutData = {'clientAccountId' : accountData.clientAccountId, 'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : accountData.number, 'walletEmailId' : ''};
                }

                let jsonData = await JsonEncode(payoutData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    patchUpdateClientAcount(loginPayload).then(res => accountHanldeResponse(res))
                    setAccountAddLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        }
    }

    const accountHanldeResponse = (res) => {
        if(res.data.statusCode === 200){
            if(accountData.accountType == 'PAYPAL'){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "You will be redirected to PayPal, Please verify using same email address entered here." ,
                    showConfirmButton: false,
                    timer: 3000
                });
                setPayoutLoader(true);
            }else{
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: res.data.statusMessage,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            setAccountData(accountInitial);
            setAccountAddRefresh(prevstate => !prevstate)
            setAccountAddLoader(false);
            setTimeout(() => {
                if(accountData.accountType === 'PAYPAL'){
                    window.location.replace(process.env.REACT_APP_SETTING_PAYPAL);
                }
            }, 3000);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setAccountAddLoader(false);
        }
    }

    const accountValidate = (values) => {
        const errors = {};
        errors.status = true;
        const emailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if(!values.accountType){
            errors.accountType = 'Account type is required';
            errors.status = false;
        }else{
            if(values.accountType == 'PAYPAL'){
                if(!values.email){
                    errors.email = 'Email is required';
                    errors.status = false;
                }else if (!emailCheck.test(values.email)) {
                    errors.email ="This is not a valid email format!";
                    errors.status = false;
                }
            }else if(values.accountType == 'VENMO'){
                if(!values.number){
                    errors.number = 'Phone number is required';
                    errors.status = false;
                }else if(values.number){
                    const numval = values.number.split('');
                    if(numval[1] === '0'){
                        errors.number = 'Enter valid phone number';
                        errors.status = false;
                    }else if(values.number && values.number.length !== 14){
                        errors.number = 'Phone number must have 10 digit';
                        errors.status = false;
                    } 
                }
            }
        }

        let tagValidate = HtmlTagValidation(values.email);

        if(!tagValidate){
            errors.email ="Invalid Input";
            errors.status = false;
        }

        return errors;
    }

    return(
        <>
            <Row>
                <Col>
                    <h4 className='mb-3'>Payout Account</h4>
                    <p className='payoutValid'>Note: <span>Please enter a valid PayPal account detail</span></p>
                    {
                        payoutLoader == true ?
                            <div style={{textAlign : 'center'}}>
                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                            </div>
                        :
                            <Row className="mb-3">
                                <Form onSubmit={accountHandleSubmit}>
                                    <Row>
                                        <Col md='6'>
                                            <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                    <Form.Label>Account Type <span>*</span></Form.Label>
                                                    <Form.Select aria-label="Default select example" name='accountType' onChange={accAddHandleChange} disabled>
                                                        <option value=''>Select account type</option>
                                                        <option value='PAYPAL' selected='selected'>PAYPAL</option>
                                                        <option value='VENMO' selected={accountData.accountType == 'VENMO' ? 'selected' : ''}>VENMO</option>
                                                    </Form.Select>
                                                    <p className="error">{accountError.accountType}</p>
                                            </Form.Group>
                                        </Col>
                                        {
                                            accountData.accountType ?
                                                accountData.accountType === 'VENMO' ? 
                                                    <Col md='6'>
                                                        <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                            <Form.Label>Venmo Phone# <span>*</span></Form.Label>
                                                            <Form.Control type="tel" name="number" onChange={accAddHandleChange} value={accountData.number} maxLength={14}/>
                                                            <p className="error">{accountError.number}</p>
                                                        </Form.Group>
                                                    </Col>
                                                : 
                                                    accountData.accountType === 'PAYPAL' ?
                                                        <Col md='6'>
                                                            <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                                <Form.Label>PayPal Email <span>*</span></Form.Label>
                                                                <Form.Control onKeyDown={BlockSQLInjection} type="email" name="email" onChange={accAddHandleChange} value={accountData.email} />
                                                                <p className="error">{accountError.email}</p>
                                                            </Form.Group> 
                                                        </Col>
                                                    :
                                                        ''
                                            :
                                                ''
                                        }
                                    </Row>
                                    <Row>
                                        <div style={{textAlign : 'center'}}>
                                            {
                                                accountAddLoader == true ? 
                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                :
                                                    <Button variant="primary" className='upload-btn' type='submit'>Verify</Button>
                                            }
                                        </div>
                                    </Row>
                                </Form>
                            </Row>
                    }
                </Col>
            </Row>
        </>
    )
}