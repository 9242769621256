import { React, useState, useRef } from "react";
import { Row, Col, Stack, Image, Form, Button, Overlay} from "react-bootstrap";
import person from "../../../images/person.png";
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import { setAdminLogout } from '../../../reducers/User/action';
import Swal from "sweetalert2";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();

  const doLogout = (popupState)=>{
    popupState.close();
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Log Out?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Log Out!'
    }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setAdminLogout());
          navigate("/adminlogin");
        }
    })
  }

  const [show, setShow] = useState(false);
  const target = useRef(null);  
  let loggedUser = useSelector(store => store.user['adminuser']);

  return(
  <div className="top_bar"> 
    <Row>
      <Col>
      <Stack direction="horizontal" className="align-items-end" gap={2}>
        <div className="ms-auto">
          
        </div>
        <div className="d-flex align-items-center">
          
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
            <div>
              
            </div>
            )}
          </PopupState>
          
        </div>
        <div className="d-flex align-items-center">
          
          <PopupState variant="popover" popupId="demo-popup-popover" >
            {(popupState) => (
            <div>
              <Button variant="" {...bindTrigger(popupState)}>
                <div className="pro-sec">
                  <div className="top-profile"> <Image src={person} />
                    <span className="active circle"></span>
                  </div>
                </div>
              </Button>

              <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              >
              <Typography sx={{ p: 2 }}> 
                <Row className="profile-dropdown">
                 <Col><Image src={person} /> 
                  
                 <h4>{loggedUser ? loggedUser.userName : ''}</h4>
               </Col>
             </Row> 
             
          <hr/>
          <Row className="profile-link">
            <Col>
            <div className="text-center"><a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Log Out</a></div>
            
          </Col>
        </Row>
        
      </Typography>
    </Popover>
  </div>
  )}
</PopupState>

</div>

</Stack>
</Col>
</Row>

</div>
)
}

export default Dashboard;