import { SET_ADMINUSER,SET_STYLISTUSER,SET_CUSTOMERUSER,SET_ADMINTOKEN,SET_STYLISTTOKEN,SET_CUSTOMERTOKEN,SET_ADMINLOGOUT,SET_STYLISTLOGOUT,SET_CUSTOMERLOGOUT,SET_BOOKINGURL,SET_BOOKINGDETAILS,SET_ADMINBOOKINGDETAILS, SET_CUSTOMERBOOKINGDETAILS, SET_QBACCESSTOKEN,SET_BOOKINGHISTORYDETAILS, SET_PRIVACYPREFERENCE, SET_BOOKINGTIMEOUT, SET_NEWBOOKINGCUSTOMERDETAILS, SET_STYLISTLOGOUTURL, SET_CUSTOMERLOGOUTURL } from './type';

const initialData={adminuser:null,stylistuser:null,customeruser:null,admintoken:'',stylisttoken:'',customertoken:'',bookingurl:false,bookingDetails: null,adminBookingDetails: {},customerBookingDetails: null, qbaccesstoken : null, bookingHistoryDetails:null, privacypreference:true, bookingtimeout:'', newbookingcustomerdetails:null, stylistLogoutUrl:null, customerLogoutUrl:null }

export default function userReducer(state = initialData, action) {
  switch (action.type) {
      case SET_ADMINUSER:
      return {
        ...state,
        adminuser:action.data
      }
      case SET_STYLISTUSER:
      return {
        ...state,
        stylistuser:action.data
      }
      case SET_CUSTOMERUSER:
      return {
        ...state,
        customeruser:action.data
      }
      case SET_ADMINTOKEN:
      return {
        ...state,
        admintoken:action.data
      }
      case SET_STYLISTTOKEN:
      return {
        ...state,
        stylisttoken:action.data
      }
      case SET_CUSTOMERTOKEN:
      return {
        ...state,
        customertoken:action.data
      }
      case SET_ADMINLOGOUT:
      return {
        ...state,
        admintoken:'',
        adminuser:null,
        adminBookingDetails:{}
      }
      case SET_STYLISTLOGOUT:
      return {
        ...state,
        stylisttoken:'',
        stylistuser:null,
        bookingDetails:null,
        newbookingcustomerdetails:null
      }
      case SET_CUSTOMERLOGOUT:
      return {
        ...state,
        customeruser:null,
        customertoken:'',
        bookingurl:false,
        customerBookingDetails:null,
        bookingtimeout:''
      }
      case SET_BOOKINGURL:
        return {
          ...state,
          bookingurl:action.data
        }
      case SET_BOOKINGDETAILS:
        return{
          ...state,
          bookingDetails:action.data
        }
      case SET_ADMINBOOKINGDETAILS:
      return{
        ...state,
        adminBookingDetails:action.data
      }
      case SET_CUSTOMERBOOKINGDETAILS:
      return{
        ...state,
        customerBookingDetails:action.data
      }
      case SET_QBACCESSTOKEN:
      return{
        ...state,
        qbaccesstoken:action.data
      }
      case SET_BOOKINGHISTORYDETAILS:
      return{
        ...state,
        bookingHistoryDetails:action.data
      }
      case SET_PRIVACYPREFERENCE:
      return{
        ...state,
        privacypreference:action.data
      }
      case SET_BOOKINGTIMEOUT:
      return{
        ...state,
        bookingtimeout:action.data
      }
      case SET_NEWBOOKINGCUSTOMERDETAILS:
      return{
        ...state,
        newbookingcustomerdetails:action.data
      }
      case SET_STYLISTLOGOUTURL:
        return {
          ...state,
          stylistLogoutUrl:action.data
      }
      case SET_CUSTOMERLOGOUTURL:
        return {
          ...state,
          customerLogoutUrl:action.data
      }
    default:
      return state;
  }
}