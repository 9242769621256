import { React, useEffect, useState, useRef } from 'react';
import { Col, Row, Stack, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAllState, getClientById, stylistProfileUpdate } from '../../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import Cropper from './PopUp/ImageCropper';
import Swal from "sweetalert2";
import { CirclePicker  } from 'react-color';
import userImage from "../../../../images/userimage.png";
import staticlogoImage from "../../../../images/logo-image.png";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function EditProfile() {
    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const inputProfileImageFile = useRef(null);
    const inputLogoImageFile = useRef(null);

    const userInitialValues = {firstName : '', lastName : '', phoneNumber : '', email : '', address1 : '', address2 : '', city : '', stateProvinceId : '', zipPostalCode : '', backgroundColour : ''};

    const [profileImages, setProfileImages] = useState([]);
    const [profileImagesError, setProfileImagesError] = useState('');
    const [logoImagesError, setLogoImagesError] = useState('');
    const [initialProfileImage, setInitialProfileImage] = useState('');
    const [initialLogoImage, setInitialLogoImage] = useState('');
    const [userFormValues, setUserFormValues] = useState(userInitialValues);
    const [userFormErrors, setuserFormErrors] = useState([]);
    const [allState, setAllState] = useState([]);
    const [userLoader, setUserLoader] = useState(false);
    const [profileRefresh, setProfileRefresh] = useState(false);

    // get client data by userid
    useEffect(() => {
        const userId = {'clientId' : userData ? userData.clientId : ''};
        getClientById(userId).then(res => initialClientResponse(res));
    },[profileRefresh]);

    const initialClientResponse = (response) => {
        if(response.data){
            let backgroundColorFromRes = '';
            if(response.data.backgroundColour !== null){
                backgroundColorFromRes = response.data.backgroundColour;
            }
            
            setInitialProfileImage(response.data.profileImageUrl);
            setInitialLogoImage(response.data.logoUrl);
            setUserFormValues({firstName : response.data.firstName, lastName : response.data.lastName, phoneNumber : response.data.phoneNumber, email : response.data.email, address1 : response.data.address1, address2 : response.data.address2, city : response.data.city, stateProvinceId : response.data.stateProvinceId, zipPostalCode : response.data.zipPostalCode, backgroundColour : backgroundColorFromRes});
        }
    }

    useEffect(() => {
        getAllState().then(res => stateResponse(res));
    },[]);

    const stateResponse = (response) => {
        if(response.data.statusCode === 200){
            setAllState(response.data.stateList)
        }
    }

    // phone number formater
    const formatPhoneNumber = (numberValue) => {
        if (!numberValue) return numberValue;
        const phoneNumber = numberValue.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        //if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 4) {
            return `(${phoneNumber.slice(0, 3)}`;
        }
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const userHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setUserFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));

        if(field === 'firstName' || field === 'lastName'){ // name field only alphabets 
            const newValue=e.target.value.replace(/[^a-zA-Z\s]/g,"");
            setUserFormValues(prevState => ({
                ...prevState,
                [field]:  newValue  
            }));
        }

        if(field === 'phoneNumber'){
            const numberValue = e.target.value;
            const formattedPhoneNumber = formatPhoneNumber(numberValue);
            setUserFormValues(prevState => ({
                ...prevState,
                [field]:  formattedPhoneNumber  
            }));
        }
    }

    const userHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = userValidate(userFormValues);
        setuserFormErrors(errorCount);
        if(errorCount.status){
            userFormValues['clientId'] = userData.clientId;

            let jsonData = await JsonEncode(userFormValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                stylistProfileUpdate(loginPayload).then(res => userHandleResponse(res));
                setUserLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const userHandleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.statusMessage,
              showConfirmButton: false,
              timer: 3000
            });
            setUserLoader(false);
        }else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              showConfirmButton: false,
              text: response.data.statusMessage,
              timer: 3000
            }); 
            setUserLoader(false);
        }
    }

    const userValidate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        errors.status = true;

        if(!values.firstName || values.firstName.trim() === ""){
            errors.firstName = 'First name is required';
            errors.status = false;
        }

        if(!values.lastName || values.lastName.trim() === ""){
            errors.lastName = 'Last name is required';
            errors.status = false;
        } else if (!regex.test(values.email)) {
            errors.email = "This is not a valid email format!";
            errors.status = false;
        }

        if(!values.email){
            errors.email = 'Email is required';
            errors.status = false;
        }

        if(!values.phoneNumber){
            errors.phoneNumber = 'Phone number is required';
            errors.status = false;
        } else if(values.phoneNumber){
            const numval = values.phoneNumber.split('');
            if(numval[1] === '0'){
                errors.phoneNumber = 'Enter valid phone number';
                errors.status = false;
            }else if(values.phoneNumber && values.phoneNumber.length !== 14){
                errors.phoneNumber = 'Phone number must have 10 digit';
                errors.status = false;
            } 
        }

        if(!values.address1 || values.address1.trim() === ""){
            errors.address1 = 'Address is required';
            errors.status = false;
        }

        if(!values.city || values.city.trim() === ""){
            errors.city = 'City is required';
            errors.status = false;
        }

        if(!values.stateProvinceId){
            errors.stateProvinceId = 'Please select a state';
            errors.status = false;
        }

        if(!values.zipPostalCode){
            errors.zipPostalCode = 'Zipcode is required';
            errors.status = false;
        } else if(values.zipPostalCode && values.zipPostalCode.length !== 5){
            errors.zipPostalCode = 'Zipcode must have 5 digit';
            errors.status = false;
        } else if(values.zipPostalCode === "00000"){
            errors.zipPostalCode = 'Invalid Zipcode';
            errors.status = false;
        }

        const validations = {
            email: HtmlTagValidation(values.email),
            address1: HtmlTagValidation(values.address1),
            address2 : HtmlTagValidation(values.address2),
            city : HtmlTagValidation(values.city)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });

        // if(!values.backgroundColour){
        //   errors.backgroundColour = 'Please select a background color';
        //   errors.status = false;
        // }

        return errors;
    }

    //background color change function
    // const handleChangeComplete = (color, event) => {
    //     setUserFormValues(prevState => ({...prevState, 'backgroundColour':  color.hex  }));
    // }

    //Cropper image upload
    const [file, setFile] = useState(null);
    const [popupStatus, setPopupStatus] = useState(false);
    const [fileUploadName, setfileUploadName] = useState('');

    const ImageHandleChange = (e) => {
        let fileNameOfCropImg = e.target.files[0].name.split('.').pop();
        if(fileNameOfCropImg == 'jpg' || fileNameOfCropImg == 'jpeg' || fileNameOfCropImg == 'png' || fileNameOfCropImg == 'JPG' || fileNameOfCropImg == 'JPEG' || fileNameOfCropImg == 'PNG'){
            setProfileImagesError('')
            setProfileImages(e.target.files[0]);
            setFile(e.target.files[0]);
            setPopupStatus(true);
            setfileUploadName(e.target.name);
        }else{
            setProfileImagesError('Please select .jpg, .jpeg or .png format only')
        }
    }

    const LogoImageHandleChange = (e) => {
        let fileNameOfCropImg = e.target.files[0].name.split('.').pop();
        if(fileNameOfCropImg == 'jpg' || fileNameOfCropImg == 'jpeg' || fileNameOfCropImg == 'png' || fileNameOfCropImg == 'JPG' || fileNameOfCropImg == 'JPEG' || fileNameOfCropImg == 'PNG'){
            setLogoImagesError('')
            setProfileImages(e.target.files[0]);
            setFile(e.target.files[0]);
            setPopupStatus(true);
            setfileUploadName(e.target.name);
        }else{
            setLogoImagesError('Please select .jpg, .jpeg or .png format only')
        }
    }

    const getDataFromPopup = (data) => {
        if(data === false){
            setPopupStatus(false);
            setFile(null);
            setProfileImages([]);
            setfileUploadName('');
            if (inputProfileImageFile.current) {
                inputProfileImageFile.current.value = "";
                inputProfileImageFile.current.type = "text";
                inputProfileImageFile.current.type = "file";
            }
            if (inputLogoImageFile.current) {
                inputLogoImageFile.current.value = "";
                inputLogoImageFile.current.type = "text";
                inputLogoImageFile.current.type = "file";
            }
        }else{
            setPopupStatus(false);
            setFile(null);
            setProfileImages([]);
            setfileUploadName('');
            if (inputProfileImageFile.current) {
                inputProfileImageFile.current.value = "";
                inputProfileImageFile.current.type = "text";
                inputProfileImageFile.current.type = "file";
            }
            if (inputLogoImageFile.current) {
                inputLogoImageFile.current.value = "";
                inputLogoImageFile.current.type = "text";
                inputLogoImageFile.current.type = "file";
            }
            setProfileRefresh(prevState => !prevState);
        }
    }

    return(
        <>
            <Row className='pro-col'>
                <Col md={6}>
                    <h4 className='mb-3'>Profile Details</h4>
                    <Stack direction='horizontal' gap={3}>
                        <div className="profile-details">
                            {
                                initialProfileImage ?
                                    <img src={initialProfileImage} alt="uploaded" ></img>
                                :
                                    <img src={userImage} alt="uploaded" ></img>   
                            }
                        </div>
                        <div className='upload__image-wrapper'>
                                <Stack direction='horizontal' gap={3} className='cropingUpload'>
                                    <div>
                                        <input type="file" name="uploadProfilefile" id="img" style={{display : 'none'}} accept="image/*" onChange={ImageHandleChange} ref={inputProfileImageFile}/>
                                        <label for="img" type="button" class="btn btn-dark file-btn btn btn-primary">Select a file to upload</label >
                                    </div>
                                </Stack>
                                {
                                    popupStatus === true && file && fileUploadName == 'uploadProfilefile'?
                                        <Cropper whichCropImage='Profile' fileName={profileImages.name} imageToCrop={URL.createObjectURL(file)} getBack={getDataFromPopup}/>
                                    :
                                        ''
                                }
                                <div className='mt-2'><h6>Allowed JPG, JPEG or PNG.</h6></div>
                        </div>
                    </Stack>
                    <p className="error">{profileImagesError}</p>
                </Col>
                <Col>
                    <h4 className='mb-3'>Logo Upload</h4>
                    <Stack direction='horizontal' gap={3}>
                        <div className="profile-details">
                            {
                                initialLogoImage ?
                                    <img src={initialLogoImage} alt="uploaded" ></img>
                                :
                                    <img src={staticlogoImage} alt="uploaded" ></img>
                                    
                            }
                        </div>
                        <div className='upload__image-wrapper'>
                                <Stack direction='horizontal' gap={3} className='cropingUpload'>
                                    <div>
                                        <input type="file" name="uploadLogofile" id="logoimg" style={{display : 'none'}} accept="image/*" onChange={LogoImageHandleChange} ref={inputLogoImageFile}/>
                                        <label for="logoimg" type="button" class="btn btn-dark file-btn btn btn-primary">Select a file to upload</label >
                                    </div>
                                </Stack>
                                {
                                    popupStatus === true && file && fileUploadName == 'uploadLogofile'?
                                        <Cropper whichCropImage='Logo' fileName={profileImages.name} imageToCrop={URL.createObjectURL(file)} getBack={getDataFromPopup}/>
                                    :
                                        ''
                                }
                                <div className='mt-2'><h6>Allowed JPG, JPEG or PNG.</h6></div>
                        </div>
                    </Stack>
                    <p className="error">{logoImagesError}</p>
                </Col>
            </Row>
            <hr />
            <Form onSubmit={userHandleSubmit} >
                <Row className="mb-3 ">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>First Name <span>*</span></Form.Label>
                            <Form.Control type="text" name='firstName' onChange={userHandleChange} value={userFormValues.firstName} />
                            <p className="error">{userFormErrors.firstName}</p>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Last Name <span>*</span></Form.Label>
                            <Form.Control type="text" name='lastName' onChange={userHandleChange} value={userFormValues.lastName} />
                            <p className="error">{userFormErrors.lastName}</p>
                        </Form.Group>
                </Row>
                <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Phone <span>*</span></Form.Label>
                            <Form.Control type="tel" name='phoneNumber' onChange={userHandleChange} value={userFormValues.phoneNumber} maxLength={14}/>
                            <p className="error">{userFormErrors.phoneNumber}</p>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email <span>*</span></Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type="email" name='email' onChange={userHandleChange} defaultValue={userFormValues.email}/>
                            <p className="error">{userFormErrors.email}</p>
                        </Form.Group>
                </Row>
                <Row className="mb-3">
                        <Form.Group as={Col} className="mb-3" controlId="formGridAddress1">
                            <Form.Label>Address 1 <span>*</span></Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type='text' name='address1' onChange={userHandleChange} defaultValue={userFormValues.address1} />
                            <p className="error">{userFormErrors.address1}</p>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" controlId="formGridAddress2">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control onKeyDown={BlockSQLInjection} type='text' name='address2' onChange={userHandleChange} defaultValue={userFormValues.address2} />
                            <p className="error">{userFormErrors.address2}</p>
                        </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} className="mb-3" controlId="formGridCity">
                    <Form.Label>City <span>*</span></Form.Label>
                    <Form.Control onKeyDown={BlockSQLInjection} type='text' name='city' onChange={userHandleChange} defaultValue={userFormValues.city} />
                    <p className="error">{userFormErrors.city}</p>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3" controlId="formGridCState">
                    <Form.Label>State <span>*</span></Form.Label>
                    <Form.Select aria-label="Default select example" name='stateProvinceId' onChange={userHandleChange}>
                        <option value=''>Select an option...</option>
                        {
                            allState.length > 0 ?
                                allState.map((data, index) => (
                                    <option value={data.stateId} key={index} selected={userFormValues.stateProvinceId === data.stateId ? 'selected' : ''}>{data.stateName}</option>
                                ))
                            : ''
                        }
                    </Form.Select>
                    <p className="error">{userFormErrors.stateProvinceId}</p>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3" controlId="formGridZipcode">
                    <Form.Label>Zipcode <span>*</span></Form.Label>
                    <Form.Control type='number' name='zipPostalCode' onChange={userHandleChange} defaultValue={userFormValues.zipPostalCode} />
                    <p className="error">{userFormErrors.zipPostalCode}</p>
                    </Form.Group>
                </Row>
                {/* <Row>
                    <Col>
                        <Stack gap={3} direction='horizontal' className='bg-colors'>`
                            <div><h5>Background:</h5></div>
                            <div>
                                <div>
                                    <CirclePicker colors={[ '#2666c0', '#27ae61', '#fb8231', '#d94c43']} onChangeComplete={ handleChangeComplete } color={userFormValues.backgroundColour} />
                                </div>
                            </div>
                        </Stack>
                        <p className="error">{userFormErrors.backgroundColour}</p>
                    </Col>
                </Row> */}
                <br/>
                <hr/>
                <div style={{textAlign : 'center'}}>
                    {
                            userLoader === true ?
                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                            :
                                <Button variant='dark' className='upload-btn' type='submit'>Update</Button>
                    }
                </div>
            </Form>
        </>
    )
}