import { AES, enc, mode, pad } from 'crypto-js';
import moment from 'moment';

export const JsonEncode = ( JsonData ) => {

    return new Promise((resolve, reject) => {
        let encodeData = JSON.stringify(JsonData);
        let utcDate = moment.utc().format('DDMM');
        let keySetup = process.env.REACT_APP_JSON_KEY+''+utcDate;
        let secretKey = enc.Utf8.parse(keySetup);

        let encryptData = AES.encrypt(encodeData, secretKey, {mode: mode.ECB, padding: pad.Pkcs7});

        if ( encryptData ) {
            resolve(encryptData.toString());
        } else {
            reject("Encryption failed");
        }
    })
    
}

export const JsonDecode = async ( encryptData ) => {
        
    return new Promise((resolve, reject) => {
        let utcDate = moment.utc().format('DDMM');
        let keySetup = process.env.REACT_APP_JSON_KEY + utcDate;
        let secretKey = enc.Utf8.parse(keySetup);

        let bytes = AES.decrypt(encryptData, secretKey, {mode: mode.ECB, padding: pad.Pkcs7});
        let decrypted = bytes.toString(enc.Utf8);
        let parseJson = JSON.parse(decrypted);

        if ( parseJson ) {
            resolve(parseJson);
        } else {
            reject("Decryption failed");
        }

    })

}