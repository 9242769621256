import { React, useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../../images/logo.png";
import { InfinitySpin } from 'react-loader-spinner';
import { useParams, useNavigate } from 'react-router-dom';
import { postEmailAddressVerification } from '../../../Service';
import linkExpired from '../../../images/link-expired.jpg';
import Swal from "sweetalert2";
import Countdown from "react-countdown";
import { useDispatch} from 'react-redux';
import { setStylistUserData, setCustomerUserData } from '../../../reducers/User/action';

export default function EmailActivation(){

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [pageLoader, setPageLoader] = useState(true);
    const [redirectTime, setRedirectTime] = useState('');
    const [expiredStatus, setExpiredStatus] = useState(false);

    useEffect(() => {
        let activationParams = {'typeOfUser' : params.type, 'token' : params.code};
        postEmailAddressVerification(activationParams).then(res => emailActivationHandleResponse(res));
    }, []);

    const emailActivationHandleResponse = (res) => {
        if(res.data.statusCode === 200){
            setPageLoader(false);
            setRedirectTime(Date.now());
        }else if(res.data.statusCode === 401){
            setPageLoader(false);
            setExpiredStatus(true);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
        }
    }

    const loginRedirectTimeOut = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            if(params.type === 'customer'){
                navigate('/customerlogin');
                dispatch(setCustomerUserData(null))
            }else{
                dispatch(setStylistUserData(null))
                navigate('/stylistlogin');
            }
        } else {
            return (
              <span>
                {seconds}
              </span>
            );
        }
    };

    const selfNavigate = () => {
        if(params.type === 'customer'){
            navigate('/customerlogin');
            dispatch(setCustomerUserData(null))
        }else{
            dispatch(setStylistUserData(null))
            navigate('/stylistlogin');
        }
    }

    return(
        <section className="emailTip">
            <Container>
                <Row>
                    <Col>
                        <div className="text-center">
                            <img src={logo} />
                        </div>
                    </Col>
                </Row>

                {
                    pageLoader ? 
                        <div style={{textAlign:'center', marginTop : '48px'}}>
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        </div>
                    : 
                        expiredStatus ? 
                            <div style={{textAlign:'center', marginTop : '16px'}}>
                                <img src={linkExpired} className='img-responsive' />
                            </div>
                        :
                            <Row className='email-verification-status'>
                                <h4 style={{fontSize : '24px', color : '#D49D29', marginBottom : '24px'}}>Welcome to Unext</h4>
                                <p>Thanks for verifying your email.</p>
                                <p>In <Countdown date={redirectTime + 10000} renderer={loginRedirectTimeOut} /> seconds, you will be automatically redirected to the login page. Alternatively, you can <span onClick={() => selfNavigate()} style={{textDecoration : 'underline', color : '#0d6efd', cursor : 'pointer'}}>click here</span> to proceed immediately.</p>
                            </Row>
                }
            </Container>
        </section>
    )
}