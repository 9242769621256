import { React, useState, useEffect, useCallback } from "react";
import { Scheduler, Editing } from 'devextreme-react/scheduler';
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar';
import { customerBookingList } from "../../../Service";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { setBookingDetails, setNewBookingCustomerDetails } from "../../../reducers/User/action";
import moments from 'moment-timezone';

export default function CustomerBooking() {
    //initial state declaration
    const [currentDate, setCurrentDate] = useState(new Date());
    const [appoinments , setAppoinments] = useState([]);
    const navigate = useNavigate();

    const views = ['month'];

    // get customer data from store
    const dispatch = useDispatch();
    const userData =  useSelector(store => store.user['stylistuser']);

    const newDate = moment(new Date()).format('YYYY-MM-DD');
    const listParams = {"clientId": userData ? userData.clientId : '', "filterDate": newDate, "filterType": "month"};

    useEffect(()=>{
        dispatch(setBookingDetails(null));
        dispatch(setNewBookingCustomerDetails(null));
        customerBookingList(listParams).then(res => initialBookingResponse(res));
    },[]);

    const initialBookingResponse = (response) =>{
        if(response.data.statusCode === 200) {
            let initialBookedData  = response.data.customerBookingList;
            let initialmonthBookedData  = [];
            initialBookedData.map((list)=>{
                if(list.status !== "Cancelled"){
                    if(list.bookingType != 'CLSB'){
                        let listOpenTime = list.startDateTime;
                        let listEndTime = list.endDateTime;
                        let singleBooedData = { "startDate": listOpenTime, "endDate": listEndTime, "text": list.customerName, "bookingId": list.bookingId, "clientId": list.clientId, "clientName": list.clientName, "createdOnUtc": list.createdOnUtc, "customerId": list.customerId, "serviceName": list.serviceName, "payment": list.payment, "productId": list.productId, "serviceId": list.serviceId, "shopId": list.shopId, "shopName": list.shopName, "slotId": list.slotId, "status": list.status, "customerEmail": list.email, "customerPhone": list.phoneNumber, "paymentStatus": list.paymentStatus, "startDateTime": list.startDateTime, "endDateTime": list.endDateTime, "timezone" : list.timeZone, "serviceCost" : list.serviceCost, "platformFees" : list.platformFees, "tip" : list.tip, "bookingType" : list.bookingType, "isCustomerRespondent" : list.isCustomerRespondent, "paymentType" : list.paymentType };
                        initialmonthBookedData.push(singleBooedData);
                    }else if(list.bookingType == 'CLSB'){
                        let listOpenTime = list.startDateTime;
                        let listEndTime = list.endDateTime;
                        let singleBooedData = { "startDate": listOpenTime, "endDate": listEndTime, "text": 'Self Booking', "bookingId": list.bookingId, "clientId": list.clientId, "clientName": list.clientName, "createdOnUtc": list.createdOnUtc, "customerId": list.customerId, "serviceName": list.serviceName, "payment": list.payment, "productId": list.productId, "serviceId": list.serviceId, "shopId": list.shopId, "shopName": list.shopName, "slotId": list.slotId, "status": list.status, "customerEmail": list.email, "customerPhone": list.phoneNumber, "paymentStatus": list.paymentStatus, "startDateTime": list.startDateTime, "endDateTime": list.endDateTime, "timezone" : list.timeZone, "serviceCost" : list.serviceCost, "platformFees" : list.platformFees, "tip" : list.tip, "bookingType" : list.bookingType, "isCustomerRespondent" : list.isCustomerRespondent, "paymentType" : list.paymentType };
                        initialmonthBookedData.push(singleBooedData);
                    }
                }
            });
            setAppoinments(initialmonthBookedData);
        }
    }

    const onAppointmentFormOpening = useCallback((e) => {
        navigate('/stylist/bookingdetails');
        dispatch(setBookingDetails(e.appointmentData));
    }, []);

    const cellClick = (e) => {
    }

    const onOptionChanged = useCallback((e) => {
        if(e.name === 'currentDate') {
            onDateNavigate(e.value);
        }
    },[]);

    const onDateNavigate = (e)=>{
        let year = moment(e).format('YYYY');
        let month = moment(e).format('MM');
        let date  = '01';
        let changedDate = year+'-'+month+'-'+date;
        const listParams = {"clientId": userData ? userData.clientId : '', "filterDate": changedDate, "filterType": "month"};
        customerBookingList(listParams).then(res=> {
            initialBookingResponse(res);
        });
    };
    
    const addBookingHandleClick = () => {
        navigate('/stylist/addbooking')
    }

    const selfBookingHandleClick = () => {
        navigate('/stylist/selfbooking/');
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <h1>Booking Calendar</h1>
                                </Col>
                            </Row> 
                            <Row className="mb-3 managesearch">
                                <Col>
                                    <Stack direction="horizontal" className="align-items-end" gap={2}>
                                        <div className="add-btn ms-auto">
                                            <Button variant="success" onClick={addBookingHandleClick}>Booking For Customer</Button>  
                                        </div>
                                        <div className="search-status-btn">
                                            <Button variant="primary" onClick={selfBookingHandleClick}>Self Booking</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <div className='booking_calendar'>
                                <Scheduler
                                    dataSource={appoinments}
                                    currentDate={currentDate}
                                    views={views}
                                    defaultCurrentView="month"
                                    startDayHour={0}
                                    endDayHour={24}
                                    adaptivityEnabled={true}
                                    showAllDayPanel={false}
                                    onCellClick={cellClick}
                                    onAppointmentFormOpening={onAppointmentFormOpening}
                                    onOptionChanged={onOptionChanged}
                                >   
                                    <Editing allowAdding={false} allowDeleting={false} />
                                </Scheduler>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}      

