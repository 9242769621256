
import {Outlet} from 'react-router-dom';

const LoginLayout = ({ props }) => {
 
    return (
    <>

     <main style={{ padding: '1rem 0' }}>
      <Outlet />
    </main>

    </>)
  };

  export default LoginLayout;