import { React, useState, useRef } from 'react';
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { doCustomerforgotPassword } from '../../../Service';
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

export default function ForgotPasswordPopup( { currentEmail, popupStatus, getBack } ) {
    const recaptcha = useRef();

    const [submitLoader, setSubmitLoader] = useState(false);
    const [formErrors, setFormErrors] = useState('');

    const popupClose = () => {
        getBack();
        setSubmitLoader(false);
        recaptcha.current.reset();
        setFormErrors('');
    }

    const handleSubmit = ( e ) => {
        e.preventDefault();

        if ( recaptcha.current.getValue() === '' ) {
            setFormErrors('Captcha is required');
        } else {
            setSubmitLoader(true);
            let forgotPassPayload = {'email' : currentEmail, 'reCaptchaToken' : recaptcha.current.getValue()};
            doCustomerforgotPassword(forgotPassPayload).then(res => handleResponse(res));
        }

    }

    const handleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            popupClose();
        } else {
            recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setSubmitLoader(false);
        }
    }

    return(
        <Modal show={popupStatus} onHide={() => popupClose()} className="custom-modal forgotPassPopup">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email <span>*</span></Form.Label>
                        <Form.Control value={currentEmail} disabled />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formCaptchaLoad">
                        <Form.Label>Captcha <span>*</span></Form.Label>
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                        <p className="error">{formErrors}</p>
                    </Form.Group> 
                </Modal.Body>
                <Modal.Footer>
                    {
                        submitLoader === true ?
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <>
                                <Button variant="primary" type='submit'>Submit</Button>
                                <Button variant="outline-secondary" onClick={popupClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}