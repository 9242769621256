import {React, useState} from 'react';
import {Button, Col, Modal, Row, Form } from "react-bootstrap"; 
import { InfinitySpin } from 'react-loader-spinner';
import { postCustomerBookingApproveOrReject } from '../../../../../Service';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import moment from 'moment';
import { JsonEncode } from '../../../../Reusable/JsonModify';

export default function AcceptRejectPupup(props) {
    // initial state declaration
    const [show, setShow] = useState(true);
    const [loader, setLoader] = useState(false);

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
    const bookingData = props.data;
    
    // popup close function
    const handleClose = () => {
        setShow(false);
        props.getBack(false);
        setLoader(false);
    };

    // accept button submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        let acceptParams = {'bookingId' : bookingData.bookingId, 'customerAccepts' : true, 'customerRejects' : false, 'serviceId' : bookingData.serviceid, 'serviceName' : bookingData.serviceName, 'payment' : bookingData.payment, 'serviceCost' : bookingData.serviceCost, 'platformFees' : bookingData.platformFees, 'tip' : bookingData.tip, 'clientId' : bookingData.clientId, 'customerId' : bookingData.customerId};

        let jsonData = await JsonEncode(acceptParams).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });
        
        if ( jsonData ) {
            let loginPayload = {data : jsonData};
            postCustomerBookingApproveOrReject(loginPayload).then(res => tipHandleResponse(res));
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: 'Something went wrong, Please try after sometimes',
                timer: 3000
            }); 
        }
        
    }

    // accept submit response
    const tipHandleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            props.getBack(true);
          }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    // reject handle click
    const rejectHandleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Really reject this booking?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Reject'
          }).then(async (result) => {
              if (result.isConfirmed) {
                let acceptParams = {'bookingId' : bookingData.bookingId, 'customerAccepts' : false, 'customerRejects' : true, 'serviceId' : bookingData.serviceid, 'serviceName' : bookingData.serviceName, 'payment' : bookingData.payment, 'serviceCost' : bookingData.serviceCost, 'platformFees' : bookingData.platformFees, 'tip' : bookingData.tip, 'clientId' : bookingData.clientId, 'customerId' : bookingData.customerId};

                let jsonData = await JsonEncode(acceptParams).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerBookingApproveOrReject(loginPayload).then(res => tipHandleResponse(res));
                    setLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
              }
          });
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }
    
    return(
        <>
            {/* Add profession HTML popup */}
            <Modal show={show} onHide={handleClose} className="custom-modal addtippopup" >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Accept / Reject Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>   
                        <Row>
                            <Col>
                                <p style={{color : '#fff', lineHeight : '28px'}}>Dear {userData.firstName+' '+userData.lastName}, Professional({bookingData.clientName}) has booked / Update a slot for you. The booking details given below, Please check and confirm.</p>
                            </Col>
                        </Row>
                        <Row>
                            <h4 style={{textAlign:'left'}}>Booking details</h4>
                            <Col md={6}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Service Name</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={ bookingData ? bookingData.serviceName : ''} disabled/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Payment</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={ bookingData ? '$'+ getFullCost(bookingData.payment) : ''} disabled/>
                                </Form.Group>
                            </Col>
                        </Row>  
                        <Row>
                            <Col md={6}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Appoinment Date</Form.Label>
                                    <Form.Control type="text" placeholder="" value={ bookingData ? moment(bookingData.appoinmentSlotDate).format('MM-DD-YYYY') : '' } disabled/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Appoinment Time</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={ bookingData ? bookingData.startTime +' - '+ bookingData.endTime : ''} disabled/>
                                </Form.Group>
                            </Col>
                        </Row>    
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader === true ? 
                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                            :   
                                <>
                                    <Button variant="primary" type="submit">Accept</Button>
                                    <Button variant="outline-secondary" onClick={rejectHandleSubmit}>Reject </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}