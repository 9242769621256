import { React, useState, useEffect } from 'react';
import { Col, Container, Row, Table, Button, Form, Popover, Card, Stack, OverlayTrigger } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { customerMyBookingList, patchCancelAuthorizeBookingSlot, postCustomerAccountDetails } from '../../../../Service';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import CustomerHeader from '../../Assets/CustomerHeader';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { setBookingHistoryDetails } from "../../../../reducers/User/action";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import StorefrontIcon from '@mui/icons-material/Storefront';
import moments from 'moment-timezone';
import AddTipPopup from './Popup/AddTipPopup';
import AcceptRejectPopup from './Popup/AcceptRejectPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilter, faFilterCircleXmark, faCircleExclamation, faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import PaginationDesign from '../../Assets/Pagination';
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode, JsonDecode } from '../../../Reusable/JsonModify';

export default function CustomerHistory() {
    var abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
        AKST : 'Alaskan Standard Time',
        HST : 'Hawaiian Standard Time'
    };

    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };

    // initial state declarations
    const [myBookingList, setMyBookingList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [refreshCall, setRefreshCall] = useState(false);
    const [cancelDisable, setCancelDisable] = useState(false);
    const [tipPageData, setTipPageData] = useState([]);
    const [tipPageStatus, setTipPageStatus] = useState(false);
    const searchInitialValues = {searchByShopName : '', searchByProfessional : '', searchByStartDate : '', searchByEndDate : ''};
    const [searchFormValues, setSearchFormValues] = useState(searchInitialValues);
    const [searchStatus, setSearchStatus] = useState(false);
    const [searchErrors ,setSearchErrors] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listCount, setListCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState('id');
    const [sortDir, setSortDir] = useState('desc');
    const [searchBTNStatus, setSearchBTNStatus] = useState(false);
    const [clearbtnDisable, setClearbtnDisable] = useState(true);
    const [cardList, setCardList] = useState([]);
    const [acceptPageData, setAcceptPageData] = useState([]);
    const [acceptPageStatus, setAcceptPageStatus] = useState(false);
    const [searchStartDate, setSearchStartDate] = useState('');
    const [searchEndDate, setSearchEndDate] = useState('');

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // get service list data by api
    useEffect(() => {
        if (userData) {
            setLoader(true);
            const whereCustomer = {'customerId' : userData.id, 'professionalName' : searchFormValues.searchByProfessional, 'shopName' : searchFormValues.searchByShopName, 'startDate' : searchFormValues.searchByStartDate, 'endDate' : searchFormValues.searchByEndDate, 'pageNo' : currentPage, 'pageSize' : perPage, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
            customerMyBookingList(whereCustomer).then(res => initialMyBookingList(res));
        }
    },[refreshCall, currentPage, sortBy, sortDir, perPage]);

    // get card list data by api
    useEffect(() => {
        (async () => {
            if (userData) {
                let whereUser = {'customer_Id' : userData.id};
    
                let jsonData = await JsonEncode(whereUser).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerAccountDetails(loginPayload).then(res => cardHandleResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })();
    },[refreshCall]);

    const cardHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });
        if ( decryptData.statusCode === 200 ) {
            setCardList(decryptData.data)
        }
    }

    const initialMyBookingList = (response) => {
        if(response.data.statusCode === 200){
            setMyBookingList(response.data.customerBookingList);
            setLoader(false);
            setListCount(response.data.filteredCount);
            setSearchBTNStatus(false);
        }else{
            setLoader(false);
        }
    }

    //get converted time to local time
    function getLocalTime(time, timezones) {
        if(time && timezones){
            let timeSplit = time.split(' ');
            var d = new Date();
            if(timeSplit[1] == 'AM'){
                let newSplit = timeSplit[0].split(':');
                d.setHours(Number(newSplit[0]));
                d.setMinutes(newSplit[1]);
            }else{
                let newSplit = timeSplit[0].split(':');
                d.setHours(Number(newSplit[0]) + 12);
                d.setMinutes(newSplit[1]);
            }
            var utc = d;
            let getTimeFromAMPM = moment(utc).format('HH:mm');

            let zonesplit = timezones.split(' ');
            const getZoneCode = moments.tz(zonesplit[0]).format('Z');
            let getLocal = moment().format('YYYY-MM-DD')+' '+getTimeFromAMPM+getZoneCode;
            const fullLocalTime = new Date(getLocal);
            
            return moment(fullLocalTime).format('hh:mm A');
        }else{
            return '';
        }
    }

    //cancel booking functionality start here
    const handleCancelClick = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to cancel this booking?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, cancel it!'
        }).then( async (result) => {
            if (result.isConfirmed) {
                var id = data.id;
                const zoneSplit = data.timeZone.split(' ');
                let zone = '';
                if(zoneSplit[0] == 'US/Arizona'){
                    zone = 'US Mountain Standard Time';
                }else{
                    zone = moments.tz([2012, 0], zoneSplit[0]).format('zz');
                }
                var cancelData = {"customerId" : userData.id, "timeZone" : zone, "bookingId" : data.bookingId, "status" : "Cancelled", "startDateTime" : data.appoinmentSlotDate, "amount" : data.payment, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

                let jsonData = await JsonEncode(cancelData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    patchCancelAuthorizeBookingSlot(loginPayload).then(res => handleCancelResponse(res));
                    setCancelDisable(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        })
    }

    const handleCancelResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setRefreshCall(prevRefreshCall => !prevRefreshCall);
            setCancelDisable(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setCancelDisable(false);
        }
    }

    const getUTCtoLocal = (onlyTime) => {
        var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

        return local_date;
    }
  
    const handleViewClick = (data) => {
        dispatch(setBookingHistoryDetails(data));
        navigate('/customer/bookinghistorydetails');
    }

    const handleTipClick = (data) => {
        setTipPageStatus(true);
        setTipPageData(data);
    }

    const getDataFromPopup = (data) => {
        if(data === false){
            setTipPageStatus(false);
            setTipPageData([]);
        }else if(data === true){
            setTipPageStatus(false);
            setTipPageData([]);
            setRefreshCall(prevRefreshCall => !prevRefreshCall);
        }
    }

    // search functionalities start here
    const searchOnClick = () => {
        setSearchStatus(true);
    }

    const clearSearchOnClick = () => {
        setSearchStatus(false);
        setSearchFormValues(searchInitialValues);
        setSearchErrors('');
        setCurrentPage(1);
        setListCount(0);
        setPerPage(10);
        setSortBy('id');
        setSortDir('desc');
        setLoader(true);
        setClearbtnDisable(true);
        setSearchStartDate('');
        setSearchEndDate('');

        const whereCustomer = {'customerId' : userData.id, 'professionalName' : '', 'shopName' : '', 'startDate' : '', 'endDate' : '', 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : 'id', 'sortDirection' : 'desc'};
        customerMyBookingList(whereCustomer).then(res => initialMyBookingList(res));
    }

    const searchHandleChange = (e) => {
        const value = e.target.value;
        const field= e.target.name;
        setSearchFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const searchHandleSearch = (e) => {
        e.preventDefault();
        const validate = searchValidate(searchFormValues);
        setSearchErrors(validate.search);
        if(validate.status){
            setCurrentPage(1);
            setPerPage(10);
            setLoader(true);
            setSearchBTNStatus(true);
            setClearbtnDisable(false);
            const whereCustomer = {'customerId' : userData.id, 'professionalName' : searchFormValues.searchByProfessional, 'shopName' : searchFormValues.searchByShopName, 'startDate' : searchFormValues.searchByStartDate, 'endDate' : searchFormValues.searchByEndDate, 'pageNo' : 1, 'pageSize' : 10, 'sortColumn' : sortBy, 'sortDirection' : sortDir};
            customerMyBookingList(whereCustomer).then(res => initialMyBookingList(res));
        }
    }
  
  const searchValidate = (values) => {
      const errors = {};
      errors.status = true;
      let formatStartDate = searchStartDate === '' || searchStartDate === null ? '' : moment(searchStartDate).format('YYYY-MM-DD');
      let formatEndDate = searchEndDate === '' || searchEndDate === null ? '' : moment(searchEndDate).format('YYYY-MM-DD');

      if((values.searchByShopName == '' || values.searchByShopName.trim() === '') && (values.searchByProfessional == '' || values.searchByProfessional.trim() === '') && (values.searchByStartDate == '' || values.searchByStartDate.trim() === '') && (values.searchByEndDate == '' || values.searchByEndDate.trim() === '')){
          errors.search = 'Provide any search term';
          errors.status = false;
      }

      if(values.searchByStartDate || values.searchByEndDate){
        if(values.searchByStartDate == '' || values.searchByEndDate == ''){
            errors.search = 'Appointment start date and end date are required';
            errors.status = false;
        }else if(values.searchByStartDate && values.searchByEndDate){
            let endDate = moment(values.searchByEndDate).format('YYYY-MM-DD');
            let StartDate = moment(values.searchByStartDate).format('YYYY-MM-DD');

            if(StartDate > endDate){
                errors.search = 'Appointment end date cannot be less than appointment start date';
                errors.status = false;
            }
        }
      }

        const validations = {
            searchByShopName: HtmlTagValidation(values.searchByShopName),
            searchByProfessional: HtmlTagValidation(values.searchByProfessional)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors.search = 'Invalid input';
                errors.status = false;
            }
        });

      return errors;
  }

    const getBackCurrentPage = (page, perPage) => {
        setPerPage(perPage);
        setCurrentPage(page);
    }

    const sortingOnClick = (data) => {
        setSortBy(data.by);
        setSortDir(data.dir);
    }

    const handleAcceptClick = (data) => {
        setAcceptPageStatus(true);
        setAcceptPageData(data);
    }

    const getDataFromAcceptPopup = (data) => {
        if(data === false){
            setAcceptPageStatus(false);
            setAcceptPageData([]);
        }else if(data === true){
            setAcceptPageStatus(false);
            setAcceptPageData([]);
            setRefreshCall(prevRefreshCall => !prevRefreshCall);
        }
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <div>
            <CustomerHeader />
            <section className='history-bg'>
                <div className='history-section'>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md='12'>
                                <h1>Appointments</h1>
                                <Row className='home-search-show'>
                                    {
                                        searchStatus == false ?
                                            <Button variant="primary" className='history-search-btn' onClick={searchOnClick}>New Filter <FontAwesomeIcon icon={faFilter} /></Button>
                                        :
                                            ''
                                    }
                                </Row>
                                {
                                    searchStatus === true ?
                                        <Row className='home-search-mobile'> 
                                            <Form onSubmit={searchHandleSearch}>
                                                <Row className='searchRow custom-home-search'>
                                                    <Col xl={8}>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Shop Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByShopName' placeholder='Shop name...' value={searchFormValues.searchByShopName} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Professional Name</Form.Label>
                                                                            <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfessional' placeholder='Professional name...' value={searchFormValues.searchByProfessional} onChange={searchHandleChange}/>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>Start Date</Form.Label>
                                                                            <Form.Control type="date" className='history-search-date' name='searchByStartDate' onChange={searchHandleChange} value={searchFormValues.searchByStartDate}/>
                                                                            {/* <DatePicker selectsStart selected={searchStartDate} startDate={searchStartDate} endDate={searchEndDate} onChange={(date) => setSearchStartDate(date)} placeholderText="MM/DD/YYYY"/> */}
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Form.Group as={Col} controlId="formGridSearchName">
                                                                            <Form.Label>End Date</Form.Label>
                                                                            <Form.Control type="date" className='history-search-date' name='searchByEndDate' onChange={searchHandleChange} value={searchFormValues.searchByEndDate}/>
                                                                            {/* <DatePicker selectsEnd selected={searchEndDate} minDate={searchStartDate} endDate={searchEndDate} onChange={(date) => setSearchEndDate(date)}  placeholderText="MM/DD/YYYY"/> */}
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={4} className="searcchAllBtn">
                                                        <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                        <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter<FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                                    </Col>
                                                    <span className='error'>{searchErrors}</span>
                                                </Row>
                                            </Form>
                                        </Row>
                                    : 
                                        ''
                                }
                                <Row className='home-search-desktop'>
                                    <Form onSubmit={searchHandleSearch}>
                                        <Row className='searchRow custom-home-search'>
                                            <Col xl={8}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Shop Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByShopName' placeholder='Shop name...' value={searchFormValues.searchByShopName} onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Professional Name</Form.Label>
                                                                    <Form.Control onKeyDown={BlockSQLInjection} type="text" name='searchByProfessional' placeholder='Professional name...' value={searchFormValues.searchByProfessional} onChange={searchHandleChange}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>Start Date</Form.Label>
                                                                    <Form.Control type="date" className='history-search-date' name='searchByStartDate' onChange={searchHandleChange} value={searchFormValues.searchByStartDate}/>
                                                                    {/* <DatePicker selectsStart selected={searchStartDate} startDate={searchStartDate} endDate={searchEndDate} onChange={(date) => setSearchStartDate(date)} placeholderText="MM/DD/YYYY"/> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label>End Date</Form.Label>
                                                                    <Form.Control type="date" className='history-search-date' name='searchByEndDate' onChange={searchHandleChange} value={searchFormValues.searchByEndDate}/>
                                                                    {/* <DatePicker selectsEnd selected={searchEndDate} minDate={searchStartDate} endDate={searchEndDate} onChange={(date) => setSearchEndDate(date)}  placeholderText="MM/DD/YYYY"/> */}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={4} className="searcchAllBtn">
                                                <Button variant="primary" type='submit' disabled={searchBTNStatus}>Search</Button>
                                                <Button variant="danger" disabled={clearbtnDisable} onClick={clearSearchOnClick} className='search-form-clear-btn'>Clear Filter<FontAwesomeIcon icon={faFilterCircleXmark} /></Button>
                                            </Col>
                                            <span className='error'>{searchErrors}</span>
                                        </Row>
                                    </Form>
                                </Row>
                                <div className="table-responsive bookHistory-dk">
                                    <Table striped bordered hover variant="dark">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Appointment<br></br> Date</th>
                                                <th>Slot Time</th>
                                                <th>Business<br></br> Name</th>
                                                <th>Professional<br></br> Name</th>
                                                <th>Service</th>
                                                <th>Booked Date</th>
                                                <th>Payment</th>
                                                <th>Payment Type</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loader === true ?
                                                    <tr>
                                                        <td colSpan='11' style={{textAlign : 'center'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></td>
                                                    </tr>
                                                :
                                                    myBookingList.length > 0 ?
                                                        myBookingList.map((data, index) => {
                                                            let zoone = data.timeZone.split(' ');
                                                            let currentDateTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));
                                                            let slotStattingDateTime = new Date(moment(data.appoinmentSlotDate));
                                                            return(
                                                                <tr key={index}>
                                                                    <td className='table-row-number'>{index + 1 + (currentPage * perPage) - perPage}</td>
                                                                    <td>{moment(data.appoinmentSlotDate).format('MM-DD-YYYY')}</td>
                                                                    <td>
                                                                        {data.startTime}<br></br>{data.endTime}
                                                                        <sup className='payment-icon'>
                                                                            <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                              overlay={
                                                                                  <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                    <p className='time-timezone'>{zoone[0]}</p>
                                                                                  </Popover>
                                                                              }
                                                                            >
                                                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                                                            </OverlayTrigger>
                                                                        </sup>
                                                                    </td>
                                                                    <td>{data.shopName}</td>
                                                                    <td>{data.clientName}</td>
                                                                    <td className='serviceNameTableData'>{data.serviceName}</td>
                                                                    <td>{moment(getUTCtoLocal(data.createdOnUtc)).format('MM-DD-YYYY')}</td>
                                                                    <td>
                                                                        ${getFullCost(data.payment)}
                                                                        <sup className='payment-icon'>
                                                                            <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                              overlay={
                                                                                  <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                    <div>
                                                                                        <h5>Service : ${getFullCost(data.serviceCost)} </h5>  
                                                                                        <h5>Tip : ${getFullCost(data.tip)}</h5>  
                                                                                        <h5>Platform Fees : ${getFullCost(data.platformFees)}</h5>  
                                                                                        <p><strong>Total : </strong>${getFullCost(data.payment)}</p>
                                                                                    </div>
                                                                                  </Popover>
                                                                              }
                                                                            >
                                                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                                                            </OverlayTrigger>
                                                                        </sup>
                                                                    </td>
                                                                    <td>{data.paymentType}</td>
                                                                    <td>{data.status === 'Booked' ? (data.bookingType === 'CL' || data.bookingType === 'CLEB') && data.isCustomerRespondent === false ? 'Verification Pending' : data.status : data.status}</td>
                                                                    <td>
                                                                        {
                                                                            (data.status === 'Booked' && data.bookingType == 'CS') || (data.status === 'Booked' && (data.bookingType == 'CL' || data.bookingType == 'CLEB') && data.isCustomerRespondent == true) ?
                                                                                currentDateTime < slotStattingDateTime ?
                                                                                    <Button variant="danger" onClick={() => handleCancelClick(data)} disabled={cancelDisable}>Cancel</Button>
                                                                                : 
                                                                                    '-'
                                                                            :
                                                                                data.status === 'Booked' && (data.bookingType == 'CL' || data.bookingType == 'CLEB') && data.isCustomerRespondent == false ?  
                                                                                    <Button variant="success" onClick={() => handleAcceptClick(data)} disabled={cancelDisable}>Accept/Reject</Button>
                                                                                :
                                                                                    data.status === 'Completed' && data.isTipNotInterested == 0 && data.tip == 0 ?
                                                                                        <Button variant="danger" className='addTipBtn' onClick={() => handleTipClick(data)} disabled={cancelDisable}>Add Tip</Button>
                                                                                    :
                                                                                        '-'
                                                                        }
                                                                    </td>
                                                                </tr>)
                                                            })
                                                    :
                                                        <tr>
                                                          <td colSpan='11'>No Data Found...</td>
                                                        </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='bookHistory-mb'>
                                    {
                                        loader === true ?
                                            <div>
                                                <div colSpan='10' style={{textAlign : 'center'}}><InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass /></div>
                                            </div>
                                        :
                                            <>    
                                              {
                                                    myBookingList.length > 0 ? 
                                                        myBookingList.map((data, index) => {
                                                            let zoone = data.timeZone.split(' ');
                                                            return(
                                                                <Card className='mb-2' key={index}>
                                                                    <Card.Body>
                                                                        <Card.Text>
                                                                            <Row className='stackCol'>
                                                                                <Col xs="12" >
                                                                                    <Stack gap={2} direction="horizontal" className='bookHistoryDate'>
                                                                                        <div><CalendarTodayIcon /></div>
                                                                                        <div className="">{moment(data.appoinmentSlotDate).format('MM-DD-YYYY')}</div>
                                                                                        {/* <div className="">{moment(data.appoinmentSlotDate).format('YYYY')}</div>
                                                                                        <div className="">{moment(data.appoinmentSlotDate).format('MMMM')}</div>
                                                                                        <div className="">{moment(data.appoinmentSlotDate).format('DD')}</div> */}
                                                                                        <div className='ms-auto'>
                                                                                            {
                                                                                                data.status === 'Completed' && data.isTipNotInterested == 0 && data.tip == 0 ?
                                                                                                    // <Button className='add-tip' onClick={()=>{handleViewClick(data)}}> <FontAwesomeIcon icon={faCirclePlus} /> Add Tip</Button>
                                                                                                    <Button className='add-tip' onClick={() => handleTipClick(data)} disabled={cancelDisable}>Add Tip</Button>
                                                                                                :
                                                                                                    <></>
                                                                                            }
                                                                                            
                                                                                            <Button onClick={()=>{handleViewClick(data)}}>View</Button>
                                                                                        </div>
                                                                                    </Stack>
                                                                                    <hr />
                                                                                </Col>
                                                                                <Col xs='12'>
                                                                                    <Stack direction="vertical" gap={1} className='bookHistoryName'>
                                                                                        <div className="detailContent"><DesignServicesIcon /> {data.serviceName}</div>
                                                                                        <div className="detailContent"><StorefrontIcon /> {data.shopName}</div>
                                                                                        <Stack direction="horizontal" className='stackbtn'>
                                                                                        <div className='detailContent' style={{display : 'block'}}><AccessTimeIcon /> 
                                                                                            {data.startTime+'-'+data.endTime}
                                                                                            <sup className='payment-icon'>
                                                                                                <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                                                overlay={
                                                                                                    <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                                        <p className='time-timezone'>{zoone[0]}</p>
                                                                                                    </Popover>
                                                                                                }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                                                                                </OverlayTrigger>
                                                                                            </sup>
                                                                                        </div>
                                                                                        <Button className={data.status === 'Booked' ? 'ms-auto bookstatus' : "ms-auto bookstatus statusCancel"}>{data.status}</Button>
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card.Text>
                                                                    </Card.Body>
                                                                </Card>
                                                            )
                                                        })
                                                    :
                                                        <p style={{color : '#fff', textAlign : 'center'}}>No Data Found...</p>
                                              }
                                            </>  
                                    }
                                </div>
                            </Col>
                        </Row>
                        {
                            acceptPageStatus === true ?
                                <AcceptRejectPopup data={acceptPageData} getBack={getDataFromAcceptPopup} />
                            :
                                ''
                        }
                        {
                            tipPageStatus === true ?
                                <AddTipPopup data={tipPageData} getBack={getDataFromPopup} card={cardList} />
                            :
                              ''
                        }
                        {
                            listCount > 0 ?
                                <PaginationDesign currentPage={currentPage} listCount={listCount} perPage={perPage} getBack={getBackCurrentPage}/>
                            : 
                                ''
                        }
                    </Container>
                </div>
            </section>
        </div>
    )
}       