import { React, useState } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { updateProfession } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function EditProfessionPopup(props){
    // state declaration
    let propsProfessionData = props.profesiionData;
    const updateInitialValues = {name:propsProfessionData.name, id :propsProfessionData.id};
    const [updateFormValues, setUpdateformValues] = useState(updateInitialValues);
    const [updateFormErrors, setUpdateFormErrors] = useState({}); 
    const [popupShow, setPopupShow] = useState(true);
    const [formSubmitLoader, setFormSubmitLoader] = useState(false);

    // popup close
    const popupHandleClose = () => {
        setPopupShow(false);
        setUpdateFormErrors({});
        setUpdateformValues(updateInitialValues);
        props.getBack(true, false);
    }

    // input field onchange
    const updateHandleChange = (e)=> {
        const value=e.target.value;
        const field=e.target.name;
        setUpdateformValues(prevState => ({
            ...prevState,
            [field]: value  }
        ));
    } 

    // edit profession form submit
    const updateHandleSubmit = (e) => {
        e.preventDefault();
        const errorCount = inputFieldsvalidate(updateFormValues);
        setUpdateFormErrors(errorCount);
        if(errorCount.status){
            updateProfession(updateFormValues).then(res => updatehandleResponse(res));
            setFormSubmitLoader(true);
        }
    } 

    // edit profession api response
    const updatehandleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Profession updated successfully',
                showConfirmButton: false,
                timer: 3000
            });
            setFormSubmitLoader(false);
            setPopupShow(false);
            setUpdateFormErrors({});
            setUpdateformValues(updateInitialValues);
            props.getBack(false, true);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: response.data.statusMessage,
                timer: 3000
            }); 
            setFormSubmitLoader(false);
        }
    }

    // input fields validation
    const inputFieldsvalidate = (values)=>{
        const errors = {};
        errors.status = true; 

        if(!values.name || values.name.trim() === ""){
            errors.name='Profession is required';
            errors.status=false;
        }

        let tagValidate = HtmlTagValidation(values.name);

        if(!tagValidate){
            errors.name='Invalid Input';
            errors.status=false;
        }

        return errors;
    }
    
    return(
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal " >
            <Form onSubmit={updateHandleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profession</Modal.Title>
                </Modal.Header>
                <Modal.Body>        
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Profession <span className="star">*</span></Form.Label>
                        <Form.Control onKeyDown={BlockSQLInjection} type="text" name="name" defaultValue={updateFormValues.name} onChange={updateHandleChange} />
                        <p className="error">{updateFormErrors.name}</p>
                    </Form.Group>       
                </Modal.Body>
                <Modal.Footer>
                    {
                        formSubmitLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <Button variant="primary" type="submit">Update</Button>
                    }
                    <Button variant="outline-secondary" onClick={popupHandleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}