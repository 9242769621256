import { React, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';

export default function ShowImagePopup({ imageData, getBack }) {
    const [popupShow, setPopupShow] = useState(true);

    const popupClose = () => {
        setPopupShow(false);
        getBack();
    }

    return(
        <Modal show={popupShow} onHide={popupClose} className="bookingModal">
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    imageData ?
                        <>
                            <Image variant="top" className='img-responsive' src={imageData.imageUrl}/>
                            <br></br><br></br>
                            <p style={{wordBreak : 'break-word'}}>{imageData.description}</p>
                        </>
                    :
                        ''
                } 
            </Modal.Body>
        </Modal>
    )
}