import { React, useEffect, useState, useRef } from "react";
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { InfinitySpin } from 'react-loader-spinner';
import '../../css/TipStyle.css';
import { useParams, useNavigate } from 'react-router-dom';
import logo from "../../images/logo.png";
import rightArror from "../../images/arrow-right.svg";
import { postCustomerDetails, doCustomerLogin } from '../../Service';
import Swal from "sweetalert2";
import { useDispatch} from 'react-redux';
import { setCustomerUserData, setCustomerToken } from '../../reducers/User/action';
import ReCAPTCHA from "react-google-recaptcha";
import { HtmlTagValidation, BlockSQLInjection } from '../Reusable';
import ForgotPasswordPopup from "../Reusable/Popup/ForgotPasswordPopup";
import { JsonEncode, JsonDecode } from '../Reusable/JsonModify';

export default function TipLogin() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const recaptcha = useRef();

    //initial state declaration
    const [passEye, setPassEye] = useState(false);
    const initialValues = {email: "", password: "", name : "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [forgotPasswordPopupStatus, setForgotPasswordPopupStatus] = useState(false);

    useEffect(() => {
        let userParams = {'customerId' : params.customerId};
        postCustomerDetails(userParams).then(res => handleInitalApiResponse(res));
    }, []);

    const handleInitalApiResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            setFormValues(prevState =>({
                ...prevState,
                'email' : res.data.customerDetails.email, 'name' : res.data.customerDetails.firstName +' '+ res.data.customerDetails.lastName 
            }));
        }

    }

    const confrimPassView = () => {
        setPassEye(prevState => !prevState);
    }

    // login form functionality start here
    const handleChange = ( e ) =>{
        const value=e.target.value;
        const field=e.target.name;
        setFormValues(prevState =>({
            ...prevState,
            [field]: value  
        }));
    }

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const errorMessage = Validate(formValues);
        setFormErrors(errorMessage);

        if ( errorMessage.status ) {
            let loginParams = {'email' : formValues.email, 'password' : formValues.password, 'reCaptchaToken' : recaptcha.current.getValue()};

            let jsonData = await JsonEncode(loginParams).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                doCustomerLogin(loginPayload).then(res => handleLoginResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }

    }

    const handleLoginResponse = async ( response ) => {
        let decryptData = await JsonDecode(response.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if ( decryptData.statusCode === 200 ) {
            dispatch(setCustomerToken(decryptData.jwtToken));
            const userData = {id : decryptData.customerId, email : decryptData.emailAddress, firstName : decryptData.firstName, lastName : decryptData.lastName, gender : decryptData.gender, phone : decryptData.phoneNumber};
            dispatch(setCustomerUserData(userData));
            setLoader(false);
            navigate('../../emailtip/'+params.customerId+'/'+params.bookingId);
        } else {
            recaptcha.current.reset();
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: decryptData.statusMessage,
                timer: 3000
            }); 
            setFormValues(formValues);
            setLoader(false);
        }  

    }

    const Validate = ( values ) => {
        const errors = {};
        errors.status = true;

        if ( formValues.email != '' ) {

            if ( !values.password ) {
                errors.password = 'Password is required';
                errors.status = false;
            }

            if ( recaptcha.current.getValue() === '' ) {
                errors.captcha = 'Captcha is required';
                errors.status = false;
            }

            let tagValidate = HtmlTagValidation(values.password);

            if ( !tagValidate ) {
                errors.password = 'Invalid Input';
                errors.status = false;
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Pomething went wrong, Please try after sometime',
                showConfirmButton: false,
                timer: 3000
            });
        }
        
        return errors;
    }

    const getBackFromForgotPassPopup = () => {
        setForgotPasswordPopupStatus(false);
    }

    return(
        <section className='login-bg tipLogin'>
            <Container>
                <Row className="justify-content-md-center ">
                    <Col md="auto">
                        <div className='text-center'>
                            <img src={logo}/>
                        </div>
                        <h1 className="text-center">Login</h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Label>Email <span>*</span></Form.Label>
                                <Form.Control type="email" name='email' defaultValue={formValues.email} disabled/>
                            </Form.Group>
                            <Form.Group className="mb-4 passwordRow" controlId="formBasicPassword">
                                <Form.Label>Password <span>*</span></Form.Label>
                                <FontAwesomeIcon icon={passEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                <Form.Control onKeyDown={BlockSQLInjection} type={passEye === false ? 'password' : 'text'} name='password' defaultValue='' onChange={handleChange}  />
                                <p className="error">{formErrors.password}{formErrors.invalid}</p>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formCaptchaLoad">
                                <Form.Label>Captcha <span>*</span></Form.Label>
                                <ReCAPTCHA size="normal" ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                <p className="error">{formErrors.captcha}</p>
                            </Form.Group>
                            <Stack gap={3} className="mx-autos">
                                <div style={{textAlign : 'right'}}>
                                    <p onClick={() => setForgotPasswordPopupStatus(true)} className="text-end forgot" style={{"text-decoration": "none", color : '#0d6efd', cursor : 'pointer', marginBottom : '0'}}>Forgot Password?</p>
                                </div>
                                {
                                    loader === true ? 
                                        <div style={{textAlign : 'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        <Button variant="secondary" type='submit' onClick={handleSubmit}>Login <img src={rightArror}/></Button>
                                }
                            </Stack>
                        </Form>
                    </Col>
                </Row>
            </Container>
            
            <ForgotPasswordPopup currentEmail={formValues.email} popupStatus={forgotPasswordPopupStatus} getBack={getBackFromForgotPassPopup} />

        </section>
    )
}