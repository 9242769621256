import React, {useState, useEffect} from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function GetPagination (props) {
    const [totalPage, setTotalPage] = useState([]);
    const [finalPage, setFinalPage] = useState([]);

    const listCount = props.listCount;
    const currentPage = props.currentPage;
    const perPage = props.perPage;

    const getPagination = () => {
        let getCount = listCount;
        let listPerPage = perPage;

        let totalPages = Math.ceil(getCount / listPerPage);
        let rows = [];
        for (let i = 1; i <= totalPages; i++) {
            rows.push(i);
        }
        
        setTotalPage(rows);

        if(rows.length > 6){
            let newPages = [];
            rows.map((data) => {
                if(currentPage <= 3 || currentPage >= rows.length - 2){
                    if(data <= 3 || data >= rows.length - 2){
                        newPages.push(data);
                    }else{
                        if(newPages[newPages.length - 1] != '...'){
                            newPages.push('...');
                        }
                        
                    }
                }else if(currentPage > 3 && currentPage < rows.length - 1){
                    if(data == 1 || data == 2 || data >= rows.length - 1){
                        newPages.push(data);
                    }else{
                        if(newPages[newPages.length - 1] != '...'){
                            newPages.push('...');
                        }
                    }

                    if(currentPage == data){
                        newPages.push(data);
                    }
                }
            })
            setFinalPage(newPages);
        }else{
            setFinalPage(rows);
        }
    }

    useEffect(() => {
        getPagination();
    }, [props.currentPage, props.listCount, props.perPage]);

    const prevHandleClick = () => {
        if(currentPage != 1){
            props.getBack(currentPage - 1, perPage);
        }
    }

    const paginationHandleClick = (data) => {
        props.getBack(data, perPage);
    }

    const nextHandleClick = () => {
        if(totalPage.length != currentPage){
            props.getBack(currentPage + 1, perPage);
        }
    }

    const perPageHandleChange = (e) => {
        props.getBack(1, e.target.value);
    }

    return(
        <Row>
            <Col>
                <div className='paginationWrapper'>
                    <ul className='PainateList'>
                        {
                            currentPage != 1 ?
                                <li className='pagination-btn previous' onClick={prevHandleClick}><FontAwesomeIcon icon={faArrowLeft} /><span>Previous</span></li>
                            :
                                <li className='pagination-btn previous cursor'><FontAwesomeIcon icon={faArrowLeft} /><span>Previous</span></li>
                        }
                        
                        {
                            totalPage.length > 0 ?
                                finalPage.map((data, index) => {
                                    if(data != '...'){
                                        return(
                                            <li className={currentPage == data ? 'pagination-btn count active' : 'pagination-btn count'} onClick={() => paginationHandleClick(data)}>{data}</li>
                                        )
                                    }else{
                                        return(
                                            <li className='pagination-btn count cursor'>{data}</li>
                                        )
                                    }
                                    
                                })
                            :
                                ''
                        }

                        {
                            totalPage.length != currentPage ? 
                                <li className='pagination-btn next' onClick={nextHandleClick}><span>Next</span><FontAwesomeIcon icon={faArrowRight} /></li>
                            :
                                <li className='pagination-btn next cursor'><span>Next</span><FontAwesomeIcon icon={faArrowRight} /></li>
                        }
                        
                    </ul>
                </div>
                {
                    listCount > 10 ?
                        <div className='paginationWrapper paginationPerPage'>
                            <div style={{display : 'inline-block', marginRight : '16px'}}>
                                <span style={{color : '#fff'}}>Per Page : </span>
                            </div>
                            <div style={{display : 'inline-block'}}>
                                <Form.Select type="text" name='firstName' onChange={perPageHandleChange}>
                                    <option value='10' selected={perPage == '10' ? 'selected' : ''}>10</option>
                                    {
                                        listCount > 10 ?
                                            <option value='20' selected={perPage == '20' ? 'selected' : ''}>20</option>
                                        :
                                            ''
                                    }
                                    {
                                        listCount > 50 ?
                                            <option value='50' selected={perPage == '50' ? 'selected' : ''}>50</option>
                                        :
                                            ''
                                    }
                                    {
                                        listCount > 100 ?
                                            <option value='100' selected={perPage == '100' ? 'selected' : ''}>100</option>
                                        :
                                            ''
                                    }
                                </Form.Select> 
                            </div>
                        </div>
                    :
                        ''
                }
            </Col>
        </Row>
    )
}