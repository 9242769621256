import {React, useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { postClientAcountCreate, patchUpdateClientAcount, clientAccountList, postPayPalAuthCode } from '../../../Service';
import { JsonEncode } from '../../Reusable/JsonModify';

export default function StepThree(){

    const navigate = useNavigate();

    const accountInitial = {accountType : 'PAYPAL', number : '', email : ''};
    const [accountData, setAccountData] = useState(accountInitial);
    const [accountError, setAccountError] = useState('');
    const [accountAddLoader, setAccountAddLoader] = useState(false);
    const [updateId, setUpdateId] = useState(0);
    const [pageLoader, setPageLoader] = useState(true);
    const [onlyPaypalVerify, setOnlyPaypalVerify] = useState(false);

    // get stylist data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // get bank account data 
    useEffect(() => {
        const accountParams = {'clientId' : userData ? userData.clientId : ''};
        clientAccountList(accountParams).then(res => bankAccountResponce(res));
    }, []); 

    const bankAccountResponce = (res) => {
        if(res.data.clientId != 0) {
            if(res.data.accountType == 'PAYPAL'){
                setAccountData({accountType : res.data.accountType, number : res.data.walletPhoneNumber, email : res.data.walletEmailId});
                setUpdateId(res.data.id);
                const getURLParams = new URLSearchParams(window.location.search);
                const getURLCode = getURLParams.get('code');
                if(getURLCode != null){
                    let requestPrams = {'clientId' : userData.clientId, 'authCode' : getURLCode};
                    postPayPalAuthCode(requestPrams).then(res => authCodeResponse(res));
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: "Please update your email address and verify with PayPal.",
                        showConfirmButton: false,
                        timer: 3000
                    });
                    setPageLoader(false);
                    setOnlyPaypalVerify(true);
                }
            }else{
                navigate('/stylist/dashboard');
                setPageLoader(false);
            }
        }else{
            setPageLoader(false);
        }
    }

    const authCodeResponse = (res) => {
        if(res.data.statusCode == 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Your PayPal account is verified successfully." ,
                showConfirmButton: false,
                timer: 3000
            });
            setTimeout(() => {
                navigate('/stylist/dashboard');
            }, 3000);
            setPageLoader(false);
        }else if(res.data.statusCode == 403){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'provided email address does not match with PayPal account. Please update your email address.',
                showConfirmButton: false,
                timer: 5000
            }); 
            setPageLoader(false);
        }else if(res.data.statusCode == 405){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'The PayPal account is not verified or else the email is not verified in your PayPal account',
                showConfirmButton: false,
                timer: 5000
            }); 
            setPageLoader(false);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setPageLoader(false);
        }
    }

    // phone number formater
    const formatPhoneNumber = (numberValue) => {
        if (!numberValue) return numberValue;
        const phoneNumber = numberValue.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        //if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 4) {
            return `(${phoneNumber.slice(0, 3)}`;
        }
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    // Payout Account details functionalities starts
    const accAddHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;

        setAccountData(prevState => ({
            ...prevState,
            [field]: value 
        }));

        if(field === 'number'){
        const numberValue = e.target.value;
        const formattedPhoneNumber = formatPhoneNumber(numberValue);
        setAccountData(prevState => ({
            ...prevState,
            [field]:  formattedPhoneNumber
        }));
        }
    }

    const accountHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = accountValidate(accountData);
        setAccountError(errorCount);
        if(errorCount.status){
            if(updateId == 0){
                let payoutData = {};
                if(accountData.accountType == 'PAYPAL'){
                    payoutData = {'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : '', 'walletEmail' : accountData.email};
                }else if(accountData.accountType == 'VENMO'){
                    payoutData = {'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : accountData.number, 'walletEmail' : ''};
                }

                let jsonData = await JsonEncode(payoutData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postClientAcountCreate(loginPayload).then(res => accountHanldeResponse(res))
                    setAccountAddLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }else{
                let payoutData = {};
                if(accountData.accountType == 'PAYPAL'){
                    payoutData = {'clientAccountId' : updateId, 'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : '', 'walletEmailId' : accountData.email};
                }else if(accountData.accountType == 'VENMO'){
                    payoutData = {'clientAccountId' : updateId, 'clientId' : userData.clientId, 'accountType' : accountData.accountType, 'walletPhoneNumber' : accountData.number, 'walletEmailId' : ''};
                }

                let jsonData = await JsonEncode(payoutData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    patchUpdateClientAcount(loginPayload).then(res => accountHanldeResponse(res))
                    setAccountAddLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
            
        }
    }

    const accountHanldeResponse = (res) => {
        if(res.data.statusCode === 200){
            if(accountData.accountType == 'PAYPAL'){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "You will be redirected to PayPal, Please verify using same email address entered here.",
                    showConfirmButton: false,
                    timer: 3000
                });
            }else if(accountData.accountType == 'VENMO'){
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: "Thanks for updating mandatory data's" ,
                    showConfirmButton: false,
                    timer: 3000
                });   
            }
            setAccountAddLoader(false);
            setTimeout(() => {
                if(accountData.accountType === 'PAYPAL'){
                    window.location.replace(process.env.REACT_APP_STEP_III_PAYPAL);
                }else{
                    navigate('/stylist/dashboard');
                }
                
            }, 3000);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setAccountAddLoader(false);
        }
    }

    const accountValidate = (values) => {
        const errors = {};
        errors.status = true;
        const emailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
        if(!values.accountType){
          errors.accountType = 'Account type is required';
          errors.status = false;
        }else{
            if(values.accountType == 'PAYPAL'){
                if(!values.email){
                    errors.email = 'Email is required';
                    errors.status = false;
                }else if (!emailCheck.test(values.email)) {
                    errors.email ="This is not a valid email format!";
                    errors.status = false;
                }
            }else if(values.accountType == 'VENMO'){
                if(!values.number){
                    errors.number = 'Phone number is required';
                    errors.status = false;
                }else if(values.number){
                    const numval = values.number.split('');
                    if(numval[1] === '0'){
                        errors.number = 'Enter valid phone number';
                        errors.status = false;
                    }else if(values.number && values.number.length !== 14){
                        errors.number = 'Phone number must have 10 digit';
                        errors.status = false;
                    } 
                }
            }
        }
    
        return errors
    }

    const paypalVerify = () => {
        setAccountAddLoader(true);
        window.location.replace(process.env.REACT_APP_STEP_III_PAYPAL);
    }

    return(
        <>
            <div>
                <Row className='CommonHeaderWrapper'>
                    <Col className="stylistCommonHeader">
                        <h1>Payout Account</h1>
                        <p>Step 3 / 3 : Add your payout account details</p>
                    </Col>
                </Row>
            </div>
            {
                pageLoader === true ?
                    <div style={{textAlign:'center'}}>
                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                    </div>
                :
                    <div className='booking-details availabilityII'>
                        <Form onSubmit={accountHandleSubmit} className='setting-sec'>
                            <Row>
                                <p className='payoutValid'>Note: <span>Please enter a valid PayPal account detail</span></p>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                        <Form.Label>Account Type <span>*</span></Form.Label>
                                        <Form.Select aria-label="Default select example" name='accountType' onChange={accAddHandleChange} disabled>
                                            <option value=''>Select account type</option>
                                            <option value='PAYPAL' selected='selected'>PAYPAL</option>
                                            <option value='VENMO' selected={accountData.accountType == 'VENMO' ? 'selected' : ''}>VENMO</option>
                                        </Form.Select>
                                        <p className="error">{accountError.accountType}</p>
                                    </Form.Group>
                                </Col>
                                {
                                    accountData.accountType ?
                                        accountData.accountType == 'PAYPAL' ?
                                            <Col md='6'>
                                                <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                    <Form.Label>PayPal Email <span>*</span></Form.Label>
                                                    <Form.Control type="email" name="email" placeholder='Enter here...' onChange={accAddHandleChange} value={accountData.email} />
                                                    <p className="error">{accountError.email}</p>
                                                </Form.Group> 
                                            </Col>
                                        :
                                        accountData.accountType == 'VENMO' ?
                                            <Col md='6'>
                                                <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                                    <Form.Label>Venmo Phone# <span>*</span></Form.Label>
                                                    <Form.Control type="tel" name="number" placeholder='Enter here...' onChange={accAddHandleChange} value={accountData.number} maxLength={14}/>
                                                    <p className="error">{accountError.number}</p>
                                                </Form.Group>
                                            </Col>
                                        :
                                            ''
                                    :
                                        ''
                                }
                            </Row>
                            <Row>
                                <div style={{textAlign : 'center'}}>
                                {
                                    accountAddLoader == true ? 
                                        <div style={{textAlign:'center'}}>
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        </div>
                                    :
                                        onlyPaypalVerify == true ?
                                            <Col style={{textAlign : 'center'}} className='stylistCommonBtn'>
                                                <Button variant="dark" className='upload-btn' onClick={paypalVerify}>Verify</Button>
                                            </Col>
                                        :
                                            <Col style={{textAlign : 'center'}} className='stylistCommonBtn'>
                                                <Button variant="dark" className='upload-btn' type='submit'>Verify</Button>
                                            </Col>
                                }
                                </div>
                            </Row>
                        </Form>
                    </div>
            }
        </>
    )
}