import { React, useState } from 'react';
import { Col, Container, Row, Stack, Button, Form } from 'react-bootstrap';
import Topbar from '../Assets/Topbar';  
import LeftSidebar from '../Assets/LeftSidebar';
import { useSelector } from 'react-redux';
import { addStylistPortfolio } from '../../../Service';
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from 'react-loader-spinner';
import { HtmlTagValidation, BlockSQLInjection } from '../../Reusable';

export default function AddPortfolio(){
    //initial state declaration
    const navigate = useNavigate();
    const initialValues = {description : '', image : ''};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState([]);
    const [imageName, setImageName] = useState('');
    const [imageBase64, setImageBase64] = useState('');
    const [loader, setLoader] = useState(false);
    const [extension, setextension] = useState('');

    // get stylist data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    // add portfolio functionality start here
    const handleChange = (e) => {
        if(e.target.type === 'file'){
            const value=e.target.files[0];
            const field=e.target.name;
            setFormValues(prevState => ({
                ...prevState,
                [field]:  value 
            }));

            setextension(e.target.files[0].name.split('.').pop()); //get image extention
            setImageName(e.target.files[0].name); //get image name

            var reader = new FileReader(); // get image base64
            reader.onload = function () {
              const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
              setImageBase64(base64String);
            }
            reader.readAsDataURL(value);
        }else{
            const value=e.target.value;
            const field=e.target.name;
            setFormValues(prevState => ({
                ...prevState,
                [field]:  value  
            }));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errorCount = validate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
            const payloadData = {'clientId' : userData.clientId, 'description' : formValues.description, 'imageName' : imageName, 'imageStr' : imageBase64};
            addStylistPortfolio(payloadData).then(res => handleRespose(res));
            setLoader(true);
        }
    }

    const handleRespose = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Portfolio added successfully...!',
              showConfirmButton: false,
              timer: 3000
            });
            setLoader(false);
            navigate(-1);
        }else{
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Oops...',
              showConfirmButton: false,
              text: response.data.statusMessage,
              timer: 3000
            }); 
            setLoader(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        errors.status = true;

        if(!values.description){
            errors.description = 'Image description is required';
            errors.status = false;
        }

        let tagValidate = HtmlTagValidation(values.description);

        if(!tagValidate){
            errors.description = 'Invalid input';
            errors.status = false;
        }

        if(!values.image){
            errors.image = 'Image is required';
            errors.status = false;
        } else if(values.image){
            if(imageName === '' || imageBase64 === ''){
                errors.image = 'Please reupload image.';
                errors.status = false;
            }

            let getLowarExt = extension.toLowerCase();

            if(getLowarExt != 'png' && getLowarExt != 'jpg' && getLowarExt != 'jpeg'){
                errors.image = 'Upload only .jpg, .jpeg, .png format image file.';
                errors.status = false;
            }
        }

        return errors;
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>    
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/> 
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div>
                                            <h1>Add Portfolio</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            <Row className="mb-3">
                                <Col className="add-profession">
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col md={6}>
                                    <div className='portfolio-upload'>
                                        <Form onSubmit={handleSubmit}>
                                            <Row className='mb-2'>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Description <span>*</span></Form.Label>
                                                        <Form.Control onKeyDown={BlockSQLInjection} type='text' maxlength="200" as="textarea" name='description' onChange={handleChange} defaultValue={formValues.description} placeholder="Maximum 200 characters"/>
                                                        <p className='error'>{formErrors.description}</p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className='mb-4'>
                                                <Col  md={12}>
                                                    <Form.Group controlId="formFile">
                                                        <Form.Label>Select File <span>*</span></Form.Label>
                                                        <Form.Control type="file" name='image' onChange={handleChange} accept=".jpg, .jpeg, .png"/>
                                                        <p className='error'>{formErrors.image}</p>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr className='mb-2' />
                                            <div className='text-center'>
                                                {
                                                    loader === true ?
                                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                    :
                                                        <Button variant="primary" type='submit' onClick={handleSubmit}>Upload</Button> 
                                                }
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 