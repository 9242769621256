import { React, useState, useEffect} from 'react';
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch} from 'react-redux';
import { postCustomerAccountDetails, postMailBookingList, postCustomerTipAdd } from '../../Service';
import logo from "../../images/logo.png";
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { InfinitySpin } from 'react-loader-spinner';
import { setCustomerLogout } from '../../reducers/User/action';
import linkExpired from '../../images/link-expired.jpg';
import Swal from "sweetalert2";
import { BlockInvalidNumber } from '../Reusable';
import { JsonEncode, JsonDecode } from '../Reusable/JsonModify';

export default function TipIndex() {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch=useDispatch();

    //initial state declaration
    const [cardList, setCardList] = useState([]);
    const [chooseCard, setChooseCard] = useState('');
    const [bookingData, setBookingData] = useState('');
    const [tipsPercent, setTipsPercent] = useState('');
    const [tipsAmount, setTipsAmount] = useState('');
    const [serviceCost, setServiceCost] = useState(0);
    const [finalErrors, setFinalErrors] = useState({});
    const [pageLoader, setPageLoader] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [afterSubmit, setAfterSubmit] = useState(false);
    const [notinterestStatus, setNotinterestStatus] = useState(false);

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
    
    useEffect(() => {
        (async () => {
            if(userData){
                if(userData.id == params.customerId){
                    let whereUser = {'customer_Id' : userData.id}

                    let jsonData = await JsonEncode(whereUser).then(result => {
                        return result
                    }).catch(error => {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Oops...',
                            showConfirmButton: false,
                            text: error,
                            timer: 3000
                        }); 
                    });
                    
                    if ( jsonData ) {
                        let loginPayload = {data : jsonData};
                        postCustomerAccountDetails(loginPayload).then(res => cardInitialResponse(res));
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Oops...',
                            showConfirmButton: false,
                            text: 'Something went wrong, Please try after sometimes',
                            timer: 3000
                        }); 
                    }
                    
    
                    const cardInitialResponse = async (res) => {
                        let decryptData = await JsonDecode(res.data.data).then(result => {
                            return result
                        }).catch(error => {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Oops...',
                                showConfirmButton: false,
                                text: error,
                                timer: 3000
                            }); 
                        });
                        setCardList(decryptData.data);
                        decryptData.data.map((data) => {
                            if(data.defaultCard === true){
                                setChooseCard(data.id);
                            }
                        })
                    }
    
                    let bookingParams = {'customerId' : params.customerId, 'bookingId' : params.bookingId};
                    postMailBookingList(bookingParams).then(res => bookingListResponse(res));
                }else{
                    navigate('../../tiplogin/'+params.customerId+'/'+params.bookingId);
                }
            }else{
                navigate('../../tiplogin/'+params.customerId+'/'+params.bookingId);
            }
        })();
    }, []);

    const bookingListResponse = (res) => {
            if(res.data.bookingList.bookingId != 0){
                if(res.data.bookingList.isTipNotInterested == 0 && res.data.bookingList.tip == 0 && res.data.bookingList.status == 'Completed'){
                    setBookingData(res.data.bookingList);
                    setServiceCost(res.data.bookingList.serviceCost);
                }else{
                    setBookingData('');
                    setServiceCost(0);
                }
                setPageLoader(false);
            }else{
                setPageLoader(false);
            }
    }

    const onCardChange = (e) => {
        setChooseCard(e.target.value);
    }

    const tipHandleChange = (value) => {
        let tipAmt = '';
        setTipsPercent(value);
        if(value == 'custom'){
            
        }else{
            let percetage = value;
            let serviceAmt = serviceCost;
            let getTip = (serviceAmt * percetage) / 100;
            tipAmt = Math.round(getTip * 100) / 100; 
        }

        setTipsAmount(tipAmt);
    }

    const setupTipAmount = (value) => {
        let roundValue = Math.round(value);
        let roundValueInString = roundValue.toString().length;
        if(roundValueInString < 5){
            let valueSplit = value.split('');
            let getValue = valueSplit.indexOf(".");
            if(getValue != -1){
                if(valueSplit.length <= getValue + 3){
                    let amtSplit = value.toString().split('');
                    let roundAmount = amtSplit.join('');
                    setTipsAmount(roundAmount);
                }
            }else{
                let amtSplit = value.toString().split('');           
                let roundAmount = amtSplit.join('');
                setTipsAmount(roundAmount);
            }
        }
    }

    const customTipHandleChange = (e) => {
        let value = e.target.value.replace(/-\d+/g, "");
        let splitValue = value.split('');
        // if(splitValue[0] == 0 || splitValue[0] == '.'){
        //     setTipsAmount('');
        // }else{
        //     let roundValue = Math.round(value);
        //     let roundValueInString = roundValue.toString().length;
        //     if(roundValueInString < 5){
        //         let valueSplit = value.split('');
        //         let getValue = valueSplit.indexOf(".");
        //         if(getValue != -1){
        //             if(valueSplit.length <= getValue + 3){
        //                 let floatTip = Math.round(value * 100) / 100;
        //                 let amtSplit = floatTip.toString().split('');
                        
        //                 if(amtSplit[0] == 0){
        //                     amtSplit.shift();
        //                 }            
        //                 let roundAmount = amtSplit.join('');
        //                 setTipsAmount(roundAmount);
        //             }
        //         }else{
        //             let floatTip = Math.round(value * 100) / 100;
        //             let amtSplit = floatTip.toString().split('');
                    
        //             if(amtSplit[0] == 0){
        //                 amtSplit.shift();
        //             }            
        //             let roundAmount = amtSplit.join('');
        //             setTipsAmount(roundAmount);
        //         }
        //     }
        // }

        if(splitValue[0] == '.' || splitValue.length === 0){
            setTipsAmount('');
        }else{
            if(splitValue[0] == 0){
                if(splitValue[1] == '.'){
                    setupTipAmount(value);
                }else{
                    setTipsAmount(0);
                }
            }else{
                setupTipAmount(value);
            }
        }
    }

    const tipAmountSubmit = async (e) => {
        e.preventDefault();
        const errorCount = finalValidate(chooseCard);
        setFinalErrors(errorCount);
        if(errorCount.status){
            let zoneSplit = bookingData.timeZone.split(' ');
            let payloadData = {'customerId' : userData.id, 'clientId' : bookingData.clientId, 'customerCardId' : Number(chooseCard), 'bookingId' : bookingData.bookingId, 'tipCost' : Number(tipsAmount), 'isTipNotInterested' : 0, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};
            
            let jsonData = await JsonEncode(payloadData).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCustomerTipAdd(loginPayload).then(res => tipHandleResponse(res));
                setButtonLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const tipHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thank you for sending tip to professional',
                showConfirmButton: false,
                timer: 3000
            });
            setButtonLoader(false);
            setAfterSubmit(true);
          }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: decryptData.statusMessage,
                timer: 3000
            }); 
            setButtonLoader(false);
        }
    }

    const finalValidate = (values) => {
        const errors = {};
        errors.status = true;
        let cardvalue = values.toString();
        if(cardList.length > 0){
            if(!cardvalue  || cardvalue.trim() === ''){
                errors.card = 'Please select a card.';
                errors.status = false;
            }
        }else{
            if(!cardvalue  || cardvalue.trim() === ''){
                errors.card = 'Please add new card.';
                errors.status = false;
            }
        }

        if (!tipsPercent) {
            errors.tip = 'Please choose a tip amount.';
            errors.status = false;
        }

        if(tipsPercent){
            if(tipsAmount == 0){
                errors.tip = 'Please enter the tip amount.';
                errors.status = false;
            }
        }
        return errors;
    }

    const notinterestHandleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Really not interested to pay Tip?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Not interested'
          }).then(async (result) => {
              if (result.isConfirmed) {
                let zoneSplit = bookingData.timeZone.split(' ');
                let notInterestParams = {'customerId' : userData.id, 'clientId' : bookingData.clientId, 'customerCardId' : 0, 'bookingId' : bookingData.bookingId, 'tipCost' : 0, 'isTipNotInterested' : 1, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};
                let jsonData = await JsonEncode(notInterestParams).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerTipAdd(loginPayload).then(res => noTipHandleResponse(res));
                    setButtonLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
              }
          });
    }

    const noTipHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Request submitted successfully.!',
                showConfirmButton: false,
                timer: 3000
            });
            setButtonLoader(false);
            setAfterSubmit(true);
            setNotinterestStatus(true);
          }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: decryptData.statusMessage,
                timer: 3000
            }); 
            setButtonLoader(false);
        }
    }

    const handleHome = () => {
        navigate('/');
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className="emailTip">
            <Container>
                <Row>
                    <Col>
                        <div className="text-center">
                            <img src={logo} />
                        </div>
                    </Col>
                </Row>
                {
                    afterSubmit === true ?
                        <div>
                            <p className='tipThanks'>Thanks for your tip. </p>
                            <p className='tipThanks'>We really excited to serve you again. <span onClick={handleHome} style={{cursor : 'pointer', color : '#8aaee2'}}>Click here</span> to visit Unext Homepage. </p>
                        </div>
                    :
                        <div className={bookingData != '' ? 'tipBlock' : ''}>
                        {
                            pageLoader === true ?
                                <div style={{textAlign:'center'}}>
                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                </div>
                            :
                                bookingData != '' ?
                                    <Row>
                                        <Col md={6}>
                                            <div className='tipLeftBlock'>
                                                <Row className="tipRow">
                                                    <Col md={12} className="">
                                                        <h2>Service Details</h2>
                                                    </Col>
                                                    <Col md={12}>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <th>Business Name</th>
                                                                <td>: {bookingData ? bookingData.shopName : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Stylist Name</th>
                                                                <td>: {bookingData ? bookingData.clientName : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Service</th>
                                                                <td>: {bookingData ? bookingData.serviceName : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Booking Date</th>
                                                                <td>: {bookingData ? moment(bookingData.appoinmentSlotDate).format('MM-DD-YYYY') : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Slot Time</th>
                                                                <td>: {bookingData ? bookingData.startTime +' - '+ bookingData.endTime : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Service Cost</th>
                                                                <td>: $ {serviceCost != 0 ? getFullCost(serviceCost) : ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Payable Tip Amount</th>
                                                                <td className='totalAmount'>: $ {tipsAmount == '' ? 0.00 : getFullCost(tipsAmount)}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <Form onSubmit={tipAmountSubmit}>
                                                <Row className="tipRow">
                                                    <Col md={12} className="">
                                                        <h2>Select a card</h2>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form onSubmit="">
                                                            <Row className='choose-card'>
                                                            {
                                                                            cardList.length > 0 ?
                                                                                cardList.map((data, index) => (
                                                                                    <div className="radio">
                                                                                        <label>
                                                                                            <input type="radio" value={data.id} checked={data.id == chooseCard ? 'checked' : ''} onChange={onCardChange} />
                                                                                            {data.accountNumber+ ' (' +data.accountType+')'}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            :
                                                                                <p className='no-card'>No cards available. Go to setting page and "+ Add New Card".</p>
                                                                        }
                                                                    <p className="error">{finalErrors.card}</p>
                                                            </Row>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                <Row className="tipRow bookAddTip">
                                                    <Col md={12} className="">
                                                        <h2>Select a tip amount</h2>
                                                    </Col>
                                                    <Col md={12}>
                                                    <div className='tipRadio'>
                                                        <input type="radio" className='tip' name="tipCheck" label={'15 %'} onChange={() => tipHandleChange(15)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'20 %'} onChange={() => tipHandleChange(20)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'25 %'} onChange={() => tipHandleChange(25)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'Custom'} onChange={() => tipHandleChange('custom')} />
                                                    </div>
                                                    {
                                                        tipsPercent == 'custom' ?
                                                            <div>
                                                                <Form.Group as={Col} controlId="formGridEmail" style={{marginTop : '16px'}}>
                                                                    <Form.Label>Enter custom tip amount (in $) <span>*</span></Form.Label>
                                                                    <Form.Control onKeyDown={BlockInvalidNumber} type="number" name='customAmount' value={tipsAmount} onChange={customTipHandleChange}/>
                                                                </Form.Group>
                                                            </div>
                                                        :
                                                        ''
                                                    }
                                                    <p className="error">{finalErrors.tip}</p>
                                                    </Col>
                                                </Row>
                                                <Row className='paymentSubmit'>
                                                    {
                                                        buttonLoader === true ? 
                                                            <Col>
                                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                            </Col>
                                                        :
                                                            <Col>
                                                                <Button variant='primary' type='submit' className='addbtn'>Pay</Button>
                                                                <Button variant="outline-secondary" onClick={notinterestHandleSubmit}>Not Interested </Button>
                                                            </Col>
                                                    }
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                :
                                    <div style={{textAlign:'center', marginTop : '16px'}}>
                                        <img src={linkExpired} className='img-responsive' />
                                    </div>
                        }
                        </div>

                }
                
            </Container>
        </section>
    )
}