import React, { useEffect } from 'react';
import Header from '../Components/Stylist/Assets/Header';
import Footer from '../Components/Stylist/Assets/Footer';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { setStylistLogoutUrl } from '../reducers/User/action';

const StylistLayout = ({ props }) => {
    const navigation = useNavigate();
    const params = useLocation();
    const dispatch = useDispatch();

    let urlPathName = params.pathname;
    const apiToken = useSelector(store => store.user['stylisttoken']);
    const stylistData = useSelector(store => store.user['stylistuser']);
    const stylistLogoutUrl = useSelector(store => store.user.stylistLogoutUrl);

    useEffect(() => {
        dispatch(setStylistLogoutUrl(null));
    }, []);

    useEffect(() => {
        if(!apiToken || !stylistData){
            dispatch(setStylistLogoutUrl(urlPathName));
            navigation('/stylistlogin');
        }
    },[apiToken]);

    return (
        <>
            <Header/>
                <main style={{ padding: '1rem 0' }}>
                    <Outlet />
                </main>
            <Footer/>
        </>
    )
};

  export default StylistLayout;