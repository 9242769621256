import {React, useState, useEffect} from 'react';
import {Button, Col, Modal, Row, Form } from "react-bootstrap"; 
import { InfinitySpin } from 'react-loader-spinner';
import { postCustomerTipAdd } from '../../../../../Service';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { BlockInvalidNumber } from '../../../../Reusable';
import { JsonEncode, JsonDecode } from '../../../../Reusable/JsonModify';
import moment from 'moment';

export default function AddTipPopup(props) {
    const [show, setShow] = useState(true);
    const [loader, setLoader] = useState(false);
    const [tipsPercent, setTipsPercent] = useState('');
    const [tipsAmount, setTipsAmount] = useState(0);
    const [chooseCard, setChooseCard] = useState('');
    const [formError, setFormErrors] = useState({});

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
    
    const cardList = props.card;
    const bookingData = props.data;
    const timeZone = bookingData.timeZone;
    const zoneSplit = timeZone.split(' ');

    useEffect(() => {
        cardList.map((data) => {
            if(data.defaultCard === true){
                setChooseCard(data.id);
            }
        })
    },[])
    
    const handleClose = () => {
        setShow(false);
        props.getBack(false);
        setFormErrors({});
        setChooseCard('');
        setTipsAmount(0);
        setTipsPercent('')
        setLoader(false);
    };

    const tipHandleChange = (value) => {
        let tipAmt = '';
        setTipsPercent(value);
        if(value == 'custom'){
            
        }else{
            let percetage = value;
            let serviceAmt = bookingData.serviceCost;
            let getTip = (serviceAmt * percetage) / 100;
            tipAmt = Math.round(getTip * 100) / 100; 
        }
        setTipsAmount(tipAmt);
        
    }

    const setupTipAmount = (value) => {
        let roundValue = Math.round(value);
        let roundValueInString = roundValue.toString().length;
        if(roundValueInString < 5){
            let valueSplit = value.split('');
            let getValue = valueSplit.indexOf(".");
            if(getValue != -1){
                if(valueSplit.length <= getValue + 3){
                    let amtSplit = value.toString().split('');
                    let roundAmount = amtSplit.join('');
                    setTipsAmount(roundAmount);
                }
            }else{
                let amtSplit = value.toString().split('');           
                let roundAmount = amtSplit.join('');
                setTipsAmount(roundAmount);
            }
        }
    }

    const customTipHandleChange = (e) => {
        let value = e.target.value.replace(/-\d+/g, "");
        let splitValue = value.split('');
        // if(splitValue[0] == 0 || splitValue[0] == '.'){
        //     setTipsAmount('');
        // }else{
        //     let roundValue = Math.round(value);
        //     let roundValueInString = roundValue.toString().length;
        //     if(roundValueInString < 5){
        //         let valueSplit = value.split('');
        //         let getValue = valueSplit.indexOf(".");
        //         if(getValue != -1){
        //             if(valueSplit.length <= getValue + 3){
        //                 let floatTip = Math.round(value * 100) / 100;
        //                 let amtSplit = floatTip.toString().split('');
                        
        //                 if(amtSplit[0] == 0){
        //                     amtSplit.shift();
        //                 }            
        //                 let roundAmount = amtSplit.join('');
        //                 setTipsAmount(roundAmount);
        //             }
        //         }else{
        //             let floatTip = Math.round(value * 100) / 100;
        //             let amtSplit = floatTip.toString().split('');
                    
        //             if(amtSplit[0] == 0){
        //                 amtSplit.shift();
        //             }            
        //             let roundAmount = amtSplit.join('');
        //             setTipsAmount(roundAmount);
        //         }
        //     }
        // }

        if(splitValue[0] == '.' || splitValue.length === 0){
            setTipsAmount('');
        }else{
            if(splitValue[0] == 0){
                if(splitValue[1] == '.'){
                    setupTipAmount(value);
                }else{
                    setTipsAmount(0);
                }
            }else{
                setupTipAmount(value);
            }
        }
    }

    const onCardChange = (e) => {
        setChooseCard(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = finalValidate();
        setFormErrors(errorCount);
        if(errorCount.status){
            let payloadData = {'customerId' : userData.id, 'clientId' : bookingData.clientId, 'customerCardId' : Number(chooseCard), 'bookingId' : bookingData.bookingId, 'tipCost' : Number(tipsAmount), 'isTipNotInterested' : 0, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

            let jsonData = await JsonEncode(payloadData).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCustomerTipAdd(loginPayload).then(res => tipHandleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }
    }

    const tipHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Thank you for sending tip to professional',
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            props.getBack(true);
          }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    const notinterestHandleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Really not interested to pay Tip?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Not interested'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let payloadData = {'customerId' : userData.id, 'clientId' : bookingData.clientId, 'customerCardId' : 0, 'bookingId' : bookingData.bookingId, 'tipCost' : 0, 'isTipNotInterested' : 1, settlementLocalDateTime : moment().tz(zoneSplit[0]).format('YYYY-MM-DD HH:mm:ss')};

                let jsonData = await JsonEncode(payloadData).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerTipAdd(loginPayload).then(res => noTipHandleResponse(res));
                    setLoader(true);
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
                
            }
        });
    }

    const noTipHandleResponse = async (res) => {
        let decryptData = await JsonDecode(res.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Request submitted successfully.!',
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            props.getBack(true);
          }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }
    }

    const finalValidate = () => {
        const errors = {};
        errors.status = true;

        let cardvalue = chooseCard.toString();
        if(!cardvalue  || cardvalue.trim() === ''){
            errors.card = 'Please select a card.';
            errors.status = false;
        }

        if(tipsPercent){
            if(tipsAmount == 0){
                errors.tip = 'Please add the tip amount';
                errors.status = false;
            }
        }else{
            errors.tip = 'Please select an option';
            errors.status = false;
        }

        return errors;
    }

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }
    
    return(
        <>
            {/* Add profession HTML popup */}
            <Modal show={show} onHide={handleClose} className="custom-modal addtippopup" >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Tip</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>   
                        <Row>
                            <Col md={4}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Service Name</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={ bookingData ? bookingData.serviceName : ''} disabled/>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Service Cost</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={ bookingData ? '$'+ getFullCost(bookingData.serviceCost) : ''} disabled/>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="md-3" controlId="formBasicEmail">
                                    <Form.Label className='mt-2'>Tip Amount (in $)</Form.Label>
                                    <Form.Control type="text" placeholder="" value={tipsAmount != '' ? getFullCost(tipsAmount) : 0.00} disabled/>
                                </Form.Group>
                            </Col>
                        </Row>    
                        <Row>
                            <h4 style={{textAlign:'left'}}>Select a tip amount</h4>
                            <div className='tipRadio'>
                                <input type="radio" className='tip' name="tipCheck" label={'15 %'} onChange={() => tipHandleChange(15)} />
                                <input type="radio" className='tip' name="tipCheck" label={'20 %'} onChange={() => tipHandleChange(20)} />
                                <input type="radio" className='tip' name="tipCheck" label={'25 %'} onChange={() => tipHandleChange(25)} />
                                <input type="radio" className='tip' name="tipCheck" label={'Custom'} onChange={() => tipHandleChange('custom')} />
                            </div>
                            {
                                tipsPercent == 'custom' ?
                                    <div>
                                        <Form.Group as={Col} controlId="formGridEmail" style={{marginTop : '16px'}}>
                                            <Form.Label>Enter custom tip amount (in $) <span>*</span></Form.Label>
                                            <Form.Control onKeyDown={BlockInvalidNumber} type="number" name='customAmount' value={tipsAmount} onChange={customTipHandleChange}/>
                                        </Form.Group>
                                    </div>
                                :
                                ''
                            }
                            <span className="error">{formError.tip}</span>
                        </Row>
                        <Row className='choose-card'>
                            <h4 style={{textAlign:'left'}}>Select a card </h4>
                            {
                                cardList.length > 0 ?
                                    cardList.map((data, index) => (
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value={data.id} checked={data.id == chooseCard ? 'checked' : ''} onChange={onCardChange} />
                                                {data.accountNumber+ ' (' +data.accountType+')'}
                                            </label>
                                        </div>
                                    ))
                                :
                                    <p className='no-card'>No cards available. Go to setting page and "+ Add New Card".</p>
                            }
                            <span className="error">{formError.card}</span>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            loader === true ? 
                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                            :   
                                <>
                                    <Button variant="primary" type="submit">Pay</Button>
                                    <Button variant="outline-secondary" onClick={notinterestHandleSubmit}>Not Interested </Button>
                                </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}