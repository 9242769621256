import { React, useState, useRef } from "react";
import { Container, Row, Col, Stack, Image, Card, Form, Button, Overlay} from "react-bootstrap";
import search from "../../../images/search.png";
import notify from '../../../images/notify.png';
import person from "../../../images/person.png";
import arrow from "../../../images/arrow.png";
import logo from '../../../images/logo1.png';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import { setStylistLogout, setStylistLogoutUrl } from '../../../reducers/User/action';
import Swal from "sweetalert2";

const Dashboard = () => {
    const params = useLocation();
    const navigate = useNavigate();
    const dispatch=useDispatch();

    let urlPathName = params.pathname;

    const doLogout = (popupState)=>{
        popupState.close();
        Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to Log Out?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log Out!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setStylistLogout());
                dispatch(setStylistLogoutUrl(urlPathName));
                navigate("/stylistlogin");
            }
        })  
    }
    let loggedUser = useSelector(store => store.user['stylistuser']);
    const [show, setShow] = useState(false);
    const target = useRef(null);  

    return(
        <div className="top_bar"> 
                <Row>
                    <Col>
                        <Stack direction="horizontal" className="align-items-end" gap={2}>
                            <div className="ms-auto">
                            {/* <Form className="d-flex" >
                            <Overlay target={target.current} show={show} placement="left">
                                <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2 seach-mob"
                                aria-label="Search"
                                style={{"width": "auto"}}
                                />
                               </Overlay>
                                <Button variant="outline" ref={target} onClick={() => setShow(!show)}><Image src={search} /></Button>
                            </Form> */}
                                
                            </div>
                            <div className="d-flex align-items-center">
                                
                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                        <div>
                                        {/* <Button variant="" {...bindTrigger(popupState)}>
                                            
                                            <Image src={notify}  />
                                             
                                        </Button>

                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}> 
                                            <h3>Notifications</h3>
                                            <hr/>
                                            <div className="notification-row">
                                                <div className="notification-img-row ">
                                                    <div className="circle"></div>
                                                    <div className="profile"><Image src={person} /></div>
                                                </div>
                                                <div className="content">
                                                <h5>Hair Cut</h5>
                                                   <p> Changed an issue from in this Hair Cut</p>
                                                </div>
                                            </div>
                                            <div className="notification-row">
                                                <div className="notification-img-row ">
                                                    <div className="circle"></div>
                                                    <div className="profile"><Image src={person} /></div>
                                                </div>
                                                <div className="content">
                                                <h5>Hair Cut</h5>
                                                   <p> Changed an issue from in this Hair Cut</p>
                                                </div>
                                            </div>
                                            <div className="notification-row">
                                                <div className="notification-img-row ">
                                                    <div className="circle"></div>
                                                    <div className="profile"><Image src={person} /></div>
                                                </div>
                                                <div className="content">
                                                <h5>Hair Cut</h5>
                                                   <p> Changed an issue from in this Hair Cut</p>
                                                </div>
                                            </div>
                                            <div className="view-all">
                                                <Link to={''}>View all Notifications</Link></div>
                                            
                                            </Typography>
                                        </Popover> */}
                                        </div>
                                    )}
                                </PopupState>
                                
                            </div>
                            <div className="d-flex align-items-center">
                                
                                <PopupState variant="popover" popupId="demo-popup-popover" >
                                    {(popupState) => (
                                        <div>
                                        <Button variant="" {...bindTrigger(popupState)}>
                                        <div className="pro-sec">
                                        <div className="top-profile"> <Image src={person} />
                                        <span className="active circle"></span>
                                        </div>
                                        </div>
                                        </Button>

                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}> 
                                            <Row className="profile-dropdown">
                                             <Col><Image src={person} /> 
                                              
                                             <h4>{loggedUser ? loggedUser.firstName+' '+loggedUser.lastName : ''}</h4>
                                             <h6>{loggedUser ? loggedUser.emailAddress : ''}</h6>
                                             </Col>
                                            </Row> 
                                           
                                            <hr/>
                                            <Row className="profile-link">
                                                <Col>
                                                <div className="text-center"><a onClick={() => doLogout(popupState)} style={{textAlign:'center'}}>Log Out</a></div>
                                               
                                                </Col>
                                            </Row>
                                            
                                            </Typography>
                                        </Popover>
                                        </div>
                                    )}
                                </PopupState>
                                 
                            </div>
                            
                        </Stack>
                    </Col>
                </Row>
 
        </div>
    )
}

export default Dashboard;