import { React, useState, useEffect, useCallback, useMemo } from 'react';
import { Scheduler, Editing } from 'devextreme-react/scheduler';
import Toolbar from 'devextreme/ui/toolbar';
import {Button, Col, Container, Modal, Row, Form } from "react-bootstrap"; 
import 'devextreme/dist/css/dx.light.css';
import { bookingSlotDayList, customerClientDetail, addBooking, customerBookingList, postCreateCustomerCreditCard } from '../../../../Service';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import moment from 'moment';
import { setBookingUrl, setCustomerBookingDetails, setBookingTimeout } from '../../../../reducers/User/action';
import CustomerHeader from '../../Assets/CustomerHeader';

export default function CustomerBookingCal() {
  // initial state declarations
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [ dateField, setDateField ] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const params = useParams();
  const [bookingList, setBookingList] = useState([]);
  const [clientData, setClientData] = useState('');
  const [productData, setProductData] = useState('');
  const [slotList, setSlotList] = useState([]);
  const bookingInitialData = {customerId : '', productId : '', shopId : '', shopName : '', clientId : '', clientName : '', serviceId : '', serviceName : '', payment : '', status : '', slotId : '', startDateTime : '', endDateTime : ''}
  const [bookingData, setBookingData] = useState(bookingInitialData);
  const [FormErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [bookedList, setBookedList] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [slotListLoader, setSlotListLoader] = useState(true);

  // get customer data from store
  const userData =  useSelector(store => store.user['customeruser']);

  // get service list data by api
  useEffect(() => {
    const todayDate = new Date().toISOString();
    //const whereBookingSlot = {'clientId' : params.clientId, 'startDate' : todayDate}
    const whereClient = {'clientId' : params.clientId};
    let currentDateMonth =  moment(new Date()).format('YYYY-MM-DD');
    let totalMonthBookingBooked = {"clientId": params.clientId, "filterDate": currentDateMonth, "filterType" : "year"};
   // bookingSlotDayList(whereBookingSlot).then(res => initialListResponse(res));
    customerClientDetail(whereClient).then(res => initialClientResponse(res));
    customerBookingList(totalMonthBookingBooked).then(res => initialBookingList(res));
  },[refresh]);

  const initialListResponse = (response) => {
    if(response.data.statusCode === 200){
      setBookingList(response.data.bookingSlotList);
    }
  } 

  const initialClientResponse =(response) => {
    let clientDetails = response.data;
    setClientData(clientDetails);
    let productArray = [];
    if(clientDetails.serviceOfferedList.length > 0){
      clientDetails.serviceOfferedList.map((data) => {
        if(data.productId == params.productId){
          productArray.push(data);
        }
      })
      setProductData(productArray);
    }
  }
  
  const initialBookingList = (response) => {
    if(response.data.statusCode === 200) {
      let initialmonthBookedData = [];
      let initialBookedData = response.data.customerBookingList;
      initialBookedData.map((data, index) => {
        let singleBooedData = {startDate : data.startDateTime, endDate : data.endDateTime, text : data.serviceName};
        let currentData = moment(singleBooedData.startDate).format('DD');
        let currentDataMonth = moment(singleBooedData.startDate).format('MM');
        if (currentData >= new Date().getDate() || (currentData < new Date().getDate() && currentDataMonth > new Date().getMonth()+1)) {
          initialmonthBookedData.push(singleBooedData);
        }
      });
      
      // setAppointments(initialmonthBookedData);
    }
  }

  const optionChanged = (e) => {
    if (e.fullName === "currentDate") {
      setCurrentDate(e.value);
    }
  }
    
  const views = ['month'];

  const onContentReady = (e) => {
    let toolbarInstance = Toolbar.getInstance(
      e.element.querySelector(".dx-toolbar")
    );
    if (toolbarInstance.option("items").length === 2) {
      let items = toolbarInstance.option("items");
      // let switcher = items[1];
      toolbarInstance.option("items", [
        items[0],
        items[1]
      ]);
    };

  }

  const handleClose = () => {
    setShow(false);
    setBookingData(bookingInitialData);
    setFormErrors({});
    setBookedList([]);
    setSlotList([]);
    setDateField('');
    setSlotListLoader(true);
  }

  const onCellClick = (e) => {
    if (e.cellData.startDate.getDate() >= new Date().getDate() || e.cellData.startDate.getMonth() > new Date().getMonth()) {
      setShow(true);
      // setBookedList([]);
    }
    if ((e.cellData.startDate.getDate() < new Date().getDate() && e.cellData.startDate.getMonth() === new Date().getMonth()) || (e.cellData.startDate.getDate() > new Date().getDate() && e.cellData.startDate.getMonth() < new Date().getMonth()) || e.cellData.startDate.getYear() < new Date().getYear()) {
      setShow(false);
      // setBookedList([]);
    }
    let newAPIDate =  moment(e.cellData.startDate).format('YYYY-MM-DD');
    setDateField(moment(e.cellData.startDate).format('YYYY-MM-DD'));

    const whereBookingSlot = {'clientId' : params.clientId, 'startDate' : newAPIDate}
    bookingSlotDayList(whereBookingSlot).then(res => initialListResponse(res));

    let configureDataList = [];
    const initialListResponse = (response) => {
      if(response.data.statusCode === 200){
        setBookingList(response.data.bookingSlotList);
        configureDataList = response.data.bookingSlotList;
        getMatchDayList(configureDataList, newAPIDate);
      }
    } 
    
  }

  const getMatchDayList = (configureDataList, today) => {
    
    if(configureDataList.length >  0){
      let currentDateSlots = [];
      configureDataList.map((data) => {
        let selectedDate= today; 
        let matchDate = moment(data.startDate).format('YYYY-MM-DD');
        if(matchDate === selectedDate){
          currentDateSlots.push(data);
        }
      });
      getSlot(currentDateSlots);
      let whereBooked = {"clientId": params.clientId, "filterDate": today, "filterType" : "day"};
      customerBookingList(whereBooked).then(res => handleBookingListResponse(res));
    }
  }

  const handleBookingListResponse = (response) => {
    let getBookedData = response.data.customerBookingList;
    if (getBookedData.length > 0) {
      setBookedList(getBookedData);
    }
  }

  const getUTCtoLocal = (onlyTime) => {
    var local_date= moment.utc(onlyTime).local().format("YYYY-MM-DD HH:mm");

    return local_date;
  }

  const getListofAvailableList = () => {
    
    if(bookedList.length > 0 && slotList.length > 0){
      let onlyAvailableSlots = [];
      bookedList.map((list) => {
        if(list.status == 'Booked'){
          let localOpen = getUTCtoLocal(list.startDateTime);
          let localClose = getUTCtoLocal(list.endDateTime);
          let bookingStartingTime = moment(localOpen).format('HH:mm');
          let bookingStartminutes = bookingStartingTime.split(':');
          let bookingStartinMin = (Number(bookingStartminutes[0]) * 60) + Number(bookingStartminutes[1]);
          let bookingEndingTime = moment(localClose).format('HH:mm');
          let bookingEndminutes = bookingEndingTime.split(':');
          let bookingEndinMin = (Number(bookingEndminutes[0]) * 60) + Number(bookingEndminutes[1]);
          let duplicateSlotList = slotList;
          slotList.map((data, index) => {
            let slotStartingTime = moment(data.startDate).format('HH:mm');
            let slotStartminutes = slotStartingTime.split(':');
            let slotStartinMin = (Number(slotStartminutes[0]) * 60) + Number(slotStartminutes[1]);
            let slotEndingTime = moment(data.endDate).format('HH:mm');
            let slotEndminutes = slotEndingTime.split(':');
            let slotendinMin = (Number(slotEndminutes[0]) * 60) + Number(slotEndminutes[1]);
            if((bookingStartinMin <= slotStartinMin && slotStartinMin <= bookingEndinMin) || (bookingStartinMin <= slotendinMin && slotendinMin <= bookingEndinMin)){
              // slotList.splice(index, 1, '1');
              slotList[index] = {...data, ...{isBooked : true}};
            } else if((bookingStartinMin >= slotStartinMin && bookingStartinMin <= slotendinMin) || (bookingEndinMin >= slotStartinMin && bookingEndinMin <= slotendinMin)){
              // slotList.splice(index, 1, '1');
              slotList[index] = {...data, ...{isBooked : true}};
            }
          })
        }
      })
    }
    
  }  

  useMemo(() => getListofAvailableList(), [bookedList]);

  const getSlot = (data) => {
    const finalArray = [];
    const timer = productData[0].duration;
    data.map((data) => {
      var d = new Date(moment(data.startDate).format('YYYY-MM-DD')+' '+moment(getUTCtoLocal(data.startDate)).format('HH:mm'));
      var end = new Date(moment(data.endDate).format('YYYY-MM-DD')+' '+moment(getUTCtoLocal(data.endDate)).format('HH:mm'));
      var newDateObj = new Date();
      var newstartDate = d;
      newDateObj.setTime(newstartDate.getTime() + (timer * 60000));
      while(newDateObj <= end){
        let slotStart = createUTCdateForISO(newstartDate).split('.');
        let slotEnd = createUTCdateForISO(newDateObj).split('.');
        let dateloop = {'startDate' : slotStart[0], 'endDate' : slotEnd[0], 'slotID' : data.slotId};
        finalArray.push(dateloop);
        newstartDate.setTime(newDateObj.getTime() + 60000);
        newDateObj.setTime(newDateObj.getTime() + (timer * 60000));
      }
    });
    finalArray.sort((date1, date2) => (date1.startDate > date2.startDate) ? 1 : (date1.startDate < date2.startDate) ? -1 : 0);
    setSlotList(finalArray);
    setSlotListLoader(false);
  }

  const bookingHandleChange = (data) => {
    const prData = productData[0];
    let customerIdNumber = '';
    if(userData !== null){
      customerIdNumber = userData.id;
    }
    setBookingData(prevState => ({
      ...prevState,
      'customerId':  customerIdNumber,  'productId' : prData.productId, 'shopId' : clientData.shopId, 'shopName' : clientData.shopName, 'clientId' : clientData.clientId, 'clientName' : clientData.clientName, 'serviceId' : prData.serviceId, 'serviceName' : prData.serviceName, 'payment' : prData.price, 'status' : 'Booked' }));
      const fieldName1='startDateTime';
      const fieldName2='endDateTime';
      const fieldName3='slotId';
      const fieldName4='startTimeLC';
      const fieldName5='endTimeLC';
      let openFull = data.startDate.split("");
      if(openFull[15] == 6 || openFull[15] == 1){
        openFull[15] = openFull[15] - 1;
      }
      let updatedOpenTime = openFull.join('');
      const openUTC = createUTCdateForISO(updatedOpenTime);
      const closeUTC = createUTCdateForISO(data.endDate);
      setBookingData(prevState => ({
        ...prevState,
        [fieldName1]:  openUTC, [fieldName2]:  closeUTC, [fieldName3] : data.slotID, [fieldName4] : updatedOpenTime, [fieldName5] : data.endDate}));

        
  }

  function createUTCdateForISO(dateString) {
    const offset = new Date().getTimezoneOffset();
    const myDate = Date.parse(dateString) - (offset * 60 * 1000);
    const dateAsISO = new Date(myDate).toISOString();
    return dateAsISO;
  }

  const handleBooking= (e) => {
    e.preventDefault();
    const errorCount = validate(bookingData);
    setFormErrors(errorCount);
    if(errorCount.status){
      if(userData !== null){
        if(productData.length > 0 && clientData !== ''){
          // addBooking(bookingData).then(res => bookingResponse(res));
          // setLoader(true);
          // setRefresh(prevState => !prevState);
          dispatch(setCustomerBookingDetails(bookingData));
          dispatch(setBookingTimeout(Date.now()));
          navigate('/customer/payment');
        }
        
      }else{
        Swal.fire({
          position: 'center',
          icon: 'warning',
          showCancelButton : 'true',
          title: 'You are not a registered user...',
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'SignIn / SignUp'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/customerlogin');
          }
        }); 
        dispatch(setBookingUrl(true));
      }
    }
  }

  const validate = (values) => {
    const errors = {};
    errors.status = true;

    if(!values.slotId){
      errors.slot = 'Please Select Available Slots';
      errors.status = false;
    } 

    return errors;
  } 

  const dataCellRender = (itemData) => {
    const { startDate } = itemData;
    const ctDate = new Date();
    const isDisabled =  startDate.getFullYear() < ctDate.getFullYear() || (startDate.getDate() < ctDate.getDate() && startDate.getMonth() === ctDate.getMonth() && startDate.getFullYear() <= ctDate.getFullYear())  || (startDate.getMonth() < ctDate.getMonth() && startDate.getFullYear() === ctDate.getFullYear())? true : false;
    return <div className={ isDisabled ? 'dx-scheduler-date-table-cell-text disable-data-table-cell' : "dx-scheduler-date-table-cell-text" }>{itemData.startDate.getDate()}</div>
  }

  const onAppointmentFormOpening = useCallback((e) => {
    e.cancel = true;
  }, []);
  
  return (
    <div>
      <CustomerHeader />
    <section className='history-bg'>
    <div className='history-section'>
      <Container>
        <Row className="justify-content-md-center">
          <Col md='12'>           
          <h1>Booking Calendar<span> - (Click on a date to check availability)</span></h1>     
          <div className='booking_calendar'>  
          <Scheduler 
            onOptionChanged={optionChanged}
            dataSource={appointments}
            currentDate={currentDate}
            views={views}
            defaultCurrentView="month"
            startDayHour={1}
            endDayHour={23}
            adaptivityEnabled={true}
            showAllDayPanel={false}
            onContentReady={onContentReady}
            onCellClick={onCellClick}
            dataCellRender={dataCellRender}
            onAppointmentFormOpening={onAppointmentFormOpening}
          >   
              <Editing allowAdding={false} allowDeleting={false} />
          </Scheduler>
          <Modal show={show} onHide={handleClose} size="lg" className='booking-slot-model'>
          <Form onSubmit={handleBooking}>
              <Modal.Header closeButton>
                <Modal.Title>Select Slot</Modal.Title>
              </Modal.Header>
              {
                slotListLoader === true ?
                  <div style={{textAlign:'center'}}>
                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                  </div>
                :
                <>
                  <Modal.Body>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="md-3" controlId="formBasicEmail">
                        <Form.Label>Service Name</Form.Label>
                        <Form.Control type="text" placeholder="" defaultValue={ productData.length > 0 ? productData[0].serviceName : ''} disabled/>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="md-3" controlId="formBasicEmail">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="text" placeholder="" defaultValue={dateField} disabled/>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                    <Form.Group className="md-3" controlId="formBasicEmail">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="text" placeholder="" defaultValue={productData.length > 0 ? '$'+ productData[0].price : ''} disabled/>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row >
                    <Col>
                    <Form.Label>Available Slots</Form.Label>
                    <div className={ slotList.length > 4 ? "radio day-bookingslots checkSlot" : "radio day-bookingslots "}>
                      {
                        slotList.length > 0?
                        
                        slotList.map((data) => {
                          // let startfirstTime = new Date(data.startDate);
                          // let openTime = startfirstTime.toLocaleTimeString([], {timeStyle: 'short'});
                          // let endfirstTime = new Date(data.endDate);
                          // let closeTime = endfirstTime.toLocaleTimeString([], {timeStyle: 'short'});
                            let openTime = moment(data.startDate).format('hh:mm A');
                            let closeTime = moment(data.endDate).format('hh:mm A');
                            let openFull = openTime.split("");
                            if(openFull[4] == 6 || openFull[4] == 1){
                              openFull[4] = openFull[4] - 1;
                            }
                            let updatedOpenTime = openFull.join('');
                            if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                              let currentDateTime = moment(new Date()).format('YYYY-MM-DD');
                              let slotDateTime = moment(data.startDate).format('YYYY-MM-DD');
                              if(currentDateTime === slotDateTime){
                                let slotOpenTimeOnly = moment(data.startDate).format('HH:mm');
                                let currentDateTimeOnly = moment(new Date()).format('HH:mm');
                                if(currentDateTimeOnly < slotOpenTimeOnly){
                                  return(
                                    <input type="radio" className={data.isBooked === true ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={updatedOpenTime+' - '+closeTime} onChange={() => bookingHandleChange(data)} disabled={data.isBooked === true ? 'disabled' : ''}/>
                                  )
                                }
                              }else{
                                return(
                                  <input type="radio" className={data.isBooked === true ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={updatedOpenTime+' - '+closeTime} onChange={() => bookingHandleChange(data)} disabled={data.isBooked === true ? 'disabled' : ''}/>
                                )
                              }
                          }
                      })
                        :
                        <p class="alert alert-warning" role="alert">There is no slots available</p>
                      }
                    </div>
                    <p className="error">{FormErrors.slot}</p>
                    </Col>
                  </Row>
                  </Modal.Body>
                  <Modal.Footer>
                  {
                    loader === true ? 
                      <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                    :
                      <Button variant="primary" type='submit'>Proceed Payment</Button>
                  }
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                  </Modal.Footer>
                </>
              }
              </Form>
          </Modal>

          </div>    
      </Col>
    </Row>
  </Container>
</div>
</section>
</div>     
  )
}  

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i><br />
      <Button style={{'fontSize': '12px', 'lineHeight':'20px'}}>Book Now</Button>
    </>
  )
}