import { React, useState } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { postCreateProfession } from '../../../../Service/index';
import Swal from "sweetalert2";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';

export default function AddProfessionPopup(props){
    // state declaration
    const initialValues = {name:''};
    const [popupShow, setPopupShow] = useState(true);
    const [formValues, setformValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({}); 
    const [formSubmitLoader, setFormSubmitLoader] = useState(false);

    // popup close
    const popupHandleClose = () => {
        setPopupShow(false);
        setFormErrors({});
        setformValues(initialValues);
        props.getBack(true, false);
    }

    // input field onchange
    const handleChange = (e)=> {
        const value=e.target.value;
        const field=e.target.name;
        setformValues(prevState => ({
            ...prevState,
            [field]: value  }
        ));
    } 

    // add profession form onsumbit
    const handleSubmit = (e) => {
        e.preventDefault();
        const errorCount = inputFieldsvalidate(formValues);
        setFormErrors(errorCount);
        if(errorCount.status){
            postCreateProfession(formValues).then(res => handleResponse(res));
            setFormSubmitLoader(true);
        }
    } 

    // add profession api response
    const handleResponse = (res) => {
        if(res.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Profession added successfully',
                showConfirmButton: false,
                timer: 3000
            });
            setFormSubmitLoader(false);
            setPopupShow(false);
            setFormErrors({});
            setformValues(initialValues);
            props.getBack(false, true);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setFormSubmitLoader(false);
        }
    }

    // input field valiate
    const inputFieldsvalidate = (values)=>{
        const errors = {};
        errors.status = true; 

        if(!values.name || values.name.trim() === ""){
            errors.name='Profession is required';
            errors.status=false;
        }

        let tagValidate = HtmlTagValidation(values.name);

        if(!tagValidate){
            errors.name='Invalid Input';
            errors.status=false;
        }

        return errors;
    }

    return(
        <Modal show={popupShow} onHide={popupHandleClose} className="custom-modal " >
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Profession</Modal.Title>
                </Modal.Header>
                <Modal.Body>        
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Profession <span className="star">*</span></Form.Label>
                        <Form.Control onKeyDown={BlockSQLInjection} type="text" name="name" defaultValue={formValues.name} onChange={handleChange} />
                        <p className="error">{formErrors.name}</p>
                    </Form.Group>       
                </Modal.Body>
                <Modal.Footer>
                    {
                        formSubmitLoader === true ? 
                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                        :
                            <>
                                <Button variant="primary" type="submit">Add</Button>
                                <Button variant="outline-secondary" onClick={popupHandleClose}>Cancel</Button>
                            </>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}