import { React, useEffect, useState } from 'react';
import { Container, Row, Col, Stack, Button, Form } from "react-bootstrap";
import Topbar from '../Assets/Topbar';
import LeftSidebar from '../Assets/LeftSidebar'; 
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { postClientEarnings, postClientTimeZoneDetails } from '../../../Service';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function EarningDetails(){
    const params = useParams();
    const navigate = useNavigate();

    const [searchDates, setSearchDates] = useState({'fromDate' : '', 'toDate' : ''});
    const [searchErrors, setSearchErrors] = useState({});
    const [earningRange, setEarningRange] = useState('');
    const [earningData, setEarningData] = useState({});
    const [pageLoader, setPageLoader] = useState(true);
    const [dateValue, setDateValue] = useState('');
    const [dateDropdownValue, setDateDropdownValue] = useState('');
    const [earningLoader, setEarningLoader] = useState(false);
    const [proTimeZone, setProTimeZone] = useState('');
    const [apiError, setApiError] = useState('');

    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    useEffect(() => {

        if (userData !== null) {

            const userId = {'clientId' : userData.clientId};
            postClientTimeZoneDetails(userId).then(res => timeZoneResponseHanlde(res));

        }
        
    }, []);

    const timeZoneResponseHanlde = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            
            setProTimeZone(res.data.timeZone);
            getEarnings(params.type, res.data.timeZone);
            setDateDropdownValue(params.type);

        } else {
            setPageLoader(false);
            setEarningLoader(false);
            setApiError('Something went wrong, Please try after sometimes');
        }
    
    }

    const getEarnings = (type, zone) => {
        setDateDropdownValue(type);
        setEarningLoader(true);
        let startDates = '';
        let endDates = '';
        let zoneName = '';

        if ( zone !== '' ) {
            
            let zoneSplit = zone.split(' ');
            zoneName = zoneSplit[0];

        }

        if ( type === 'day' ) {

            if ( zoneName ) {

                setEarningRange(moment().tz(zoneName).format('MMM DD YYYY'));
                startDates = moment().tz(zoneName).format('YYYY-MM-DD');
                endDates =  moment().tz(zoneName).format('YYYY-MM-DD');

            } else {

                setEarningRange(moment(new Date()).format('MMM DD YYYY'));
                startDates = moment(new Date()).format('YYYY-MM-DD');
                endDates =  moment(new Date()).format('YYYY-MM-DD');

            }
            
        } else if ( type === 'month' ) {

            if ( zoneName ) {

                setEarningRange(moment().tz(zoneName).startOf('month').format('MMM DD YYYY')+' - '+moment().tz(zoneName).format('MMM DD YYYY'));
                startDates = moment().tz(zoneName).startOf('month').format('YYYY-MM-DD');
                endDates =  moment().tz(zoneName).format('YYYY-MM-DD');

            } else {

                setEarningRange(moment().startOf('month').format('MMM DD YYYY')+' - '+moment(new Date()).format('MMM DD YYYY'));
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates =  moment(new Date()).format('YYYY-MM-DD');

            }

        } else if ( type === 'year' ) {

            if ( zoneName ) {

                setEarningRange(moment().tz(zoneName).startOf('year').format('MMM DD YYYY')+' - '+moment().tz(zoneName).format('MMM DD YYYY'));
                startDates = moment().tz(zoneName).startOf('year').format('YYYY-MM-DD');
                endDates =  moment().tz(zoneName).format('YYYY-MM-DD');

            } else {

                setEarningRange(moment().startOf('year').format('MMM DD YYYY')+' - '+moment(new Date()).format('MMM DD YYYY'));
                startDates = moment().startOf('year').format('YYYY-MM-DD');
                endDates =  moment(new Date()).format('YYYY-MM-DD');

            }

        } else if ( type === 'custom' ) {

            setEarningRange(moment(searchDates.fromDate).format('MMM DD YYYY')+' - '+moment(searchDates.toDate).format('MMM DD YYYY'));
            startDates = moment(searchDates.fromDate).format('YYYY-MM-DD');
            endDates =  moment(searchDates.toDate).format('YYYY-MM-DD');

        }

        let earningParams = {'startDate' : startDates, 'endDate' : endDates, 'type' : 'Earnings'};
        postClientEarnings(earningParams).then(res => earningHandleResponse(res));
    }

    const earningHandleResponse = (res) => {

        if ( res.data.statusCode === 200 ) {

            setEarningData(res.data);
            setPageLoader(false);
            setEarningLoader(false);
            setApiError('');

        } else { 

            setPageLoader(false);
            setEarningLoader(false);
            setApiError('Something went wrong, Please try after sometimes');

        }
    }

    const rangeHandleChange = (e) => {
        let value = e.target.value;
        setDateValue(value);
        setDateDropdownValue(value);

        if ( value !== 'custom' ) {
            getEarnings(value, proTimeZone);
        }

    }

    const dateHandleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSearchDates((prevState) => ({
            ...prevState,
            [name] : value
        }))
    }

    const dateSearchHandleClick = () => {
        let checkValidation = validate();
        setSearchErrors(checkValidation);

        if ( checkValidation.status ) {
            getEarnings('custom', proTimeZone);
        }

    }

    const validate = () => {
        let errors = {};
        errors.status = true;

        if ( !searchDates.fromDate || !searchDates.toDate ) {

            errors.date = 'From date / To date is required';
            errors.status = false;

        } else if ( searchDates.fromDate && searchDates.toDate ) {

            let StartDate = moment(searchDates.fromDate).format('YYYY-MM-DD');
            let endDate = moment(searchDates.toDate).format('YYYY-MM-DD');

            if ( StartDate > endDate ) {
                errors.date = 'To date cannot be less than from date';
                errors.status = false;
            }

        }

        return errors;
    }

    const handlePrevPage = () => {
        navigate(-1);
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'> 
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar tab-view'>
                            <Topbar/>
                            <Row className="main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div >
                                            <h1>My Earnings</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center text-center">
                                <div className='booking-details earningDetails'>
                                    {
                                        pageLoader ? 
                                            <div style={{textAlign : 'center'}}>
                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                            </div>
                                        : 
                                            apiError !== '' ?
                                                <div style={{textAlign : 'center'}}>
                                                    <p>{apiError}</p>
                                                </div>
                                            :
                                                <>
                                                    <div className='earning-head'>
                                                        <h4>Net Earnings</h4>
                                                        <p className='earningsCost'>{earningData.netEarnings}</p>
                                                        <p>{earningRange}</p>
                                                        <Row>
                                                            <div>
                                                                <Form.Group as={Col} controlId="formGridSearchName">
                                                                    <Form.Label></Form.Label>
                                                                    <Form.Select aria-label="Default select example" name='stateProvinceId' onChange={rangeHandleChange} style={{width : '250px', margin : '0 auto'}}> 
                                                                        <option value='day' selected={dateDropdownValue === 'day' ? 'selected' : ''}>Today</option>
                                                                        <option value='month' selected={dateDropdownValue === 'month' ? 'selected' : ''}>This month</option>
                                                                        <option value='year' selected={dateDropdownValue === 'year' ? 'selected' : ''}>This year</option>
                                                                        <option value='custom' selected={dateDropdownValue === 'custom' ? 'selected' : ''}>Custom range</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </div>
                                                            {
                                                                dateValue === 'custom' ? 
                                                                    <>
                                                                        <hr style={{marginTop : '16px'}}></hr>
                                                                        <Row className="mb-2" style={{textAlign : 'left'}}>
                                                                            <Form.Group as={Col} controlId="formGridZip">
                                                                                <Form.Label>From Date <span>*</span></Form.Label>
                                                                                <Form.Control type="date" name='fromDate' onChange={dateHandleChange} value={searchDates.fromDate}/>
                                                                            </Form.Group> 
                                                                            <Form.Group as={Col} controlId="formGridPassword">
                                                                                <Form.Label>To Date <span>*</span></Form.Label>
                                                                                <Form.Control type="date" name='toDate' onChange={dateHandleChange} value={searchDates.toDate}/>
                                                                            </Form.Group>
                                                                        </Row>
                                                                        <p className='error'>{searchErrors.date}</p>
                                                                        <Row className='searcchAllBtn' style={{textAlign : 'center', display : 'block', marginTop : '8px'}}>
                                                                            <Button style={{width : '120px'}} variant="primary" onClick={() => dateSearchHandleClick()}>Search</Button>
                                                                        </Row>
                                                                    </>
                                                                :
                                                                    ''
                                                            }
                                                        </Row>
                                                    </div>
                                                    {
                                                        earningLoader ? 
                                                            <div style={{textAlign : 'center'}}>
                                                                <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                            </div>
                                                        : 
                                                            <div className='earningBody'>
                                                                <div className='earningsplitbody'>
                                                                    <h3 className='earningHead'>Earnings (A)</h3>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Card Transactions Amount</div>
                                                                        <div className='ms-auto'>{earningData.cardAndOtherPayments}</div>
                                                                    </Stack>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Cash Transactions Amount</div>
                                                                        <div className='ms-auto'>{earningData.cashTransactions}</div>
                                                                    </Stack>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Tips Amount</div>
                                                                        <div className='ms-auto'>{earningData.tips}</div>
                                                                    </Stack>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div className='boldTitle'>Gross Earnings</div>
                                                                        <div className='ms-auto'>{earningData.grossEarnings}</div>
                                                                    </Stack>
                                                                </div>

                                                                <div className='earningsplitbody'>
                                                                    <h3 className='earningHead'>Deductions (B)</h3>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Processing Fee on Card Transactions</div>
                                                                        <div className='ms-auto'>{earningData.cardProcessingFee}</div>
                                                                    </Stack>
                                                                    {/* <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Processing Fee on Cash Transactions</div>
                                                                        <div className='ms-auto'>{earningData.cashProcessingFee}</div>
                                                                    </Stack> */}
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div>Processing Fee on Tips</div>
                                                                        <div className='ms-auto'>{earningData.tipProcessingFee}</div>
                                                                    </Stack>
                                                                    <Stack direction='horizontal' className='earningsBold'>
                                                                        <div className='boldTitle'>Total Processing Fees</div>
                                                                        <div className='ms-auto'>{earningData.totalProcessingFee}</div>
                                                                    </Stack>
                                                                </div>

                                                                <hr></hr>
                                                                <Stack direction='horizontal' className='earningsBold' >
                                                                    <div className='neterningBold'>Net Earnings (A - B)</div>
                                                                    <div className='ms-auto'>{earningData.netEarnings}</div>
                                                                </Stack>
                                                                {/* <Stack direction='horizontal' className='earningsBold'>
                                                                    <div>Gross Earnings</div>
                                                                    <div className='ms-auto'>{earningData.grossEarnings}</div>
                                                                </Stack> */}
                                                                <hr/>
                                                                {/* <Stack direction='horizontal' >
                                                                    <div>Refunds</div>
                                                                    <div className='ms-auto'>-$45.00</div>
                                                                </Stack>
                                                                <hr/> */}
                                                                {/* <Stack direction='horizontal' className='earningsBold'>
                                                                    <div>Net Revenue</div>
                                                                    <div className='ms-auto'>{earningData.netRevenue}</div>
                                                                </Stack>
                                                                <hr/>
                                                                <Stack direction='horizontal' >
                                                                    <div>Tax</div>
                                                                    <div className='ms-auto'>$0.00</div>
                                                                </Stack>
                                                                <hr/>
                                                                <Stack direction='horizontal' >
                                                                    <div>Tip</div>
                                                                    <div className='ms-auto'>{earningData.tips}</div>
                                                                </Stack>
                                                                <hr/>
                                                                <Stack direction='horizontal' className='earningsBold'>
                                                                    <div>Total Collected</div>
                                                                    <div className='ms-auto'>{earningData.totalCollected}</div>
                                                                </Stack>
                                                                <Stack direction='horizontal' className='innerStack'>
                                                                    <div>Card Transactions <ChevronRightIcon /></div>
                                                                    <div className='ms-auto'>{earningData.cardAndOtherPayments}</div>
                                                                </Stack>
                                                                <Stack direction='horizontal' className='innerStack'>
                                                                    <div>Cash and Other Payments</div>
                                                                    <div className='ms-auto'>{earningData.cashTransactions}</div>
                                                                </Stack>
                                                                <Stack direction='horizontal' className='innerStack'>
                                                                    <div>Total Collected</div>
                                                                    <div className='ms-auto'>{earningData.totalCollected}</div>
                                                                </Stack>
                                                                <hr></hr>
                                                                <Stack direction='horizontal' >
                                                                    <div>Fees <ChevronRightIcon /></div>
                                                                    <div className='ms-auto'>-{earningData.fees}</div>
                                                                </Stack>
                                                                <hr></hr>
                                                                <Stack direction='horizontal' className='earningsBold' >
                                                                    <div>Net Earnings</div>
                                                                    <div className='ms-auto'>{earningData.netEarnings}</div>
                                                                </Stack> */}
                                                            </div>
                                                    }
                                                    
                                                </>
                                    }
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}