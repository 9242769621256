import { React, useState, useEffect, useMemo } from 'react';
import { Col, Container, Row, Table, Button, Modal,Form, Stack } from "react-bootstrap";
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import CustomerHeader from '../../Assets/CustomerHeader';
import { postCreateCustomerCreditCard, addBooking, postCustomerAccountDetails } from '../../../../Service';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { setCustomerBookingDetails } from '../../../../reducers/User/action';
import Countdown from "react-countdown";
import { BlockInvalidNumber } from '../../../Reusable';
import { JsonEncode, JsonDecode } from '../../../Reusable/JsonModify';

export default function PaymentDetails() {
    // navigation and dispatch set into variable
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const initialCard = {name : '', number : '', expiry : '', cvc : ''}
    const [card, setCard] = useState(initialCard);
    const [formErrors, setFormErrors] = useState({});
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [loader, setLoader] = useState(false);
    const [chooseCard, setChooseCard] = useState(0);
    const [finalErrors, setFinalErrors] = useState({});
    const [cardList, setCardList] = useState([]);
    const [cardRefresh, setCardRefresh] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [serviceCost, setServiceCost] = useState('');
    const [totalCost, setTotalCost] = useState('');
    const [platformCost, setPlatformCost] = useState('');
    const [tipStatus, setTipStatus] = useState(false);
    const [tipsPercent, setTipsPercent] = useState('');
    const [tipsAmount, setTipsAmount] = useState('');
    const [timeoutCheck, setTimeoutCheck] = useState(false);

    // get customer data from store
    const userData =  useSelector(store => store.user['customeruser']);
    const getBookingDetails = useSelector(store => store.user.customerBookingDetails);
    const getBokkingTimeout = useSelector(store => store.user.bookingtimeout);

    // Booking slot add popup open function
    const handleShow = () => {
        setShow(true);
    };

    // Booking slot edit popup close function
    const handleClose = () => {
        setShow(false);
        setCard(initialCard);
        setCardNumber('');
        setFormErrors({});
        setCardExpiry('');
    };

    // get card list 
    useEffect(() => {
        (async () => {
            if ( getBookingDetails == null ) {
                navigate(-1);
            } else {
                let whereUser = {'customer_Id' : userData.id};

                let jsonData = await JsonEncode(whereUser).then(result => {
                    return result
                }).catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: error,
                        timer: 3000
                    }); 
                });
                
                if ( jsonData ) {
                    let loginPayload = {data : jsonData};
                    postCustomerAccountDetails(loginPayload).then(res => cardInitialResponse(res));
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Oops...',
                        showConfirmButton: false,
                        text: 'Something went wrong, Please try after sometimes',
                        timer: 3000
                    }); 
                }
    
                const cardInitialResponse = async (res) => {

                    let decryptData = await JsonDecode(res.data.data).then(result => {
                        return result
                    }).catch(error => {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Oops...',
                            showConfirmButton: false,
                            text: error,
                            timer: 3000
                        }); 
                    });
    
                    if ( decryptData.statusCode == 200 ) {
                        setCardList(decryptData.data);
                        decryptData.data.map((data) => {
    
                            if ( data.defaultCard === true ) {
                                setChooseCard(data.id);
                            }
    
                        })
                    }
    
                }
    
                if ( getBookingDetails ) {
                    setServiceCost(getBookingDetails.payment);
                    let totalAmtCalculate = getBookingDetails.payment * 1.03;
                    let roundTotalAmt = Math.round(totalAmtCalculate * 100) / 100;
                    setTotalCost(roundTotalAmt);
                    let platformFee = Math.round((roundTotalAmt - getBookingDetails.payment) * 100) / 100;
                    setPlatformCost(platformFee);
                }
    
            }
        })();
    }, [cardRefresh, timeoutCheck])

    const hanldeChange = ( e ) => {
        const value=e.target.value;
        const field=e.target.name;
        setCard(prevState => ({
            ...prevState,
            [field]: value 
        }));
        
        if ( field == 'number' ) {
            var val = e.target.value;
            const valArray = val.split(' ').join('').split('');
            
            var valSpace = val.split("");

            // to work with backspace
            if ( valSpace[valSpace.length-1] == ' ' ) {
                var valSpaceN = valSpace.slice(0, -1)
                val = valSpaceN.join("")
                setCardNumber(val);
                return;
            }

            if ( isNaN(valArray.join('')) )
                return;
            if ( valArray.length === 17 )
                return
            if ( valArray.length % 4 === 0 && valArray.length <= 15 ) {
                setCardNumber(e.target.value + " ");
            } else {
                setCardNumber(e.target.value)
            }

        }

        if ( field == 'expiry' ) {
            var val = e.target.value;
            const valArray = val.split('/').join('').split('');
            var valSpace = val.split("")
            
            // to work with backspace
            if ( valSpace[valSpace.length-1] == '/' ) {
                var valSpaceN = valSpace.slice(0, -1)
                val = valSpaceN.join("")
                setCardExpiry(val);
                return;
            }
            if ( isNaN(valArray.join('')) )
                return;
            if ( valArray.length === 5 )
                return;
            if ( valArray.length === 2 ) {
                setCardExpiry(e.target.value + "/");
            } else {
                setCardExpiry(e.target.value)
            }

        }

    }

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = validate(card);
        setFormErrors(errorCount);

        if(errorCount.status){
            const cardDetails = {cardNumber : cardNumber.replace(/ /g, ''), expirationDate : cardExpiry.replace('/', ''), cardCode : card.cvc, customerId : userData.id, cardStatus : 1};

            let jsonData = await JsonEncode(cardDetails).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postCreateCustomerCreditCard(loginPayload).then(res => handleResponse(res));
                setLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }

    }

    const handleResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setLoader(false);
            handleClose();
            setCardRefresh(prevCardRefresh => !prevCardRefresh);
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setLoader(false);
        }

    }

    const validate = ( values ) => {
        const errors = {};
        errors.status = true;

        // if(!values.name  || values.name.trim() === ''){
        //     errors.name = 'Card holder name is required';
        //     errors.status = false;
        // }

        if ( !values.number  || values.number.trim() === '' ) {
            errors.number = 'Card number is required';
            errors.status = false;
        }

        if ( !values.expiry  || values.expiry.trim() === '' ) {
            errors.expiry = 'Card expiry is required';
            errors.status = false;
        } else if ( values.expiry ) {
            let cardSplit = values.expiry.split('/');

            if ( cardSplit[0] > 12 || cardSplit[0] == '00' ) {
                errors.expiry = 'Enter valid month';
                errors.status = false;
            }

        }

        if ( !values.cvc  || values.cvc.trim() === '' ) {
            errors.cvc = 'CVC is required';
            errors.status = false;
        } else if ( values.cvc && (values.cvc.length < 3 || values.cvc.length > 4) ) {
            errors.cvc = 'CVV must be either 3 or 4 digits.';
            errors.status = false;
        }

        return errors;
    }

    const onCardChange = ( e ) => {
        setChooseCard(e.target.value);
    }

    function createUTCdateForISO( dateString ) {
        const offset = new Date().getTimezoneOffset();
        const myDate = Date.parse(dateString) + (offset * 60 * 1000);
        const dateAsISO = new Date(myDate).toISOString();
        return dateAsISO;
    }

    const finalOnSubmit = async ( e ) => {
        e.preventDefault();
        const errorCount = finalValidate(chooseCard);
        setFinalErrors(errorCount);

        if ( errorCount.status ) {
            const setTip = tipsAmount != '' ? tipsAmount : 0;
            setPaymentLoader(true);
            let bookingDetailsData = getBookingDetails;
            bookingDetailsData['customerCardId'] = Number(chooseCard);
            bookingDetailsData['startDateTime'] = bookingDetailsData.startDateTime;
            bookingDetailsData['endDateTime'] = bookingDetailsData.endDateTime;
            bookingDetailsData['payment'] = totalCost;
            bookingDetailsData['platformFees'] = platformCost;
            bookingDetailsData['serviceCost'] = serviceCost;
            bookingDetailsData['tipCost'] = setTip;

            let jsonData = await JsonEncode(bookingDetailsData).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                addBooking(loginPayload).then(res => bookingResponse(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }

        }
    }

    const bookingResponse = ( res ) => {

        if ( res.data.statusCode === 200 ) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Slot booked successfully',
                showConfirmButton: false,
                timer: 3000
            });
            setPaymentLoader(false);
            setTimeout(()=>{
                dispatch(setCustomerBookingDetails(null));
                navigate('/customer/bookinghistory');
            },3000)
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setPaymentLoader(false);
        }
    }

    const finalValidate = ( values ) => {
        const errors = {};
        errors.status = true;

        let cardvalue = values.toString();
        if ( cardList.length > 0 ) {

            if ( !cardvalue  || cardvalue.trim() === '' || cardvalue == 0 ) {
                errors.card = 'Please select a card.';
                errors.status = false;
            }

        } else {

            if ( !cardvalue  || cardvalue.trim() === '' || cardvalue == 0 ) {
                errors.card = 'Please add new card.';
                errors.status = false;
            }

        }

        if ( tipStatus === true ) {

            if ( tipsPercent ) {

                if ( tipsAmount == 0 ) {
                    errors.tip = 'Please enter the tip amount or remove the tip amount.';
                    errors.status = false;
                }

                // else if(tipsAmount < 3){
                //     errors.tip = 'Tip amount can not be less than $3';
                //     errors.status = false;
                // }
            } else {
                errors.tip = 'Please choose a tip amount or remove the tip amount.';
                errors.status = false;
            }

        }

        return errors;
    }

    const setTip = () =>{
        let serviceAddTip = (Number(serviceCost) * 1.03) + Number(tipsAmount);
        let roundTotalAmt = Math.round(serviceAddTip * 100) / 100;
        setTotalCost(roundTotalAmt);
    }

    useMemo(() => setTip(), [tipsAmount]);

    const tipHandler = ( status ) => {
        setTipStatus(status);

        if ( status === false ) {
            setTipsAmount(0);
            setTipsPercent('');
            setFinalErrors({});
        }

    }

    const tipHandleChange = ( value ) => {
        let tipAmt = '';
        setTipsPercent(value);

        if ( value == 'custom' ) {
            
        } else {
            let percetage = value;
            let serviceAmt = serviceCost;
            let getTip = (serviceAmt * percetage) / 100;
            tipAmt = Math.round(getTip * 100) / 100; 
        }

        setTipsAmount(tipAmt);
    }
    
    const setupTipAmount = ( value ) => {
        let roundValue = Math.round(value);
        let roundValueInString = roundValue.toString().length;

        if ( roundValueInString < 5 ) { 
            let valueSplit = value.split('');
            let getValue = valueSplit.indexOf(".");

            if  (getValue != -1 ) {

                if ( valueSplit.length <= getValue + 3 ) {
                    let amtSplit = value.toString().split('');
                    let roundAmount = amtSplit.join('');
                    setTipsAmount(roundAmount);
                }

            } else {
                let amtSplit = value.toString().split('');           
                let roundAmount = amtSplit.join('');
                setTipsAmount(roundAmount);
            }

        }

    }

    const customTipHandleChange = ( e ) => {
        let value = e.target.value.replace(/-\d+/g, ""); 
        let splitValue = value.split('');
        // if(splitValue[0] == 0 || splitValue[0] == '.'){
        //     setTipsAmount('');
        // }else{
        //     let roundValue = Math.round(value);
        //     let roundValueInString = roundValue.toString().length;
        //     if(roundValueInString < 5){
        //         let valueSplit = value.split('');
        //         let getValue = valueSplit.indexOf(".");
        //         if(getValue != -1){
                    // if(valueSplit.length <= getValue + 3){
                    //     let floatTip = Math.round(value * 100) / 100;
                    //     let amtSplit = floatTip.toString().split('');
                        
                    //     if(amtSplit[0] == 0){
                    //         amtSplit.shift();
                    //     }            
                    //     let roundAmount = amtSplit.join('');
                    //     setTipsAmount(roundAmount);
                    // }
        //         }else{
        //             let floatTip = Math.round(value * 100) / 100;
        //             let amtSplit = floatTip.toString().split('');
                    
        //             if(amtSplit[0] == 0){
        //                 amtSplit.shift();
        //             }            
        //             let roundAmount = amtSplit.join('');
        //             setTipsAmount(roundAmount);
        //         }
        //     }
        // }

        if ( splitValue[0] == '.' || splitValue.length === 0 ) {
            setTipsAmount('');
        } else {

            if ( splitValue[0] == 0 ) {

                if ( splitValue[1] == '.' ) {
                    setupTipAmount(value);
                } else {
                    setTipsAmount(0);
                }

            } else {
                setupTipAmount(value);
            }

        }
        
    }

    const handlePrevPage = () => {
        navigate(-1);
        dispatch(setCustomerBookingDetails(null));
    }

    const paymentTimeOut = ( { hours, minutes, seconds, completed } ) => {

        if ( completed ) {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Timed out',
                text: 'Redirecting back to booking calendar',
                showConfirmButton: false,
                timer: 3000
            });
            setTimeout(() => {
                dispatch(setCustomerBookingDetails(null));
                setTimeoutCheck(true);
            }, 3000);
        } else {
            return (
              <span>
                {hours}{minutes}:{seconds}
              </span>
            );
        }

    };

    // get decimal two degit cost
    const getFullCost = ( dollar ) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <div>
            <CustomerHeader />
            <section className='history-bg'>
                <div className='history-section'>
                <Container>
                    <Row className="justify-content-md-center">
                    <Col md='12'>
                        <Stack direction='horizontal' className='Booking-back backStack'>
                            <div>
                                <h1>Confirm Your Booking</h1>
                            </div>
                            <div className='ms-auto'>
                                <span className='booking-timeout'>{getBookingDetails != null ? <span>Booking before : <Countdown date={getBokkingTimeout + 120000} renderer={paymentTimeOut} /></span> : ''}</span>
                                <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                            </div>
                        </Stack>
                        
                        <Row>
                            <Col md='6' className='payment-booking-details pay-box'>
                            <h4>Booking details</h4>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Business Name</th>
                                        <td>: {getBookingDetails ? getBookingDetails.shopName : ''}</td> 
                                    </tr>
                                    <tr>
                                        <th>Stylist Name</th>
                                        <td>: {getBookingDetails ? getBookingDetails.clientName : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Service</th>
                                        <td>: {getBookingDetails ? getBookingDetails.serviceName : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Service Cost</th>
                                        <td>: $ {serviceCost ? getFullCost(serviceCost) : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Tip Amount</th>
                                        <td>: $ {tipsAmount == '' ? '0.00' : getFullCost(tipsAmount)}</td>
                                    </tr>
                                    <tr>
                                        <th>Platform Fees - 3%</th>
                                        <td>: $ {getFullCost(platformCost)}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Payable Amount</th>
                                        <td>: $ {totalCost ? getFullCost(totalCost) : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Booking Date</th>
                                        <td>: {getBookingDetails ? moment(getBookingDetails.startDateTime).format('MM-DD-YYYY') : ''}</td>
                                    </tr>
                                    <tr>
                                        <th>Slot Time</th>
                                        <td>: {getBookingDetails ? moment(getBookingDetails.startDateTime).format('hh:mm A') : ''} - {getBookingDetails ? moment(getBookingDetails.endDateTime).format('hh:mm A') : ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </Col>
                            <Col md='6' className='pay-box'>
                                <h4 style={{textAlign:'left'}}>Select a card </h4>
                                <Form onSubmit={finalOnSubmit}>
                                    <Row className='choose-card'>
                                        {
                                            cardList.length > 0 ?
                                                cardList.map((data, index) => (
                                                    <div className="radio" key={index} >
                                                        <label>
                                                            <input type="radio" value={data.id} checked={data.id == chooseCard ? 'checked' : ''} onChange={onCardChange} />
                                                            {data.accountNumber+ ' (' +data.accountType+')'}
                                                        </label>
                                                    </div>
                                                ))
                                            :
                                                <p className='no-card'>No cards available. Click on "+ Add New Card".</p>
                                        }
                                        <p className="error">{finalErrors.card}</p>
                                    </Row>
                                    {
                                        cardList.length > 0 ?
                                            ''
                                        :
                                            <Row>
                                                <p className='payment-card-btn' onClick={handleShow}>+ Add New Card</p>
                                            </Row>
                                    }
                                    <Row className='bookAddTip'>
                                        {
                                            tipStatus === true ?
                                                <>
                                                    <h4 style={{textAlign:'left'}}>Select a tip amount</h4>
                                                    <div className='tipRadio'>
                                                        <input type="radio" className='tip' name="tipCheck" label={'15 %'} onChange={() => tipHandleChange(15)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'20 %'} onChange={() => tipHandleChange(20)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'25 %'} onChange={() => tipHandleChange(25)} />
                                                        <input type="radio" className='tip' name="tipCheck" label={'Custom'} onChange={() => tipHandleChange('custom')} />
                                                    </div>
                                                    {
                                                        tipsPercent == 'custom' ?
                                                            <div>
                                                                <Form.Group as={Col} controlId="formGridEmail" style={{marginTop : '16px'}}>
                                                                    <Form.Label>Enter custom tip amount (in $) <span>*</span></Form.Label>
                                                                    <Form.Control onKeyDown={BlockInvalidNumber} type="number" name='customAmount' value={tipsAmount} onChange={customTipHandleChange}/>
                                                                </Form.Group>
                                                            </div>
                                                        :
                                                        ''
                                                    }
                                                    <div>
                                                        <p className="error">{finalErrors.tip}</p>
                                                        <p className='removeTip'><span onClick={() => tipHandler(false)}><FontAwesomeIcon icon={faTrash} /> Remove Tip</span></p>
                                                    </div>
                                                </>
                                            :
                                                <div>
                                                    <p className='addTip'><span onClick={() => tipHandler(true)}><FontAwesomeIcon icon={faCirclePlus} /> Add Tip</span></p>
                                                </div>

                                        }
                                    </Row>
                                    
                                    <Row className='payment-submit-btn'>
                                    {
                                        paymentLoader === true ?
                                            <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                        :
                                            <Button variant="primary" className='addbtn' type='submit'>Confirm Booking</Button>
                                    }
                                        
                                    </Row>
                                </Form>
                                
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                </Container>
                </div>

                {/* Add card HTML start here */}
                <Modal show={show} onHide={handleClose} className="custom-modal Add-Card">
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Card</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md='12'>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridFirstName">
                                            <Form.Label>Card Number <span>*</span></Form.Label>
                                            <Form.Control type="tel" name="number" placeholder='1234 1234 1234 1234' value={cardNumber} onChange={hanldeChange}/>
                                            <p className="error">{formErrors.number}</p>
                                        </Form.Group> 
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                            <Form.Label>Exp Date<span>*</span></Form.Label>
                                            <Form.Control type="tel" name="expiry" placeholder='MM/YY' value={cardExpiry} onChange={hanldeChange}/>
                                            <p className="error">{formErrors.expiry}</p>
                                        </Form.Group> 
                                        <Form.Group as={Col}  className="mb-6" controlId="formGridFirstName">
                                            <Form.Label>CVC <span>*</span></Form.Label>
                                            <Form.Control type="number" name="cvc" placeholder='123' onChange={hanldeChange}/>
                                            <p className="error">{formErrors.cvc}</p>
                                        </Form.Group> 
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                loader === true ?
                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                :
                                    <Button variant="primary" className='addbtn' type='submit'>Add</Button>
                            }
                            <Button variant="outline-secondary" onClick={handleClose}>Cancel</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </section>
        </div>
    )
}