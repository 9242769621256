import { React, useEffect, useMemo } from "react";
import {Button, Col, Container, Modal, Row, Form, Stack, OverlayTrigger, Popover } from "react-bootstrap"; 
import { Scheduler } from "devextreme-react";
import { Editing } from "devextreme-react/scheduler";
import { useState } from "react";
import { postClientBookingSlotList, postClientUpcomingBookingSlotList, customerClientDetail, customerBookingList, postClientTransferSingleBooking, postClientUpcomingSlotDaysList } from "../../../../Service";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import LeftSidebar from '../../Assets/LeftSidebar';
import Topbar from '../../Assets/Topbar';
import Swal from "sweetalert2";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TodayIcon from '@mui/icons-material/Today';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getYear, getWeek, addYears, getMonth } from "date-fns";
import Tooltip from 'react-bootstrap/Tooltip';
import { JsonEncode, JsonDecode } from "../../../Reusable/JsonModify";

export default function EditBookingMultiSlotCalendar() {

    // navigation set into variable
    const navigate = useNavigate();
    const params = useParams();

    // get booking details data from store
    const getBookingDetails = useSelector(store => store.user.bookingDetails);
    const bookingDate = getBookingDetails !== null ? moment(new Date(getBookingDetails.startDate)).format('MM-DD-YYYY') : '';
    const bookingStartTIme = getBookingDetails !== null ? moment(new Date(getBookingDetails.startDate)).format('LT') : '';
    const bookingEndTIme = getBookingDetails !== null ? moment(new Date(getBookingDetails.endDate)).format('LT') : '';

    // initial state declaration
    const [productData, setProductData] = useState('');
    const [ daysTimeSlot, setDaysTimeSlot ] = useState({});
    const [ daysBreakTimeSlot, setDaysBreakTimeSlot ] = useState({});
    const [ timezone, setTimezone ] = useState('');
    const [slotList, setSlotList] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [slotListLoader, setSlotListLoader] = useState(true);
    const [show, setShow] = useState(false);
    const bookingInitialData = {bookingId : '', startDateTime : '', endDateTime : '', serviceId : '', serviceName : '', payment : '', serviceCost : '', clientId : ''};
    const [bookingData, setBookingData] = useState(bookingInitialData);
    const [FormErrors, setFormErrors] = useState({});
    const [bookingList, setBookingList] = useState([]);
    const [slotCount, setSlotCount] = useState(0);
    const [allDayOpen, setAllDayOpen] = useState(true);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [serviceTimeDuration, setServiceTimeDuration] = useState(0);
    const [mainSlotList, setMainSlotList] = useState([]);
    const [pageLoader, setPageLoader] = useState(true);
    const [morningStatus, setMorningStatus] = useState(false);
    const [eveningStatus, setEveningStatus] = useState(false);
    const [afternoonStatus, setAfternoonStatus] = useState(false);
    const [updatedDaysList, setUpdatedDaysList] = useState([]);
    const [statusOfDays, setstatusOfDays] = useState('');

    // get client id from params
    const clientId = {"clientId": params.clientId};

    // initial get api declaration
    useEffect(()=>{
        let disableParams = {"clientId": params.clientId, 'fromDate' : new Date()};
        postClientUpcomingSlotDaysList(disableParams).then(res => updatedDaysHandleResponse(res));
        postClientBookingSlotList(clientId).then(res => initialClientBookingSlotList(res));
        (async () => {
            let jsonData = await JsonEncode(clientId).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                customerClientDetail(loginPayload).then(res => initialClientResponse(res));
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
        })();
        
    },[]);

    const updatedDaysHandleResponse = (res) => {
        if(res.data.statusCode === 200){
            setUpdatedDaysList(res.data.data);
        }
    }

    // client slot and break data api handle response
    const initialClientBookingSlotList = (response) => {
        if(response.data.statusCode === 200) {
            setstatusOfDays(response.data.statusOfTheDays);
            setDaysTimeSlot(response.data.daysTimeSlot);
            setDaysBreakTimeSlot(response.data.daysBreakTimeSlot);
            setTimezone(response.data.timeZone);
            setAllDayOpen(response.data.statusOfTheDays.allDay);
        }
    }

    // client details get api handle response
    const initialClientResponse = async (response) => {
        let decryptData = await JsonDecode(response.data.data).then(result => {
            return result
        }).catch(error => {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: error,
                timer: 3000
            }); 
        });

        let clientDetails = decryptData;
        let productArray = [];
        if(clientDetails.serviceOfferedList.length > 0){
            clientDetails.serviceOfferedList.map((data) => {
                if(data.productId == params.productId){
                productArray.push(data);
                }
            })
            setProductData(productArray);
            if(productArray.length > 0){
                setServiceTimeDuration(productArray[0].duration);
            }
            setPageLoader(false);
        }
      }

    const views = ['month'];

    const checkDisabled = (day, allDays, checkDayStatus) => {
        let dayName = '';
        let dayStatus = true;
        if(day === 0){
            dayName = 'sunday';
        }else if(day === 1){
            dayName = 'monday';
        }else if(day === 2){
            dayName = 'tuesday';
        }else if(day === 3){
            dayName = 'wednesday';
        }else if(day === 4){
            dayName = 'thursday';
        }else if(day === 5){
            dayName = 'friday';
        }else if(day === 6){
            dayName = 'saturday';
        }

        if(allDays){
            dayStatus = allDays[dayName];
        }

        if(checkDayStatus.length > 0){
            if(dayStatus === true && checkDayStatus[0].status === false){
                dayStatus = false;
            }else if(dayStatus === false && checkDayStatus[0].status === true){
                dayStatus = true;
            }
        }

        return dayStatus;
    }

    const dataCellRender = (itemData) => {
        const { startDate } = itemData;
        if(timezone){
            let checkDayStatus = updatedDaysList.filter((item) => {return item.date === moment(startDate).format('YYYY-MM-DD')});
            let checkDisable = checkDisabled(itemData.startDate.getDay(), statusOfDays, checkDayStatus);
            let zoone = timezone.split(' ');
            let currentZoneTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));

            const isDisabled =  startDate.getFullYear() < currentZoneTime.getFullYear() || (startDate.getDate() < currentZoneTime.getDate() && startDate.getMonth() === currentZoneTime.getMonth() && startDate.getFullYear() <= currentZoneTime.getFullYear())  || (startDate.getMonth() < currentZoneTime.getMonth() && startDate.getFullYear() === currentZoneTime.getFullYear()) ? true : checkDisable === false ? true : false;

            return <div className={ isDisabled ? 'dx-scheduler-date-table-cell-text disable-data-table-cell' : "dx-scheduler-date-table-cell-text" }>{itemData.startDate.getDate()}</div>
        }
    }

    const onCellClick = (e) => {
        let zoone = timezone.split(' ');
        let currentZoneTime = new Date(moment().tz(zoone[0]).format('YYYY-MM-DD HH:mm'));
        const startDate  = e.cellData.startDate;
        const currentDate = startDate;
        let currentYearNumber = getYear(startDate);
        let currentMonthNumber = getMonth(startDate) + 1;
        if(currentMonthNumber == 12 && getWeek(startDate)  == 1){
            currentYearNumber = getYear(addYears(startDate, 1));
        }
        const clientAndWeekId = {"clientId": params.clientId, "week": getWeek(startDate), "year" : currentYearNumber};

        const currentTimeZoneDate = moment().tz(zoone[0]).format('YYYY-MM-DD');
        const currentCellClickdate = moment(e.cellData.startDate).format('YYYY-MM-DD');
        let checkDayStatus = updatedDaysList.filter((item) => {return item.date === moment(startDate).format('YYYY-MM-DD')});
        let checkCurrentDay =  checkDisabled(moment(e.cellData.startDate).day(), statusOfDays, checkDayStatus);
        if (currentTimeZoneDate <= currentCellClickdate && checkCurrentDay) {
            if (currentTimeZoneDate <= currentCellClickdate) {
                setSelectedDate(moment(e.cellData.startDate).format('YYYY-MM-DD'));
                setShow(true);
                let newAPIDate =  moment(e.cellData.startDate).format('YYYY-MM-DD');
                const whereBookingSlot = {'clientId' : params.clientId, 'filterDate' : newAPIDate, 'filterType' : 'day'}
                customerBookingList(whereBookingSlot).then(res => initialBooedResponse(res));

                const initialBooedResponse = (response) => {
                    if(response.data.statusCode === 200){
                        setBookingList(response.data.customerBookingList);
                    }
                    postClientUpcomingBookingSlotList(clientAndWeekId).then(response => initialUpcommingBookingSlotList(response, startDate));
                }
            }else if (currentTimeZoneDate > currentCellClickdate) {
                setShow(false);
            }
        }
    }

    // get upcomming api response hanlde
    const initialUpcommingBookingSlotList = (response, startDate) => {
        if(response.data.statusCode === 200){
           if (response.data.clientId === 0) {
                let startDay = startDate.toString().split(' ')[0].toUpperCase();

                let slotTime = [];
                let dayTimeSlot = daysTimeSlot;
                for( let x in dayTimeSlot){
                    let slotTimeDay = x.toUpperCase();
                    if (slotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithSlotTime = dayTimeSlot[x];
                        slotTime.push(daysWithSlotTime);
                    }
                }
                
                let breakSlotTime = [];
                let breakTimeSlot = daysBreakTimeSlot;
                for( let x in breakTimeSlot){
                    let breakSlotTimeDay = x.toUpperCase();
                    if (breakSlotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithBreakSlotTime = breakTimeSlot[x];
                        breakSlotTime.push(daysWithBreakSlotTime);
                    }
                }

                let slotTimeArr = [];
                let breakSlotTimeArr = [];
                if(breakSlotTime[0] != ''){
                    breakSlotTimeArr = {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]};

                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : breakSlotTime[0]}, {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]}, {startDayTime : breakSlotTime[1], endDayTime : slotTime[1]}];
                }else{
                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : slotTime[1]}];
                }

                getSlot(slotTimeArr, startDate);

            }else{
                let dayTimeSlot = response.data.daysTimeSlot;
                let slotTime = [];
                let startDay = startDate.toString().split(' ')[0].toUpperCase();
                
                for( let x in dayTimeSlot){
                    let slotTimeDay = x.toUpperCase();
                    if (slotTimeDay.indexOf(startDay) !== -1) {
                        let daysWithSlotTime = dayTimeSlot[x];
                        if(!daysWithSlotTime){
                        }
                        
                        slotTime.push(daysWithSlotTime);
                    }
                }
                
                let breakTimeSlot = response.data.daysBreakTimeSlot;
                let breakSlotTime = [];
                for( let x in breakTimeSlot){
                    let slotTimeBreak = x.toUpperCase();
                    if (slotTimeBreak.indexOf(startDay) !== -1) {
                        let daysWithBreakSlotTime = breakTimeSlot[x];
                        breakSlotTime.push(daysWithBreakSlotTime);
                    }
                }
                let slotTimeArr = [];
                let breakSlotTimeArr = [];
                if(breakSlotTime[0] != ''){
                    breakSlotTimeArr = {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]};

                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : breakSlotTime[0]}, {startDayTime : breakSlotTime[0] , endDayTime : breakSlotTime[1]}, {startDayTime : breakSlotTime[1], endDayTime : slotTime[1]}];
                }else{
                    slotTimeArr = [{startDayTime : slotTime[0] , endDayTime : slotTime[1]}];
                }       

                getSlot(slotTimeArr, startDate);

            }
        }
    }

    const getAvailableList = () => { // set break and booking into individual slots function 
        if(bookingList.length > 0 ){
            bookingList.map((list) => { 
                if(list.status == 'Booked'){
                    let bookOpen = new Date(moment(selectedDate).format('YYYY-MM-DD')+' '+moment(list.startDateTime).format('HH:mm'));
                    let bookClose = new Date(moment(selectedDate).format('YYYY-MM-DD')+' '+moment(list.endDateTime).format('HH:mm'));
                    if(list.startDateTime != getBookingDetails.startDateTime && list.endDateTime != getBookingDetails.endDateTime){
                        let bookingStartingTime = moment(bookOpen).format('HH:mm');
                        let bookingStartminutes = bookingStartingTime.split(':');
                        let bookingStartinMin = (Number(bookingStartminutes[0]) * 60) + Number(bookingStartminutes[1]);
                        let bookingEndingTime = moment(bookClose).format('HH:mm');
                        let bookingEndminutes = bookingEndingTime.split(':');
                        let bookingEndinMin = (Number(bookingEndminutes[0]) * 60) + Number(bookingEndminutes[1]);
                        slotList.map((data, index) => {
                            let slotStartingTime = moment(data.startDate).format('HH:mm');
                            let slotStartminutes = slotStartingTime.split(':');
                            let slotStartinMin = (Number(slotStartminutes[0]) * 60) + Number(slotStartminutes[1]);
                            let slotEndingTime = moment(data.endDate).format('HH:mm');
                            let slotEndminutes = slotEndingTime.split(':');
                            let slotendinMin = (Number(slotEndminutes[0]) * 60) + Number(slotEndminutes[1]);
                            if((bookingStartinMin < slotStartinMin && slotStartinMin < bookingEndinMin) || (bookingStartinMin < slotendinMin && slotendinMin <= bookingEndinMin)){
                                // if(list.customerId == params.customerId && )
                                slotList[index] = {...data, ...{isBooked : true}};
                            } else if((bookingStartinMin > slotStartinMin && bookingStartinMin < slotendinMin) || (bookingEndinMin > slotStartinMin && bookingEndinMin <= slotendinMin)){
                                slotList[index] = {...data, ...{isBooked : true}};
                            }
                        })
                    }
                }
            })
        }
        
    }

    useMemo(() => getAvailableList(), [slotList]);

    // get individual slot from day open time and close time 
    const getSlot = (slotTime, selectDay) => {
        const finalArray = [];
        if(allDayOpen === true){
            let breakStatus = slotTime.length === 3 ? true : false;
            slotTime.map((data, index) => {
                if(breakStatus === true && index === 1){
                    var startTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.startDayTime);
                    var endTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.endDayTime);

                    let dateloop = {'startDate' : moment(selectDay).format("YYYY-MM-DD")+'T'+moment(startTimeForSelectDate).add(1, 'minutes').format("HH:mm"), 'endDate' : moment(selectDay).format("YYYY-MM-DD")+'T'+moment(endTimeForSelectDate).format("HH:mm"), 'isBreak' : true};
                    finalArray.push(dateloop);
                }else{
                    const slotTimer = 15;
                    var startTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.startDayTime);
                    var endTimeForSelectDate = new Date(moment(selectDay).format('YYYY-MM-DD')+' '+data.endDayTime);
                    
                    var newDateObj = new Date();
                    var newstartDate = startTimeForSelectDate;
                    newDateObj.setTime(newstartDate.getTime() + (slotTimer * 60000));
                    while(newDateObj <= endTimeForSelectDate){
                        let slotStart = moment(newstartDate).format("YYYY-MM-DD")+'T'+moment(newstartDate).format("HH:mm");
                        let slotEnd = moment(newDateObj).format("YYYY-MM-DD")+'T'+moment(newDateObj).format("HH:mm");
                        let dateloop = {'startDate' : slotStart, 'endDate' : slotEnd, 'isBreak' : false};
                        finalArray.push(dateloop);
                        newstartDate.setTime(newDateObj.getTime() + 60000);
                        newDateObj.setTime(newDateObj.getTime() + (slotTimer * 60000));
                    }
                }
            });
            finalArray.sort((date1, date2) => (date1.startDate > date2.startDate) ? 1 : (date1.startDate < date2.startDate) ? -1 : 0);
        }
        let timezoneSlots = [];
        finalArray.map(data => {
            let zoone = timezone.split(' ');
            let currentDateTime = moment().tz(zoone[0]).format('YYYY-MM-DD');
            let slotDateTime = moment(data.startDate).format('YYYY-MM-DD');
            if(currentDateTime === slotDateTime){
                                                                                
                let slotOpenTimeOnly = moment(data.startDate).format('HH:mm');
                let currentDateTimeOnly = moment().tz(zoone[0]).format('HH:mm');
                if(currentDateTimeOnly < slotOpenTimeOnly){
                    timezoneSlots.push(data);
                }
            }else{
                timezoneSlots.push(data);
            }    
        })
        setSlotList(timezoneSlots);
        setMainSlotList(timezoneSlots);
        setTimeout(() => {
            setSlotListLoader(false);
        }, 1000);
    }

    // slot popup close
    const handleClose = () => {
        setShow(false);
        setBookingData(bookingInitialData);
        setFormErrors({});
        setSlotList([]);
        setSelectedDate('');
        setSlotListLoader(true);
        setSlotCount(0);
        setBookingList([]);
        setMainSlotList([]);
    }

    const autoSlotSelection = (index) => { // get multiple auto selected slot by clicking one slot
        let slotStartTime = '';
        let slotEndTime = '';
        let duplicateSlots = mainSlotList.map(item => ({...item}));
        let slotsBasedService =  serviceTimeDuration / 15;
        
        let slotCount = 0;
        let slotIndex = [];
        
        if(duplicateSlots.length >= slotsBasedService){
                for(let i = 0; i < slotsBasedService; i++){
                    if((index + i) >= duplicateSlots.length){
                        slotIndex = [];
                        slotCount = 0;
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Oops...',
                            showConfirmButton: false,
                            text: 'The service you chose requires more time than is available. Please choose a different time slot.',
                            timer: 3000
                        }); 
                        break;
                    }else{
                        if(!duplicateSlots[index + i].isBooked && !duplicateSlots[index + i].isBreak){
                            slotIndex.push(index + i);
                            slotCount = slotCount + 1;
                        }else{
                            slotIndex = [];
                            slotCount = 0;
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Oops...',
                                showConfirmButton: false,
                                text: 'The service you chose requires more time than is available. Please choose a different time slot.',
                                timer: 3000
                            }); 
                            break;
                        }
                    }
                }
        }
        
        slotIndex.sort(function(a, b){return a-b});

        slotIndex.map((data) => {
            duplicateSlots[data].isSelected = 'true';
        })

        if(slotIndex.length > 0){
            if(slotIndex.length === 1){
                slotStartTime =  duplicateSlots[slotIndex[0]].startDate;
                slotEndTime = duplicateSlots[slotIndex[0]].endDate;
            }else{
                slotStartTime =  duplicateSlots[slotIndex[0]].startDate;
                slotEndTime = duplicateSlots[slotIndex[slotIndex.length - 1]].endDate;
            }
        }
        setSlotList(duplicateSlots);

        let finalSlotTime = {'startDate' : slotStartTime, 'endDate' : slotEndTime}
        return finalSlotTime;
    }

    // booking time slot handle change
    const bookingHandleChange = (data, index) => {
        let getautoSlots = autoSlotSelection(index);
        const prData = productData[0];
        let customerIdNumber = params.customerId;
        let bookingIdNumber = params.bookingId;
        let clientIdNumber = params.clientId;
        let totalAmtCalculate = prData.price * 1.03;
        let roundTotalAmt = Math.round(totalAmtCalculate * 100) / 100;
        setBookingData(prevState => ({
            ...prevState,
            'customerId':  customerIdNumber,  'bookingId' : bookingIdNumber, 'serviceId' : prData.subServiceId, 'serviceName' : prData.subServiceName, 'payment' : roundTotalAmt, 'serviceCost' : prData.price, 'clientId' : clientIdNumber
        }));
        const fieldName1='startDateTime';
        const fieldName2='endDateTime';
        
        let openFull = getautoSlots.startDate.split("");
        if(openFull[15] == 6 || openFull[15] == 1){
            openFull[15] = openFull[15] - 1;
        }
        let updatedOpenTime = openFull.join('');
        let openSplitTime = updatedOpenTime.split('T');
        let closeSplitTime = getautoSlots.endDate.split('T');
        const openUTC = updatedOpenTime;
        const closeUTC = getautoSlots.endDate;
        setBookingData(prevState => ({
            ...prevState,
            [fieldName1]:  openUTC, [fieldName2]:  closeUTC
        }));
    }

    // booking submit 
    const handleBooking = async (e) => {
        e.preventDefault();
        const errorCount = validate(bookingData);
        setFormErrors(errorCount);
        if(errorCount.status){

            let jsonData = await JsonEncode(bookingData).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                postClientTransferSingleBooking(loginPayload).then(res => updateBookingResponse(res));
                setUpdateLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    }

    const updateBookingResponse = (res) => {
        if(res.data.statusCode == 200){
            setUpdateLoader(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: res.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            navigate('../stylist/bookingcalendar');
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: res.data.statusMessage,
                timer: 3000
            }); 
            setUpdateLoader(false);
        }
    }

    // slot time validation
    const validate = (values) => {
        const errors = {};
        errors.status = true;
        
        if(!values.startDateTime && !values.endDateTime){
        errors.slot = 'Please Select Available Slots';
        errors.status = false;
        } 

        return errors;
    } 

    const handlePrevPage = () => {
        navigate(-1);
    }

    useEffect(() => {
        if(slotList.length > 0){
            slotList.map((item) => {
                let selectDateTimeForCheckMorning = selectedDate+'T12:01';
                if(item.startDate < selectDateTimeForCheckMorning){
                    if(!morningStatus){
                        setMorningStatus(true);
                    }
                }

                let selectDateStartTimeForCheckAfterNoon = selectedDate+'T12:01';
                let selectDateEndTimeForCheckAfterNoon = selectedDate+'T17:01';
                if(item.startDate >= selectDateStartTimeForCheckAfterNoon && item.startDate < selectDateEndTimeForCheckAfterNoon){
                    if(!afternoonStatus){
                        setAfternoonStatus(true);
                    }
                }

                let selectDateTimeForCheckEvening = selectedDate+'T17:01';
                if(item.startDate >= selectDateTimeForCheckEvening){
                    if(!eveningStatus){
                        setEveningStatus(true);
                    }
                }
            });
        }
    }, [slotList])

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return(
        <section className='dashboard'>
            <Container>
                <Row className='sideBarRow'>
                    <Col md={3} className='sideBarCol'>
                        <LeftSidebar/>
                    </Col>
                    <Col md={9}>
                        <div className='rightsidebar'>
                            <Topbar/>
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction='horizontal' className='stackInside'>
                                        <div >
                                            <h1>Edit Booking</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row> 
                            {
                                pageLoader === true ? 
                                    <div style={{textAlign:'center'}}>
                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                    </div>
                                :
                                    productData.length > 0 ?
                                        <>
                                            <Row className='addBooking-title'>
                                                <Col>
                                                    <div className="oldBookingData">
                                                        <h2>Old Booking Details</h2>
                                                        <Stack direction="horizontal" gap={4} className="oldDetails">
                                                            <div className="">
                                                                <p><DesignServicesIcon /> {getBookingDetails.serviceName} </p>
                                                            </div>
                                                            <div className="">
                                                                <p><TodayIcon /> {bookingDate} </p>
                                                            </div>
                                                            <div className="">
                                                                <p><AccessTimeIcon /> {bookingStartTIme + " " + "-" +  " " + bookingEndTIme} </p>
                                                            </div>
                                                            <div className="">
                                                                <p>
                                                                    <PaidIcon /> 
                                                                    $ {getFullCost(getBookingDetails.payment)}
                                                                    <sup className='payment-icon popover-payment'>
                                                                        <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                            overlay={
                                                                                <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                <div>
                                                                                    <h5>Service : ${getFullCost(getBookingDetails.serviceCost)} </h5>  
                                                                                    <h5>Tip : ${getFullCost(getBookingDetails.tip)}</h5>  
                                                                                    <h5>Platform Fees : ${getFullCost(getBookingDetails.platformFees)}</h5>  
                                                                                    <p><strong>Total : </strong>${getFullCost(getBookingDetails.payment)}</p>
                                                                                </div>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                                                        </OverlayTrigger>
                                                                    </sup> 
                                                                    <sup className='payment-icon popover-payment-mobile'>
                                                                        <OverlayTrigger trigger="click" placement="right" rootClose={true}
                                                                            overlay={
                                                                                <Popover id={'popover-payment-mobile'} style={{"background":"#222", "color":"#fff"}}>
                                                                                <div>
                                                                                    <h5>Service :<span> ${getFullCost(getBookingDetails.serviceCost)}</span> </h5>  
                                                                                    <h5>Tip :<span> ${getFullCost(getBookingDetails.tip)}</span></h5>  
                                                                                    <h5>Platform<br></br> Fees :<span> ${getFullCost(getBookingDetails.platformFees)}</span></h5>  
                                                                                    <p><strong>Total : </strong>${getFullCost(getBookingDetails.payment)}</p>
                                                                                </div>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faCircleExclamation} />
                                                                        </OverlayTrigger>
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-md-center addBooking-title">
                                                <Col md='12'>   
                                                    <div className="oldBookingData">
                                                    <h2>Select Date & Time</h2>    
                                                    <div className='booking_calendar'>
                                                        <Scheduler
                                                            views={views}
                                                            defaultCurrentView='month'
                                                            shadeUntilCurrentTime={true}
                                                            adaptivityEnabled={true}
                                                            dataCellRender={dataCellRender}
                                                            onCellClick={onCellClick}
                                                        >
                                                            <Editing allowAdding={false} allowDeleting={false} />
                                                        </Scheduler>
                                                        
                                                        <Modal show={show} onHide={handleClose} size="lg" className='booking-slot-model'>
                                                            <Form onSubmit={handleBooking}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Select Slot</Modal.Title>
                                                                </Modal.Header>
                                                                {
                                                                    slotListLoader === true ?
                                                                    <div style={{textAlign:'center'}}>
                                                                        <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                    </div>
                                                                    :
                                                                    <>
                                                                    <Modal.Body>
                                                                        <Row className="popupBookingData">
                                                                            <div className="oldBookingData">
                                                                                <h2>Old Booking Details</h2>
                                                                                <Stack direction="horizontal" gap={4} className="oldDetails">
                                                                                    <div className="">
                                                                                        <p><DesignServicesIcon /> {getBookingDetails.serviceName} </p>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <p><TodayIcon /> {bookingDate} </p>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <p><AccessTimeIcon /> {bookingStartTIme + " " + "-" +  " " + bookingEndTIme} </p>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <p>
                                                                                            <PaidIcon /> 
                                                                                            $ {getFullCost(getBookingDetails.payment)}
                                                                                            <sup className='payment-icon popover-payment'>
                                                                                                <OverlayTrigger trigger="click" placement="left" rootClose={true}
                                                                                                    overlay={
                                                                                                        <Popover id={'popover-payment'} style={{"background":"#222", "color":"#fff"}}>
                                                                                                        <div>
                                                                                                            <h5>Service : ${getFullCost(getBookingDetails.serviceCost)} </h5>  
                                                                                                            <h5>Tip : ${getFullCost(getBookingDetails.tip)}</h5>  
                                                                                                            <h5>Platform Fees : ${getFullCost(getBookingDetails.platformFees)}</h5>  
                                                                                                            <p><strong>Total : </strong>${getFullCost(getBookingDetails.payment)}</p>
                                                                                                        </div>
                                                                                                        </Popover>
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                                                                                </OverlayTrigger>
                                                                                            </sup> 
                                                                                            <sup className='payment-icon popover-payment-mobile'>
                                                                                                <OverlayTrigger trigger="click" placement="right" rootClose={true}
                                                                                                    overlay={
                                                                                                        <Popover id={'popover-payment-mobile'} style={{"background":"#222", "color":"#fff"}}>
                                                                                                        <div>
                                                                                                            <h5>Service :<span> ${getFullCost(getBookingDetails.serviceCost)}</span> </h5>  
                                                                                                            <h5>Tip :<span> ${getFullCost(getBookingDetails.tip)}</span></h5>  
                                                                                                            <h5>Platform<br></br> Fees :<span> ${getFullCost(getBookingDetails.platformFees)}</span></h5>  
                                                                                                            <p><strong>Total : </strong>${getFullCost(getBookingDetails.payment)}</p>
                                                                                                        </div>
                                                                                                        </Popover>
                                                                                                    }
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                                                                                </OverlayTrigger>
                                                                                            </sup>
                                                                                        </p>
                                                                                    </div>
                                                                                </Stack>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="newBookingData">
                                                                            <h2>New Booking Details</h2>
                                                                            <Col md={4}>
                                                                                <Form.Group className="md-3" controlId="formBasicEmail">
                                                                                    <Form.Label className="noMargin">Service Name</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" defaultValue={ productData.length > 0 ? productData[0].subServiceName : ''} disabled/>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={4}>
                                                                                <Form.Group className="md-3" controlId="formBasicEmail">
                                                                                    <Form.Label className="noMargin">Booking Date</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" defaultValue={moment(selectedDate).format('MM-DD-YYYY')} disabled/>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col md={4}>
                                                                                <Form.Group className="md-3" controlId="formBasicEmail">
                                                                                    <Form.Label className="noMargin">Price</Form.Label>
                                                                                    <Form.Control type="text" placeholder="" defaultValue={productData.length > 0 ? '$'+ getFullCost(productData[0].price) : ''} disabled/>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row >
                                                                            <Col>
                                                                                <Form.Label>Available Slots</Form.Label>
                                                                                <div className={ slotList.length > 4 ? "newSlot radio day-bookingslots checkSlot" : "newSlot radio day-bookingslots "}>
                                                                                    {
                                                                                        slotList.length > 0 ? 
                                                                                            <>
                                                                                                {
                                                                                                    morningStatus ? 
                                                                                                        <div>
                                                                                                            <h5>Morning</h5>
                                                                                                            {
                                                                                                                slotList.map((data, index) => {
                                                                                                                    let openTime = moment(data.startDate).format('hh:mm A');
                                                                                                                    let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                                                    let openFull = openTime.split("");
                                                                                                                    if(openFull[4] == 6 || openFull[4] == 1){
                                                                                                                    openFull[4] = openFull[4] - 1;
                                                                                                                    }
                                                                                                                    let updatedOpenTime = openFull.join('');
                                                                                                                    if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                                        if(data.breakTime === true){

                                                                                                                        }else{
                                                                                                                            let selectDateTimeForCheck = selectedDate+'T12:01';
                                                                                                                            if(data.startDate < selectDateTimeForCheck){
                                                                                                                                if(slotCount < 1){
                                                                                                                                    setSlotCount(slotCount + 1);
                                                                                                                                }
                                                                                                                                if(data.isBreak){
                                                                                                                                    return(
                                                                                                                                        <OverlayTrigger
                                                                                                                                            placement="bottom"
                                                                                                                                            overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                                        >
                                                                                                                                            <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked || data.isBreak === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                        </OverlayTrigger>
                                                                                                                                        
                                                                                                                                    )
                                                                                                                                }else{
                                                                                                                                    return(
                                                                                                                                        <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? updatedOpenTime+' - '+closeTime :updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                        ''
                                                                                                }
                                                                                                
                                                                                                {
                                                                                                    afternoonStatus ? 
                                                                                                        <div>
                                                                                                            <h5>Afternoon</h5>
                                                                                                            {
                                                                                                                slotList.map((data, index) => {
                                                                                                                    let openTime = moment(data.startDate).format('hh:mm A');
                                                                                                                    let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                                                    let openFull = openTime.split("");
                                                                                                                    if(openFull[4] == 6 || openFull[4] == 1){
                                                                                                                    openFull[4] = openFull[4] - 1;
                                                                                                                    }
                                                                                                                    let updatedOpenTime = openFull.join('');
                                                                                                                    if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                                        if(data.breakTime === true){

                                                                                                                        }else{
                                                                                                                            let selectDateStartTimeForCheck = selectedDate+'T12:01';
                                                                                                                            let selectDateEndTimeForCheck = selectedDate+'T17:01';
                                                                                                                            if(data.startDate >= selectDateStartTimeForCheck && data.startDate < selectDateEndTimeForCheck){
                                                                                                                                if(slotCount < 1){
                                                                                                                                    setSlotCount(slotCount + 1);
                                                                                                                                }
                                                                                                                                if(data.isBreak){
                                                                                                                                    return(
                                                                                                                                        <OverlayTrigger
                                                                                                                                            placement="bottom"
                                                                                                                                            overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                                        >
                                                                                                                                            <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked || data.isBreak === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                        </OverlayTrigger>
                                                                                                                                        
                                                                                                                                    )
                                                                                                                                }else{
                                                                                                                                    return(
                                                                                                                                        <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? updatedOpenTime+' - '+closeTime :updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                        ''
                                                                                                }
                                                                                                
                                                                                                {
                                                                                                    eveningStatus ? 
                                                                                                        <div>
                                                                                                            <h5>Evening</h5>
                                                                                                            {
                                                                                                                slotList.map((data, index) => {
                                                                                                                    let openTime = moment(data.startDate).format('hh:mm A');
                                                                                                                    let closeTime = moment(data.endDate).format('hh:mm A');
                                                                                                                    let openFull = openTime.split("");
                                                                                                                    if(openFull[4] == 6 || openFull[4] == 1){
                                                                                                                    openFull[4] = openFull[4] - 1;
                                                                                                                    }
                                                                                                                    let updatedOpenTime = openFull.join('');
                                                                                                                    if(openTime !== 'Invalid Date' || closeTime !== 'Invalid Date'){
                                                                                                                        if(data.breakTime === true){

                                                                                                                        }else{
                                                                                                                            let selectDateTimeForCheck = selectedDate+'T17:01';
                                                                                                                            if(data.startDate >= selectDateTimeForCheck){
                                                                                                                                if(slotCount < 1){
                                                                                                                                    setSlotCount(slotCount + 1);
                                                                                                                                }
                                                                                                                                if(data.isBreak){
                                                                                                                                    return(
                                                                                                                                        <OverlayTrigger
                                                                                                                                            placement="bottom"
                                                                                                                                            overlay={<Tooltip id="button-tooltip-2">{updatedOpenTime+' - '+closeTime}</Tooltip>}
                                                                                                                                        >
                                                                                                                                            <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? 'Break' : updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked || data.isBreak === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                        </OverlayTrigger>
                                                                                                                                        
                                                                                                                                    )
                                                                                                                                }else{
                                                                                                                                    return(
                                                                                                                                        <input type="checkbox" className={data.isBooked === true ? 'bookedslot' : data.isSelected ? 'selectedslots' : data.isBreak ? 'bookedslot' : 'slot'} name="slotId" value={data} openTime={data.startDate} closeTime={data.endDate} label={data.isBreak ? updatedOpenTime+' - '+closeTime :updatedOpenTime} onChange={() => bookingHandleChange(data, index)} disabled={data.isBooked === true ? 'disabled' : ''}  checked={data.isSelected === true ? 'checked' : ''}/>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    :
                                                                                                        ''
                                                                                                }
                                                                                            </>
                                                                                        :
                                                                                            <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    slotCount == 0 && slotList.length > 0 ?
                                                                                        <p class="alert alert-warning" role="alert">No appointment slots available</p>
                                                                                    :''
                                                                                }
                                                                                <p className="error">{FormErrors.slot}</p>
                                                                            </Col>
                                                                        </Row>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        {
                                                                            updateLoader == true ?
                                                                                <div style={{textAlign:'center'}}>
                                                                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                                                                </div>
                                                                            :
                                                                                <Button variant="primary" type='submit'>Update Booking</Button>
                                                                        }
                                                                        <Button variant="secondary" onClick={handleClose} className="paymentCancelBtn">Cancel</Button>
                                                                    </Modal.Footer>
                                                                    </>
                                                                }
                                                            </Form>
                                                        </Modal>
                                                    </div>
                                                    </div>   
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        <Row>
                                            <Col>
                                                <div className="oldBookingData">
                                                    <p style={{marginBottom : '0', fontWeight : 'bold', textAlign : 'center'}}>Service not exists</p>
                                                </div>
                                            </Col>
                                        </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
                    
    )
}