import { React, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { AES, enc, mode, pad } from 'crypto-js';
import moment from 'moment';

export default function CryptoJSForJson() {
    const [secretKey, setSecretKey] = useState('abcdefghijklmnop');
    const [jsonInput, setJsonInput] = useState('');
    const [encriptValue, setEncryptValue] = useState("");
    const [decryptStatus, setDecryptStatus] = useState(false);
    const [decryptValue, setDecryptValue] = useState('');
    const [jsonError, setJsonError] = useState('');

    const keyHandleChange = (e) => {
        setSecretKey(e.target.value);
    }

    const jsonHandleChange = (e) => {
        setJsonInput(e.target.value)
    }

    const encryptHandleChange = (e) => {
        setEncryptValue(e.target.value)
    }

    const encryptJson = () => {
        
        if (!jsonInput) {
            setJsonError('Please enter some values');
        } else {
            setJsonError('');
            let encryptValue = JSON.stringify(jsonInput);
            let utcDate = moment.utc().format('DDMM');
            let abc = process.env.REACT_APP_JSON_KEY+''+utcDate;
            let key = enc.Utf8.parse(abc);
            let encryptData = AES.encrypt(jsonInput, key, {mode: mode.ECB, padding: pad.Pkcs7});
            setEncryptValue(encryptData.toString());
        } 
    }

    const decryptJson = () => {
        setDecryptStatus(true);

        try {
            let utcDate = moment.utc().format('DDMM');
            let abc = process.env.REACT_APP_JSON_KEY+''+utcDate;
            let key = enc.Utf8.parse(abc);
            let bytes = AES.decrypt(encriptValue, key, {mode: mode.ECB, padding: pad.Pkcs7});
            const decrypted = bytes.toString(enc.Utf8);
            
            setDecryptValue(decrypted);
        } catch (err) {
            alert('UNABLE TO DECIPHER', err);
        }
    }

    return(
        <section style={{background : '#d2d2d2'}}>
            <Container>
                <h1>Json Converter</h1>
                <Row style={{width : '50%', margin : '0 auto'}}>
                    <Col>
                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Secret Key <span>*</span></Form.Label>
                            <Form.Control type="text" onChange={(e) => keyHandleChange(e)} defaultValue={secretKey}/>
                        </Form.Group> */}
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Json <span>*</span></Form.Label>
                            <Form.Control as="textarea" rows={10} onChange={(e) => jsonHandleChange(e)}/>
                            <p className='error'>{jsonError}</p>
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                            <Form.Label>Encryption Json</Form.Label>
                            <Form.Control as="textarea" rows={10} onChange={(e) => encryptHandleChange(e)} value={encriptValue}/>
                        </Form.Group>
                        <Button variant="primary" onClick={() => encryptJson()}>Encrypt</Button>
                        {
                            encriptValue !== '' ?
                                <Button variant="secondary" style={{marginLeft : '16px'}} onClick={() => decryptJson()}>Decrypt</Button>
                            :
                                <></>
                        }
                        
                        {
                            decryptStatus ?
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Decrypt Json</Form.Label>
                                    <Form.Control as="textarea" rows={10} disabled value={decryptValue}/>
                                </Form.Group>
                            :
                                <></>
                        }
                    </Col>
                    
                </Row>
            </Container>
        </section>
    )
}