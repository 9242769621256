import { React, useState } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { stylistChangePassword } from '../../../../Service';
import Swal from "sweetalert2";
import { InfinitySpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { HtmlTagValidation, BlockSQLInjection } from '../../../Reusable';
import { JsonEncode } from '../../../Reusable/JsonModify';

export default function PayoutAccount() {
    // get customer data from store
    const userData =  useSelector(store => store.user['stylistuser']);

    const passwordInitialValues = {currentPassword : '', newPassword : '', confirmPassword : ""};

    const [passEye, setPassEye] = useState(false);
    const [confrimPassEye, setConfrimPassEye] = useState(false);
    const [passwordFormValues, setPasswordFormValues] = useState(passwordInitialValues);
    const [passwordFormErrors, setPasswordFormErrors] = useState([]);
    const [passwordLoader, setPasswordLoader] = useState(false);

    const passwordHandleChange = (e) => {
        const value=e.target.value;
        const field=e.target.name;
        setPasswordFormValues(prevState => ({
            ...prevState,
            [field]:  value  
        }));
    }

    const passwordHandleSubmit = async (e) => {
        e.preventDefault();
        const errorCount = passwordValidate(passwordFormValues);
        setPasswordFormErrors(errorCount);
        if(errorCount.status){
            passwordFormValues['email']=userData.emailAddress;

            let jsonData = await JsonEncode(passwordFormValues).then(result => {
                return result
            }).catch(error => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: error,
                    timer: 3000
                }); 
            });
            
            if ( jsonData ) {
                let loginPayload = {data : jsonData};
                stylistChangePassword(loginPayload).then(res => passwordHandleResponse(res));
                setPasswordLoader(true);
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Oops...',
                    showConfirmButton: false,
                    text: 'Something went wrong, Please try after sometimes',
                    timer: 3000
                }); 
            }
            
        }
    } 

    const passwordHandleResponse = (response) => {
        if(response.data.statusCode === 200){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: response.data.statusMessage,
                showConfirmButton: false,
                timer: 3000
            });
            setPasswordLoader(false);
            setPasswordFormValues(passwordInitialValues);
        }else{
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Oops...',
                showConfirmButton: false,
                text: 'Invalid current password',
                timer: 3000
            }); 
            setPasswordLoader(false);
        }
    }

    const passwordValidate = (values) => {
        const errors = {};
        errors.status = true;
        const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_-]).{8,}/;

        if(!values.currentPassword){
            errors.currentPassword = 'Current password is required';
            errors.status = false;
        }

        if(!values.newPassword){
            errors.newPassword = 'New password is required';
            errors.status = false;
        }else if(values.newPassword && !passRegex.test(values.newPassword)){
            errors.newPassword = 'Password should contain atleast a Uppercase, a Lowercase, a Number and a Special character';
            errors.status = false;
        }

        if(!values.confirmPassword){
            errors.confirmPassword = 'Confirm password is required';
            errors.status = false;
        }

        if(values.newPassword && values.confirmPassword && values.newPassword === values.currentPassword ){
            errors.checkPassword = 'New password and Current Password should not be same';
            errors.status = false;
        }

        if(values.newPassword && values.confirmPassword && values.newPassword !== values.confirmPassword){
            errors.confirmPassword = 'New password & confirm password doesnot match';
            errors.status = false;
        }

        const validations = {
            currentPassword: HtmlTagValidation(values.currentPassword),
            newPassword: HtmlTagValidation(values.newPassword),
            confirmPassword : HtmlTagValidation(values.confirmPassword)
        };

        Object.entries(validations).forEach(([key, value]) => {
            if (!value) {
                errors[key] = 'Invalid input';
                errors.status = false;
            }
        });

        return errors;
    }
    
    const passView = () => {
        setPassEye(prevState => !prevState);
    }

    const confrimPassView = () => {
        setConfrimPassEye(prevState => !prevState);
    }

    return(
        <>
            <Row>
                <Col>
                    <h4 className='mb-3'>Change Password</h4>
                    <p className='grey-txt'>Change your account password</p>
                    <hr />
                    <Form onSubmit={passwordHandleSubmit} >
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3 passwordRow" controlId="formGridCurrent">
                                <FontAwesomeIcon icon={passEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={passView}/>
                                <Form.Label>Current Password <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type={passEye === false ? 'password' : 'text'} name='currentPassword' onChange={passwordHandleChange} value={passwordFormValues.currentPassword}/>
                                <p className="error">{passwordFormErrors.currentPassword}</p>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3 passwordRow" controlId="formGridNewPwd">
                                <FontAwesomeIcon icon={confrimPassEye === false ? faEye : faEyeSlash} className='passwordEye' onClick={confrimPassView}/>
                                <Form.Label>New Password <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type={confrimPassEye === false ? 'password' : 'text'} name='newPassword' onChange={passwordHandleChange}  value={passwordFormValues.newPassword}/>
                                <p className="error">{passwordFormErrors.newPassword}</p>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="formGridCfmPwd">
                                <Form.Label>Confirm New Password <span>*</span></Form.Label>
                                <Form.Control onKeyDown={BlockSQLInjection} type='text' name='confirmPassword' onChange={passwordHandleChange}  value={passwordFormValues.confirmPassword}/>
                                <p className="error">{passwordFormErrors.confirmPassword}</p>
                            </Form.Group>
                            <p className="error">{passwordFormErrors.checkPassword}</p>
                        </Row>
                        <div style={{textAlign : 'center'}}>
                            {
                                passwordLoader === true ?
                                    <InfinitySpin height="80" width="200" radius="9" color="#D49D29" ariaLabel="loading" wrapperStyle wrapperClass />
                                :
                                    <Button variant='dark' className='upload-btn' type='submit' >Save</Button>
                            }
                        </div>
                    </Form>
                </Col>
            </Row>
        </>
    )
}