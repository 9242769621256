//import { React, useState } from "react";
import * as React from "react";
import { Col, Container, Image, Row, Stack, Button, OverlayTrigger, Popover } from "react-bootstrap";
import userImage from "../../../images/booking-profile.png";
import Topbar from "../Assets/Topbar";
import LeftSidebar from "../Assets/LeftSidebar";
import { useSelector } from "react-redux";
import moment from "moment";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AdminBookingDetails() {
    const navigate = useNavigate();

    const getAdminBookingDetails = useSelector((store) => store.user.adminBookingDetails);

    const bookingDate = moment(new Date(getAdminBookingDetails.startDate)).format("MM-DD-YYYY");
    const bookingStartTIme = moment(new Date(getAdminBookingDetails.startDate)).format("LT");
    const bookingEndTIme = moment(new Date(getAdminBookingDetails.endDate)).format("LT");

    const handlePrevPage = () => {
        navigate(-1);
    };

    // get decimal two degit cost
    const getFullCost = (dollar) => {
        let roundCost = (Math.round(dollar * 100) / 100).toFixed(2);
        return roundCost;
    }

    return (
        <section className="dashboard">
            <Container>
                <Row className="sideBarRow">
                    <Col md={3} className="sideBarCol">
                        <LeftSidebar />
                    </Col>
                    <Col md={9}>
                        <div className="rightsidebar">
                            <Topbar />
                            <Row className="mb-3 main-title">
                                <Col>
                                    <hr />
                                    <Stack direction="horizontal" className="stackInside">
                                        <div>
                                            <h1>Booking Details</h1>
                                        </div>
                                        <div className="ms-auto">
                                            <Button variant="outline-secondary" onClick={handlePrevPage}>Back</Button>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>

                            <Row className="justify-content-md-center text-center">
                                <div className="booking-details">
                                    <Col md={12}>
                                        <div className="profile">
                                            <Image src={userImage} />{" "}
                                        </div>
                                        <h2>{getAdminBookingDetails.text}</h2>
                                        <Row className="text-start">
                                            <Col>
                                                <h4>Professional Name</h4>
                                            </Col>
                                            <Col>
                                                <p>{getAdminBookingDetails.clientName}</p>
                                            </Col>
                                        </Row>
                                        {
                                            getAdminBookingDetails.text !== 'Self Booking' ?
                                              <>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Business Name</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{getAdminBookingDetails.shopName}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Service Offered</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{getAdminBookingDetails.serviceName}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Date</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{bookingDate}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Time</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{bookingStartTIme + " " + "-" + " " + bookingEndTIme}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Service Charge fee</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>
                                                            ${getFullCost(getAdminBookingDetails.payment)}
                                                            <sup className="payment-icon">
                                                              <OverlayTrigger
                                                                trigger="click"
                                                                placement="left"
                                                                rootClose={true}
                                                                overlay={
                                                                  <Popover
                                                                    id={"popover-payment"}
                                                                    style={{ background: "#222", color: "#fff" }}
                                                                  >
                                                                    <div>
                                                                      <h5>
                                                                        Service : $
                                                                        {getFullCost(getAdminBookingDetails.serviceCost)}{" "}
                                                                      </h5>
                                                                      <h5>
                                                                        Tip : ${getFullCost(getAdminBookingDetails.tips)}
                                                                      </h5>
                                                                      <h5>
                                                                        Platform Fees : $
                                                                        {getFullCost(getAdminBookingDetails.platformFee)}
                                                                      </h5>
                                                                      <p>
                                                                        <strong>Total : </strong>$
                                                                        {getFullCost(getAdminBookingDetails.payment)}
                                                                      </p>
                                                                    </div>
                                                                  </Popover>
                                                                }
                                                              >
                                                                <FontAwesomeIcon icon={faCircleExclamation} />
                                                              </OverlayTrigger>
                                                            </sup>
                                                          </p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Payment Type</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{getAdminBookingDetails.paymentType}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Status</h4>
                                                      </Col>
                                                      <Col>
                                                          <p className="" style={{ float: "right" }}>
                                                              {getAdminBookingDetails.status === 'Booked' ? (getAdminBookingDetails.bookingType == 'CL' || getAdminBookingDetails.bookingType == 'CLEB') && getAdminBookingDetails.isCustomerRespondent === false ? 'Verification Pending' : getAdminBookingDetails.status : getAdminBookingDetails.status}
                                                          </p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Booked / Edited By</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>
                                                            {getAdminBookingDetails.bookingType == 'CS' ? 'Customer' : (getAdminBookingDetails.bookingType == 'CL' || getAdminBookingDetails.bookingType == 'CLEB') ? 'Professional' : 'Self Booking'}
                                                          </p>
                                                      </Col>
                                                  </Row>
                                              </>
                                            :
                                              <>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Date</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>{bookingDate}</p>
                                                      </Col>
                                                  </Row>
                                                  <Row className="text-start">
                                                      <Col>
                                                          <h4>Time</h4>
                                                      </Col>
                                                      <Col>
                                                          <p>
                                                            {bookingStartTIme + " " + "-" + " " + bookingEndTIme}
                                                          </p>
                                                      </Col>
                                                  </Row>
                                              </>
                                        }
                                    </Col>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
